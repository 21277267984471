import i18n from '@/i18n'
import store from '@/store'
import { NOTIFICATION_TYPE_ERROR } from '@/utils/constants'

const mixin = {
  beforeRouteEnter(to, from, next) {
    let teamId

    const param = to.params.teamId
    const query = to.query.team_id

    if (param) {
      teamId = parseInt(param)
    } else if (query) {
      teamId = parseInt(query)
    } else {
      next({ name: 'TeamOverview' })
    }

    const team = store.getters.getTeamById(teamId)
    if (team) return next()

    store
      .dispatch('loadTeams')
      .then(() => {
        if (store.getters.getTeamById(teamId)) {
          next()
        } else {
          store.dispatch('addNotification', {
            text: i18n.t('errors.teams.404'),
            type: NOTIFICATION_TYPE_ERROR
          })
          next({ name: 'TeamOverview' })
        }
      })
      .catch(() => {
        next({ name: 'TeamOverview' })
      })
  },
  computed: {
    team() {
      const { params, query } = this.$route
      const teamId = parseInt(params.teamId || query.teamid)
      return this.$store.getters.getTeamById(teamId)
    }
  }
}

export default mixin
