<template>
  <div class="code-input">
    <input
      v-for="index in 6"
      type="number"
      :key="index"
      class="code-input__input"
      :class="{ 'code-input__input--invalid': isError, 'ml-3': index === 4 }"
      :data-cy="'code-input-' + index"
      v-model="otps[index - 1]"
      :ref="'input' + index"
      @keyup.right="focusInput(index + 1)"
      @keyup.left="focusInput(index - 1)"
      @keyup.delete="focusInput(index - 1)"
      :disabled="isDisabled"
      @paste="onPaste"
      @input="onInput($event, index + 1)"
      :autofocus="index === 1"
      min="0"
      max="9"
    />
  </div>
</template>

<script>
export default {
  name: 'OTPInput',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isError: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      otps: this.value.split('')
    }
  },
  watch: {
    value(value) {
      if (value) return

      this.otps = []
      this.focusNext()
    },
    otps() {
      if (!this.otps.length) return

      this.$emit('change', this.otps.join(''))
    }
  },
  methods: {
    focusInput(index) {
      const ref = this.$refs[`input${index}`]
      if (!ref) return

      ref[0].focus()
      ref[0].select()
    },
    focusNext() {
      this.focusInput(this.otps.length + 1)
    },
    onInput(event, next) {
      if (event.inputType === 'deleteContentBackward') return false
      this.focusInput(next)
      event.preventDefault()
    },
    onPaste(event) {
      // Getting copy text
      const clipboardData =
        event.clipboardData || window.clipboardData || event.originalEvent.clipboardData

      const pastedData = clipboardData.getData('Text').replace(/[^0-9.]/g, '')

      const arrayOfNumbers = pastedData.split('')
      // set the length to 6
      if (arrayOfNumbers.length > 6) arrayOfNumbers.slice(0, 6)
      this.otps = arrayOfNumbers

      this.focusNext()
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss">
.code-input {
  text-align: center;
  margin: 0 -5px;

  &__input {
    width: 40px;
    height: 50px;
    margin: 0 5px;
    text-align: cente;
    font-size: 20px;
    appearance: textfield;
    border-radius: 10px;
    background-color: $gray-200;
    border: 0;
    text-align: center;
    outline: 0;

    &:focus {
      box-shadow: 0 0 0 1px $gray-500;
    }

    @include sm-up {
      width: 45px;
      height: 70px;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
