import Vue from 'vue'

import {
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT,
  DECISION_UNKNOWN,
  REQUEST_STATE_LOADING,
  REQUEST_STATE_SUCCESS
} from '@/utils/constants'

import keyBy from 'lodash.keyby'
import pickBy from 'lodash.pickby'
import { getDefaultState } from './default'

export const EVENTS_RESET = 'EVENTS_RESET'
export const EVENTS_LOAD_REQUEST = 'EVENTS_LOAD_REQUEST'
export const EVENTS_LOAD_SUCCESS = 'EVENTS_LOAD_SUCCESS'
export const EVENTS_LOAD_ERROR = 'EVENTS_LOAD_ERROR'

export const EVENT_ADD = 'EVENT_ADD'
export const EVENT_EDIT = 'EVENT_EDIT'

export const EVENTS_SET_DECISION = 'EVENTS_SET_DECISION'
export const EVENTS_DELETE_BY_TEAM = 'EVENTS_DELETE_BY_TEAM'
export const EVENTS_INVITEE_DELETE = 'EVENTS_INVITEE_DELETE'
export const SET_TEAM_FILTER = 'SET_TEAM_FILTER'
export const SET_SHOW_HISTORY = 'SET_SHOW_HISTORY'

export const EVENT_INVITATION_SET = 'EVENT_INVITATION_SET'
export const EVENT_INVITATION_RESET = 'EVENT_INVITATION_RESET'

export default {
  [EVENTS_RESET]: (state) => {
    Object.assign(state, getDefaultState())
  },
  [EVENTS_LOAD_REQUEST]: (state) => {
    state.error = null
    state.status = REQUEST_STATE_LOADING
  },
  [EVENTS_LOAD_SUCCESS]: (state, { events, count, reset }) => {
    const eventsObj = keyBy(events, 'id')

    state.status = REQUEST_STATE_SUCCESS
    state.error = null
    state.events = reset ? eventsObj : { ...state.events, ...eventsObj }
    state.count = count
  },
  [EVENTS_LOAD_ERROR]: (state, error) => {
    state.status = 'error'
    state.error = error
  },
  [EVENTS_SET_DECISION]: (state, { eventId, invitee }) => {
    state.error = null

    const { user, comment, decision } = invitee

    const event = state.events[eventId]
    const currentInvitee = event.invitees.find((invitee) => invitee.user.id === user)

    currentInvitee.comment = comment
    currentInvitee.decision = decision

    event.countAttending = event.invitees.filter((x) => x.decision === DECISION_PRESENT).length
    event.countNotAttending = event.invitees.filter(
      (x) => x.decision === DECISION_NOT_PRESENT
    ).length
    event.countMaybeAttending = event.invitees.filter((x) => x.decision === DECISION_MAYBE).length
    event.countUnknownAttending = event.invitees.filter(
      (x) => x.decision === DECISION_UNKNOWN
    ).length
    event.isFull = event.max_attendees ? event.max_attendees <= event.countAttending : false
  },
  [EVENT_ADD]: (state, { events }) => {
    const eventsObj = keyBy(events, 'id')
    Vue.set(state, 'events', { ...state.events, ...eventsObj })
  },
  [EVENT_EDIT]: (state, { events }) => {
    const eventsObj = keyBy(events, 'id')
    Vue.set(state, 'events', { ...state.events, ...eventsObj })
  },
  [SET_TEAM_FILTER]: (state, teamId) => {
    state.events = {}
    state.teamFilter = teamId
  },
  [SET_SHOW_HISTORY]: (state, showHistory) => {
    state.events = {}
    state.showHistory = showHistory
  },
  [EVENTS_DELETE_BY_TEAM]: (state, teamId) => {
    // remove events from given teamId
    const filteredEvents = pickBy(state.events, (event) => {
      return event.team_id !== teamId
    })
    Vue.set(state, 'events', filteredEvents)
  },
  [EVENTS_INVITEE_DELETE]: (state, { teamId, userId }) => {
    // remove invitee from events from given teamId
    const filteredEvents = keyBy(
      Object.values(state.events).map((event) => {
        if (event.team_id === teamId) {
          event.users = event.users.filter((invitee) => invitee.id !== userId)
        }
        return event
      }),
      'id'
    )
    Vue.set(state, 'events', filteredEvents)
  },
  [EVENT_INVITATION_SET]: (state, invitation) => {
    state.invitation = invitation
  },
  [EVENT_INVITATION_RESET]: (state, invitation) => {
    state.invitation = null
  }
}
