import {
  REQUEST_STATE_ERROR,
  REQUEST_STATE_LOADING,
  REQUEST_STATE_SUCCESS
} from '@/utils/constants'
import keyBy from 'lodash.keyby'
import Vue from 'vue'
import { getDefaultState } from './default'

export const TEAMS_RESET = 'TEAMS_RESET'
export const TEAMS_CREATE = 'TEAMS_CREATE'
export const TEAMS_DELETE = 'TEAMS_DELETE'
export const TEAMS_EDIT = 'TEAMS_EDIT'
export const TEAMS_EDIT_PHOTO = 'TEAMS_EDIT_PHOTO'
export const TEAMS_REMOVE_MEMBER = 'TEAMS_REMOVE_MEMBER'
export const TEAMS_MAKE_MANAGER = 'TEAMS_MAKE_MANAGER'

export const TEAMS_LOAD_REQUEST = 'TEAMS_LOAD_REQUEST'
export const TEAMS_LOAD_SUCCESS = 'TEAMS_LOAD_SUCCESS'
export const TEAMS_LOAD_ERROR = 'TEAMS_LOAD_ERROR'

export const TEAM_INVITATION_SET = 'TEAM_INVITATION_SET'
export const TEAM_INVITATION_RESET = 'TEAM_INVITATION_RESET'

export default {
  [TEAMS_RESET]: (state) => {
    Object.assign(state, getDefaultState())
  },
  [TEAMS_LOAD_REQUEST]: (state) => {
    state.status = REQUEST_STATE_LOADING
  },
  [TEAMS_LOAD_SUCCESS]: (state, teams) => {
    const teamsObj = keyBy(teams, (team) => team.id)

    state.status = REQUEST_STATE_SUCCESS
    state.error = null
    Vue.set(state, 'teams', { ...state.teams, ...teamsObj })
  },
  [TEAMS_LOAD_ERROR]: (state, error) => {
    state.status = REQUEST_STATE_ERROR
    state.error = error
  },
  [TEAMS_CREATE]: (state, newTeam) => {
    const teamsObj = keyBy([newTeam], (team) => team.id)
    Vue.set(state, 'teams', { ...state.teams, ...teamsObj })
  },
  [TEAMS_DELETE]: (state, teamId) => {
    Vue.delete(state.teams, teamId)
  },
  [TEAMS_REMOVE_MEMBER]: (state, { teamId, userId }) => {
    const currentUsers = state.teams[teamId].users
    const filteredUsers = currentUsers.filter((user) => user.id !== userId)

    Vue.set(state.teams[teamId], 'users', filteredUsers)
  },
  [TEAMS_MAKE_MANAGER]: (state, { teamId, userId }) => {
    const member = state.teams[teamId].users.find((member) => member.id === userId)
    member.is_manager = true
  },
  [TEAMS_EDIT]: (state, { teamId, name, type, onlyManagersCanAddEvents }) => {
    const team = state.teams[teamId]

    team.name = name
    team.team_type = type
    team.only_managers_can_add_events = onlyManagersCanAddEvents
  },
  [TEAMS_EDIT_PHOTO]: (state, { teamId, teamPhoto, teamPhotoLarge }) => {
    const team = state.teams[teamId]

    team.team_photo = teamPhoto
    team.team_photo_large = teamPhotoLarge
  },
  [TEAM_INVITATION_SET]: (state, invitation) => {
    state.invitation = invitation
  },
  [TEAM_INVITATION_RESET]: (state) => {
    state.invitation = null
  }
}
