<template>
  <modal @close="$router.push({ name: 'TeamDetails' })" size="xs" class="text-center" v-if="member">
    <transition-fade>
      <div v-if="showDetail" key="main">
        <user-avatar :user="member" :size="200" />
        <dl class="text-left mt-5 mb-0">
          <dt>{{ $t('teams.member_details.name') }}</dt>
          <dd>
            {{ member.first_name }} <em v-if="member.nickname">"{{ member.nickname }}" </em
            >{{ member.last_name }}
          </dd>
          <dt>{{ $t('teams.member_details.email') }}</dt>
          <dd>
            <a
              :href="'mailto:' + member.email"
              :title="member.email"
              class="d-block text-truncate"
              >{{ member.email }}</a
            >
          </dd>
          <dt>{{ $t('teams.member_details.member_since', { team: team.name }) }}</dt>
          <dd>{{ member.joined.format($t('general.date_format.short')) }}</dd>
        </dl>
        <a
          v-if="team.user_is_manager && !member.is_manager"
          class="btn mt-5 btn-block btn-primary"
          href="#"
          @click.prevent="showPromoteConfirm = true"
        >
          <icon :icon="['fal', 'crown']" class="action-list__icon" fixed-width />
          <span>{{ $t('teams.member_details.promote') }}</span>
        </a>
        <a
          class="btn mt-3 btn-block btn-danger"
          v-if="team.user_is_manager && !isCurrentUser(member.id)"
          href="#"
          @click.prevent="showRemoveConfirm = true"
        >
          <icon :icon="['fal', 'times']" class="action-list__icon" fixed-width />
          <span>{{ $t('teams.member_details.remove') }}</span>
        </a>
      </div>
      <div v-else-if="showRemoveConfirm" key="remove">
        <i18n tag="p" path="teams.member_details.remove_confirm.text" class="my-5">
          <template v-slot:name>
            <span>{{ member.first_name }}</span>
          </template>
          <template v-slot:team>
            <strong place="team">{{ team.name }}</strong>
          </template>
        </i18n>
        <button
          type="submit"
          class="btn btn-danger btn-block"
          :disabled="loading"
          @click="leaveTeam"
        >
          {{ $t('teams.member_details.remove_confirm.confirm') }}
        </button>
        <a @click="showRemoveConfirm = false" class="btn-block btn-link mt-4">{{
          $t('teams.member_details.remove_confirm.cancel')
        }}</a>
      </div>
      <div v-else-if="showPromoteConfirm" key="promote">
        <i18n tag="p" path="teams.member_details.promote_confirm.text" class="mt-5 mb-3">
          <template v-slot:name>
            <span>{{ member.first_name }}</span>
          </template>
          <template v-slot:team>
            <strong>{{ team.name }}</strong>
          </template>
        </i18n>
        <p class="text-sm mb-5">
          {{
            $t('teams.member_details.promote_confirm.text2', {
              name: member.first_name,
              team: team.name
            })
          }}
        </p>
        <button type="submit" :disabled="loading" class="btn btn-danger btn-block" @click="promote">
          {{ $t('teams.member_details.promote_confirm.confirm') }}
        </button>
        <a @click="showPromoteConfirm = false" class="btn-block btn-link mt-4">{{
          $t('teams.member_details.promote_confirm.cancel')
        }}</a>
      </div>
    </transition-fade>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import UserAvatar from '@/components/UserAvatar'
import TransitionFade from '@/components/TransitionFade'

export default {
  name: 'TeamMemberDetails',
  components: {
    Modal,
    UserAvatar,
    TransitionFade
  },
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  computed: {
    showDetail() {
      return this.member && !this.showRemoveConfirm && !this.showPromoteConfirm
    }
  },
  data() {
    const { userId } = this.$route.params
    const member = this.team.users.find((user) => user.id === userId)

    if (!userId || !member) {
      this.$router.push({ name: 'TeamDetails' })
    }

    return {
      member,
      loading: false,
      showRemoveConfirm: false,
      showPromoteConfirm: false
    }
  },
  methods: {
    isCurrentUser(userId) {
      return this.$store.getters.userId === userId
    },
    promote() {
      this.loading = true
      this.$store
        .dispatch('makeManager', {
          teamId: this.$route.params.teamId,
          userId: this.member.id
        })
        .then(() => this.$router.replace({ name: 'TeamDetails' }))
        .finally(() => (this.loading = false))
    },
    leaveTeam() {
      this.loading = true
      this.$store
        .dispatch('leaveTeam', {
          teamId: this.$route.params.teamId,
          userId: this.member.id
        })
        .then(() => this.$router.replace({ name: 'TeamDetails' }))
        .finally(() => (this.loading = false))
    }
  }
}
</script>
