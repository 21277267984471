<template>
  <section class="card-body page-subsection" :class="{ 'page-subsection--fluid': fluid }">
    <div class="page-subsection__header" :class="headerClass">
      <h2 class="page-subsection__title h4" v-if="title">{{ title }}</h2>
      <div class="page-subsection__actions d-none d-sm-flex" v-if="actions">
        <div class="page-subsection__action" v-for="(action, index) in actions" :key="index">
          <icon-button @click.native="action.onPress" :icon="action.icon" :label="action.label" />
        </div>
      </div>
    </div>
    <div class="page-subsection__content" :class="contentClass">
      <slot></slot>
    </div>
    <div class="page-subsection__actions d-sm-none" v-if="actions && actions.length">
      <div class="page-subsection__action" v-for="(action, index) in actions" :key="index">
        <icon-button @click.native="action.onPress" :icon="action.icon" :label="action.label" />
      </div>
    </div>
  </section>
</template>

<script>
import IconButton from '@/components/IconButton'

export default {
  name: 'PageSubsection',
  components: {
    IconButton
  },
  props: {
    contentClass: {
      type: String
    },
    fluid: {
      default: false,
      type: Boolean
    },
    title: {
      type: String
    },
    headerClass: String,
    actions: Array
  }
}
</script>

<style lang="scss">
.page-subsection {
  @include xs-only {
    padding: 0 1.25rem !important;
  }

  &__title {
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 2rem; //test

    @include sm-up {
      margin-top: 1rem;
      padding: 0 0 0.5rem 0;
      border-bottom: 1px solid $gray-300;

      .page-subsection--fluid & {
        padding: 0;
        border: 0;
      }
    }
  }

  &__actions {
    @include sm-up {
      display: flex;
      align-items: center;
    }
  }

  &__action {
    padding: 5px 0;
    @include xs-only {
      background-color: $white;
      border-bottom: 1px solid $gray-200;
      margin: 0 -1.25rem;
      padding: 1.25rem;
    }

    @include sm-up {
      & + & {
        margin-left: 2rem;
      }
    }
  }

  &__content {
    padding-top: 1rem;

    @include xs-only {
      margin: 0 -1.25rem;
      padding: 1.25rem;
      background-color: $white;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
    }
  }
}
</style>
