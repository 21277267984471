<template>
  <router-link class="team-card card" :to="{ name: 'TeamDetails', params: { teamId: team.id } }">
    <div class="team-card__header" :style="{ 'background-image': 'url(' + image + ')' }"></div>
    <div class="team-card__body">
      <h4 class="team-card__title" data-cy="team-title">{{ team.name }}</h4>
      <p class="m-0 text-muted">
        {{ $tc('teams.details.members', team.users.length, { count: team.users.length }) }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'TeamCard',
  computed: {
    image() {
      const { team_photo } = this.team
      if (!team_photo) return require('@/assets/generic-background.png')

      return team_photo
    }
  },
  props: {
    team: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.team-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: opacity 0.15s;

  &:hover {
    opacity: 0.8;
  }

  &__title {
    color: $gray-800;

    small {
      font-size: 70%;
    }
  }

  &__view-details {
    font-weight: $font-weight-semi-bold;
    color: $gray-500;

    @include hover-focus {
      text-decoration: none;
      color: $gray-600;
    }
  }

  &__header {
    padding-bottom: percentage(9/16);
    background-color: $primary;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__body {
    flex: 1;
    padding: 1.5rem;
  }
}
</style>
