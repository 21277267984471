import { SET_WIZARD_VALUES, WIZARDS_RESET } from './mutations'

export default {
  setWizardValues: ({ commit }, values) => {
    commit(SET_WIZARD_VALUES, values)
  },
  resetWizard: ({ commit }) => {
    commit(WIZARDS_RESET)
  }
}
