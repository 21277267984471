export default {
  en: {
    title: 'About Erbij',
    founders: {
      title: 'Founders',
      michael:
        'Responsible for both the front and back end of the website and making sure everything is always up and running. Prefers working at a hipster coffee bar, in the train or at home on his ironing board.',
      michiel:
        "Focuses on the design, the Erbij app and helps develop the front end of the website. Loves shapes, sizes and colors, even though he's color blind."
    },
    donate: {
      title: 'Buy us a cup of coffee',
      text: 'Michiel en Michael put a lot of effort and energy into keeping Erbij up and running. In order for us to keep going, we could use a cup of coffee every now and then. Hit the button below if you would like to support us.',
      cta: 'Buy us a cup of coffee'
    },
    donation_success: {
      title: 'Thanks a million! ☕',
      text: 'Thank you very much for your donation. We can sure use the coffee...'
    },
    donation_cancelled: {
      title: 'Donating cancelled',
      text: 'Too bad. We could really use a cup of coffee. Please try again in case something went wrong.',
      try_again: 'Buy us a cup of coffee'
    },
    social: {
      title: "Don't be a stranger",
      text: "We wouldn't be a hip startup without social media. So follow us on {instagram}, tag us on {twitter}, like us on {facebook}. You know the drill."
    }
  },
  nl: {
    title: 'Over Erbij',
    founders: {
      title: 'De oprichters',
      michael:
        'Verantwoordelijk voor zowel de voor- als de achterkant van de website en zorgt ervoor dat alles te allen tijde in de lucht is. Werkt het liefst in een hipster koffiebar, in de trein of thuis op zijn strijkplank.',
      michiel:
        'Richt zicht zich op het ontwerp, de Erbij-app en de voorkant van de website. Dol op vormpjes en kleurtjes, ook al is-ie kleurenblind.'
    },
    donate: {
      title: 'Bakkie doen?',
      text: 'Michiel en Michael steken veel tijd en energie om Erbij draaiende te houden. Om dit vol te houden kunnen we af en toe wel een kopje koffie gebruiken. Via onderstaande knop kan je ons helpen.',
      cta: 'Trakteer ons op een bakkie'
    },
    donation_success: {
      title: 'Superdankjewel! ☕',
      text: 'Hartelijk dank voor je donatie. We kunnen de koffie goed gebruiken...'
    },
    donation_cancelled: {
      title: 'Trakteren mislukt',
      text: 'Jammer. We hadden best trek in een bakkie. Ging er iets verkeerd? Probeer het dan opnieuw.',
      try_again: 'Trakteer ons op een bakkie'
    },
    social: {
      title: 'Wees geen vreemde',
      text: 'We zouden geen hippe startup zijn zonder sociale mediakanalen. Dus volg ons op {instagram}, tag ons op {twitter}, like ons op {facebook}. Je kent het wel.'
    }
  }
}
