<template>
  <wizard @close="$router.push({ name: 'Home' })">
    <div class="wizard__section text-center">
      <img
        src="@/assets/gifs/close/close1.gif"
        class="rounded-circle d-block m-auto"
        height="200"
        width="200"
      />
      <h2 class="mt-5 mb-3">{{ $t('events.invitation_success.lead') }}</h2>
      <i18n path="events.invitation_success.result" tag="p">
        <template v-slot:email>
          <strong>{{ $route.query.email }}</strong>
        </template>
      </i18n>
      <p class="mt-5">
        <strong>{{ $t('events.invitation_success.heads_up') }}</strong
        ><br />{{ $t('events.invitation_success.instructions') }}
      </p>
    </div>
  </wizard>
</template>

<script>
import Wizard from '@/components/Wizard'

export default {
  name: 'EventInvitationSuccess',
  components: {
    Wizard
  }
}
</script>
