<template>
  <div class="notifications">
    <notification
      v-for="notification in notifications"
      v-bind="notification"
      :key="notification.uuid"
      @remove="removeItem(notification.uuid)"
    />
  </div>
</template>

<script>
import Notification from './Notification'
export default {
  name: 'Notifications',
  components: { Notification },
  methods: {
    removeItem(uuid) {
      this.$store.dispatch('removeNotification', { uuid })
    }
  },
  computed: {
    notifications() {
      return this.$store.getters.notifications
    }
  }
}
</script>

<style lang="scss">
.notifications {
  position: fixed;
  left: 10px;
  right: 10px;
  z-index: 1050;
  top: $mobile-header-height + 2px;

  @include media-breakpoint-up(lg) {
    top: auto;
    left: auto;
    bottom: 1rem;
    right: 1rem;
  }
}
</style>
