<template>
  <form
    class="wizard__screen wizard__screen--has-actions wizard__what"
    action="#"
    @submit.prevent="onSubmit"
  >
    <h2 class="h4 wizard__subtitle">{{ $t('event_wizard.what.title') }}</h2>
    <div class="wizard__section">
      <div class="row">
        <div class="col-sm-6">
          <b-form-group
            :label="$t('event_wizard.what.name')"
            label-for="inputName"
            label-class="form-label"
            class="mb-4"
          >
            <b-form-input
              v-model="form.name"
              :placeholder="$t('event_wizard.what.name_placeholder')"
              id="inputName"
              ref="inputName"
              tabindex="1"
              :maxlength="TITLE_MAX_LENGTH"
              :state="$v.form.name.$dirty ? !$v.form.name.$invalid : null"
            />
            <b-form-invalid-feedback id="inputNameFeedback">{{
              $t('errors.validations.required')
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
      </div>
      <b-form-group
        :label="$t('event_wizard.what.description')"
        label-for="inputDescription"
        label-class="form-label"
      >
        <b-form-textarea
          v-model="form.description"
          :placeholder="randomDescriptionPlaceholder"
          id="inputDescription"
          :rows="5"
          tabindex="3"
        />
      </b-form-group>
    </div>
    <div class="wizard__footer">
      <div class="wizard__actions" v-if="eventId">
        <button class="btn btn-responsive btn-outline" type="button" @click="onCancelPress">
          <icon class="btn__icon--left" :icon="['fal', 'times']" />{{
            $t('general.buttons.cancel')
          }}
        </button>
        <button class="btn btn-responsive btn-primary" tabindex="4" type="submit">
          {{ $t('general.buttons.accept_changes')
          }}<icon :icon="['fal', 'check']" class="btn__icon--right" />
        </button>
      </div>
      <div class="wizard__actions" v-else-if="$route.params.fromOverview">
        <button class="btn btn-responsive btn-outline" type="button" @click="onCancelPress">
          <icon class="btn__icon--left" :icon="['fal', 'times']" />{{
            $t('general.buttons.cancel')
          }}
        </button>
        <button class="btn btn-responsive btn-secondary" tabindex="4" type="submit">
          {{ $t('general.buttons.continue')
          }}<icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
      <div class="wizard__actions" v-else>
        <button class="btn btn-outline" type="button" @click="onPrevPress">
          <icon class="btn__icon--left" :icon="['fal', 'arrow-left']" />{{
            $t('general.buttons.back')
          }}
        </button>
        <button class="btn btn-secondary" tabindex="4" type="submit">
          {{ $t('general.buttons.next')
          }}<icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

const TITLE_MAX_LENGTH = 30

export default {
  name: 'EventWizardWhat',
  props: {
    eventId: {
      type: Number,
      required: false
    }
  },
  created() {
    const team = this.$store.getters.getWizardValue('team')

    if (team === undefined) {
      this.$router.replace({ name: 'EventAdd' })
    }
  },
  data() {
    return {
      TITLE_MAX_LENGTH,
      form: {
        name: this.$store.getters.getWizardValue('name'),
        description: this.$store.getters.getWizardValue('description')
      }
    }
  },
  computed: {
    randomDescriptionPlaceholder() {
      const { locale } = this.$i18n
      const options =
        locale === 'nl'
          ? [
              'Iedereen graag eigen chips meenemen',
              'Zorg voor eigen drank 🍻',
              'Je wordt thuisgebracht incl. snoepzakje',
              'Neem je zwemspullen mee',
              'Ik neem de BBQ mee! 🔥',
              'Cadeautip: washandjes',
              'Dresscode: vierkant',
              'Na afloop zal er een kussengevecht plaatsvinden',
              'Het thema is ‘Begin 15e-eeuwse renaissance’ 🧐',
              'Er zullen diverse lekkernijen op cocktailprikkers geserveerd worden',
              'Wie is er aan de beurt om oma in de gaten te houden? 👵',
              'Linda, neem jij weer een bal mee? ⚽️'
            ]
          : [
              'There will be pizza! 🍕',
              "+1's are welcome! 😎",
              "I'll bring the BBQ, you bring the booze!",
              'Dress code: square',
              'There will be a pillow fight afterwards',
              'There are vegan options this time 🐮👍',
              'Whose turn is it to watch grandma this time? 👵',
              'Kristy, can you bring a ball again? ⚽️'
            ]
      const randomNumber = Math.round(Math.random() * (options.length - 1))

      return this.$i18n.t('general.for_example') + ' ' + options[randomNumber]
    }
  },
  mounted() {
    this.$refs.inputName.focus()
  },
  validations: {
    form: {
      name: { required }
    }
  },
  methods: {
    onPrevPress() {
      this.$router.push({ name: 'AddEventWizardWho' })
    },
    onCancelPress() {
      if (this.eventId) {
        this.$router.push({ name: 'EditEventWizardOverview' })
      } else {
        this.$router.push({ name: 'AddEventWizardSummary' })
      }
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.$error) return

      const { name, description } = this.form

      this.$store
        .dispatch('setWizardValues', {
          name: name.substring(0, TITLE_MAX_LENGTH).trim(),
          description: description ? description.trim() : ''
        })
        .then(() => {
          const nextRoute = this.eventId
            ? 'EditEventWizardOverview'
            : this.$route.params.fromOverview
            ? 'AddEventWizardSummary'
            : 'AddEventWizardWhen'

          this.$router.push({
            name: nextRoute
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.type-buttons {
  margin-left: -4px;
  margin-right: -4px;
  display: flex;
  flex-wrap: wrap;

  &__button {
    margin: 4px;
  }
}
</style>
