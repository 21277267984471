import Bowser from 'bowser'
const browser = Bowser.parse(window.navigator.userAgent)
const { VUE_APP_APPLE_ENABLED } = process.env

// general

export const API_VERSION = '1.9'
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD'
export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY

export const REQUEST_STATE_ERROR = 'error'
export const REQUEST_STATE_LOADING = 'loading'
export const REQUEST_STATE_SUCCESS = 'success'
export const REQUEST_STATE_UPLOADING_DATA = 'uploading_data'

// events
export const DECISION_PRESENT = 'present'
export const DECISION_NOT_PRESENT = 'not-present'
export const DECISION_MAYBE = 'maybe'
export const DECISION_UNKNOWN = 'unknown'
export const EVENTS_PAGE_SIZE = browser.platform.type === 'mobile' ? 10 : 20

export const TIMELINE_HISTORY = 'history'

export const REPETITION_ONCE = 'REPETITION_ONCE'
export const REPETITION_WEEKLY = 'REPETITION_WEEKLY'
export const REPETITION_BIWEEKLY = 'REPETITION_BIWEEKLY'

export const MODIFY_TYPE_FUTURE = 'FUTURE'
export const MODIFY_TYPE_SINGLE = 'SINGLE'

export const EDIT_EVENT_PUSH_TYPE_NONE = 'NONE'
export const EDIT_EVENT_PUSH_TYPE_ALL_INVITED = 'ALL-INVITED'
export const EDIT_EVENT_PUSH_TYPE_ALL_GOING = 'ALL-GOING'

export const CALENDAR_SYNC_PRIVILEGE = 'calendar_sync'

// invitations
export const INVITATION_STATUS_ALREADY_IN_TEAM = 'ALREADY_IN_TEAM'
export const INVITATION_STATUS_EXPIRED = 'EXPIRED'
export const INVITATION_STATUS_SUCCESS = 'SUCCESS'

export const INVITATION_TYPE_TEAM = 'TEAM_INVITATION'
export const INVITATION_TYPE_EVENT = 'EVENT_INVITATION'

export const INVITATION_CONFIRM_STATUS_SUCCESS = 'SUCCESS'
export const INVITATION_CONFIRM_STATUS_FAILED = 'FAILED'

// notifications
export const NOTIFICATION_TYPE_SUCCESS = 'success'
export const NOTIFICATION_TYPE_ERROR = 'error'
export const NOTIFICATION_TYPE_WARNING = 'warning'
export const NOTIFICATION_TYPE_INFO = 'info'

export const APPLE_ENABLED = VUE_APP_APPLE_ENABLED === 'true'
