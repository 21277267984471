<template>
  <div class="modal">
    <div class="modal__dialog modal__dialog--centered modal__dialog--sm">
      <div class="modal__content text-center">
        <icon :icon="['fal', 'spinner-third']" class="spinner" />
        <span> {{ $t('auth.oauth.google') }}</span>
      </div>
    </div>
    <div class="modal__backdrop"></div>
  </div>
</template>
<script>
import { captureException } from '@/utils/errors'
import { isValidState, stateToRedirectUri } from '@/utils/oauth'

export default {
  name: 'GoogleLogin',
  components: {},
  created() {
    const { code, error, redirectUri } = this.getParams()
    const payload = { code, error }

    this.$store.dispatch('loginGoogle', payload).then((isNew) => {
      if (error) {
        this.$router.push({ name: 'Login' })
      } else if (isNew) {
        this.$router.push({ name: 'CompleteProfile' })
      } else if (redirectUri) {
        this.$router.push(redirectUri)
      }
    })
  },
  methods: {
    getParams() {
      // gather parameters from query
      try {
        const { code, error, state } = this.$route.query
        const params = { code, error }

        // validate state to prevent CSRF attacks
        if (isValidState(state)) {
          params.redirectUri = stateToRedirectUri(state)
          return params
        } else {
          throw new Error('csrf verification failed')
        }
      } catch (e) {
        // capture error in Sentry
        captureException(e)
        return { error: true }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  font-size: 3rem;

  margin: 3rem auto;
}
</style>
