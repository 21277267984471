<template>
  <wizard :title="$t('teams.invitation.title')" @close="$router.push({ name: 'Home' })">
    <div class="wizard__section text-center mt-5 mt-sm-0">
      <transition-fade>
        <div v-if="invitation">
          <i18n path="teams.invitation.text" tag="p" class="text-lg" data-cy="invitation-text">
            <template v-slot:name>
              <strong>{{ invitation.inviter.first_name }}</strong>
            </template>
          </i18n>
          <h2 class="my-3" data-cy="team-name">{{ invitation.team.name }}</h2>
          <user-avatar-row
            class="my-5 justify-content-center"
            :users="invitation.team.users"
            :maxAvatars="$breakpoint.smAndUp ? 10 : 5"
            :avatarSize="30"
            :avatarSizeLg="50"
          />
          <button class="btn btn-primary" @click="accept" data-cy="accept-invitation">
            {{ $t('teams.invitation.accept') }}
          </button>
        </div>
        <div v-else-if="error">
          <h3 data-cy="error-title">{{ $t('teams.invitation.code_error.title') }}</h3>
          <p class="mt-3 mb-0" data-cy="error-text">{{ error }}</p>
        </div>
        <spinner :size="50" v-else />
      </transition-fade>
    </div>
  </wizard>
</template>

<script>
import Spinner from '@/components/Spinner'
import TransitionFade from '@/components/TransitionFade'
import UserAvatarRow from '@/components/UserAvatarRow'
import Wizard from '@/components/Wizard'

import { INVITATION_STATUS_ALREADY_IN_TEAM, INVITATION_TYPE_TEAM } from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamInvitation',
  components: {
    Wizard,
    Spinner,
    TransitionFade,
    UserAvatarRow
  },
  data() {
    return {
      invitation: null,
      error: null
    }
  },
  created() {
    const { code } = this.$route.query

    if (!code) {
      this.error = this.$i18n.t('teams.invitation.code_error.text')
    } else if (this.isAuthenticated) {
      this.$store
        .dispatch('redeemTeamInvitation', { code })
        .then(({ team, status }) => {
          if (status === INVITATION_STATUS_ALREADY_IN_TEAM) {
            this.$store
              .dispatch('addNotification', {
                type: 'info',
                text: this.$i18n.t('teams.invitation.already_in_team', {
                  team: team.name
                })
              })
              .then(() => {
                this.$router.replace({
                  name: 'TeamDetails',
                  params: { teamId: team.id }
                })
              })

            return
          }

          const query = { team_id: team.id }
          this.$router.replace({ name: 'TeamInvitationSuccess', query })
        })
        .catch((error) => (this.error = error.message))
    } else {
      this.$store
        .dispatch('loadTeamInvitation', { code })
        .then((invitation) => (this.invitation = invitation))
        .catch((error) => {
          this.error = error.message
        })
    }
  },
  methods: {
    accept() {
      // In case of an anonynmous user, we save the invitation details
      // and redirect to the sign-up where we load the invitation details again
      // This way a user can decide to sign up later and still have his invitation waiting
      const invitationObj = {
        type: INVITATION_TYPE_TEAM,
        code: this.$route.query.code
      }

      localStorage.setItem('invitation', JSON.stringify(invitationObj))
      this.$router.push({ name: 'Login' })
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    })
  }
}
</script>
