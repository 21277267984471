<template>
  <wizard @close="goBack">
    <div v-if="showThanks" class="wizard__screen text-sm-center">
      <div class="wizard__section">
        <h1 class="h4">{{ $t('feedback.thanks.subtitle') }}</h1>
        <p>{{ $t('feedback.thanks.intro') }}</p>
        <div class="mt-3">
          <button class="btn btn-primary btn-inline-sm" type="submit" @click="showThanks = false">
            {{ $t('feedback.send_more') }}
          </button>
        </div>
      </div>
    </div>
    <b-form @submit.prevent="onSubmit" novalidate v-else class="wizard__screen text-sm-center">
      <div class="wizard__section">
        <h1 class="h4">{{ $t('feedback.subtitle') }}</h1>
        <p class="mb-3 mb-sm-5">{{ $t('feedback.intro') }}</p>
        <div class="form-row" v-if="!isAuthenticated">
          <div class="col-sm-6">
            <div class="form-label-group">
              <b-form-input
                :class="{ empty: form.name.length === 0 }"
                v-model="form.name"
                type="text"
                id="inputName"
                aria-describedby="inputName"
              />
              <label for="inputName">{{ $t('feedback.fields.name') }}</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-label-group">
              <b-form-input
                :class="{ empty: form.email.length === 0 }"
                v-model="form.email"
                type="text"
                id="inputEmail"
                :state="$v.form.email.$dirty ? !$v.form.email.$invalid : null"
                aria-describedby="inputEmail"
              />
              <b-form-invalid-feedback id="inputEmail">{{
                $t('feedback.fields.email_invalid_feedback')
              }}</b-form-invalid-feedback>
              <label for="inputEmail">{{ $t('feedback.fields.email') }}</label>
            </div>
          </div>
        </div>
        <b-form-textarea
          :placeholder="$t('feedback.fields.message')"
          v-model="form.message"
          rows="5"
          type="text"
          id="inputMessage"
          :state="$v.form.message.$dirty ? !$v.form.message.$invalid : null"
          aria-describedby="inputMessage"
        />
        <b-form-invalid-feedback id="inputMessage">{{
          $t('feedback.fields.message_invalid_feedback')
        }}</b-form-invalid-feedback>
        <div class="mt-3 text-right">
          <button class="btn btn-primary btn-inline-sm" type="submit" :disabled="loading">
            {{ $t('feedback.submit_label') }}
          </button>
          <div class="alert alert-warning mt-4 mb-0" role="alert" v-if="error">{{ error }}</div>
        </div>
      </div>
    </b-form>
  </wizard>
</template>

<script>
import Wizard from '@/components/Wizard'
import routerBackMixin from '@/mixins/routerBackMixin'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'Feedback',
  mixins: [routerBackMixin],
  data() {
    return {
      showThanks: false,
      error: null,
      loading: false,
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  validations: {
    form: {
      email: {
        required: requiredIf(function () {
          return !this.isAuthenticated
        }),
        email
      },
      message: { required }
    }
  },
  components: {
    Wizard
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    })
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.$error) return

      const { name, email, message } = this.form

      this.error = null
      this.loading = true

      this.$store
        .dispatch('sendFeedback', { name, email, message })
        .then((response) => {
          this.showThanks = true
          this.form.name = ''
          this.form.email = ''
          this.form.message = ''
          this.$v.form.$reset()
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>
