import i18n from '@/i18n'
import { isThisWeek, isThisYear, isToday } from '@/utils/date'
import { capitalizeFirstCharacter } from '@/utils/string'

export function sortEvents(events, inverse = false) {
  return events.sort((a, b) => {
    if (a.startDateTime.isBefore(b.startDateTime)) {
      return inverse ? 1 : -1
    }

    if (a.startDateTime.isAfter(b.startDateTime)) {
      return inverse ? -1 : 1
    }

    return 0
  })
}

export function addToSection(event, sectionTitle, sections) {
  let section = sections.find((section) => section.title === sectionTitle)

  if (!section) {
    section = {
      title: sectionTitle,
      events: []
    }
    sections.push(section)
  }

  section.events.push(event)

  return sections
}

export function makeSections(events) {
  const sections = []
  events.forEach((event) => {
    if (isToday(event.startDateTime)) {
      addToSection(event, i18n.t('events.overview.today'), sections)
    } else if (isThisWeek(event.startDateTime)) {
      addToSection(event, i18n.t('events.overview.this_week'), sections)
    } else if (isThisYear(event.startDateTime)) {
      addToSection(event, capitalizeFirstCharacter(event.startDateTime.format('MMMM')), sections)
    } else {
      addToSection(
        event,
        capitalizeFirstCharacter(event.startDateTime.format('MMMM YYYY')),
        sections
      )
    }
  })

  return sections
}
