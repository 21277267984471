<template>
  <auth-base :title="$t('auth.email.title')" :intro="$t('auth.email.intro')">
    <b-form class="login-page__form" @submit.prevent="onSubmit" novalidate>
      <div class="form-label-group mt-5">
        <b-form-input
          :class="{ empty: form.email.length === 0 }"
          v-model="form.email"
          :placeholder="$t('general.fields.email')"
          type="email"
          id="inputUsername"
          autofocus=""
          :state="$v.form.email.$dirty ? !$v.form.email.$invalid : null"
          autocomplete="email"
          autocapitalize="off"
          aria-describedby="inputEmailFeedback"
        />
        <b-form-invalid-feedback id="inputEmailFeedback">{{
          $t('errors.validations.email')
        }}</b-form-invalid-feedback>
        <label for="inputUsername">{{ $t('general.fields.email') }}</label>
      </div>
      <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">
        {{ $t('auth.email.submit') }}
      </button>
      <div class="alert alert-warning mt-4 mb-0" role="alert" v-if="error">{{ error }}</div>
    </b-form>
    <i18n path="auth.email.privacy_disclaimer" tag="p" class="small text-muted mt-3">
      <template v-slot:link>
        <router-link :to="{ name: 'Privacy' }" target="_blank">{{
          $t('auth.email.privacy')
        }}</router-link>
      </template>
    </i18n>
  </auth-base>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import AuthBase from './AuthBase'

export default {
  name: 'RequestSignInCode',
  components: {
    AuthBase
  },
  data() {
    return {
      error: null,
      isLoading: false,
      form: {
        email: this.$route.query.email || ''
      }
    }
  },
  validations: {
    form: {
      email: { required, email }
    }
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.$error) return

      const { email } = this.form
      this.error = null

      try {
        this.isLoading = true
        await this.$store.dispatch('requestSignInCode', { email })
        this.isLoading = false
        this.$router.push({ name: 'VerifySignInCode', query: { email } })
      } catch (error) {
        this.isLoading = false
        this.error = error.message
      }
    }
  }
}
</script>
