<template>
  <div class="centered not-found">
    <div class="container">
      <div class="mt-5">
        <fail-gif class="mb-5" size="200" />
        <h2 class="mb-2 text-white">{{ $t('errors.not_found.title') }}</h2>
        <p class="mb-4 text">{{ $t('errors.not_found.text', { path: this.$route.fullPath }) }}</p>
        <div class="mt-5">
          <router-link :to="{ name: 'Home' }" class="btn btn-inverted">{{
            $t('general.buttons.to_home')
          }}</router-link>
          <div class="mt-4">
            <a href="#" @click="goBack" class="btn-link text-white">{{
              $t('general.buttons.back')
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FailGif from '@/components/FailGif'
import routerBackMixin from '@/mixins/routerBackMixin'

export default {
  name: 'NotFound',
  mixins: [routerBackMixin],
  components: {
    FailGif
  }
}
</script>

<style lang="scss">
.not-found {
  background: linear-gradient($green, $green-light);
  text-align: center;

  .text {
    color: $white;
    font-size: $font-size-lg;
  }
}
</style>
