<template>
  <div class="scrollspy"></div>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'ScrollSpy',
  props: {
    threshold: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.debounced = debounce(this.checkIfInViewport, 200)
    window.addEventListener('scroll', this.debounced)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.debounced)
  },
  methods: {
    checkIfInViewport() {
      const rect = this.$el.getBoundingClientRect()

      if (
        rect.top >= 0 &&
        rect.bottom + this.threshold <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.$emit('reach')
        this.$destroy()
      }
    }
  }
}
</script>
