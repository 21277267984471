<template>
  <div>
    <img
      v-if="user.avatar_small && !hasError"
      :src="user.avatar_small"
      :width="size"
      :height="size"
      :style="{ width: size + 'px', height: size + 'px' }"
      class="user-avatar user-avatar--img rounded-circle"
      @click="onAvatarClick"
      @error="hasError = true"
    />
    <div
      v-else
      class="user-avatar user-avatar--text rounded-circle"
      :style="{ width: size + 'px', height: size + 'px', 'font-size': fontSize + 'px' }"
    >
      {{ userInitials }}
    </div>
    <avatar-modal
      v-if="isModalVisible"
      :src="user.avatar_large"
      :alt="user.first_name + ' ' + user.last_name"
      @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import AvatarModal from './AvatarModal'
import { nameToIntials } from '@/utils/string'

export default {
  name: 'UserAvatar',
  props: {
    allowFullScreen: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 60
    },
    user: {
      type: Object,
      required: true
    }
  },
  components: {
    AvatarModal
  },
  methods: {
    onAvatarClick() {
      if (this.allowFullScreen && !this.hasError && this.user.avatar_large) {
        this.isModalVisible = true
      }
    }
  },
  data() {
    return {
      hasError: false,
      isModalVisible: false
    }
  },
  computed: {
    fontSize() {
      return Math.round(this.size / 3)
    },
    userInitials() {
      return nameToIntials(this.user.first_name + ' ' + this.user.last_name)
    }
  }
}
</script>

<style lang="scss">
.user-avatar {
  border: 2px solid $white;

  &--text {
    background-color: $yellow;
    color: $white;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
