import store from '@/store'

const mixin = {
  beforeRouteEnter(to, from, next) {
    let eventId

    if (to.params.eventId) {
      eventId = parseInt(to.params.eventId)
    } else if (to.query.event_id) {
      eventId = parseInt(to.query.event_id)
    } else {
      return next({ name: 'Home' })
    }

    const event = store.getters.getEventById(eventId)
    if (event) return next()

    store
      .dispatch('loadEvent', { eventId })
      .then(() => {
        next()
      })
      .catch(() => {
        next({ name: 'Home' })
      })
  },
  computed: {
    event() {
      const { params, query } = this.$route
      const eventId = parseInt(params.eventId || query.event_id)
      return this.$store.getters.getEventById(eventId)
    }
  }
}

export default mixin
