export default {
  en: {
    title: 'Erbij in your own calendar',
    intro:
      'Click on the button below to show your Erbij activities in your personal calendar app. All activities (except the ones where you have indicated that you won’t be present) will appear in your calendar.',
    subscribe: 'Subscribe to your Erbij calendar',
    disclaimer:
      "Don't worry, the synchronization is one-way only. Erbij does <strong>not</strong> have access to your calendar app and will <strong>not</strong> be able to view its contents.",
    copy_text:
      'Is the subscribe button not working for you? You can also copy the link below and paste it into your calendar app. Instructions on how to do this can usually be found on the website of the app you use.',
    copy_success: 'Copied!',
    vendor_instructions: {
      apple_calendar: 'Instructions for Apple Calendar',
      google_calendar: 'Instructions for Google Calendar',
      lotus: 'Instructions for IBM Lotus',
      outlook_desktop: 'Instructions for Outlook Desktop (from step 5)',
      outlook: 'Instructions for Outlook.com',
      thunderbird: 'Instructions for Thunderbird',
      yahoo_calendar: 'Instructions for Yahoo Calendar'
    }
  },
  nl: {
    title: 'Erbij in je eigen agenda',
    intro:
      'Klik op onderstaande knop om je Erbij-activiteiten in je favoriete agenda-app te tonen. Alle activiteiten zullen in je agenda verschijnen, behalve die waarbij je hebt aangegeven dat je niet aanwezig zul zijn.',
    disclaimer:
      'Maak je geen zorgen, de synchronisatie gaat slechts één kant op. Erbij krijgt hiermee <strong>geen</strong> toegang tot je agenda-app en kan je agenda dus <strong>niet</strong> inzien.',
    subscribe: 'Abonneren op Erbij-agenda',
    copy_text:
      'Werkt bovenstaande knop niet voor je? Kopieer dan onderstaande link en plak deze in je agenda-app. De instructies hiervoor kan je meestal vinden op de website van de app die je gebruikt.',
    copy_success: 'Gekopieerd!',
    vendor_instructions: {
      apple_calendar: 'Instructies voor Apple Agenda',
      google_calendar: 'Instructies voor Google Calendar',
      lotus: 'Instructies voor IBM Lotus',
      outlook_desktop: 'Instructies voor Outlook Desktop (vanaf stap 5)',
      outlook: 'Instructies voor Outlook.com',
      thunderbird: 'Instructies voor Thunderbird',
      yahoo_calendar: 'Instructies voor Yahoo Calendar'
    }
  }
}
