import i18n from '@/i18n'
import { API_VERSION } from '@/utils/constants'
import axios from 'axios'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)

export const setAuthorisationToken = (token) => {
  axios.defaults.headers.common['Authorization'] = `JWT ${token}`
}

export const unsetAuthorisationToken = () => {
  axios.defaults.headers.common['Authorization'] = null
}

function logResponse(response, isError) {
  /* eslint-disable no-console */
  console.groupCollapsed(
    `%c ${isError ? '[ERROR] ' : ''}${response.config.method.toUpperCase()} ${
      response.request.responseURL
    }`,
    `color: ${isError ? '#D11020' : '#30BF89'}`
  )
  console.log('Request data: ', response.config.data)
  console.log('Request headers: ', response.config.headers)
  console.log('Response status: ', response.status)
  console.log('Response status text: ', response.statusText)
  console.log('Response data: ', response.data)
  console.log('Request took: ', Math.round(Number(performance.now() - response.config.ts)), 'ms')
  console.groupEnd()
  /* eslint-enable no-console */
}

export const getInstance = (path) => {
  const { NODE_ENV, VUE_APP_API_BASE_URL } = process.env
  const isDevelopment = NODE_ENV === 'development'

  const instance = axios.create({
    baseURL: [VUE_APP_API_BASE_URL, API_VERSION, path].join('/')
  })

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('user-token')
    if (token) config.headers.Authorization = `JWT ${token}`
    config.headers['Accept-Language'] = i18n.locale
    config.headers['TimeZone'] = dayjs.tz.guess()

    if (isDevelopment) {
      config.ts = performance.now()
    }

    return config
  })

  if (isDevelopment) {
    instance.interceptors.response.use(
      (response) => {
        logResponse(response)

        return response
      },
      (error) => {
        if (error.response) {
          logResponse(error.response, true)
        }

        throw error
      }
    )
  }

  return instance
}
