import dayjs from 'dayjs'
import isTodayPlugin from 'dayjs/plugin/isToday'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(isTodayPlugin)

export function localizeDateTime(dateTime, tz) {
  if (!dateTime) return null
  return dayjs(dateTime, 'YYYY-MM-DD HH:mm:ssZZ').tz(tz)
}

export function parseDateTime(dateTime) {
  if (!dateTime) return null
  return dayjs(dateTime, 'YYYY-MM-DD HH:mm:ssZZ')
}

export function parseTime(time) {
  if (!time) return time

  const parts = time.split(':')

  if (parts.length === 3) {
    return [parts[0], parts[1]].join(':')
  }

  return time
}

export function isToday(date) {
  return date.isToday()
}

export function isThisYear(date) {
  return date.isSame(dayjs(), 'year')
}

export function isThisWeek(date) {
  return date.isSame(dayjs(), 'isoweek')
}

export function isBeforeToday(date) {
  return date.isBefore(dayjs(), 'day')
}

export function isWithinXHoursAgo(date, hours) {
  const now = dayjs()
  return date.isBefore(now) && now.diff(date, 'hours') <= hours
}

export function getNextHalfHourDate(date) {
  const parsed = dayjs(date)

  return parsed
    .set('hour', parsed.minute() > 30 ? parsed.hour() + 1 : parsed.hour())
    .set('minute', parsed.minute() > 30 ? 0 : 30)
    .set('second', 0)
}

export function isBusy(start, end) {
  const now = dayjs()

  if (end) {
    return start.isBefore(now) && end.isAfter(now)
  }

  return isWithinXHoursAgo(start, 2)
}
