<template>
  <div class="location-preview">
    <div class="location-preview__map-overlay" v-if="name || address">
      <form v-show="isEdittingName" @submit.prevent="onSave">
        <div class="input-group mb-2">
          <input v-model="nameInputValue" ref="nameInput" class="form-control form-control-sm" />
          <div class="input-group-append">
            <button type="submit" class="btn btn-sm btn-primary">OK</button>
          </div>
        </div>
      </form>
      <span v-show="!isEdittingName">{{ name }}</span>
      <a
        href="#"
        @click.prevent="onEditClick"
        v-if="canEditName && !isEdittingName"
        class="text-link text-sm ml-2 location-preview__edit-btn"
        >{{ $t('general.buttons.edit_name') }}</a
      >
      <div class="text-muted" v-if="address">{{ address }}</div>
    </div>
    <button
      @click="onClearClick"
      class="location-preview__clear-btn"
      v-if="$listeners && $listeners.clear"
    >
      <icon icon="times" />
    </button>
    <GmapMap
      :center="gps"
      :zoom="16"
      map-type-id="terrain"
      class="location-preview__map"
      :options="mapOptions"
    >
      <GmapMarker :position="gps" />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: 'LocationPreview',
  props: ['name', 'address', 'gps'],
  data() {
    return {
      isEdittingName: false,
      nameInputValue: '',
      mapOptions: {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControlOptions: { position: 7 }, // Top right
        zoomControlOptions: { position: 7 } // Top right
      }
    }
  },
  computed: {
    canEditName() {
      return this.$listeners && this.$listeners.nameChange
    }
  },
  methods: {
    onEditClick() {
      this.nameInputValue = this.name
      this.isEdittingName = true

      this.$nextTick(() => {
        this.$refs.nameInput.focus()
      })
    },
    onSave() {
      this.$emit('nameChange', this.nameInputValue)
      this.isEdittingName = false
    },
    onClearClick() {
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss">
.location-preview {
  position: relative;

  &__map {
    width: 100%;
    height: 300px;
  }

  &__clear-btn {
    padding: 0;
    border: 0;
    position: absolute;
    left: 10px;
    top: 10px;
    height: 40px;
    width: 40px;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    z-index: 1;
    font-size: 22px;
    color: $gray-600;
  }

  &__edit-btn {
    vertical-align: middle;
  }

  &__map-overlay {
    background-color: $white;
    font-size: $font-size-sm;
    padding: 0.6rem;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border: 1px solid $gray-200;

    @include sm-up {
      padding: 10px;
      max-width: 270px;
      right: auto;
    }
  }
}
</style>
