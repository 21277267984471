<template>
  <auth-base :title="$t('auth.login.title')" :intro="intro">
    <router-link
      :to="{ name: 'RequestSignInCode', query: { email: $route.query.email } }"
      class="btn btn-block btn-primary mt-5"
      ><icon :icon="['fas', 'envelope']" class="btn__icon--left" size="lg" />{{
        $t('auth.login.login_email')
      }}</router-link
    >
    <div>
      <span class="my-3 my-sm-4 text-winged">{{ $t('general.or') }}</span>
    </div>
    <a v-if="APPLE_ENABLED" class="btn btn-block btn-apple" :href="appleURL"
      ><icon :icon="['fab', 'apple']" class="btn__icon--left" size="lg" />{{
        $t('auth.login.login_apple')
      }}</a
    >
    <a class="btn btn-block btn-google" :href="googleURL"
      ><icon :icon="['fab', 'google']" class="btn__icon--left" size="lg" />{{
        $t('auth.login.login_google')
      }}</a
    >
    <a class="btn btn-block btn-facebook" @click="showFacebookDialog = true"
      ><icon :icon="['fab', 'facebook']" class="btn__icon--left" size="lg" />{{
        $t('auth.login.login_facebook')
      }}</a
    >
    <modal
      v-if="showFacebookDialog"
      :title="$t('auth.facebook_removed.title')"
      @close="showFacebookDialog = false"
      size="sm"
    >
      <div class="modal__stretcher">
        <p>{{ $t('auth.facebook_removed.text') }}</p>
        <div class="text-center mt-5">
          <button
            type="submit"
            class="btn btn-primary btn-block"
            @click="
              $router.replace({ name: 'RequestSignInCode', query: { email: $route.query.email } })
            "
          >
            {{ $t('auth.login.login_email') }}
          </button>
          <a @click="showFacebookDialog = false" class="d-inline-block btn btn-link mt-3">{{
            $t('general.buttons.cancel')
          }}</a>
        </div>
      </div>
    </modal>
    <router-view></router-view>
  </auth-base>
</template>

<script>
import Modal from '@/components/Modal'
import { APPLE_ENABLED } from '@/utils/constants'
import { getAppleURL, getGoogleURL } from '@/utils/oauth'
import AuthBase from './AuthBase'

export default {
  name: 'Login',
  components: {
    AuthBase,
    Modal
  },
  created() {
    if (this.$route.params.logout) {
      this.$store.dispatch('logout').then(() => {
        this.$store.dispatch('addNotification', {
          text: this.$t('auth.login.logged_out')
        })
      })
    } else if (this.isAuthenticated) {
      this.$router.replace({ name: 'Home' })
    }
  },
  data() {
    return {
      APPLE_ENABLED,
      error: null,
      loading: false,
      showFacebookDialog: false
    }
  },
  computed: {
    intro() {
      if (!this.teamInvitation) return null

      const teamName = this.teamInvitation.team.name

      return this.$i18n.t('auth.login.team_invitation_title', {
        team: teamName
      })
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    apiError() {
      return this.$store.getters.authError
    },
    appleURL() {
      return getAppleURL(this.$route.query.redirect)
    },
    googleURL() {
      return getGoogleURL(this.$route.query.redirect)
    }
  }
}
</script>
