<template>
  <wizard :title="title" :progress="progress" @close="$emit('close')">
    <transition-fade>
      <router-view :eventId="eventId" class="wizard__screen"></router-view>
    </transition-fade>
  </wizard>
</template>

<script>
import TransitionFade from '@/components/TransitionFade'
import Wizard from '@/components/Wizard'

export default {
  name: 'EventWizardBase',
  props: {
    eventId: {
      type: Number,
      required: false
    },
    title: {
      type: String
    },
    progress: {
      type: Number,
      required: false,
      default: 0
    }
  },
  components: {
    TransitionFade,
    Wizard
  }
}
</script>
