<template>
  <div class="text-page">
    <div class="container container--xs">
      <EnglishContent v-if="$i18n.locale === 'en'" />
      <DutchContent v-else />
    </div>
  </div>
</template>

<script>
import EnglishContent from './translations/PrivacyEN'
import DutchContent from './translations/PrivacyNL'

export default {
  name: 'Privacy',
  components: {
    EnglishContent,
    DutchContent
  }
}
</script>

<style>
.privacy-table {
  max-width: 100%;
}
.privacy-table td {
  padding-left: 20px;
  padding-bottom: 20px;
}

.privacy-table th {
  vertical-align: top;
}
</style>
