<template>
  <date-picker
    :id="id"
    v-if="showCustomDatepicker"
    v-model="pickerVal"
    :disabled-date="isDisabled"
    :lang="lang"
    :input-class="className"
    format="DD-MM-YYYY"
    type="date"
    :style="{ width: '130px' }"
    :clearable="false"
    :first-day-of-week="1"
    :disabled="disabled"
    :title="title"
  />
  <input
    v-else
    type="date"
    :class="className"
    :id="id"
    v-model="inputVal"
    :min="min"
    :max="max"
    :disabled="disabled"
  />
</template>

<script>
import { datepickerLocale } from '@/utils/i18n'
import { yyyymmdd } from '@/utils/regex'
import bowser from 'bowser'
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'

const browser = bowser.getParser(window.navigator.userAgent)
const dateValidator = (value) => !value || yyyymmdd.test(value)

export default {
  name: 'HybridDatepicker',
  components: {
    DatePicker
  },
  props: {
    id: {
      type: String,
      required: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      validator: dateValidator,
      required: false
    },
    min: {
      type: String,
      validator: dateValidator,
      required: false
    },
    max: {
      type: String,
      validator: dateValidator,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      lang: datepickerLocale[this.$i18n.locale],
      inputVal: this.value,
      pickerVal: new Date(this.value)
    }
  },
  methods: {
    isDisabled(date) {
      const parsed = dayjs(date)

      if (this.min && parsed.isBefore(this.min, 'day')) return true
      if (this.max && parsed.isAfter(this.max, 'day')) return true

      return false
    }
  },
  computed: {
    className() {
      return this.invalid ? 'form-control is-invalid' : 'form-control'
    },
    showCustomDatepicker() {
      return browser.parsedResult.platform.type === 'desktop'
    }
  },
  watch: {
    min(min) {
      if (this.inputVal < min) {
        this.inputVal = min
        this.pickerVal = new Date(min)
      }
    },
    max(max) {
      if (this.inputVal > max) {
        this.inputVal = max
        this.pickerVal = new Date(max)
      }
    },
    inputVal(val) {
      this.$emit('input', val)
    },
    pickerVal(val) {
      this.$emit('input', dayjs(val).format('YYYY-MM-DD'))
    }
  }
}
</script>

<style lang="scss">
.mx-input-append {
  cursor: pointer;
}

.mx-calendar,
.mx-datepicker {
  font-family: $font-family-base !important;
}
.mx-calendar .cell.actived {
  font-weight: $font-weight-bold;
  background-color: $primary;
  border-radius: 4px;
}
</style>
