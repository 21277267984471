export default {
  en: {
    title: 'Bug in Android app',
    text: "July 1st 2022: Dear visitor, the latest update of the Erbij app for Android crashes when starting up the app. We are aware of this issue and are currently working around the clock to fix this issue. Please use this website in the meanwhile. We'll let all Android users know when this problem has been fixed. We sincerely apologize for the inconvenience."
  },
  nl: {
    title: 'Bug in Android app',
    text: '1 juli 2022: Beste bezoeker, de laatste update van Erbij app voor Android crasht wanneer je deze opstart. We zijn op de hoogte van dit probleem en zijn druk bezig met het oplossen ervan. In de tussentijd kan je deze website gebruiken. Wanneer we het probleem hebben opgelost, zullen we alle Android gebruikers hiervan op de hoogte brengen. Onze welgemeende excuses voor het ongemak.'
  }
}
