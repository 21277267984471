<template>
  <div class="container container--sm mb-3 mb-sm-5">
    <page-header :title="$t('teams.overview.title')" :actions="actions" />
    <error v-if="error" :error="error" @tryAgain="refresh" class="team-overview__error" />
    <div class="row" v-else>
      <div class="col-md-6 mb-4" v-for="team in teams" :key="team.id">
        <team-card :team="team" />
      </div>
      <div v-if="teams.length === 0" class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h2 class="team-overview__empty-text">{{ $t('teams.overview.no_teams.title') }}</h2>
            <p>{{ $t('teams.overview.no_teams.text') }}</p>
            <i18n path="teams.overview.no_teams.text2" tag="p" class="m-0">
              <template v-slot:create_team>
                <router-link :to="{ name: 'TeamAdd' }">{{
                  $t('teams.overview.no_teams.add_label')
                }}</router-link>
              </template>
            </i18n>
          </div>
        </div>
      </div>
    </div>
    <router-link
      v-if="$breakpoint.xsOnly"
      tag="button"
      :to="{ name: 'TeamAdd' }"
      class="action-button team-overview__add-team"
      :aria-label="$t('teams.overview.add_label')"
      :title="$t('teams.overview.add_label')"
      ><icon icon="plus" fixed-width
    /></router-link>
    <router-view></router-view>
  </div>
</template>

<script>
import Error from '@/components/Error'
import PageHeader from '@/components/PageHeader'
import TeamCard from '@/components/TeamCard'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamOverview',
  components: {
    PageHeader,
    TeamCard,
    Error
  },
  methods: {
    refresh() {
      this.$store.dispatch('loadTeams')
    }
  },
  computed: {
    ...mapGetters({
      teams: 'teams',
      error: 'teamsOverviewError',
      isLoading: 'isTeamsLoading'
    }),
    actions() {
      if (this.$breakpoint.xsOnly) return []

      return [
        {
          icon: 'plus',
          to: { name: 'TeamAdd' },
          label: this.$i18n.t('teams.overview.add_label')
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.team-overview {
  &__add-team {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 3;
  }
  &__empty-text {
    font-size: $font-size-lg;
    color: $gray-600;
  }
}
</style>
