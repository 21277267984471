import {
  INVITATION_STATUS_ALREADY_IN_TEAM,
  INVITATION_STATUS_SUCCESS,
  INVITATION_TYPE_EVENT,
  INVITATION_TYPE_TEAM,
  NOTIFICATION_TYPE_INFO
} from '@/utils/constants'

const mixin = {
  data() {
    return {
      error: null
    }
  },
  created() {
    const invitationString = localStorage.getItem('invitation')

    if (!invitationString) return

    const invitation = JSON.parse(invitationString)

    if (invitation.type === INVITATION_TYPE_TEAM) {
      this.handleTeamInvitation(invitation)
    } else if (invitation.type === INVITATION_TYPE_EVENT) {
      this.handleEventInvitation(invitation)
    }
  },
  methods: {
    handleTeamInvitation(invitation) {
      this.$store
        .dispatch('redeemTeamInvitation', {
          code: invitation.code
        })
        .then(({ team, status }) => {
          const context = { team: team.name }

          const alreadyInTeamText = this.$i18n.t('teams.invitation.already_in_team', context)
          const successTitle = this.$i18n.t('teams.invitation.success.title')
          const successText = this.$i18n.t('teams.invitation.success.text', context)

          if (status === INVITATION_STATUS_ALREADY_IN_TEAM) {
            return this.$store.dispatch('addNotification', {
              text: alreadyInTeamText,
              type: NOTIFICATION_TYPE_INFO
            })
          } else if (status === INVITATION_STATUS_SUCCESS) {
            this.$store.dispatch('addNotification', {
              title: successTitle,
              text: successText
            })
          }
        })
        .finally(() => localStorage.removeItem('invitation'))
    },
    handleEventInvitation({ code, decision }) {
      this.$store
        .dispatch('convertEventInvitation', { code })
        .then((invitation) => {
          const eventId = invitation.event.id

          return this.$store
            .dispatch('setDecision', {
              eventId,
              decision
            })
            .then(() => {
              this.$router.push({
                name: 'EventDetails',
                params: { eventId }
              })
            })
        })
        .finally(() => localStorage.removeItem('invitation'))
    }
  }
}

export default mixin
