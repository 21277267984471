export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD'
export const NOTIFICATIONS_CLEAR = 'NOTIFICATIONS_CLEAR'
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE'

export default {
  [NOTIFICATIONS_ADD]: (state, notification) => {
    state.notifications.push(notification)
  },
  [NOTIFICATIONS_REMOVE]: (state, uuid) => {
    state.notifications = state.notifications.filter((notification) => notification.uuid !== uuid)
  },
  [NOTIFICATIONS_CLEAR]: (state, token) => {
    state.notifications = []
  }
}
