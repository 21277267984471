export default {
  en: {
    login: {
      title: 'Welcome',
      logged_out: 'You have been successfully logged out.',
      login_email: 'Continue with email',
      login_apple: 'Continue with Apple',
      login_facebook: 'Continue with Facebook',
      login_google: 'Continue with Google',
      team_invitation_title: 'Joining group "{team}"'
    },
    facebook_removed: {
      title: 'Signing in with Facebook is no longer supported',
      text: "In order to access your account please click 'Continue with email' and fill in the same email address you used to sign in with Facebook."
    },
    oauth: {
      apple: 'Logging in with Apple…',
      google: 'Logging in with Google…'
    },
    email: {
      title: 'Sign in to Erbij',
      intro: 'Please fill in your email below to sign in',
      privacy_disclaimer: 'By submitting your email you agree to our {link}.',
      privacy: 'privacy statement',
      submit: 'Continue'
    },
    email_verify: {
      title: 'Enter the code',
      intro: "We've emailed a login code to {email}",
      resend: 'Send me a new login code',
      submit: 'Sign in',
      resend_success: "We've sent you a new log in code"
    },
    event_invitation: {
      title: 'Who are you?',
      submit_email: 'Confirm with email',
      privacy_disclaimer:
        'By confirming you agree and consent to Erbij storing your email and decision in our database. More information can be found in our {link}.',
      privacy: 'privacy statement',
      confirm_with_apple: 'Confirm with Apple',
      confirm_with_google: 'Confirm with Google',
      choice: 'Your choice:'
    }
  },
  nl: {
    login: {
      title: 'Welkom',
      logged_out: 'Je bent nu uitgelogd.',
      login_email: 'Ga verder met email',
      login_apple: 'Ga verder met Apple',
      login_facebook: 'Ga verder met Facebook',
      login_google: 'Ga verder met Google',
      team_invitation_title: 'Aanmelden bij groep "{team}"'
    },
    facebook_removed: {
      title: 'Inloggen met Facebook wordt niet meer ondersteund',
      text: "Om toegang te krijgen tot je account klik je op 'Ga verder met email' en vul je hetzelfde e-mailadres in dat je gebruikt om bij Facebook in te loggen."
    },
    oauth: {
      apple: 'Inloggen met Apple…',
      google: 'Inloggen met Google…'
    },
    email: {
      title: 'Aanmelden bij Erbij',
      intro: 'Vul hieronder je e-mailadres in',
      privacy_disclaimer: 'Door op bovenstaande knop te klikken ga je akkoord met onze {link}.',
      privacy: 'privacyvoorwaarden',
      submit: 'Continue'
    },
    email_verify: {
      title: 'Vul de code in',
      intro: 'We hebben een inlogcode verstuurd naar {email}',
      resend: 'Stuur me een nieuwe inlogcode',
      submit: 'Aanmelden',
      resend_success: 'We hebben je een nieuwe inlogcode gestuurd'
    },
    event_invitation: {
      title: 'Wie ben je?',
      submit_email: 'Bevestig met e-mail',
      privacy_disclaimer:
        'Door te bevestigen ga je ermee akkoord dat we je e-mailadres en besluit opslaan in onze database. Meer informatie vind je in onze {link}.',
      privacy: 'privacyverklaring',
      confirm_with_apple: 'Bevestig met Apple',
      confirm_with_google: 'Bevestig met Google',
      choice: 'Jouw besluit:'
    }
  }
}
