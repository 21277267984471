import { parseDateTime } from '@/utils/date'
import dayjs from 'dayjs'

export function normalizeMember(teamMember) {
  return {
    ...teamMember.user,
    joined: dayjs(teamMember.created),
    is_manager: teamMember.is_manager
  }
}

export function normalizeMembershipToTeam(membership) {
  return {
    ...membership.team,
    created: dayjs(membership.team.created),
    user_can_add_events: membership.can_add_events,
    user_joined: parseDateTime(membership.created),
    user_is_active: membership.is_active,
    user_is_manager: membership.is_manager,
    users: membership.team.users.map(normalizeMember)
  }
}

export function normalizeCreatedTeam(team) {
  return {
    ...team,
    created: dayjs(team.created),
    user_can_add_events: true,
    user_joined: dayjs(),
    user_is_active: true,
    user_is_manager: true,
    users: team.users.map(normalizeMember)
  }
}

export function normalizeJoinedTeam(team) {
  return {
    ...team,
    created: dayjs(team.created),
    user_can_add_events: !team.only_managers_can_add_events,
    user_joined: dayjs(),
    user_is_active: true,
    user_is_manager: false,
    users: team.users.map(normalizeMember)
  }
}

export function normalizeTeamInvitation(invitation) {
  return {
    ...invitation,
    team: normalizeJoinedTeam(invitation.team)
  }
}

export function normalizeRedeemedTeamInvitation(invitation) {
  return {
    ...invitation,
    team: normalizeJoinedTeam(invitation.membership.team)
  }
}
