<template>
  <div class="text-page">
    <div class="container container--xs">
      <h1 class="text-page__title">Terms & Conditions</h1>
      <p>
        These terms and conditions outline the rules and regulations for the use of Erbij's web app,
        located at
        <a href="https://erbij.app">https://erbij.app</a> as well as the mobile Erbij apps that can
        be downloaded via the App Store and Play Store.
      </p>
      <p>
        By accessing this website or downloading one of our apps we assume you accept these terms
        and conditions. Do not continue to use Erbij if you do not agree to take all of the terms
        and conditions stated on this page.
      </p>
      <p>
        The following terminology applies to these Terms and Conditions, our Privacy Statement and
        all other Agreements: "Client", "You" and "Your" refers to you, the person log on this
        website and compliant to the Company’s terms and conditions. "Erbij", "Michiel van Roon Web
        Development", "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
        "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the
        offer, acceptance and consideration of payment necessary to undertake the process of our
        assistance to the Client in the most appropriate manner for the express purpose of meeting
        the Client’s needs in respect of provision of the Company’s stated services, in accordance
        with and subject to, prevailing law of The Netherlands. Any use of the above terminology or
        other words in the singular, plural, capitalization and/or he/she or they, are taken as
        interchangeable and therefore as referring to same.
      </p>

      <h2 class="h3">Age requirements</h2>
      <p>To use Erbij, you must be at least 13 years old.</p>
      <h2 class="h3">Cookies</h2>
      <p>
        We employ the use of cookies. By accessing the erbij.app, you agreed to the use of cookies
        in agreement with our
        <a href="/cookies">Cookie Policy</a>.
      </p>
      <p>
        Most interactive websites use cookies to let us retrieve the user’s details for each visit.
        Cookies are used by our website to enable the functionality of certain areas to make it
        easier for people visiting our website.
      </p>
      <h2 class="h3">License</h2>
      <p>
        Unless otherwise stated, Erbij and/or its licensors own the intellectual property rights for
        all material on erbij.app and within the Erbij mobile apps. All intellectual property rights
        are reserved unless stated otherwise. You may access this from erbij.app for your own
        personal use, subjected to restrictions set in these terms and conditions.
      </p>
      <p>You must not:</p>
      <ul>
        <li>Republish material from erbij.app or from the Erbij apps</li>
        <li>Sell, rent or sub-license material from erbij.app or from the Erbij apps</li>
        <li>Reproduce, duplicate or copy material from erbij.app or from the Erbij apps</li>
      </ul>
      <p>This Agreement shall begin on the date hereof.</p>
      <p>
        Parts of our software offer an opportunity for users to post and exchange opinions and
        information in certain areas of the website. Erbij does not filter, edit, publish or review
        Comments prior to their presence on the website. Comments do not reflect the views and
        opinions of Erbij, its agents and/or affiliates. Comments reflect the views and opinions of
        the person who post their views and opinions. To the extent permitted by applicable laws,
        Erbij shall not be liable for the Comments or for any liability, damages or expenses caused
        and/or suffered as a result of any use of and/or posting of and/or appearance of the
        Comments on this website or in the mobile apps.
      </p>
      <p>
        Erbij reserves the right to monitor all Comments and to remove any Comments which can be
        considered inappropriate, offensive or causes breach of these Terms and Conditions.
      </p>
      <p>You warrant and represent that:</p>
      <ul>
        <li>
          The Comments do not invade any intellectual property right, including without limitation
          copyright, patent or trademark of any third party;
        </li>
        <li>
          The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise
          unlawful material which is an invasion of privacy
        </li>
        <li>
          The Comments will not be used to solicit or promote business or custom or present
          commercial activities or unlawful activity.
        </li>
      </ul>
      <p>
        You hereby grant Erbij a non-exclusive license to use, reproduce, edit and authorize others
        to use, reproduce and edit any of your Comments in any and all forms, formats or media.
      </p>
      <h2 class="h3">IFrames</h2>
      <p>
        Without prior approval and written permission, you may not create frames around our Webpages
        that alter in any way the visual presentation or appearance of our Website.
      </p>
      <h2 class="h3">Your Privacy</h2>
      <p>Please read our <a href="/privacy">Privacy Policy</a></p>
      <h2 class="h3">Reservation of Rights</h2>
      <p>
        We reserve the right to request that you remove all links or any particular link to our
        Website. You approve to immediately remove all links to our Website upon request. We also
        reserve the right to amend these terms and conditions and it’s linking policy at any time.
        By continuously linking to our Website, you agree to be bound to and follow these linking
        terms and conditions.
      </p>
      <h2 class="h3">Removal of links from our website</h2>
      <p>
        If you find any link on our Website that is offensive for any reason, you are free to
        contact and inform us any moment. We will consider requests to remove links but we are not
        obligated to do or so or to reply to you directly.
      </p>
      <p>
        We do not ensure that the information on this website is correct, we do not warrant its
        completeness or accuracy; nor do we promise to ensure that the website remains available or
        that the material on the website is kept up to date.
      </p>
      <h2 class="h3">Disclaimer of Warranties and Liability</h2>
      <p>
        THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND CONTENT AVAILABLE IN THE APP ARE PROVIDED
        TO YOU “AS IS” AND WITHOUT WARRANTY. MICHIEL VAN ROON WEB DEVELOPMENT, ERBIJ AND ITS
        SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS HEREBY
        DISCLAIM ALL WARRANTIES WITH REGARD TO SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
        CONTENT, INCLUDING, WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT. MICHIEL VAN
        ROON WEB DEVELOPMENT, ERBIJ AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
        PARTNERS, AND LICENSORS MAKE NO WARRANTY THAT (a) THE SERVICE WILL MEET YOUR REQUIREMENTS;
        (b) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (c) THE RESULTS THAT
        MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; (d) THE QUALITY OF
        ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH
        THE SERVICE WILL MEET YOUR EXPECTATIONS; AND (e) ANY ERRORS IN THE APP WILL BE CORRECTED.
      </p>
      <p>
        YOU EXPRESSLY AGREE TO RELEASE MICHIEL VAN ROON WEB DEVELOPMENT, ERBIJ, ITS SUBSIDIARIES,
        AFFILIATES, OFFICERS, AGENTS, REPRESENTATIVES, EMPLOYEES, PARTNERS AND LICENSORS (THE
        “RELEASED PARTIES”) FROM ANY AND ALL LIABILITY CONNECTED WITH YOUR ACTIVITIES, AND PROMISE
        NOT TO SUE THE RELEASED PARTIES FOR ANY CLAIMS, ACTIONS, INJURIES, DAMAGES, OR LOSSES
        ASSOCIATED WITH YOUR ACTIVITIES. YOU ALSO AGREE THAT IN NO EVENT SHALL THE RELEASED PARTIES
        BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL
        OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (a) YOUR USE OR MISUSE
        OF THE APP, (b) YOUR DEALINGS WITH THIRD PARTY SERVICE PROVIDERS OR ADVERTISERS AVAILABLE
        THROUGH THE APP, (c) ANY DELAY OR INABILITY TO USE THE APP EXPERIENCED BY YOU, (d) ANY
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES, OR CONTENT OBTAINED THROUGH THE APP, WHETHER
        BASED ON CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF MICHIEL VAN ROON WEB
        DEVELOPMENT, ERBIJ HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
        STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
        OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
      </p>
      <h2 class="h3">Contact information</h2>
      Owner of Erbij:<br />Michiel van Roon Web Development<br />Address: Battutalaan 523<br />3526VS
      Utrecht<br />Email: michiel@erbij.app
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms'
}
</script>
