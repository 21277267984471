<template>
  <nav class="mobile-nav" :class="{ 'mobile-nav--visible': visible }">
    <div class="mobile-nav__container">
      <div class="mobile-nav__header mobile-nav__section d-flex align-items-center" v-if="profile">
        <user-avatar :user="profile" :size="70" class="mobile-nav__avatar mr-3" />
        <div>
          <span class="mobile-nav__name">{{ fullName }}</span>
          <router-link class="mobile-nav__link" :to="{ name: 'Profile' }">{{
            $t('general.header.edit_profile')
          }}</router-link>
        </div>
      </div>
      <div class="mobile-nav__section" v-if="profile">
        <router-link class="mobile-nav__icon-link" :to="{ name: 'EventAdd' }">
          <icon :icon="['fal', 'calendar-plus']" class="mobile-nav__link-icon" fixed-width />
          <span class="mobile-nav__icon-link__label">{{ $t('general.header.add_event') }}</span>
        </router-link>
        <router-link class="mobile-nav__icon-link" :to="{ name: 'Home' }">
          <icon :icon="['fal', 'calendar-alt']" class="mobile-nav__link-icon" fixed-width />
          <span class="mobile-nav__icon-link__label">{{ $t('general.header.calendar') }}</span>
        </router-link>
        <router-link class="mobile-nav__icon-link" :to="{ name: 'TeamOverview' }">
          <icon :icon="['fal', 'users']" class="mobile-nav__link-icon" fixed-width />
          <span class="mobile-nav__icon-link__label">{{ $t('general.header.teams') }}</span>
        </router-link>
      </div>
      <div class="mobile-nav__section" v-else>
        <router-link class="mobile-nav__icon-link" :to="{ name: 'Login' }">
          <span class="mobile-nav__icon-link__label">{{ $t('general.header.log_in') }}</span>
        </router-link>
      </div>
      <div class="mobile-nav__section">
        <router-link
          v-if="hasSyncPrivilege"
          class="mobile-nav__link"
          :to="{ name: 'CalendarSync' }"
          >{{ $t('general.menu.calendar_sync') }}</router-link
        >
        <router-link class="mobile-nav__link" :to="{ name: 'FAQ' }">{{
          $t('general.menu.faq')
        }}</router-link>
        <router-link class="mobile-nav__link" :to="{ name: 'Feedback' }">{{
          $t('general.menu.feedback')
        }}</router-link>
        <router-link class="mobile-nav__link" :to="{ name: 'About' }">{{
          $t('general.menu.about')
        }}</router-link>
        <router-link class="mobile-nav__link" :to="{ name: 'Privacy' }">{{
          $t('general.menu.privacy')
        }}</router-link>
      </div>
      <div class="mobile-nav__section" v-if="profile">
        <a
          :href="'?lang=' + otherLocale"
          :hreflang="otherLocale"
          class="mobile-nav__link"
          @click.prevent="switchLanguage"
          >{{ $t('general.menu.otherLanguage') }}</a
        >
        <router-link class="mobile-nav__link" :to="{ name: 'Login', params: { logout: true } }">{{
          $t('general.header.log_out')
        }}</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { CALENDAR_SYNC_PRIVILEGE } from '@/utils/constants'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'MobileNav',
  components: { UserAvatar },
  props: ['visible'],
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.otherLocale
    }
  },
  computed: {
    otherLocale() {
      return this.$i18n.locale === 'en' ? 'nl' : 'en'
    },
    fullName() {
      const { first_name, last_name } = this.$store.getters.profile

      return [first_name, last_name].join(' ')
    },
    profile() {
      return this.$store.getters.profile
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    hasSyncPrivilege() {
      return this.$store.getters.hasPrivilege(CALENDAR_SYNC_PRIVILEGE)
    }
  }
}
</script>

<style lang="scss">
.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: $mobile-header-height;
  transform: translateY(-100%);
  color: $white;
  background: $primary;
  transition: transform 0.3s;
  will-change: transform;
  @include font-smoothing;

  &__section {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10px 10px;
  }

  &__link {
    opacity: 1;
    transition: opacity 0.15s;
    display: block;
    padding: 5px 0;
    @include hover-focus {
      color: $white;
      opacity: 0.8;
      text-decoration: none;
    }
  }

  &__header {
    padding: 20px 0;
  }

  &__name {
    display: block;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
  }

  &__icon-link {
    padding: 5px 0;
    margin: 5px 0;
    display: flex;

    @include hover-focus {
      color: $white;
      opacity: 0.8;
      text-decoration: none;
    }

    &--emphasize &__label {
      padding: 5px 10px;
      margin-left: -10px;
      background-color: $white;
      color: $primary;
      border-radius: 3px;
    }

    &__label {
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }

  &__link-icon {
    font-size: 20px;
    margin-right: 10px;
  }

  &--visible {
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.3s, opacity 0.1s;

    .mobile-nav__section {
      opacity: 1;
      transform: translateX(0);

      &:nth-child(1) {
        transition-delay: 0.22s;
      }

      &:nth-child(2) {
        transition-delay: 0.25s;
      }

      &:nth-child(3) {
        transition-delay: 0.3s;
      }

      &:nth-child(4) {
        transition-delay: 0.34s;
      }
    }
  }

  &__section {
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.2s, transform 0.2s;
    transition-delay: 0s;
  }

  &__container {
    margin: 0 15px;
  }
}
</style>
