export default {
  en: {
    general: {
      back_label: 'Go back',
      manager: 'Manager'
    },
    overview: {
      title: 'Groups',
      add_label: 'Create a new group',
      add_activity: 'Add new activity',
      others: 'other | others',
      no_teams: {
        title: 'You are not a member of a group yet',
        text: 'Groups are perfect for people that often do things together, because members will invited to group activities automatically.',
        text2:
          'You can either join an existing group by asking a group manager to invite you or {create_team}.',
        add_label: 'create your own group'
      }
    },
    details: {
      add_event_label: 'Add group activity',
      edit_label: 'Edit',
      leave_label: 'Leave',
      details_title: 'Details',
      members_title: 'Members',
      invite_more: 'Invite more',
      name: 'Name',
      type: 'Type',
      created: 'Established',
      joined: 'Member since',
      members: '1 member | {count} members',
      edit_photo_title: 'Edit group photo'
    },
    add: {
      title: 'Create a new group',
      submit: 'Create group',
      success_notification: {
        title: 'Group created!  🎉',
        text: 'You are now the manager of "{team}"'
      }
    },
    edit: {
      title: 'Edit group',
      submit: 'Save changes',
      success_notification: {
        title: 'Group updated',
        text: 'Your changes have been saved'
      }
    },
    member_details: {
      promote: 'Promote to manager',
      remove: 'Remove from group',
      name: 'Name',
      email: 'Email',
      member_since: 'Member of "{team}" since',
      remove_confirm: {
        text: 'Are you sure you want to remove {name} from {team}?',
        confirm: 'Yes, remove',
        cancel: 'Maybe not'
      },
      promote_confirm: {
        text: 'Are you sure you want to make {name} a manager of {team}?',
        text2: "This means you won't be able to remove this person from your group anymore.",
        confirm: 'Yes, promote',
        cancel: 'Maybe not'
      }
    },
    invite: {
      title: 'Invite more friends',
      intro: 'Invite all your teammates, friends, colleagues and family to the group.',
      warning:
        'Heads up! Be careful when handing out this link. Everyone can use it to join your group.',
      share: {
        title: 'Invite people to "{team}"',
        text: 'I would like to invite you to join "{team}".\n\nYou can accept or decline my invitation using the following link:\n{url}\n\nThis link expires on {expires}',
        subject: 'You are invited to "{team}"',
        email: 'Email',
        whatsapp: 'WhatsApp',
        copy: 'Copy link',
        other: 'Other'
      },
      copy_success: 'Copied!'
    },
    add_edit_form: {
      name: 'Name',
      name_placeholder: 'Name of your group',
      type: 'Type',
      type_placeholder: 'Choose…',
      only_managers_can_add_events: 'Only managers can add events'
    },
    leave: {
      title: 'Hold up!',
      text: 'Are you sure you want to leave "{team}"?',
      confirm: 'Leave group',
      leave_success: {
        self: {
          text: 'You have left "{team}".'
        },
        other: {
          text: '{name} was removed from "{team}".'
        }
      }
    },
    invitation: {
      title: 'Invitation',
      text: '{name} has invited you to group',
      already_in_team: 'You are already a member of "{team}"',
      accept: 'Count me in!',
      code_error: {
        title: 'Oh no!',
        text: 'Invalid invitation code. Ask the person that invited you to send you a new invitation-link.'
      },
      success: {
        title: "You're in!  🎉",
        text: 'You are now a member of "{team}"'
      }
    },
    invitation_success: {
      title: 'You made it to the team!  🎉',
      text: 'Congrats {name}, your are now a member of {team}!'
    },
    make_manager: {
      success_notification: {
        title: 'Promoted! 🎩',
        text: '{name} is now a manager of "{team}".'
      }
    }
  },
  nl: {
    general: {
      back_label: 'Ga terug',
      manager: 'Beheerder'
    },
    overview: {
      title: 'Groepen',
      add_label: 'Nieuwe groep beginnen',
      add_activity: 'Activiteit toevoegen',
      others: 'andere | anderen',
      no_teams: {
        title: 'Je bent nog geen lid van een groep',
        text: 'Groepen zijn ideaal voor mensen die vaker dingen samen doen. Leden worden automatisch uitgenodigd voor groepsactiviteiten. Je hoeft dan dus geen linkje meer te delen om ze uit te nodigen.',
        text2: 'Vraag de beheerder van een groep om je uit te nodigen of {create_team}.',
        add_label: 'maak een nieuwe groep'
      }
    },
    details: {
      add_event_label: 'Groepsactiviteit toevoegen',
      edit_label: 'Wijzigen',
      leave_label: 'Verlaten',
      details_title: 'Details',
      members_title: 'Leden',
      invite_more: 'Meer uitnodigen',
      name: 'Naam',
      type: 'Soort',
      created: 'Opgericht',
      joined: 'Lid sinds',
      members: '1 lid | {count} leden',
      edit_photo_title: 'Groepsfoto wijzigen'
    },
    add: {
      title: 'Nieuwe groep beginnen',
      submit: 'Groep opslaan',
      success_notification: {
        title: 'Groep toegevoegd! 🎉',
        text: 'Je bent nu beheerder van "{team}"'
      }
    },
    edit: {
      title: 'Groep wijzigen',
      submit: 'Wijzigingen opslaan',
      success_notification: {
        title: 'Groep gewijzigd',
        text: 'De aanpassingen zijn doorgevoerd'
      }
    },
    member_details: {
      promote: 'Promoveren tot beheerder',
      remove: 'Verwijderen uit groep',
      name: 'Name',
      email: 'E-mailadres',
      member_since: 'Lid van "{team}" sinds',
      remove_confirm: {
        text: 'Weet je zeker dat je {name} wilt verwijderen uit {team}?',
        confirm: 'Ja, verwijderen',
        cancel: 'Toch maar niet'
      },
      promote_confirm: {
        text: 'Weet je zeker dat je {name} beheerder van {team} wilt maken?',
        text2: 'Deze persoon kan daarna niet meer uit de groep worden verwijderd.',
        confirm: 'Ja, promoveren',
        cancel: 'Toch maar niet'
      }
    },
    invite: {
      title: 'Wie mag Erbij?',
      intro:
        'Nodig al je je sportmaatjes, vrienden, collega’s en familieleden uitnodigen voor je groep.',
      warning: 'Let op! Iedereen die deze link in handen krijgt kan zich bij de groep voegen.',
      share: {
        title: 'Uitnodigen voor "{team}"',
        text: 'Bij deze ben je uitgenodigd om lid te worden van "{team}". Ben je Erbij?\n\nJe kunt op deze uitnodiging reageren door op te volgende link te klikken:\n{url}\n\nDeze link is geldig tot {expires}',
        subject: 'Lid worden van "{team}"',
        email: 'Email',
        whatsapp: 'WhatsApp',
        copy: 'Copy link',
        other: 'Other'
      },
      copy_success: 'Gekopieerd!'
    },
    add_edit_form: {
      name: 'Naam',
      name_placeholder: 'Naam van je groep',
      type: 'Type',
      type_placeholder: 'Maak een keuze',
      only_managers_can_add_events: 'Alleen managers kunnen activiteiten toevoegen'
    },
    leave: {
      title: 'Wacht even!',
      text: 'Weet je zeker dat je "{team}" wilt verlaten?',
      confirm: 'Groep verlaten',
      leave_success: {
        self: {
          text: 'Je hebt "{team}" verlaten.'
        },
        other: {
          text: '{name} is verwijderd uit "{team}".'
        }
      }
    },
    invitation: {
      title: 'Uitnodiging',
      text: '{name} heeft je uitgenodigd voor',
      already_in_team: 'Je bent al lid van "{team}"',
      accept: 'Ik ben Erbij!',
      code_error: {
        title: 'Oeps...',
        text: 'Ongeldige uitnodigingscode. Vraag degene die je heeft uitgenodigd om je een nieuwe uitnodigingslink te sturen.'
      },
      success: {
        title: 'Je bent Erbij!  🎉',
        text: 'Gefeliciteerd, je bent nu lid van "{team}".'
      }
    },
    invitation_success: {
      title: 'Hoppa, je bent Erbij!  🎉',
      text: 'Gefeliciteerd {name}, je bent nu lid van {team}.'
    },
    make_manager: {
      success_notification: {
        title: 'Gepromoveerd! 🎩',
        text: '{name} is nu ook manager van "{team}".'
      }
    }
  }
}
