<template>
  <transition
    name="fade-height"
    mode="out-in"
    @beforeLeave="beforeLeave"
    @enter="enter"
    @afterEnter="afterEnter"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionFade',
  data() {
    return {
      prevHeight: 0
    }
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter(element) {
      const { height } = getComputedStyle(element)

      element.style.height = this.prevHeight

      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    }
  }
}
</script>

<style lang="scss">
.fade-height-enter-active,
.fade-height-leave-active {
  transition-duration: 0.15s;
  transition-property: height, opacity;
  overflow: hidden;
}
.fade-height-enter,
.fade-height-leave-active {
  opacity: 0;
}
</style>
