<template>
  <div class="store-buttons">
    <a :href="appStoreURL" class="mr-2" v-if="!isAndroid" target="_blank">
      <img class="store-button" :src="appStore" width="149" height="50" />
    </a>
    <a :href="playStoreURL" v-if="!isIos" target="_blank">
      <img class="store-button" :src="playStore" width="167" height="50" />
    </a>
  </div>
</template>

<script>
import bowser from 'bowser'
const browser = bowser.getParser(window.navigator.userAgent)
export default {
  name: 'StoreButtons',
  data() {
    const { locale } = this.$i18n

    return {
      isAndroid: browser.parsedResult.os.name === 'Android',
      isIos: browser.parsedResult.os.name === 'iOS',
      appStoreURL: 'https://itunes.apple.com/nl/app/erbij/id1360268931?l=' + locale,
      playStoreURL:
        'https://play.google.com/store/apps/details?id=nl.michielvanroon.erbij&hl=' + locale,
      appStore:
        locale === 'nl'
          ? require('@/assets/nl/app-store-badge.png')
          : require('@/assets/en/app-store-badge.png'),
      playStore:
        locale === 'nl'
          ? require('@/assets/nl/google-play-badge.png')
          : require('@/assets/en/google-play-badge.png')
    }
  }
}
</script>

<style lang="scss">
.store-button {
  @include sm-up {
    height: 36px;
    width: auto;
  }
}
</style>
