import { REQUEST_STATE_LOADING } from '@/utils/constants'
import { sortTeams } from '@/utils/teams'

export default {
  teams: (state) => sortTeams(Object.values(state.teams)),
  getTeamById: (state) => (id) => state.teams[id],
  teamsOverviewError: (state) => state.error,
  teamInvitation: (state) => state.invitation,
  isTeamsLoading: (state) => state.status === REQUEST_STATE_LOADING
}
