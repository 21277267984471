import dayjs from 'dayjs'

import i18n from '@/i18n'
import {
  EVENTS_DELETE_BY_TEAM,
  EVENTS_INVITEE_DELETE,
  SET_TEAM_FILTER
} from '@/store/modules/events/mutations'
import { getInstance } from '@/utils/api'
import { INVITATION_STATUS_EXPIRED } from '@/utils/constants'
import {
  TEAMS_CREATE,
  TEAMS_DELETE,
  TEAMS_EDIT,
  TEAMS_EDIT_PHOTO,
  TEAMS_LOAD_ERROR,
  TEAMS_LOAD_REQUEST,
  TEAMS_LOAD_SUCCESS,
  TEAMS_MAKE_MANAGER,
  TEAMS_REMOVE_MEMBER,
  TEAM_INVITATION_SET
} from './mutations'
import {
  normalizeCreatedTeam,
  normalizeMembershipToTeam,
  normalizeRedeemedTeamInvitation,
  normalizeTeamInvitation
} from './normalizers'

import { captureApiException } from '@/utils/errors'

export const api = getInstance('teams')

export default {
  loadTeams: ({ commit, dispatch }) => {
    commit(TEAMS_LOAD_REQUEST)

    return api
      .get('/')
      .then((response) => {
        const teams = response.data.map(normalizeMembershipToTeam)
        commit(TEAMS_LOAD_SUCCESS, teams)
        return teams
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.teams.load'),
          type: TEAMS_LOAD_ERROR
        })

        captureApiException(error)

        throw error
      })
  },
  leaveTeam: ({ commit, dispatch, getters, rootState }, { teamId, userId }) => {
    userId = userId || getters.userId
    const team = getters.getTeamById(teamId)
    const member = team.users.find((user) => user.id === userId)
    const teamFilter = rootState.events.teamFilter

    return api
      .post(`/${teamId}/delete-user/`, { user: userId })
      .then((response) => {
        if (userId === getters.userId) {
          commit(TEAMS_DELETE, teamId)
          if (teamFilter === teamId) {
            commit(SET_TEAM_FILTER, null)
          }
          commit(EVENTS_DELETE_BY_TEAM, teamId)
          dispatch('loadEvents', { reset: true })
          dispatch('addNotification', {
            text: i18n.t('teams.leave.leave_success.self.text', {
              team: team.name,
              name: member.first_name
            })
          })
        } else {
          commit(TEAMS_REMOVE_MEMBER, { teamId, userId })
          commit(EVENTS_INVITEE_DELETE, { teamId, userId })
          dispatch('loadEvents', { reset: true })
          dispatch('addNotification', {
            text: i18n.t('teams.leave.leave_success.other.text', {
              team: team.name,
              name: member.first_name
            })
          })
        }
      })
      .catch((error) => {
        const shortError =
          userId === getters.userId
            ? i18n.t('errors.teams.leave')
            : i18n.t('errors.teams.remove_other')
        dispatch('handleError', {
          error,
          shortError
        })

        captureApiException(error)
      })
  },
  createTeam: ({ commit, dispatch }, { name, type, onlyManagersCanAddEvents }) => {
    const data = {
      name: name,
      team_type: type,
      only_managers_can_add_events: onlyManagersCanAddEvents
    }
    return api
      .post('/', data)
      .then((response) => {
        const newTeam = normalizeCreatedTeam(response.data)
        commit(TEAMS_CREATE, newTeam)

        dispatch('addNotification', {
          title: i18n.t('teams.add.success_notification.title'),
          text: i18n.t('teams.add.success_notification.text', {
            team: newTeam.name
          })
        })

        return newTeam
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.teams.create')
        })

        captureApiException(error)

        throw error
      })
  },
  editTeam: ({ commit, dispatch }, { teamId, name, type, onlyManagersCanAddEvents }) => {
    return api
      .patch(`/${teamId}/`, {
        name: name,
        team_type: type,
        only_managers_can_add_events: onlyManagersCanAddEvents
      })
      .then((response) => {
        commit(TEAMS_EDIT, {
          teamId,
          name,
          type,
          onlyManagersCanAddEvents
        })
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.teams.edit')
        })

        captureApiException(error)
      })
  },
  editTeamPhoto: ({ commit, dispatch }, { teamId, image }) => {
    const data = new FormData()

    data.append('team_photo', image, 'avatar.jpg')

    return api
      .put(`/${teamId}/team-photo/`, data)
      .then((resp) => {
        commit(TEAMS_EDIT_PHOTO, {
          teamId,
          teamPhoto: resp.data.team_photo_url,
          teamPhotoLarge: resp.data.team_photo_large
        })
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.teams.edit_photo')
        })

        captureApiException(error)
      })
  },
  shareTeam: ({ commit, dispatch }, { teamId }) => {
    return api
      .post(`/${teamId}/create-code/`)
      .then((response) => {
        const { code, expires } = response.data
        const { protocol, host } = window.location

        return {
          url: `${protocol}//${host}/invitation/?code=${code}`,
          expires: dayjs(expires)
        }
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.teams.share')
        })

        captureApiException(error)

        throw error
      })
  },
  redeemTeamInvitation: ({ commit, dispatch }, { code }) => {
    return api
      .post('/redeem/', { code })
      .then((response) => {
        const invitation = normalizeRedeemedTeamInvitation(response.data)

        commit(TEAMS_CREATE, invitation.team)
        dispatch('loadEvents', { reset: true })

        return invitation
      })
      .catch((error) => {
        captureApiException(error)
        error.message = i18n.t('errors.teams.invitation_generic')

        if (error.response && error.response.data.status === INVITATION_STATUS_EXPIRED) {
          error.message = i18n.t('errors.teams.invitation_expired')
        }

        throw error
      })
  },
  loadTeamInvitation: ({ commit, dispatch }, { code }) => {
    return api
      .get('/invites/details/', { params: { code } })
      .then((response) => {
        const invitation = normalizeTeamInvitation(response.data)
        commit(TEAM_INVITATION_SET, invitation)

        return invitation
      })
      .catch((error) => {
        captureApiException(error)

        throw new Error(i18n.t('errors.teams.load_invitation'))
      })
  },
  makeManager: ({ commit, getters, dispatch }, { teamId, userId }) => {
    const team = getters.getTeamById(teamId)
    const member = team.users.find((user) => user.id === userId)

    return api
      .post(`/${teamId}/set-is-manager/`, { user: userId })
      .then((response) => {
        commit(TEAMS_MAKE_MANAGER, { teamId, userId })

        dispatch('addNotification', {
          title: i18n.t('teams.make_manager.success_notification.title'),
          text: i18n.t('teams.make_manager.success_notification.text', {
            team: team.name,
            name: member.first_name
          })
        })
        return response.data
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.teams.make_manager', {
            team: team.name,
            name: member.first_name
          })
        })

        captureApiException(error)
      })
  }
}
