<template>
  <auth-base :title="$t('auth.email_verify.title')">
    <template v-slot:intro>
      <i18n path="auth.email_verify.intro">
        <template v-slot:email>
          <strong>{{ email }}</strong>
        </template>
      </i18n>
    </template>
    <b-form class="login-page__form" @submit.prevent="onSubmit" novalidate>
      <div class="my-5">
        <code-input @change="onChange" :value="code" :disabled="isLoading" />
      </div>
      <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">
        {{ $t('auth.email_verify.submit') }}
      </button>
      <button
        class="btn btn-link mt-2"
        type="button"
        @click="requestNewCode"
        :disabled="isLoading || isRequestingNewCodeDisabled"
      >
        {{ $t('auth.email_verify.resend') }}
      </button>
      <div class="alert alert-warning mt-4 mb-0" role="alert" v-if="error">{{ error }}</div>
    </b-form>
  </auth-base>
</template>

<script>
import CodeInput from '@/components/CodeInput'
import AuthBase from './AuthBase'

export default {
  name: 'VerifySignInCode',
  components: {
    AuthBase,
    CodeInput
  },
  data() {
    return {
      isLoading: false,
      isRequestingNewCode: false,
      isRequestingNewCodeDisabled: false,
      error: null,
      email: '',
      code: ''
    }
  },
  mounted() {
    const { data, email } = this.$route.query
    if (data) {
      try {
        const parsed = JSON.parse(atob(data))
        this.email = parsed.email
        this.onChange(parsed.code)
      } catch (error) {
        this.error = error.message
      }
    } else if (email) {
      this.email = email
    }
  },
  watch: {
    isRequestingNewCodeDisabled(isDisabled) {
      if (isDisabled) {
        setTimeout(() => {
          this.isRequestingNewCodeDisabled = false
        }, 10000)
      }
    }
  },
  methods: {
    onChange(code) {
      this.code = code
      if (code.length === 6) {
        this.onSubmit()
      }
    },
    async onSubmit() {
      this.error = null
      this.isLoading = true

      try {
        const isNew = await this.$store.dispatch('verifySignInCode', {
          email: this.email,
          code: this.code
        })

        this.isLoading = false

        if (isNew) {
          this.$router.push({ name: 'CompleteProfile' })
        } else {
          this.$router.push({ name: 'Home' })
        }
      } catch (error) {
        this.isLoading = false
        this.code = ''
        this.error = error.message
      }
    },
    async requestNewCode() {
      try {
        this.isRequestingNewCode = true
        this.isRequestingNewCodeDisabled = true // Disable requesting new code for 10 seconds
        await this.$store.dispatch('requestSignInCode', { email: this.email })
        this.$store.dispatch('addNotification', {
          text: this.$t('auth.email_verify.resend_success')
        })
        this.isRequestingNewCode = false
      } catch (error) {
        this.isRequestingNewCode = false
        this.isRequestingNewCodeDisabled = false
        this.error = error.message
      }
    }
  }
}
</script>
