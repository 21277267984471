<template>
  <footer class="footer" :class="modeClass">
    <div class="container">
      <div class="footer__inner">
        <div class="footer__row">
          <router-link class="footer__link" :to="{ name: 'Donate' }">{{
            $t('general.menu.donate')
          }}</router-link>
          <router-link class="footer__link" :to="{ name: 'FAQ' }">{{
            $t('general.menu.faq')
          }}</router-link>
          <router-link class="footer__link" :to="{ name: 'Feedback' }">{{
            $t('general.menu.feedback')
          }}</router-link>
          <router-link class="footer__link" :to="{ name: 'About' }">{{
            $t('general.menu.about')
          }}</router-link>
          <router-link class="footer__link" :to="{ name: 'Privacy' }">{{
            $t('general.menu.privacy')
          }}</router-link>
        </div>
        <div class="footer__row mt-5 mt-sm-1">
          <a
            :href="'?lang=' + otherLocale"
            :hreflang="otherLocale"
            class="footer__link"
            @click.prevent="switchLanguage"
            >{{ $t('general.menu.otherLanguage') }}</a
          >
          <div class="mx-sm-4">
            <a
              href="https://www.instagram.com/erbijapp/"
              target="_blank"
              rel="noreferrer nofollow"
              class="mr-2"
            >
              <icon :icon="['fab', 'instagram']" class="footer__social-icon instagram-icon" />
            </a>
            <a
              href="https://www.facebook.com/erbijapp/"
              target="_blank"
              rel="noreferrer nofollow"
              class="mr-2"
            >
              <icon :icon="['fab', 'facebook']" class="footer__social-icon facebook-icon" />
            </a>
            <a href="https://twitter.com/erbijapp" target="_blank" rel="noreferrer nofollow">
              <icon :icon="['fab', 'twitter']" class="footer__social-icon twitter-icon" />
            </a>
          </div>
          <span class="footer__copyright">&copy; {{ currentYear }} Erbij</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    const now = new Date()
    return {
      currentYear: now.getFullYear()
    }
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.otherLocale
    }
  },
  computed: {
    otherLocale() {
      return this.$i18n.locale === 'en' ? 'nl' : 'en'
    },
    modeClass() {
      const type = this.$route.meta.footerType

      return type ? `footer--${type}` : ''
    }
  }
}
</script>

<style lang="scss">
.footer {
  background-color: $white;
  padding: 1rem 0;

  &__social-icon {
    font-size: 1.2rem;
    @include lg-up {
      font-size: 1.5rem;
    }
  }

  &__row {
    @include md-up {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__link {
    color: $gray-600;
    font-weight: $font-weight-normal;
    &:hover,
    &:focus {
      color: $gray-500;
      text-decoration: none;
    }
  }

  &__copyright {
    color: $gray-500;
  }

  &__link,
  &__copyright {
    display: block;
    padding: 0.5rem 0;

    @include md-up {
      padding: 0.5rem;
    }
  }
}
</style>
