import { localizeDateTime, parseDateTime, parseTime } from '@/utils/date'
import { getTimeZone } from '@/utils/timeZones'

import {
  DATE_INPUT_FORMAT,
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT,
  DECISION_UNKNOWN,
  REPETITION_BIWEEKLY,
  REPETITION_WEEKLY
} from '@/utils/constants'

export function normalizeEvent(event, userId, userTimeZoneValue) {
  /*
  Normalize event object with given userId and userTimeZone
  */
  const countAttending = event.invitees.filter((x) => x.decision === DECISION_PRESENT).length
  const countNotAttending = event.invitees.filter((x) => x.decision === DECISION_NOT_PRESENT).length
  const countMaybeAttending = event.invitees.filter((x) => x.decision === DECISION_MAYBE).length
  const countUnknownAttending = event.invitees.filter((x) => x.decision === DECISION_UNKNOWN).length

  const expires = parseDateTime(event.expires_datetime)
  const hasDifferentTimeZone = event.event_tz !== userTimeZoneValue

  return {
    ...event,
    canCancel: event.can_cancel,
    canDelete: event.can_delete,
    canEdit: event.can_modify,
    canEditDecisions: event.can_modify_decisions,
    canInvite: event.can_invite_others,
    canLeave: event.can_leave,
    startDateTime: localizeDateTime(event.start_datetime, event.event_tz),
    userStartDateTime: localizeDateTime(event.start_datetime, userTimeZoneValue),
    presentTime: event.present_time ? parseTime(event.present_time) : null,
    endDateTime: localizeDateTime(event.end_datetime, event.event_tz),
    userEndDateTime: localizeDateTime(event.end_datetime, userTimeZoneValue),
    expiresDateTime: expires,
    userInvitee: event.invitees.find((x) => x.user.id === userId),
    isRepeatable: !!event.event_group_id,
    hasPassed: expires.isBefore(),
    timeZone: getTimeZone(event.event_tz),
    hasDifferentTimeZone,
    countAttending,
    countNotAttending,
    countMaybeAttending,
    countUnknownAttending,
    isCanceled: event.is_canceled,
    maxAttendees: event.max_attendees ? event.max_attendees : 0,
    isFull: event.max_attendees ? event.max_attendees <= countAttending : false
  }
}

export function normalizeEventInvitation(invitation, userId, userTimeZoneValue) {
  return {
    ...invitation,
    hasExpired: parseDateTime(invitation.expires).isBefore(),
    event: normalizeEvent(invitation.event, userId, userTimeZoneValue)
  }
}

export function denormalizeEvent(event) {
  const {
    name,
    description,
    team,
    startDateTime,
    endDateTime,
    presentTime,
    repetition,
    repetitionEndDate,
    location,
    timeZone
  } = event

  const params = {
    name,
    description,
    team: team ? team.id : null,
    start_datetime: startDateTime.format(),
    end_datetime: endDateTime ? endDateTime.format() : null,
    present_time: presentTime,
    event_tz: timeZone.value
  }

  // always give location_name, as sending an empty string
  // removes the location from the server
  params.location_name = location.name

  if (location.address) {
    params.location_address = location.address.substring(0, 200)
  }

  if (location.gps) {
    params.location_gps = [location.gps.lat, location.gps.lng].join(',')
  }

  const isRepeatingEvent =
    team &&
    repetitionEndDate &&
    (repetition === REPETITION_WEEKLY || repetition === REPETITION_BIWEEKLY)

  if (!isRepeatingEvent) return params

  params.use_repetition = true
  params.repetition_freq = 'weekly'
  params.repetition_interval = repetition === REPETITION_WEEKLY ? 1 : 2
  params.repetition_until = repetitionEndDate.format(DATE_INPUT_FORMAT)

  return params
}
