<template>
  <input
    v-if="isAndroidOrIos"
    type="time"
    v-model="inputVal"
    class="form-control time-input"
    :class="{ 'is-invalid': invalid }"
  />
  <cleave
    v-else
    v-model="inputVal"
    :options="options"
    :raw="false"
    class="form-control time-input"
    :class="{ 'is-invalid': invalid }"
  />
</template>

<script>
import Bowser from 'bowser'
import Cleave from 'vue-cleave-component'

const browser = Bowser.parse(window.navigator.userAgent)

export default {
  name: 'TimeSelect',
  components: {
    Cleave
  },
  props: {
    value: {
      type: String,
      required: false
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: {
        time: true,
        rawValueTrimPrefix: true,
        timePattern: ['h', 'm']
      },
      inputVal: this.value
    }
  },
  methods: {
    onDeleteKeyUp(event) {
      const { value } = event.target

      if (/^\d{2}:$/g.test(value)) {
        this.$emit('input', value.substring(0, 2))
      }
    }
  },
  computed: {
    className() {
      return this.invalid ? 'form-control is-invalid' : 'form-control'
    },
    isAndroidOrIos() {
      return ['iOS', 'Android'].includes(browser.os.name)
    }
  },
  watch: {
    inputVal(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.time-input {
  @include sm-up {
    width: 85px;
  }
}
</style>
