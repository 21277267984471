<template>
  <div class="page-section card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageSection'
}
</script>

<style lang="scss">
.page-section {
  overflow: hidden;

  @include xs-only {
    background-color: transparent !important;
    margin-left: -15px;
    margin-right: -15px;
    box-shadow: none;
  }
}
</style>
