export default {
  en: {
    title: 'Profile',
    settings: 'Settings',
    profile_picture: {
      title: 'Edit profile picture',
      save: 'Save photo',
      upload: 'Choose new photo',
      cancel: 'Cancel'
    },
    edit_profile: {
      title: 'Edit profile',
      first_name: 'First name',
      last_name: 'Last name',
      nickname: 'Nickname',
      profile_picture: 'Profile picture',
      edit_profile_picture_edit: 'update',
      edit_profile_picture_new: 'Upload your profile picture',
      submit: 'Save changes'
    },
    profile_saved: {
      title: 'Great!',
      text: 'Your profile data has been saved.'
    },
    settings_form: {
      language: 'Language'
    },
    avatar_saved: {
      title: "How you doin'? 😍",
      text: 'Your new profile photo has been saved.'
    }
  },
  nl: {
    title: 'Profiel',
    settings: 'Instellingen',
    profile_picture: {
      title: 'Profielfoto bewerken',
      save: 'Foto opslaan',
      upload: 'Nieuwe foto kiezen',
      cancel: 'Annuleren'
    },
    edit_profile: {
      title: 'Profiel bewerken',
      first_name: 'Voornaam',
      last_name: 'Achternaam',
      nickname: 'Bijnaam',
      profile_picture: 'Profielfoto',
      edit_profile_picture_edit: 'wijzigen',
      edit_profile_picture_new: 'Upload je profielfoto',
      submit: 'Opslaan'
    },
    profile_saved: {
      title: 'Hatsikidee!',
      text: 'Je profielgegevens zijn opgeslagen.'
    },
    settings_form: {
      language: 'Taal'
    },
    avatar_saved: {
      title: "How you doin'? 😍",
      text: 'Je nieuwe profielfoto is opgeslagen.'
    }
  }
}
