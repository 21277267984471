<template>
  <modal
    v-if="event"
    :title="$t('events.leave.title')"
    @close="$router.replace({ name: 'EventDetails' })"
    size="sm"
  >
    <div class="modal__stretcher">
      <p v-if="event.isRepeatable" v-html="$t('events.leave.text_multiple')"></p>
      <p v-else v-html="$t('events.leave.text_single')"></p>
      <div class="text-center mt-5">
        <button
          v-if="event.isRepeatable"
          :disabled="loading"
          type="submit"
          class="btn btn-danger btn-block"
          @click="onConfirmLeavePress(false)"
        >
          {{ $t('events.leave.confirm_multiple_just_this_one') }}
        </button>
        <button
          v-else
          :disabled="loading"
          type="submit"
          class="btn btn-danger btn-block"
          @click="onConfirmLeavePress(false)"
        >
          {{ $t('events.leave.confirm_single') }}
        </button>
        <button
          v-if="event.isRepeatable"
          :disabled="loading"
          type="submit"
          class="btn btn-danger btn-block"
          @click="onConfirmLeavePress(true)"
        >
          {{ $t('events.leave.confirm_multiple_plus_future') }}
        </button>
        <a
          @click="$router.replace({ name: 'EventDetails' })"
          class="d-inline-block btn btn-link mt-3"
          >{{ $t('general.buttons.cancel') }}</a
        >
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'EventLeave',
  data() {
    return {
      loading: false
    }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal
  },
  created() {
    if (!this.event.can_leave) {
      this.$router.replace({ name: 'Home' })
    }
  },
  methods: {
    onConfirmLeavePress(leaveFuture = false) {
      this.loading = true

      this.$store.dispatch('leaveEvent', { eventId: this.event.id, leaveFuture }).finally(() => {
        this.loading = false
        this.$router.replace({ name: 'Home' })
      })
    }
  }
}
</script>
