export default {
  en: {
    title: 'Feedback',
    subtitle: 'Any suggestions for Erbij?',
    intro: 'Compliments and hilarious one-liners are also welcome.',
    fields: {
      name: 'Name',
      email: 'Email address',
      email_invalid_feedback: 'We need this in order to reply to you :)',
      message: 'Your feedback',
      message_invalid_feedback: 'Try explaining it in a bit more words'
    },
    submit_label: 'Send',
    thanks: {
      subtitle: 'Much appreciated',
      intro:
        "We've received your feedback and will drop you an email if we have any questions. For now: thanks a million!"
    },
    send_more: 'Send more feedback'
  },
  nl: {
    title: 'Feedback',
    subtitle: 'Heb je nog suggesties voor Erbij?',
    intro: 'Complimentjes en hilarische moppen zijn ook welkom.',
    fields: {
      name: 'Naam',
      email: 'E-mailadres',
      email_invalid_feedback: 'Dit hebben we nodig om antwoord te geven :)',
      message: 'Jouw feedback',
      message_invalid_feedback: 'Probeer anders iets meer woorden te gebruiken'
    },
    submit_label: 'Versturen',
    thanks: {
      subtitle: 'Superdankjewel',
      intro:
        'We hebben je feedback ontvangen. Als we vragen hebben over je opmerking, sturen we je een e-mailtje. Voor nu alvast hartstike bedankt!'
    },
    send_more: 'Verstuur nog meer feedback'
  }
}
