<template>
  <div class="page-header" :class="{ 'page-header--with-img': $breakpoint.xsOnly && image }">
    <template v-if="$breakpoint.xsOnly && image">
      <div
        class="page-header__img"
        :style="{ 'background-image': 'url(' + image + ')' }"
        :class="{ 'page-header__img--busy': isBusy }"
      ></div>
      <spinner class="page-header__spinner" v-show="isBusy" />
      <button
        class="page-header__edit-photo-button"
        type="button"
        v-if="$listeners && $listeners.editPhoto && !isBusy"
        @click="$emit('editPhoto')"
      >
        <icon :icon="['fas', 'camera-alt']" />
      </button>
    </template>
    <div class="page-header__upper">
      <router-link :to="{ name: backButton.route }" v-if="backButton">
        <icon :icon="['fal', 'arrow-left']" size="sm" class="mr-2" />
        <span class="page-header__back-btn-label">{{ backButton.label }}</span>
      </router-link>
    </div>
    <h1 class="page-header__title">{{ title }}</h1>
    <div class="page-header__lower" v-if="(actions && actions.length) || ($slots && $slots.meta)">
      <div class="page-header__actions" v-if="actions && actions.length">
        <template v-for="action in actions">
          <router-link
            v-if="$breakpoint.xsOnly"
            :to="action.to"
            :key="action.icon"
            :class="{ 'page-header__action--destructive': action.isDestructive }"
            class="page-header__action"
          >
            <icon :icon="['fas', action.icon]" class="page-header__action-icon" />
          </router-link>
          <router-link
            v-else
            :to="action.to"
            :key="action.icon"
            :class="action.isDestructive ? 'btn-danger' : 'btn-primary'"
            class="btn ml-2"
          >
            <icon :icon="['fas', action.icon]" class="btn__icon--left" />
            {{ action.label }}
          </router-link>
        </template>
      </div>
      <div class="page-header__meta mt-4 mt-sm-5" v-if="$slots && $slots.meta">
        <slot name="meta"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  name: 'PageHeader',
  components: {
    Spinner
  },
  props: {
    title: {
      type: String,
      required: true
    },
    isBusy: {
      type: Boolean,
      required: false
    },
    image: {
      type: String,
      required: false
    },
    actions: {
      type: Array,
      required: false
    },
    backButton: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.page-header {
  margin: 1rem 0 1rem;
  position: relative;

  @include sm-up {
    margin: 3rem 0 2rem;
  }

  &--with-img {
    margin: 0 -15px;

    .page-header__upper {
      margin: 0;
      position: absolute;
      top: 20px;
      left: 15px;

      a {
        color: $white;
      }
    }

    .page-header__lower {
      margin: 0;
      position: absolute;
      top: 15px;
      right: 15px;
      left: 0;
    }

    .page-header__title {
      margin: 0;
      position: absolute;
      bottom: 15px;
      left: 15px;
      right: 50px;
      color: $white;
    }
  }

  &__img {
    display: block;
    height: 56.25vw; //16:9 (100 / 16*9)
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 70%,
        rgba(0, 0, 0, 0.3) 100%
      );
    }

    &--busy::before {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__spinner {
    font-size: 40px;
    color: $white;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;

    @include sm-up {
      padding-bottom: 1rem;
      border-bottom: 1px solid $gray-200;
    }

    @include xs-only {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }

  &__edit-photo-button {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: $white;
    font-size: 24px;
    margin: 0;
    @include button-unstyled;
  }

  &__upper {
    margin: 1.5rem 0;
    color: $gray-600;
    @include sm-up {
      height: 1em;
      line-height: 1;
      font-size: $font-size-lg;
      margin: 2rem 0;
    }
  }

  &__lower {
    margin-top: 1rem;
  }

  &__action {
    background-color: $primary;
    border: 1px solid $gray-300;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 0;
    color: $white;
    @include box-shadow-dark-1;

    @include hover-focus {
      opacity: 0.8;
      color: $white;
    }

    &--destructive {
      background-color: $red;
    }

    & + & {
      margin-left: 0.5rem;
    }

    &-icon {
      margin-right: 0;
      font-size: 18px;
    }
  }

  &__actions {
    @include lg-up {
      text-align: right;
    }

    @include xs-only {
      text-align: left;
      position: absolute;
      top: -2px;
      right: 0;

      .btn__icon--left {
        margin-right: 6px;
      }

      .btn {
        padding: 3px 7px;
      }
    }
  }
}
</style>
