<template>
  <div class="app-promo" :class="{ 'app-promo--show': show }">
    <div class="app-promo__inner">
      <img src="@/assets/app-icon.png" width="60" height="60" />
      <p class="app-promo__copy">
        {{ $t('general.app_promo') }}
      </p>
      <a class="app-promo__button btn btn-light" @click="close" href="https://erbij.app.link">
        {{ $t('general.buttons.switch_to_app') }}
      </a>
      <button class="app-promo__button btn btn-link text-white" @click="close">
        {{ $t('general.buttons.not_now') }}
      </button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'AppPromoBar',
  data() {
    return {
      show: false
    }
  },
  mounted() {
    setTimeout(() => {
      if (!Cookies.get('app-promo')) {
        this.show = true
      } else {
        this.$emit('destroy')
      }
    }, 1000)
  },
  methods: {
    close() {
      Cookies.set('app-promo', 'hide', { expires: 30 })
      this.show = false
      setTimeout(() => {
        this.$emit('destroy')
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.app-promo {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $blue-50;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  z-index: 5;
  transition: transform 0.3s;
  transform: translateY(100%);

  &--show {
    transform: translateY(0%);
  }

  &__inner {
    align-items: center;
    margin: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 5px 20px;
  }

  &__button {
    background-color: $white;
    border-color: $white;
    color: $blue !important;
  }

  &__copy {
    font-weight: normal;
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.25;
    color: $white;
    text-align: center;
  }

  @include sm-up {
    &__inner {
      display: flex;
      text-align: left;
    }

    &__copy {
      margin: 0 30px 0 0;
    }
  }
}
</style>
