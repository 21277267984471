<template>
  <wizard
    :title="$t('calendar_sync.title')"
    @close="$router.push({ name: 'Home' })"
    class="calendar-sync"
  >
    <div class="wizard__screen">
      <div class="wizard__section">
        <p>{{ $t('calendar_sync.intro') }}</p>
        <a
          :href="url"
          class="btn btn-sm-lg btn-secondary mt-3"
          target="_blank"
          rel="noreferrer nofollow"
          >{{ $t('calendar_sync.subscribe') }}</a
        >
        <p
          class="small alert alert-primary mt-sm-5 mt-3 mb-0"
          v-html="$t('calendar_sync.disclaimer')"
        />
      </div>
      <div class="wizard__section">
        <p class="mb-2">{{ $t('calendar_sync.copy_text') }}</p>
        <div
          @click="onCopyClick"
          :class="{ 'cursor-pointer': canCopy }"
          class="alert alert-light text-left text-sm d-flex align-items-center justify-content-between"
          role="alert"
          id="copyBtn"
        >
          <span :class="{ 'text-no-select': canCopy }">{{ url }}</span>
          <icon :icon="['fal', 'copy']" size="lg" />
        </div>
        <b-tooltip
          target="copyBtn"
          :title="$t('calendar_sync.copy_success')"
          :show.sync="showCopyTooltip"
          triggers=""
          placement="top"
        />
        <div class="alert alert-warning mt-4 mb-0" role="alert" v-if="error">{{ error }}</div>
        <ul class="read-more-list">
          <li>
            <a href="https://support.apple.com/HT202361">{{
              $t('calendar_sync.vendor_instructions.apple_calendar')
            }}</a>
          </li>
          <li>
            <a
              href="https://support.google.com/calendar/answer/37100?co=GENIE.Platform%3DDesktop"
              >{{ $t('calendar_sync.vendor_instructions.google_calendar') }}</a
            >
          </li>
          <li>
            <a
              href="https://www.ibm.com/support/knowledgecenter/en/SSKTWP_10.0.0/cal_impexp_t.html"
              >{{ $t('calendar_sync.vendor_instructions.lotus') }}</a
            >
          </li>
          <li>
            <a href="https://support.mozilla.org/kb/creating-new-calendars#w_icalendar-ics">{{
              $t('calendar_sync.vendor_instructions.thunderbird')
            }}</a>
          </li>
          <li>
            <a href="https://help.yahoo.com/kb/unfollow-calendars-yahoo-mail-sln22051.html">{{
              $t('calendar_sync.vendor_instructions.yahoo_calendar')
            }}</a>
          </li>
          <li>
            <a
              href="https://support.office.com/article/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c"
              >{{ $t('calendar_sync.vendor_instructions.outlook') }}</a
            >
          </li>
          <li>
            <a
              href="https://support.office.com/article/See-your-Google-Calendar-in-Outlook-C1DAB514-0AD4-4811-824A-7D02C5E77126"
              >{{ $t('calendar_sync.vendor_instructions.outlook_desktop') }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </wizard>
</template>

<script>
import Wizard from '@/components/Wizard'
import { getInstance } from '@/utils/api'
import { CALENDAR_SYNC_PRIVILEGE, NOTIFICATION_TYPE_WARNING } from '@/utils/constants'
export default {
  name: 'CalendarSync',
  data() {
    return {
      url: null,
      showCopyTooltip: false,
      canCopy: !!window.navigator.clipboard,
      error: null
    }
  },
  created() {
    if (this.$store.getters.hasPrivilege(CALENDAR_SYNC_PRIVILEGE)) {
      const api = getInstance('events')

      return api
        .get('/ical-feed/')
        .then((response) => (this.url = response.data.url))
        .catch((error) => (this.error = error.message))
    } else {
      this.$store
        .dispatch('addNotification', {
          text: this.$i18n.t('errors.calendar_sync.missing_privilege'),
          type: NOTIFICATION_TYPE_WARNING
        })
        .then(() => this.$router.replace({ name: 'Home' }))
    }
  },
  components: {
    Wizard
  },
  methods: {
    onCopyClick() {
      if (!window.navigator.clipboard) return

      navigator.clipboard.writeText(this.url).then(() => {
        this.showCopyTooltip = true

        setTimeout(() => (this.showCopyTooltip = false), 1500)
      })
    }
  }
}
</script>

<style lang="scss">
.calendar-sync {
  @include sm-up {
    text-align: center;
  }
}
</style>
