<template>
  <div class="wizard__screen--has-actions wizard__summary">
    <modal
      v-if="showPushType"
      :title="$t('events.choose_push_type.title')"
      @close="showPushType = false"
      size="sm"
    >
      <div class="modal__stretcher">
        <div>
          <p>{{ $t('events.choose_push_type.text') }}</p>
          <div class="text-center mt-2">
            <button
              :disabled="this.loading"
              class="btn btn-primary btn-block mt-5"
              @click="onSendNotificationsPress(EDIT_EVENT_PUSH_TYPE_ALL_INVITED)"
            >
              {{ $t('events.choose_push_type.allInvited') }}
            </button>
            <button
              :disabled="this.loading"
              class="btn btn-primary btn-block mt-3"
              @click="onSendNotificationsPress(EDIT_EVENT_PUSH_TYPE_ALL_GOING)"
            >
              {{ $t('events.choose_push_type.allGoing') }}
            </button>
            <button
              :disabled="this.loading"
              class="btn btn-primary btn-block mt-3"
              @click="onSendNotificationsPress(EDIT_EVENT_PUSH_TYPE_NONE)"
            >
              {{ $t('events.choose_push_type.none') }}
            </button>
          </div>
        </div>
      </div>
    </modal>
    <h2 class="h4 wizard__subtitle">{{ title }}</h2>
    <div class="wizard__section py-0">
      <div class="summary-section" v-if="!eventId" @click="navigate('Who')">
        <dl class="row">
          <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.invite_type')" />
          <dd class="col-8 summary-section__value" v-text="teamText" />
        </dl>
      </div>
      <div class="summary-section" @click="navigate('What')">
        <dl class="row">
          <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.name')" />
          <dd class="col-8 summary-section__value" v-text="name" />
          <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.description')" />
          <dd
            class="col-8 summary-section__value white-space-preline"
            v-text="description || '-'"
          />
        </dl>
      </div>
      <div data-cy="when" class="summary-section" @click="navigate('When')">
        <dl class="row">
          <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.start')" />
          <dd class="col-8 summary-section__value" v-text="formatDateTime(startDateTime)" />
          <template v-if="presentTime">
            <dt class="col-4 summary-section__key">
              {{ $t('event_wizard.summary.present') }}
            </dt>
            <dd class="col-8 summary-section__value">{{ presentTime }}</dd>
          </template>
          <dt class="col-4 summary-section__key">
            {{ $t('event_wizard.summary.end') }}
          </dt>
          <dd
            class="col-8 summary-section__value"
            v-text="endDateTime ? formatDateTime(endDateTime) : $t('event_wizard.summary.no_end')"
          />
          <template v-if="hasDifferentTimeZone">
            <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.time_zone')" />
            <dd class="col-8 summary-section__value" v-text="timeZoneText" />
          </template>
        </dl>
      </div>
      <div class="summary-section" v-if="!eventId" @click="navigate('When')">
        <dl class="row">
          <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.repetition')" />
          <dd class="col-8 summary-section__value" v-text="repetition" />
          <template v-if="repetitionEndDate">
            <dt
              class="col-4 summary-section__key"
              v-text="$t('event_wizard.summary.repetion_end_date')"
            />
            <dd class="col-8 summary-section__value" v-text="formatDate(repetitionEndDate)" />
          </template>
        </dl>
      </div>
      <div class="summary-section" @click="navigate('Where')">
        <dl class="row">
          <dt class="col-4 summary-section__key" v-text="$t('event_wizard.summary.location')" />
          <dd class="col-8 summary-section__value" v-text="locationText" />
        </dl>
      </div>
    </div>
    <div class="wizard__footer">
      <div class="form-group row align-items-center" v-if="showEditType">
        <label for="updateFutureEvents" class="col-9 col-form-label">{{
          $t('event_wizard.summary.also_update_future')
        }}</label>
        <div class="col-3 text-right">
          <toggle-switch id="updateFutureEvents" v-model="updateFutureEvents" />
        </div>
      </div>
      <div class="wizard__actions flex-row-reverse" v-if="eventId">
        <button class="btn btn-primary btn-block" @click="showPushType = true">
          {{ $t('general.buttons.save_changes') }}
          <icon :icon="['fal', 'thumbs-up']" class="btn__icon--right" size="lg" />
        </button>
      </div>
      <div class="wizard__actions" v-else>
        <button class="btn btn-outline" @click="onPrevPress">
          <icon class="btn__icon--left" :icon="['fal', 'arrow-left']" />
          {{ $t('general.buttons.back') }}
        </button>
        <button class="btn btn-primary" @click="onSavePress" :disabled="loading">
          {{ $t('general.buttons.save') }}
          <icon :icon="['fal', 'thumbs-up']" class="btn__icon--right" size="lg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import ToggleSwitch from '@/components/ToggleSwitch'
import {
  EDIT_EVENT_PUSH_TYPE_ALL_GOING,
  EDIT_EVENT_PUSH_TYPE_ALL_INVITED,
  EDIT_EVENT_PUSH_TYPE_NONE,
  MODIFY_TYPE_FUTURE,
  MODIFY_TYPE_SINGLE,
  REPETITION_BIWEEKLY,
  REPETITION_ONCE,
  REPETITION_WEEKLY
} from '@/utils/constants'
import { getTimeZoneLabel } from '@/utils/timeZones'

export default {
  name: 'EventWizardSummary',
  props: {
    eventId: {
      type: Number,
      required: false
    }
  },
  components: {
    Modal,
    ToggleSwitch
  },
  computed: {
    timeZoneText() {
      return getTimeZoneLabel(this.timeZone)
    },
    title() {
      return this.eventId
        ? this.$i18n.t('event_wizard.summary.edit_title')
        : this.$i18n.t('event_wizard.summary.add_title')
    },
    teamText() {
      return this.team
        ? this.$i18n.t('event_wizard.summary.invite_type_team', {
            team: this.team.name
          })
        : this.$i18n.t('event_wizard.summary.invite_type_no_team')
    },
    locationText() {
      if (!this.location) return '-'

      if (this.location.name && this.location.address) {
        return `${this.location.name}, ${this.location.address}`
      } else if (this.location.name) {
        return this.location.name
      } else if (this.location.address) {
        return this.location.address
      }

      return '-'
    },
    editType() {
      return this.updateFutureEvents ? MODIFY_TYPE_FUTURE : MODIFY_TYPE_SINGLE
    },
    hasDifferentTimeZone() {
      return this.timeZone !== this.$store.getters.userTimeZone
    }
  },
  data() {
    const wizardValues = this.$store.getters.wizardValues
    const { repetition, ...otherValues } = wizardValues
    const isRepeatable = this.eventId
      ? this.$store.getters.getEventById(this.eventId).isRepeatable
      : false

    return {
      ...otherValues,
      showPushType: false,
      updateFutureEvents: false,
      showEditType: isRepeatable,
      EDIT_EVENT_PUSH_TYPE_ALL_GOING,
      EDIT_EVENT_PUSH_TYPE_ALL_INVITED,
      EDIT_EVENT_PUSH_TYPE_NONE,
      loading: false,
      repetition: {
        [REPETITION_ONCE]: this.$i18n.t('event_wizard.when.repetition_once'),
        [REPETITION_WEEKLY]: this.$i18n.t('event_wizard.when.repetition_weekly'),
        [REPETITION_BIWEEKLY]: this.$i18n.t('event_wizard.when.repetition_biweekly')
      }[repetition]
    }
  },
  methods: {
    navigate(screen) {
      const prefix = this.eventId ? 'EditEventWizard' : 'AddEventWizard'
      this.$router.push({
        name: prefix + screen,
        params: { fromOverview: true }
      })
    },
    formatDateTime(dateTime) {
      const format = this.$breakpoint.smAndUp
        ? this.$i18n.t('general.datetime_format.long')
        : this.$i18n.t('general.datetime_format.medium')
      return dateTime.format(format)
    },
    formatDate(date) {
      const format = this.$breakpoint.smAndUp
        ? this.$i18n.t('general.date_format.long')
        : this.$i18n.t('general.date_format.medium')
      return date.format(format)
    },
    onPrevPress() {
      // Back to event details if we're editting
      // or back to 'Where' step in case were creating a new event
      this.$router.push({
        name: this.eventId ? 'EventDetails' : 'AddEventWizardWhere'
      })
    },
    async onSavePress() {
      this.loading = true

      try {
        const events = await this.$store.dispatch('addEvent', this.$store.state.wizards.event)
        this.$store.dispatch('resetWizard')
        this.$router.replace({
          name: 'EventAddSuccess',
          query: { event_id: events[0].id }
        })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    async onSendNotificationsPress(pushType) {
      this.loading = true

      try {
        await this.$store.dispatch('editEvent', {
          id: this.eventId,
          values: this.$store.state.wizards.event,
          pushType,
          editType: this.editType
        })

        this.loading = false

        this.$store.dispatch('resetWizard')
        this.$router.push({ name: 'EventDetails' })
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.summary-section {
  cursor: pointer;
  margin: 0 -15px;
  padding: 15px;

  @include sm-up {
    padding: 20px;
    margin: 0 -20px;
  }

  @include xxl-up {
    padding: 30px;
    margin: 0 -30px;
  }

  dl {
    margin-bottom: 0;
  }

  &:hover {
    background: $gray-100;
  }

  & + & {
    border-top: 1px solid $gray-300;
  }

  &__value {
    color: $gray-700;
    text-align: right;
    overflow-wrap: break-word;
    word-wrap: break-word;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
