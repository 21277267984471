<template>
  <div class="wizard__screen--has-actions wizard__where">
    <h2 class="h4 wizard__subtitle">{{ $t('event_wizard.where.title') }}</h2>
    <div class="wizard__section">
      <div class="location-search">
        <div class="location-search__input-wrapper">
          <GmapAutocomplete
            :placeholder="$t('event_wizard.where.search_placeholder')"
            @place_changed="setPlace"
            id="inputLocation"
            class="location-search__input"
            tabindex="1"
            ref="queryInput"
            @input.native="onInput"
            :select-first-on-enter="true"
          >
          </GmapAutocomplete>
          <icon :icon="['fal', 'search']" class="location-search__search-icon" />
        </div>
        <div
          class="location-search__map-container"
          :class="{ 'location-search__map-container--visible': location && location.gps }"
        >
          <LocationPreview
            v-if="location && location.gps"
            v-bind="location"
            class="location-search__map"
            @clear="onClear"
            @nameChange="onLocationNameChange"
          />
        </div>
      </div>
    </div>
    <div class="wizard__footer">
      <div class="wizard__actions" v-if="eventId">
        <button class="btn btn-responsive btn-outline" type="button" @click="onCancelPress">
          <icon class="btn__icon--left" :icon="['fal', 'times']" />{{
            $t('general.buttons.cancel')
          }}
        </button>
        <button class="btn btn-responsive btn-primary" @click="onSubmit">
          {{ $t('general.buttons.accept_changes')
          }}<icon :icon="['fal', 'check']" class="btn__icon--right" />
        </button>
      </div>
      <div class="wizard__actions" v-else-if="$route.params.fromOverview">
        <button class="btn btn-responsive btn-outline" type="button" @click="onCancelPress">
          <icon class="btn__icon--left" :icon="['fal', 'times']" />{{
            $t('general.buttons.cancel')
          }}
        </button>
        <button class="btn btn-responsive btn-secondary" tabindex="4" @click="onSubmit">
          {{ $t('general.buttons.continue')
          }}<icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
      <div class="wizard__actions" v-else>
        <button class="btn btn-outline" @click="onPrevPress">
          <icon class="btn__icon--left" :icon="['fal', 'arrow-left']" />{{
            $t('general.buttons.back')
          }}
        </button>
        <button class="btn btn-secondary" @click="onSubmit">
          {{ $t('general.buttons.next')
          }}<icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LocationPreview from '@/components/LocationPreview'
export default {
  name: 'EventWizardWhere',
  props: {
    eventId: {
      type: Number,
      required: false
    }
  },
  components: {
    LocationPreview
  },
  data() {
    const location = this.$store.getters.getWizardValue('location') || null
    return {
      location: location,
      query: '',
      place: null
    }
  },
  mounted() {
    const input = this.$refs.queryInput.$refs.input
    input.focus()

    if (this.location && !this.location.gps) {
      input.value = this.query = this.location.name
    }
  },
  methods: {
    onInput(event) {
      this.query = event.target.value
    },
    onClear() {
      const input = this.$refs.queryInput.$refs.input

      this.place = null
      this.location = null
      input.value = ''
      input.focus()
    },
    onLocationNameChange(newName) {
      // if input was left empty, use original name
      if (!newName && this.place) {
        newName = this.place.name
      }

      this.location.name = newName
    },
    setPlace(place) {
      if (!place.geometry) return

      this.place = place

      const { name, formatted_address, geometry } = place

      this.location = {
        name,
        address: formatted_address,
        gps: {
          lat: geometry.location.lat(),
          lng: geometry.location.lng()
        }
      }
    },
    onPrevPress() {
      this.$router.push({ name: 'AddEventWizardWhen' })
    },
    onCancelPress() {
      if (this.eventId) {
        this.$router.push({ name: 'EditEventWizardOverview' })
      } else {
        this.$router.push({ name: 'AddEventWizardSummary' })
      }
    },
    onSubmit() {
      this.$store
        .dispatch('setWizardValues', {
          location: this.location && this.location.gps ? this.location : { name: this.query }
        })
        .then(() =>
          this.$router.push({
            name: this.eventId ? 'EditEventWizardOverview' : 'AddEventWizardSummary'
          })
        )
    }
  }
}
</script>

<style lang="scss">
.pac-item {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: 30px;
  border: 0;
}

.pac-item:hover,
.pac-item-selected {
  background: mix($primary, $white, 10);
}

.pac-item-query {
  font-size: $font-size-base;
}

.pac-icon {
  display: none;
}

.pac-container {
  margin-top: 10px;
  border: 0;
  box-shadow: none;
}

.location-search {
  &__current-value {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  &__map-container {
    overflow: hidden;
    opacity: 0;
    height: 150px;
    position: relative;
    width: 100%;
    margin-top: 3rem;
    transition-duration: 0.3s;
    transition-property: height, opacity;

    &--visible {
      height: 300px;
      opacity: 1;
    }
  }

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__input-wrapper {
    position: relative;
  }

  &__search-icon {
    position: absolute;
    right: 0;
    top: calc(50% - 0.5em);
    font-size: 20px;
    color: $gray-700;
  }

  &__input {
    width: 100%;
    border: 0;
    transition: box-shadow 0.2s;
    box-shadow: 0 1px 0 $gray-300;
    font-size: 1.3rem;
    color: $gray-700;
    line-height: 1.5em;
    border-radius: 0;
    padding: 0.25em 30px 0.25em 0;
    appearance: none;

    &:focus {
      box-shadow: 0 2px 0 $grimson;
      outline: 0;
    }
  }

  @include sm-up {
    padding: 0 20px;
  }
}
</style>
