<template>
  <modal @close="$router.push({ name: 'Donate' })" size="sm" class="text-center">
    <img
      src="@/assets/gifs/donation/cancelled.gif"
      class="rounded-circle mb-5 mt-3 mx-auto"
      height="200"
      width="200"
    />
    <h1 class="h4">{{ $t('about.donation_cancelled.title') }}</h1>
    <p>{{ $t('about.donation_cancelled.text') }}</p>
    <a
      href="https://www.paypal.com/donate/?hosted_button_id=BTTTA2JQWY9KE"
      class="btn btn-primary mt-3"
    >
      <icon :icon="['fas', 'coffee']" class="btn__icon--left" />
      {{ $t('about.donation_cancelled.try_again') }}
    </a>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'DonationCancelled',
  components: {
    Modal
  }
}
</script>
