<template>
  <b-form @submit.prevent="onSubmit" class="text-left">
    <div class="wizard__section">
      <h1 class="h4 mb-5 text-center">{{ $t('onboarding.complete_profile.title') }}</h1>
      <b-form-group
        horizontal
        :label-cols="4"
        breakpoint="sm"
        :label="$t('profile.edit_profile.first_name')"
        label-for="inputFirstName"
      >
        <b-form-input
          v-model="form.firstName"
          :placeholder="$t('general.fields.first_name_placeholder')"
          id="inputFirstName"
          :state="$v.form.firstName.$dirty ? !$v.form.firstName.$invalid : null"
          autocomplete="given-name"
        />
        <b-form-invalid-feedback id="inputFirstName">{{
          $t('errors.validations.required')
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        horizontal
        :label-cols="4"
        breakpoint="sm"
        class="mt-4"
        :label="$t('profile.edit_profile.last_name')"
        label-for="inputLastName"
      >
        <b-form-input
          v-model="form.lastName"
          :placeholder="$t('general.fields.last_name_placeholder')"
          id="inputLastName"
          :state="$v.form.lastName.$dirty ? !$v.form.lastName.$invalid : null"
          autocomplete="family-name"
        />
        <b-form-invalid-feedback id="inputLastName">{{
          $t('errors.validations.required')
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        horizontal
        :label-cols="4"
        breakpoint="sm"
        class="mt-4"
        :label="$t('profile.edit_profile.nickname')"
        label-for="inputNickname"
      >
        <b-form-input
          v-model="form.nickname"
          :placeholder="$t('general.fields.nickname_placeholder')"
          id="inputNickname"
          :state="$v.form.nickname.$dirty ? !$v.form.nickname.$invalid : null"
          autocomplete="nickname"
        />
        <b-form-invalid-feedback id="inputNickname">{{
          $t('errors.validations.required')
        }}</b-form-invalid-feedback>
      </b-form-group>
    </div>
    <div class="wizard__footer">
      <div class="wizard__actions" :class="{ 'flex-row-reverse': !$route.params.canGoBack }">
        <router-link
          :to="{ name: 'ActivationSuccess' }"
          class="btn-link"
          v-if="$route.params.canGoBack"
        >
          <icon :icon="['fal', 'arrow-left']" class="btn__icon--left" />{{
            $t('general.buttons.back')
          }}
        </router-link>
        <button type="submit" class="btn btn-secondary" :disabled="loading">
          {{ $t('onboarding.complete_profile.submit') }}
          <icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
    </div>
  </b-form>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'CompleteProfile',
  data() {
    const profile = this.$store.getters.profile

    return {
      loading: false,
      form: {
        firstName: profile.first_name,
        lastName: profile.last_name,
        nickname: profile.nickname
      }
    }
  },
  validations: {
    form: {
      firstName: { required, maxLength: maxLength(30) },
      lastName: { required, maxLength: maxLength(30) },
      nickname: { maxLength: maxLength(30) }
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.$error) return
      this.loading = true
      this.$store
        .dispatch('editProfile', this.form)
        .then(() => {
          this.$router.push({ name: 'UploadAvatar' })
        })
        .finally(() => (this.loading = false))
    }
  },
  computed: mapGetters({
    profile: 'profile'
  })
}
</script>
