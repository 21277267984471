<template>
  <div class="custom-modal">
    <div
      class="custom-modal__dialog custom-modal__dialog--centered"
      :class="'custom-modal__dialog--' + size"
    >
      <div class="custom-modal__content">
        <button class="custom-modal__close" type="button" @click="onCloseClick">
          <icon :icon="['fal', 'times']" />
        </button>
        <h1 class="h4 custom-modal__title mb-4" v-if="title">{{ title }}</h1>
        <slot></slot>
      </div>
    </div>
    <div class="custom-modal__backdrop" @click="onCloseClick"></div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false
    }
  },
  created() {
    document.body.classList.add('modal-open')
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
  },
  methods: {
    onCloseClick() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.custom-modal {
  $margin-y: 3rem;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1040;

  &__close {
    @include button-unstyled;
    color: $gray-700;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 2rem;
    line-height: 1em;

    @include sm-up {
      right: 1rem;
      top: 1rem;
      font-size: 18px;
    }
  }

  &__title {
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-200;
  }

  &__dialog {
    position: relative;
    z-index: 1;
    height: 100%;
    pointer-events: none;

    @include sm-up {
      height: auto;
      max-width: 600px;
      margin: $margin-y auto;

      &.custom-modal__dialog--xs {
        max-width: 350px;
      }

      &.custom-modal__dialog--sm {
        max-width: 400px;
      }

      &.custom-modal__dialog--lg {
        max-width: 800px;
      }
    }

    &--centered {
      @include sm-up {
        min-height: calc(100% - #{$margin-y * 2});
        display: flex;
        align-items: center;
      }
    }
  }

  &__content {
    position: relative;
    width: 100%;
    background: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    padding: 15px;

    @include sm-up {
      padding: 2rem;
      height: auto;
      @include box-shadow-dark-2;
      border-radius: 4px;
    }
  }

  &__stretcher {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__backdrop {
    opacity: 0.7;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
  }
}
</style>
