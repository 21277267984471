<template>
  <event-overview v-if="isAuthenticated" />
  <landing v-else />
</template>

<script>
import EventOverview from './events/EventOverview'
import Landing from './landing/Landing'

export default {
  name: 'Home',
  components: {
    Landing,
    EventOverview
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  }
}
</script>
