export default {
  en: {
    title: 'Cookies',
    intro: [
      'Erbij uses cookies when you visit our website (erbij.app). Sorry, no stroopwafels, lady fingers or biscuits, but small (temporary) text files stored on your computer.',
      "Thanks to cookies, you don't have to enter or download the same information every time you return to our site. In addition, cookies help us understand how you use our sites and how to make it better and more user-friendly."
    ],
    name: 'Name',
    function: 'Function',
    period: 'Storage period',
    ga_cookie: {
      title: 'Google Analytics',
      description:
        'Using Google Analytics, we measure how you use the Erbij website and how you found us. We then use this data to improve our website.'
    },
    hide_bar_cookie: {
      title: 'Hide cookie bar',
      description: "Makes sure that the cookie bar isn't displayed anymore once you've closed it."
    },
    lang_cookie: {
      title: 'Language preference',
      description: 'Makes sure that this website is shown in your preferred language.'
    },
    bar: {
      text: "We use cookies to improve your experience on this website. We don't store personal details in cookies.",
      read_more: 'Read more',
      close: 'Understood'
    }
  },
  nl: {
    title: 'Cookies',
    intro: [
      'Erbij deelt cookies uit wanneer je onze website (erbij.app) bezoekt. Geen stroopwafels, lange vingers of gevulde koeken, maar kleine (tijdelijke) tekstbestanden die worden opgeslagen op je computer.',
      'Dankzij cookies hoef je niet steeds dezelfde informatie in te voeren of te downloaden wanneer je bij ons terugkomt. Bovendien helpen ze ons inzien hoe je onze sites gebruikt en hoe wij de boel beter en gebruiksvriendelijker kunnen maken.'
    ],
    name: 'Naam',
    function: 'Functie',
    period: 'Bewaartermijn',
    ga_cookie: {
      title: 'Google Analytics',
      description:
        'Met Google Analytics meten we hoe je de Erbij-website gebruikt en hoe je ons hebt gevonden. Deze kennis gebruiken we vervolgens weer om de website te verbeteren.'
    },
    hide_bar_cookie: {
      title: 'Cookiemelding verbergen',
      description:
        'Zorgt ervoor dat de cookiemelding niet meer wordt weergeven als je hem eenmaal hebt geaccepteerd.'
    },
    lang_cookie: {
      title: 'Taalvoorkeur',
      description: 'Zorgt ervoor dat deze website in jouw voorkeurstaal wordt getoond.'
    },
    bar: {
      text: 'Onze website gebruikt cookies zodat-ie fatsoenlijk functioneert en waardoor we kunnen meten hoe je de website precies gebruikt.',
      read_more: 'Meer over cookies',
      close: 'Oké dan'
    }
  }
}
