import i18n from '@/i18n'
import { getInstance } from '@/utils/api'
import { captureApiException } from '@/utils/errors'
import bowser from 'bowser'

export const api = getInstance('feedback')

export default {
  sendFeedback: ({ dispatch }, { name, email, message }) => {
    const browser = bowser.getParser(window.navigator.userAgent)
    const browserInfo = browser.parse()
    const deviceInfo = JSON.stringify(browserInfo.parsedResult)

    return api
      .post('/', {
        name,
        email,
        content: message,
        device_info: deviceInfo
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.feedback.send_feedback')
        })

        captureApiException(error)

        throw error
      })
  }
}
