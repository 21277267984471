<template>
  <div
    class="wizard__screen--gateway wizard__who"
    :class="{ 'wizard__screen--modal-visible': isAddTeamModalVisible }"
  >
    <h2 class="h4 wizard__subtitle">{{ $t('event_wizard.who.title') }}</h2>
    <div class="wizard__section p-md-5">
      <p class="lead mb-2">{{ $t('event_wizard.who.team_text') }}</p>
      <p class="text-sm text-muted mb-3 mb-sm-5">{{ $t('event_wizard.who.team_explanation') }}</p>
      <div v-if="isTeamsLoading || teams.length">
        <div class="form-row justify-content-center">
          <div class="col-sm-auto">
            <select v-model="team" class="custom-select mb-3 mb-sm-0" :disabled="isTeamsLoading">
              <option v-for="team in teams" :key="team.id" :value="team.id">{{ team.name }}</option>
            </select>
          </div>
          <div class="col-sm-12 mt-sm-3 mt-md-0 col-md-auto">
            <button
              class="btn btn-inline-sm btn-secondary"
              :disabled="isTeamsLoading"
              @click="onSubmitGroupPress"
            >
              {{ $t('event_wizard.who.team_button_label') }}
            </button>
          </div>
        </div>
        <div class="text-center">
          <p class="text-muted mb-0 mt-3">
            {{ $t('event_wizard.who.create_more_teams') }}
            <a @click.prevent="isAddTeamModalVisible = true" href="#">{{
              $t('event_wizard.who.create_more_teams_cta')
            }}</a>
          </p>
        </div>
      </div>
      <template v-else>
        <div class="text-center">
          <p class="text-muted">{{ $t('event_wizard.who.no_teams') }}</p>
          <button @click="isAddTeamModalVisible = true" class="btn btn-inline-sm btn-secondary">
            {{ $t('event_wizard.who.add_team') }}
          </button>
        </div>
      </template>
      <modal
        :title="$t('teams.add.title')"
        @close="isAddTeamModalVisible = false"
        size="sm"
        v-if="isAddTeamModalVisible"
        class="text-left"
      >
        <p class="mb-5 text-muted text-sm">{{ $t('teams.overview.no_teams.text') }}</p>
        <team-form class="modal__stretcher" @submit="onFormSubmit" />
      </modal>
    </div>
    <div class="wizard__section p-md-5">
      <p class="lead mb-2">{{ $t('event_wizard.who.no_team_text') }}</p>
      <p class="text-sm text-muted mb-3 mb-sm-5">
        {{ $t('event_wizard.who.no_team_explanation') }}
      </p>
      <button class="btn btn-inline-sm btn-secondary" @click="onViaLinkPress">
        {{ $t('event_wizard.who.no_team_button_label') }}
      </button>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import TeamForm from '@/components/TeamForm'
import { REPETITION_ONCE } from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'EventWizardWho',
  props: {
    eventId: {
      type: Number,
      required: false
    }
  },
  components: {
    Modal,
    TeamForm
  },
  data() {
    const teams = this.$store.getters.teams
    const currentValue = this.$store.getters.getWizardValue('team')

    return {
      isAddTeamModalVisible: false,
      team: currentValue ? currentValue.id : teams.length ? teams[0].id : null
    }
  },
  computed: {
    ...mapGetters({
      isTeamsLoading: 'isTeamsLoading'
    }),
    teams() {
      return this.$store.getters.teams.filter((team) => team.user_can_add_events)
    }
  },
  watch: {
    teams(teams) {
      if (teams) {
        this.team = teams[0].id
      }
    }
  },
  methods: {
    navigate() {
      const nextRoute = this.$route.params.fromOverview
        ? 'AddEventWizardSummary'
        : 'AddEventWizardWhat'

      this.$router.push({ name: nextRoute })
    },
    onFormSubmit(values) {
      this.$store
        .dispatch('createTeam', {
          name: values.name,
          type: values.type,
          onlyManagersCanAddEvents: values.managersOnly
        })
        .then(() => {
          this.isAddTeamModalVisible = false
        })
    },
    onViaLinkPress() {
      this.$store
        .dispatch('setWizardValues', {
          team: null,
          repetition: REPETITION_ONCE,
          repetitionEndDate: null
        })
        .then(this.navigate)
    },
    onSubmitGroupPress() {
      this.$store
        .dispatch('setWizardValues', {
          team: this.$store.getters.getTeamById(this.team)
        })
        .then(this.navigate)
    }
  }
}
</script>
