import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { determineInitialLocale } from '@/utils/i18n'

import about from './about'
import auth from './auth'
import calendarSync from './calendarSync'
import cookies from './cookies'
import errors from './errors'
import events from './events'
import eventWizard from './eventWizard'
import faq from './faq'
import feedback from './feedback'
import general from './general'
import home from './home'
import onboarding from './onboarding'
import profile from './profile'
import teams from './teams'
import upgrade from './upgrade'
import warning_android from './warning_android'

Vue.use(VueI18n)

const sections = {
  about,
  auth,
  calendar_sync: calendarSync,
  cookies,
  errors,
  event_wizard: eventWizard,
  events,
  faq,
  feedback,
  general,
  home,
  onboarding,
  profile,
  teams,
  upgrade,
  warning_android
}

const messages = {}

for (const section in sections) {
  for (const lang in sections[section]) {
    if (!messages[lang]) messages[lang] = {}
    messages[lang][section] = sections[section][lang]
  }
}

export default new VueI18n({
  locale: determineInitialLocale(),
  messages
})
