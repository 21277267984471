<template>
  <b-form @submit.prevent="onSubmit" novalidate class="team-form">
    <slot />
    <input v-if="id" type="hidden" name="id" :value="id" />
    <b-form-group
      horizontal
      :label-cols="3"
      breakpoint="sm"
      :label="$t('teams.add_edit_form.name')"
      label-for="inputName"
    >
      <b-form-input
        v-model="form.name"
        :placeholder="$t('teams.add_edit_form.name_placeholder')"
        id="inputName"
        autofocus
        maxlength="30"
        :state="$v.form.name.$dirty ? !$v.form.name.$invalid : null"
      />
      <b-form-invalid-feedback id="inputNameFeedback">{{
        $t('errors.validations.required')
      }}</b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      horizontal
      :label-cols="3"
      breakpoint="sm"
      :label="$t('teams.add_edit_form.type')"
      label-for="inputType"
    >
      <b-form-select
        v-model="form.type"
        :placeholder="$t('teams.add_edit_form.type_placeholder')"
        id="inputType"
        :options="typeOptions"
        :state="$v.form.type.$dirty ? !$v.form.type.$invalid : null"
      />
      <b-form-invalid-feedback id="inputTypeFeedback">{{
        $t('errors.validations.required')
      }}</b-form-invalid-feedback>
    </b-form-group>
    <div class="form-group row align-items-center">
      <label for="managersOnly" class="col-9 col-form-label">{{
        $t('teams.add_edit_form.only_managers_can_add_events')
      }}</label>
      <div class="col-3 text-right">
        <toggle-switch id="managersOnly" v-model="form.managersOnly" />
      </div>
    </div>
    <div class="text-center mt-4">
      <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
        {{ id ? $t('teams.edit.submit') : $t('teams.add.submit') }}
      </button>
      <router-link :to="{ name: 'TeamDetails' }" class="d-inline-block mt-4">{{
        $t('general.buttons.cancel')
      }}</router-link>
    </div>
  </b-form>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators'
import getTypes from '@/utils/teamTypes'
import ToggleSwitch from '@/components/ToggleSwitch'
export default {
  name: 'TeamForm',
  props: {
    id: Number,
    name: String,
    type: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    managersOnly: Boolean
  },
  data() {
    const typeOptions = getTypes().map((type) => ({
      value: type.id,
      text: type.label
    }))

    typeOptions.unshift({
      value: 0,
      text: this.$t('teams.add_edit_form.type_placeholder')
    })

    return {
      typeOptions,
      form: {
        name: this.name,
        type: this.type,
        managersOnly: this.managersOnly
      }
    }
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.$error) return

      const values = {
        name: this.form.name,
        type: this.form.type,
        managersOnly: this.form.managersOnly
      }

      if (this.id) values.id = this.id

      this.$emit('submit', values)
    }
  },
  validations: {
    form: {
      name: { required },
      type: { minValue: minValue(1) }
    }
  },
  components: {
    ToggleSwitch
  }
}
</script>

<style lang="scss">
.team-form label {
  color: $gray-600;
}
</style>
