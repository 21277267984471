export default {
  en: {
    activation_success: {
      title: "You're in",
      subtitle: '{name}, good news!',
      text: 'You are almost ready to start using Erbij. We just want to double-check if you are who we think you are.',
      complete_profile: 'Complete profile'
    },
    complete_profile: {
      title: 'Who are you, really?',
      submit: 'Save and continue'
    },
    upload_avatar: {
      title: 'Say cheese!',
      placeholder_text: 'Upload your profile photo',
      submit: 'Next',
      skip: 'Skip'
    },
    intro_gateway: {
      show_intro: 'Would you like us to quickly explain Erbij?',
      show_intro_label: 'View short intro',
      skip_intro: '…or would you rather just figure it out yourself?',
      skip_intro_label: 'Skip intro'
    },
    intro: {
      slide1: {
        title: 'Your new calendar',
        text: 'Add meetings, parties, matches and other appointments.'
      },
      slide2: {
        title: 'Sharing = Caring',
        text: 'Invite your friends by sharing the unique invitation link via WhatsApp, email or whatever you prefer.'
      },
      slide3: {
        title: 'Groups',
        text: 'Team up and add your group activities. This way, your friends, teammates or colleagues will be invited automatically.'
      },
      slide4: {
        title: "Who's coming?",
        text: "All invitees can now let you know whether they're planning to join your event. Excuses are optional."
      },
      slide5: {
        title: 'And voila!',
        text: 'One simple overview with all your appointments. Including directions, reminders and other dandy features.'
      },
      skip: 'Skip',
      go: "Let's go!"
    }
  },
  nl: {
    activation_success: {
      title: 'Bijna klaar',
      subtitle: '{name}, je bent Erbij!',
      text: 'Je bent bijna klaar om Erbij te gaan gebruiken. We willen alleen nog even dubbelchecken of we de juiste persoon voor ons hebben.',
      complete_profile: 'Profiel aanvullen'
    },
    complete_profile: {
      title: 'Hoe mogen we je noemen?',
      submit: 'Opslaan en verder'
    },
    upload_avatar: {
      title: 'Lach eens naar het vogeltje!',
      placeholder_text: 'Upload je profielfoto',
      submit: 'Volgende',
      skip: 'Overslaan'
    },
    intro_gateway: {
      show_intro: 'Wil je dat we Erbij even binnen een minuut uitleggen?',
      show_intro_label: 'Korte uitleg bekijken',
      skip_intro: '…of vogel je het liever gewoon zelf uit?',
      skip_intro_label: 'Uitleg overslaan'
    },
    intro: {
      slide1: {
        title: 'Je nieuwe agenda',
        text: 'Voeg vergaderingen, sportwedstrijden, verjaardagen en andere afspraken toe.'
      },
      slide2: {
        title: 'Delen = vermenigvuldigen',
        text: 'Nodig je vrienden uit door de uitnodigingslink te delen via WhatsApp, e-mail of op wat voor manier dan ook.'
      },
      slide3: {
        title: 'Groups',
        text: "Vorm samen een groep en voeg groepsactiviteiten toe. Zo worden je vrienden, sportmaatjes of collega's automatisch uitgenodigd."
      },
      slide4: {
        title: 'Wie zijn Erbij?',
        text: 'Alle genodigden kunnen aangeven of ze Erbij zijn of niet. Smoesjes zijn optioneel.'
      },
      slide5: {
        title: 'Hatseflats',
        text: 'Eén overzicht met al jullie afspraken. Inclusief routebeschrijvingen, herinneringen en nog meer handigheidjes.'
      },
      skip: 'Overslaan',
      go: 'Aan de slag!'
    }
  }
}
