<template>
  <event-wizard-base
    :title="$breakpoint.smAndUp ? $t('event_wizard.add_title') : null"
    :progress="progress"
    @close="onClose"
  />
</template>

<script>
import EventWizardBase from './views/EventWizardBase'

export default {
  name: 'EventAdd',
  components: {
    EventWizardBase
  },
  created() {
    const teamId = this.$route.params.team

    if (teamId) {
      this.$store
        .dispatch('setWizardValues', {
          team: this.$store.getters.getTeamById(teamId)
        })
        .then(() => this.$router.push({ name: 'AddEventWizardWhat' }))
    } else {
      if (this.$route.name !== 'AddEventWizardWho') {
        this.$router.replace({ name: 'AddEventWizardWho' })
      }
    }
  },
  methods: {
    onClose() {
      this.$router.push({ name: 'Home' })
    }
  },
  computed: {
    progress() {
      return {
        AddEventWizardWho: 20,
        AddEventWizardWhat: 40,
        AddEventWizardWhen: 60,
        AddEventWizardWhere: 80,
        AddEventWizardSummary: 100
      }[this.$route.name]
    }
  }
}
</script>
