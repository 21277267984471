import { AUTH_RESET } from '@/store/modules/auth/mutations'
import { EVENTS_RESET } from '@/store/modules/events/mutations'
import { TEAMS_RESET } from '@/store/modules/teams/mutations'
import { WIZARDS_RESET } from '@/store/modules/wizards/mutations'
import { NOTIFICATION_TYPE_ERROR } from '@/utils/constants'

export function handleError({ commit, dispatch }, { error, shortError, type }) {
  // TODO: rename shortError to errorMessage and error to errorInstance or something
  const { response } = error

  const serverErrorMessage =
    response && response.data && response.data.errors ? response.data.errors[0].message : null

  if (type) {
    commit(type, serverErrorMessage || shortError)
  }

  if (serverErrorMessage) {
    dispatch('addNotification', {
      title: shortError,
      text: serverErrorMessage,
      type: NOTIFICATION_TYPE_ERROR
    })
  } else {
    dispatch('addNotification', {
      text: shortError,
      type: NOTIFICATION_TYPE_ERROR
    })
  }
}

export function resetAll({ commit }) {
  commit(AUTH_RESET)
  commit(EVENTS_RESET)
  commit(TEAMS_RESET)
  commit(WIZARDS_RESET)
}
