<template>
  <div>
    <input
      type="checkbox"
      class="toggle-switch-checkbox sr-only"
      :id="id"
      :checked="value"
      @change="updateInput"
    />
    <label class="toggle-switch" :for="id">
      <div class="toggle-switch__knob"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: ['id', 'value'],
  methods: {
    updateInput(event) {
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style lang="scss">
$track-width: 50px;
$track-height: 30px;
$knob-size: 26px;

.toggle-switch {
  width: $track-width;
  height: $track-height;
  border-radius: $track-height / 2;
  position: relative;
  display: inline-block;
  transition: background-color 0.2s;
  background-color: $gray-200;
  margin: 0;

  &__knob {
    transition: transform 0.2s;
    background-color: $white;
    position: absolute;
    left: ($track-height - $knob-size) / 2;
    top: ($track-height - $knob-size) / 2;
    height: $knob-size;
    width: $knob-size;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }

  .toggle-switch-checkbox:checked + & {
    background-color: $primary;

    .toggle-switch__knob {
      transform: translateX($track-width - $knob-size - ($track-height - $knob-size));
    }
  }
}
</style>
