<template>
  <div @keyup.right="next" @keyup.left="previous">
    <div class="p-sm-5">
      <carousel
        :perPage="1"
        class="mb-3 mb-sm-5 text-center"
        @pageChange="onSlideChange"
        ref="carousel"
      >
        <slide v-for="(slide, index) in slides" :key="index">
          <div
            class="d-flex flex-column flex-lg-row-reverse justify-content-between align-items-center"
          >
            <img
              :src="slide.image"
              width="280"
              height="280"
              class="mb-4 mb-sm-5 mb-lg-0 intro__img"
            />
            <div>
              <h4>{{ slide.title }}</h4>
              <p class="intro__text m-auto">{{ slide.text }}</p>
            </div>
          </div>
        </slide>
      </carousel>
      <div class="wizard__footer">
        <div class="d-flex justify-content-center" v-if="isOnLastSlide">
          <router-link :to="{ name: 'Home' }" class="btn btn-lg btn-primary">{{
            $t('onboarding.intro.go')
          }}</router-link>
        </div>
        <div class="d-flex justify-content-between justify-content-sm-center" v-else>
          <button class="btn btn-lg btn-outline" @click="previous">
            {{ $t('general.buttons.previous') }}
          </button>
          <button class="btn btn-lg btn-secondary ml-sm-4" @click="next">
            {{ $t('general.buttons.next') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'Intro',
  components: {
    Carousel,
    Slide
  },
  methods: {
    onSlideChange(slide) {
      this.currentSlide = slide
    },
    next() {
      this.$refs.carousel.goToPage(this.currentSlide + 1)
    },
    previous() {
      this.$refs.carousel.goToPage(this.currentSlide - 1)
    }
  },
  computed: {
    isOnLastSlide() {
      return this.currentSlide >= this.slides.length - 1
    }
  },
  data() {
    return {
      slides: [
        {
          title: this.$i18n.t('onboarding.intro.slide1.title'),
          text: this.$i18n.t('onboarding.intro.slide1.text'),
          image: require('@/assets/intro/1.png')
        },
        {
          title: this.$i18n.t('onboarding.intro.slide2.title'),
          text: this.$i18n.t('onboarding.intro.slide2.text'),
          image: require('@/assets/intro/2.png')
        },
        {
          title: this.$i18n.t('onboarding.intro.slide3.title'),
          text: this.$i18n.t('onboarding.intro.slide3.text'),
          image: require('@/assets/intro/3.png')
        },
        {
          title: this.$i18n.t('onboarding.intro.slide4.title'),
          text: this.$i18n.t('onboarding.intro.slide4.text'),
          image: require('@/assets/intro/4.png')
        },
        {
          title: this.$i18n.t('onboarding.intro.slide5.title'),
          text: this.$i18n.t('onboarding.intro.slide5.text'),
          image: require('@/assets/intro/5.png')
        }
      ],
      currentSlide: 0
    }
  }
}
</script>

<style lang="scss">
.intro__text {
  padding: 0 15px;
  max-width: 450px;

  @include sm-up {
    padding: 0 30px;
  }
}
.intro__img {
  @include xs-only {
    max-width: 200px;
    height: auto;
  }
}
</style>
