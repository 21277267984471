import {
  REQUEST_STATE_LOADING,
  REQUEST_STATE_SUCCESS,
  REQUEST_STATE_UPLOADING_DATA
} from '@/utils/constants'
import { getTimeZone } from '@/utils/timeZones'

export default {
  authError: (state) => state.error,
  token: (state) => state.token,
  profile: (state) => state.profile,
  userId: (state) => (state.profile ? state.profile.id : null),
  hasPrivilege: (state) => (privilege) =>
    state.profile && state.profile.privileges.find((x) => x.privilege === privilege),
  userTimeZone: (state) => (state.profile ? getTimeZone(state.profile.user_tz) : getTimeZone()),
  authStatus: (state) => state.status,
  isAuthLoading: (state) => state.status === REQUEST_STATE_LOADING,
  isAuthenticated: (state) => state.status === REQUEST_STATE_SUCCESS && state.token !== null,
  isUploadingAvatar: (state) => state.status === REQUEST_STATE_UPLOADING_DATA
}
