<template>
  <div class="text-page">
    <div class="container container--xs">
      <h1 class="text-page__title">{{ $t('upgrade.title') }}</h1>
      <div class="text-sm-lg" v-if="$i18n.locale === 'en'">
        <p class="lead">Upgrade to Erbij Plus today and enjoy all that Erbij has to offer!</p>
        <ul class="list-unstyled ml-0">
          <li>🔄 Sync with your own calendar</li>
          <li>💭 Send reminders</li>
          <li>🙌 Duplicate activities</li>
          <li>💻 Premium support, 7 days a week</li>
          <li>❤️ Support the development of Erbij</li>
        </ul>
        <p>
          Erbij Plus is currently only available through the Erbij app (version 2.0 and above)<br />You
          can download the app for <a :href="appStoreURL">iOS</a> and
          <a :href="playStoreURL">Android</a>.
        </p>
      </div>
      <div class="text-sm-lg" v-else>
        <p class="lead">
          Upgrade vandaag nog naar Erbij Plus en geniet van alles wat Erbij Plus te bieden heeft!
        </p>
        <ul class="list-unstyled ml-0">
          <li>🔄 Synchroniseer met je eigen agenda</li>
          <li>💭 Verstuur herinneringen</li>
          <li>🙌 Dupliceer activiteiten</li>
          <li>💻 7 dagen per week ondersteuning</li>
          <li>❤️ Steun de ontwikkeling van Erbij</li>
        </ul>
        <p>
          Op dit moment is Erbij Plus alleen beschikbaar via de Erbij-app (vanaf versie 2.0)<br />Je
          kunt de app downloaden voor <a :href="appStoreURL">iOS</a> en
          <a :href="playStoreURL">Android</a>.
        </p>
      </div>
      <StoreButtons class="mt-4" />
    </div>
  </div>
</template>

<script>
import StoreButtons from '@/components/StoreButtons'
import bowser from 'bowser'
const browser = bowser.getParser(window.navigator.userAgent)

export default {
  name: 'Upgrade',
  components: {
    StoreButtons
  },
  data() {
    const { locale } = this.$i18n

    return {
      isAndroid: browser.parsedResult.os.name === 'Android',
      isIos: browser.parsedResult.os.name === 'iOS',
      appStoreURL: 'https://itunes.apple.com/nl/app/erbij/id1360268931?l=' + locale,
      playStoreURL:
        'https://play.google.com/store/apps/details?id=nl.michielvanroon.erbij&hl=' + locale
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  color: $orange;
}
</style>
