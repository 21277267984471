<template>
  <button class="icon-button" type="button">
    <icon :icon="['fal', icon]" class="icon-button__icon" />
    <span class="icon-button__label">{{ label }}</span>
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.icon-button {
  @include center-content;
  @include button-unstyled;
  color: $gray-600;

  @include hover-focus {
    color: $gray-700;
  }

  &__icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;

    @include sm-up {
      font-size: 1.4rem;
    }
  }

  &__label {
  }
}
</style>
