<template>
  <div v-if="team" class="container container--sm mb-5">
    <page-header
      :title="team.name"
      :back-button="{ route: 'TeamOverview', label: $t('teams.overview.title') }"
      :actions="actions"
      :image="image"
      :isBusy="isUploading"
      @editPhoto="isCropperVisible = true"
    />
    <page-section>
      <div
        class="team-details__header"
        :class="{ 'team-details__header--busy': isUploading }"
        v-if="$breakpoint.smAndUp"
        :style="{ 'background-image': 'url(' + imageLarge + ')' }"
      >
        <spinner class="team-details__spinner" v-show="isUploading" />
        <button
          type="button"
          @click="isCropperVisible = true"
          class="team-details__edit-photo-button"
          v-if="!isUploading"
        >
          <icon :icon="['fas', 'camera-alt']" class="btn__icon--left" />
          <span>{{ $t('general.buttons.edit') }}</span>
        </button>
      </div>
      <page-subsection :title="$t('teams.details.details_title')">
        <dl class="row mb-0">
          <dt class="col-sm-3 col-md-2">{{ $t('teams.details.name') }}</dt>
          <dd class="col-sm-9 col-md-10">{{ team.name }}</dd>
          <dt class="col-sm-3 col-md-2">{{ $t('teams.details.type') }}</dt>
          <dd class="col-sm-9 col-md-10">{{ type }}</dd>
          <dt class="col-sm-3 col-md-2">{{ $t('teams.details.created') }}</dt>
          <dd class="col-sm-9 col-md-10">
            {{ team.created.format($t('general.date_format.short')) }}
          </dd>
          <dt class="col-sm-3 col-md-2">{{ $t('teams.details.joined') }}</dt>
          <dd class="col-sm-9 col-md-10">
            {{ team.user_joined.format($t('general.date_format.short')) }}
          </dd>
        </dl>
      </page-subsection>
      <page-subsection :title="$t('teams.details.members_title')" :actions="memberActions">
        <user-list :users="team.users" :columns="2" @userClick="onUserClick" />
      </page-subsection>
    </page-section>
    <router-view :team="team"></router-view>
    <avatar-cropper
      v-if="isCropperVisible"
      :title="$t('teams.details.edit_photo_title')"
      :image="cropperImage"
      @submit="onCropperSubmit"
      @close="isCropperVisible = false"
      :aspectRatio="16 / 9"
      :outputOptions="{ width: 1600, height: 900 }"
    />
  </div>
</template>

<script>
import AvatarCropper from '@/components/AvatarCropper'
import PageHeader from '@/components/PageHeader'
import PageSection from '@/components/PageSection'
import PageSubsection from '@/components/PageSubsection'
import Spinner from '@/components/Spinner'
import UserList from '@/components/UserList'
import loadTeamMixin from '@/mixins/loadTeamMixin'
import getTeamTypes from '@/utils/teamTypes'

export default {
  name: 'TeamDetails',
  mixins: [loadTeamMixin],
  data() {
    return {
      isUploading: false,
      isCropperVisible: false
    }
  },
  components: {
    UserList,
    PageHeader,
    PageSection,
    PageSubsection,
    AvatarCropper,
    Spinner
  },
  methods: {
    async onCropperSubmit(blob) {
      this.isUploading = true
      await this.$store.dispatch('editTeamPhoto', { teamId: this.team.id, image: blob })
      this.isUploading = false
    },
    invite() {
      this.$router.push({ name: 'TeamInvite' })
    },
    onUserClick(userId) {
      this.$router.push({ name: 'TeamMemberDetails', params: { userId } })
    }
  },
  computed: {
    image() {
      const { team_photo } = this.team
      if (!team_photo) return require('@/assets/generic-background.png')

      return team_photo
    },
    imageLarge() {
      if (window.devicePixelRatio < 1.25) return this.image

      const { team_photo_large } = this.team
      if (!team_photo_large) return require('@/assets/generic-background@2x.png')

      return team_photo_large
    },
    cropperImage() {
      const { team_photo } = this.team
      if (!team_photo) return null

      return team_photo
    },
    actions() {
      const actions = []

      if (this.team.user_can_add_events) {
        actions.push({
          icon: 'calendar-plus',
          to: { name: 'EventAdd', params: { team: this.team.id } },
          label: this.$i18n.t('teams.details.add_event_label')
        })
      }

      if (this.team.user_is_manager) {
        actions.push({
          icon: 'pencil',
          to: { name: 'TeamEdit' },
          label: this.$i18n.t('teams.details.edit_label')
        })
      }

      actions.push({
        icon: 'trash-alt',
        to: { name: 'TeamLeave' },
        label: this.$i18n.t('teams.details.leave_label'),
        isDestructive: true
      })

      return actions
    },
    type() {
      const types = getTeamTypes()
      return types.find((type) => type.id === this.team.team_type).label
    },
    memberActions() {
      const inviteAction = {
        label: this.$i18n.t('teams.details.invite_more'),
        icon: 'user-plus',
        onPress: this.invite
      }

      return this.team.user_is_manager ? [inviteAction] : null
    }
  }
}
</script>

<style lang="scss">
.team-details {
  &__header {
    padding-bottom: percentage(9/16);
    background-color: $primary;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.3) 100%);
    }

    &--busy::before {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__spinner {
    font-size: 40px;
    color: $white;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  &__edit-photo-button {
    @include button-unstyled;
    font-size: 16px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: $white;
    display: inline-flex;
    align-items: center;

    svg {
      margin-bottom: 2px;
      font-size: 20px;
    }
  }
}
</style>
