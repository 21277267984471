<template>
  <div>
    <h1 class="text-page__title">Privacy</h1>
    <p>
      Michiel van Roon Web Development, gevestigd aan Battutalaan 523, 3526VS Utrecht, is
      verantwoordelijk voor de verwerking van persoonsgegevens gebruikt in de Erbij apps en via
      erbij.app zoals weergegeven in deze privacyverklaring.
    </p>
    <h2 class="h3">Contactgegevens</h2>
    <p>Michiel van Roon Web Development, Battutalaan 523, 3526VS Utrecht</p>
    <p>
      Michiel van Roon is de Functionaris Gegevensbescherming van Erbij. Hij is te bereiken via
      <a href="mailto:michiel@erbij.app">michiel@erbij.app</a>
    </p>
    <h2 class="h3">Persoonsgegevens die wij verwerken</h2>
    <p>
      Erbij verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je
      deze gegevens zelf aan ons verstrekt.
    </p>
    <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
    <ul>
      <li>voornaam</li>
      <li>achternaam</li>
      <li>bijnaam</li>
      <li>e‑mailadres</li>
      <li>profielfoto</li>
      <li>
        gegevens van je smartphone, zoals het model, het besturingssysteem en de naam van je
        smartphone (bijv. “iPhone van Jan Jansen”)
      </li>
    </ul>
    <h2 class="h3">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
    <p>
      Onze website en onze apps hebben niet de intentie gegevens te verzamelen over gebruikers die
      jonger zijn dan 16 jaar, tenzij ze toestemming hebben van ouders of voogd. We kunnen echter
      niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te
      zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over
      kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij
      zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan
      contact met ons op via <a href="mailto:privacy@erbij.app">privacy@erbij.app</a>, zodat deze
      informatie verwijderd kan worden.
    </p>
    <h2 class="h3">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
    <p>Erbij verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
    <h5>Profielfoto, voornaam, achternaam en bijnaam</h5>
    <p>
      Zodat je groepsgenoten of mensen die je hebt uitgenodigd voor activiteiten kunnen zien we je
      bent
    </p>
    <h5>E-mailadres</h5>
    <p>
      Zodat we je kunnen e-mailen wanneer je bijvoorbeeld je wachtwoord vergeten bent, of wanneer je
      bent uitgenodigd voor een activiteit of een groep. We zullen je nooit een nieuwsbrief sturen
      zonder dat je je hiervoor hebt aangemeld en we beloven dat we je e‑mailadres nooit zullen
      delen met derden die niet in deze verklaring genoemd zijn.
    </p>
    <h5>Gegevens van je smartphone</h5>
    <p>
      Deze gegevens gebruiken we zodat we fouten in de website of in de apps sneller kunnen
      oplossen, doordat we weten op wat voor soort smartphone de fout plaatsvond.
    </p>
    <h2 class="h3">Geautomatiseerde besluitvorming</h2>
    <p>
      Erbij neemt op basis van geautomatiseerde verwerkingen geen besluiten over zaken die
      (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden
      genomen door computerprogramma’s of -systemen, zonder dat daar een mens (bijvoorbeeld een
      medewerker van Erbij) tussen zit.
    </p>
    <h2 class="h3">Bewaartermijn</h2>
    <p>
      Wanneer je een Erbij-account aanmaakt (of inlogd via Google) bewaren we je profiel in principe
      oneindig lang. Tenzij je ons verzoekt je account te verwijderen.
    </p>
    <h2 class="h3">Account verwijderen</h2>
    <p>
      Ben je helemaal klaar met Erbij en wil je je account verwijderen? Stuur dan een e-mail naar
      <a
        href="mailto:nietmeererbij@erbij.app?subject=Verwijder%20mijn%20Erbij%20account&amp;body=Ik%20vind%20Erbij%20maar%20niks.%20Verwijder%20mijn%20account%20alsjeblieft."
        >nietmeererbij@erbij.app</a
      >
      Vergeet niet te vermelden met welk e‑mailadres je inlogt. Als je met Apple of Google inlogt
      dien je dit e-mailadres door te vermelden. We verwijderen dan je gegevens binnen vier weken.
      We zouden het op prijs stellen als je even vermeldt waarom je ons verlaat, maar voel je niet
      verplicht.
    </p>
    <h2 class="h3">Delen van persoonsgegevens met derden</h2>
    <p>
      Zodra je de website of de apps gebruikt om een activiteit toe te voegen en anderen hiervoor
      uitnodigt of wanneer je je bij een groep voegt, kunnen deze Erbij-leden de gegevens in je
      openbare profiel inzien. In dit profiel staan de volgende gegevens:
    </p>
    <ul>
      <li>voornaam</li>
      <li>achternaam</li>
      <li>bijnaam</li>
      <li>e‑mailadres</li>
      <li>profielfoto</li>
    </ul>
    <p>
      Wanneer je via Google inlogt, wordt je profielfoto van deze dienst automatisch aan je profiel
      toegevoegd.
    </p>
    <p>
      Bovenstaande gegevens worden opgeslagen op de beveiligde servers van Amazon Web Services
      (AWS).
    </p>
    <h2 class="h3">Cookies</h2>
    <p>
      De Erbij website (erbij.app) gebruikt functionele en analytische cookies. Een cookie is een
      klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser
      van je computer, tablet of smartphone. Erbij gebruikt cookies met een puur technische
      functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw
      voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te
      laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we analytische cookies die
      jouw gedrag op erbij.app bijhouden. Op deze manier kunnen we de website gebruiksvriendelijker
      maken.
    </p>
    <p>Bij jouw eerste bezoek aan onze website hebben wij je al geïnformeerd over deze cookies.</p>
    <p>
      Op <a href="/cookies" title="Over cookies">onze pagina over cookies</a> vind je meer
      informatie over de cookies die we plaatsen.
    </p>
    <p>
      Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen
      cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de
      instellingen van je browser verwijderen.
    </p>
    <p>
      Zie voor een toelichting:
      <a
        href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/"
        >https://veiliginternetten.nl/</a
      >
    </p>
    <h2 class="h3">Gegevens inzien, aanpassen of verwijderen</h2>
    <p>
      Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen.
      Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te
      trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Erbij en heb je
      het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen
      om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een
      ander, door jou genoemde organisatie, te sturen.
    </p>
    <p>
      Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je
      persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van
      jouw persoonsgegevens sturen naar
      <a href="mailto:privacy@erbij.app">privacy@erbij.app</a>.
    </p>
    <p>
      Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een
      kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto,
      MRZ (machine readable zone, de strook met nummers onderaan het identiteitsbewijs),
      identiteitsbewijsnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je
      privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.
    </p>
    <p>
      Erbij wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de
      nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan online op de website van
      <a
        href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
        >Autoriteit Persoonsgegevens</a
      >.
    </p>
    <h2 class="h3">Hoe wij persoonsgegevens beveiligen</h2>
    <p>
      Erbij neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om
      misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging
      tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er
      zijn aanwijzingen van misbruik, stuur ons dan een mail:
      <a href="mailto:privacy@erbij.app">privacy@erbij.app</a>. Erbij heeft de volgende maatregelen
      genomen om jouw persoonsgegevens te beveiligen:
    </p>
    <ul>
      <li>
        Al het dataverkeer dat wij versturen of ontvangen is versleuteld met behulp van TLS. Onze
        websites en apps zijn beveiligd met een SSL (TLS) certificaat. De gegevens van het
        certificaat, inclusief de partij die verantwoordelijk is voor de uitgifte ervan en – bij
        bedrijfsgevalideerde certificaten – de bedrijfsgegevens van de website-eigenaar kan je
        controlen door te klikken op het slotje in je browser.
      </li>
      <li>
        Je data wordt opgeslagen op de beveiligde servers van Amazon Web Services. Meer informatie
        met betrekking tot de beveiliging van deze servers kan je lezen op de
        <a href="https://aws.amazon.com/security/">website van Amazon</a>.
      </li>
    </ul>
    <h2 class="h3">Verstrekken aan derden</h2>
    <p>
      Erbij verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit
      nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke
      verplichting. Bedrijven die jouw gegevens verwerken in onze opdracht zorgen voor eenzelfde
      niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Erbij blijft verantwoordelijk
      voor deze verwerkingen.
    </p>
    <p>Hieronder vind je een lijst met bedrijven en/of diensten die jouw gegevens verwerken:</p>
    <h4>OneSignal</h4>
    <dl class="row">
      <dt class="col-sm-4">Functie:</dt>
      <dd class="col-sm-8">
        Push notificaties versturen naar je smartphone, bijvoorbeeld wanneer je bent uitgenodigd
        voor een activiteit of een groep.
      </dd>
      <dt class="col-sm-4">Welke gegevens en waarom?</dt>
      <dd class="col-sm-8">
        <p>
          We delen de unieke code van jouw smartphone, zodat OneSignal weet naar welke apparaat
          relevante push notificaties moeten worden verstuurd.
        </p>
        <p>
          Verder leest OneSignal gegevens uit met betrekking tot je Erbij-sessies. Denk hierbij aan
          de versie van de app die je gebruikt, hoe vaak en hoelang je Erbij gebruikt en uit welk
          land je komt.
        </p>
        <p>
          Gegevens zoals je naam, je e‑mailadres of andere persoongegevens
          <strong>verstrekken wij niet</strong> aan OneSignal.
        </p>
      </dd>
      <dt class="col-sm-4">Privacystatement:</dt>
      <dd class="col-sm-8">
        <a href="https://onesignal.com/privacy_policy">https://onesignal.com/privacy_policy</a>
      </dd>
    </dl>
    <h4>Sentry</h4>
    <dl class="row">
      <dt class="col-sm-4">Doel:</dt>
      <dd class="col-sm-8">
        Foutmeldingen verwerken en inzichtelijk maken voor onze ontwikkelaars
      </dd>
      <dt class="col-sm-4">Welke gegevens en waarom?</dt>
      <dd class="col-sm-8">
        Je naam en e‑mailadres worden meegestuurd met de error-logs zodra er iets fout gaat in de
        website of app. Op deze manier kunnen we je een e‑mailen en je om meer informatie vragen met
        betreking tot de foutmelding.
      </dd>
      <dt class="col-sm-4">Privacystatement:</dt>
      <dd class="col-sm-8"><a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a></dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'PrivacyContentDutch'
}
</script>
