<template>
  <div class="avatar-modal">
    <button class="avatar-modal__close" type="button" @click="onCloseClick">
      <icon :icon="['fal', 'times']" />
    </button>
    <img :src="src" :alt="alt" width="300" height="300" class="avatar-modal__img rounded-circle" />
    <div class="avatar-modal__backdrop" @click="onCloseClick"></div>
  </div>
</template>

<script>
export default {
  name: 'AvatarModal',
  props: {
    alt: {
      type: String,
      required: false
    },
    src: {
      type: String,
      required: true
    }
  },
  methods: {
    onCloseClick() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.avatar-modal {
  @include center-content;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1040;

  &__img {
    position: relative;
    z-index: 1;

    @include sm-up {
      width: 450px;
      height: 450px;
    }
  }

  &__close {
    @include button-unstyled;
    color: $white;
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 30px;
    z-index: 1;
    line-height: 1;
  }

  &__backdrop {
    opacity: 0.7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
  }
}
</style>
