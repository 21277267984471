import actions from './actions'
import { getDefaultState } from './default'
import getters from './getters'
import mutations from './mutations'

const state = getDefaultState()

export default {
  state,
  getters,
  actions,
  mutations
}
