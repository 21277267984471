export default {
  en: {
    add_title: 'New activity',
    edit_title: 'Edit "{event}"',
    who: {
      title: 'Who is this activity for?',
      team_text: 'For one of your groups',
      team_explanation: 'Group members will be invited automatically afterwards.',
      team_button_label: 'Add group activity',
      no_team_text: 'For anyone else',
      no_team_explanation:
        'You can invite people afterwards via WhatsApp or email. They can then respond to your invitation without creating an account.',
      no_team_button_label: 'Invite people yourself',
      no_teams: "You're not a member of a group yet",
      create_more_teams: 'Or',
      create_more_teams_cta: 'create a new group',
      add_team: 'Create a new group'
    },
    what: {
      title: 'What are we doing?',
      name: 'Name',
      name_placeholder: 'Game of football',
      type: 'Type',
      type_placeholder: 'Make a choice',
      description: 'Description'
    },
    when: {
      title: 'When are we going?',
      start: 'Start',
      time: 'Time',
      end: 'End time known?',
      at: 'at',
      hrs: 'hrs',
      enable_present_time: 'Meet beforehand?',
      no: 'No',
      yes: 'Yes',
      disable_date_picker_title: 'You cannot change the date of a recurring activity',
      repetition: 'Repeat',
      repetition_no_team: 'You can only add recurring activities when you invite a group.',
      repetition_once: 'Never',
      repetition_weekly: 'Every week',
      repetition_biweekly: 'Every other week',
      repetition_until: 'until',
      time_zone: 'Time zone'
    },
    where: {
      title: 'Where do we meet?',
      search_placeholder: "E.g. 'Times Square' or '3 Abbey Road'"
    },
    summary: {
      add_title: "Let's recap",
      edit_title: 'What would you like to change?',
      invite_type: 'Invite',
      invite_type_team: 'All members of "{team}"',
      invite_type_no_team: 'Manually invite with a link',
      name: 'Name',
      type: 'Type',
      description: 'Description',
      start: 'Start',
      present: 'Present',
      end: 'End',
      no_end: 'No end time',
      repetition: 'Repeat',
      repetion_end_date: 'Until',
      location: 'Location',
      also_update_future: 'Also update future occurrences',
      time_zone: 'Time zone'
    },
    add_success: {
      title: 'has been added to your calendar!  🎉',
      team_members_invited: 'The other members of your group have been invited',
      invite_friends: 'How about we invite some other people? More fun that way.',
      invite: 'Invite friends',
      invite_more: 'Invite even more people',
      invite_later: 'Close'
    },
    edit_success: {
      text: '"{event}" has been updated'
    }
  },
  nl: {
    add_title: 'Nieuwe activiteit',
    edit_title: '"{event}" wijzigen',
    who: {
      title: 'Voor wie is deze activiteit?',
      team_text: 'Voor een van je groepen',
      team_explanation: 'Groepsleden worden achteraf automatisch uitgenodigd.',
      team_button_label: 'Groepsactiviteit toevoegen',
      no_team_text: 'Niet voor een groep',
      no_team_explanation:
        'Je kunt mensen achteraf uitnodigen via bijv. WhatsApp of e-mail. Ze kunnen reageren op je uitnodiging zonder een account aan te maken.',
      no_team_button_label: 'Zelf mensen uitnodigen',
      no_teams: 'Je bent nog geen lid van een groep.',
      create_more_teams: 'Of',
      create_more_teams_cta: 'maak een nieuwe groep',
      add_team: 'Nieuwe groep maken'
    },
    what: {
      title: 'Wat gaan we doen?',
      name: 'Naam',
      name_placeholder: 'Potje voetbal',
      type: 'Type',
      type_placeholder: 'Kies een type activiteit',
      description: 'Omschrijving'
    },
    when: {
      title: 'Wanneer gaan we?',
      start: 'Start',
      time: 'Tijd',
      end: 'Eindtijd bekend?',
      at: 'om',
      hrs: 'uur',
      enable_present_time: 'Van te voren verzamelen?',
      no: 'Nee',
      yes: 'Ja',
      disable_date_picker_title:
        'Het is niet mogelijk om de datum van een herhaalde activiteit te wijzigen',
      repetition: 'Herhaling',
      repetition_no_team:
        'Je kunt alleen terugkerende activeiten toevoegen wanneer je een groep uitnodigt.',
      repetition_once: 'Nooit',
      repetition_weekly: 'Elke week',
      repetition_biweekly: 'Om de week',
      repetition_until: 'tot',
      time_zone: 'Tijdzone'
    },
    where: {
      title: 'Waar spreken we af?',
      search_placeholder: "Bijv. 'Domtoren' of 'Kalverstraat 20'"
    },
    summary: {
      add_title: 'Klopt het zo?',
      edit_title: 'Wat wil je wijzigen?',
      invite_type: 'Uitnodigen',
      invite_type_team: 'Alle leden van "{team}"',
      invite_type_no_team: 'Zelf uitnodigen met een link',
      name: 'Naam',
      type: 'Type',
      description: 'Omschrijving',
      start: 'Start',
      present: 'Verzameltijd',
      end: 'Einde',
      no_end: 'Geen eindtijd',
      repetition: 'Herhaling',
      repetion_end_date: 'Tot',
      location: 'Locatie',
      also_update_future: 'Toekomstige herhalingen ook aanpassen',
      time_zone: 'Tijdzone'
    },
    add_success: {
      title: 'is toevoegd aan je agenda!  🎉',
      team_members_invited: 'De andere leden van je groep zijn uitgenodigd.',
      invite_friends: 'Zullen we meteen wat mensen uitnodigen? Stuk gezelliger op die manier.',
      invite: 'Vrienden uitnodigen',
      invite_more: 'Nog meer mensen uitnodigen',
      invite_later: 'Sluiten'
    },
    edit_success: {
      text: '"{event}" is gewijzigd'
    }
  }
}
