<template>
  <div class="event-list">
    <div class="event-list__section" v-for="section in sections" :key="section.title">
      <h2 class="event-list__section-title h4">{{ section.title }}</h2>
      <div class="card event-list__items">
        <event-list-item
          v-for="event in section.events"
          :key="event.id"
          :event="event"
          @click.native="$router.push({ name: 'EventDetails', params: { eventId: event.id } })"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { makeSections } from '@/utils/events'
import EventListItem from '@/components/EventListItem'

export default {
  name: 'EventList',
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  computed: {
    sections() {
      return makeSections(this.events)
    }
  },
  components: {
    EventListItem
  }
}
</script>

<style lang="scss">
.event-list {
  &__section {
    margin-bottom: 2rem;

    @include sm-up {
      margin-bottom: 4rem;
    }
  }

  &__section-title {
    margin-bottom: 20px;

    @include xs-only {
      background-color: $gray-100;
      position: sticky;
      top: $mobile-header-height;
      z-index: 1;
      margin: 0 -15px;
      padding: 15px;
      border-bottom: 1px solid $gray-200;
    }
  }

  &__items {
    overflow: hidden;

    @include xs-only {
      overflow: hidden;
      margin: 0 -15px;
      border-radius: 0;
      box-shadow: none;
      border-bottom: 1px solid $gray-200;
    }
  }
}
</style>
