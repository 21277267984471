<template>
  <div :style="{ width: size + 'px', height: size + 'px' }">
    <div
      @click="isCropperVisible = true"
      class="avatar-uploader"
      :class="{ 'avatar-uploader--busy': isUploadingAvatar, 'avatar-uploader--empty': !avatar }"
    >
      <template v-if="avatar && !hasError">
        <img :src="avatar" :width="size" :height="size" @error="hasError = true" />
        <div class="avatar-uploader__overlay" v-show="!isUploadingAvatar">
          <div>
            <icon icon="camera-alt" class="avatar-uploader__icon" />
            <span class="avatar-uploader__text">{{
              $t('profile.edit_profile.edit_profile_picture_edit')
            }}</span>
          </div>
        </div>
      </template>
      <div v-else class="avatar-uploader__fallback">
        <icon :icon="['fal', 'camera-alt']" class="avatar-uploader__large-icon" />
        <p class="avatar-uploader__cta mb-0">
          {{ $t('profile.edit_profile.edit_profile_picture_new') }}
        </p>
      </div>
      <spinner class="avatar-uploader__spinner" v-show="isUploadingAvatar" />
    </div>
    <avatar-cropper
      :title="$t('profile.profile_picture.title')"
      v-if="isCropperVisible"
      :image="avatar"
      @submit="onCropperSubmit"
      @close="isCropperVisible = false"
    />
  </div>
</template>

<script>
import AvatarCropper from '@/components/AvatarCropper'
import Spinner from '@/components/Spinner'
import { mapGetters } from 'vuex'

export default {
  name: 'AvatarUploader',
  data() {
    return {
      isCropperVisible: false,
      hasError: false
    }
  },
  props: {
    size: {
      type: Number,
      required: false,
      default: 140
    }
  },
  components: {
    AvatarCropper,
    Spinner
  },
  methods: {
    onCropperSubmit(blob) {
      this.$store
        .dispatch('editAvatar', {
          image: blob
        })
        .then(() => {
          this.$emit('uploaded')
        })
    }
  },
  computed: {
    avatar() {
      return this.size > 150 ? this.profile.avatar_large : this.profile.avatar_small
    },
    ...mapGetters({
      profile: 'profile',
      isUploadingAvatar: 'isUploadingAvatar'
    })
  }
}
</script>

<style lang="scss">
.avatar-uploader {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &--empty {
    border: 4px dashed $gray-400;
    color: $gray-600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--busy {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &__fallback {
    text-align: center;
    background-color: $gray-200;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    font-size: 40px;
    color: $white;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.5) 100%);
    color: $white;
    text-align: center;
  }

  &__text {
    font-weight: $font-weight-bold;
    vertical-align: middle;
  }

  &__icon {
    vertical-align: middle;
    font-size: 18px;
    margin-right: 10px;
  }

  &__large-icon {
    margin-bottom: 1rem;
    font-size: 50px;
  }
}
</style>
