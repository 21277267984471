<template>
  <button class="hamburger" :class="{ 'hamburger--close': menuVisible }">
    <span class="hamburger__bar hamburger__bar--top"></span>
    <span class="hamburger__bar hamburger__bar--middle"></span>
    <span class="hamburger__bar hamburger__bar--bottom"></span>
  </button>
</template>

<script>
export default {
  name: 'Hamburger',
  props: ['menu-visible']
}
</script>

<style lang="scss">
$bar-width: 2px;
$bar-length: 25px;
$button-height: 20px;

.hamburger {
  display: block;
  outline: 0;
  border: 0;
  background: none;
  padding: 0;
  width: $bar-length;
  height: $button-height;
  position: relative;
  color: $white;

  &:focus {
    outline: 0;
  }

  &__bar {
    height: $bar-width;
    background: currentColor;
    width: 100%;
    position: absolute;
    left: 0;
    border-radius: 2px;

    &--top,
    &--bottom {
      transition: transform 0.2s;
      transform-origin: center left;
    }

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
    }

    &--middle {
      transition: opacity;
      top: calc(50% - #{$bar-width / 2});
    }
  }

  &--close {
    .hamburger__bar--top {
      transform: rotate(45deg);
    }

    .hamburger__bar--middle {
      opacity: 0;
    }

    .hamburger__bar--bottom {
      transform: rotate(-45deg);
    }
  }
}
</style>
