/* =======
   Imports
   ======= */
import 'core-js/stable'
import 'cropperjs/dist/cropper.css'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import 'vue2-datepicker/index.css'
import Vuelidate from 'vuelidate'
import App from './App'
import router from './router'
import store from './store'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

// Icons
import { library } from '@fortawesome/fontawesome-svg-core'

// Solid
import { faCalendarPlus as fasCalendarPlus } from './assets/icons/fas/faCalendarPlus'
import { faCameraAlt as fasCameraAlt } from './assets/icons/fas/faCameraAlt'
import { faCaretRight as fasCaretRight } from './assets/icons/fas/faCaretRight'
import { faCheck as fasCheck } from './assets/icons/fas/faCheck'
import { faCheckCircle as fasCheckCircle } from './assets/icons/fas/faCheckCircle'
import { faDownload as fasDownload } from './assets/icons/fas/faDownload'
import { faEllipsisH as fasEllipsisH } from './assets/icons/fas/faEllipsisH'
import { faEnvelope as fasEnvelope } from './assets/icons/fas/faEnvelope'
import { faExclamationCircle as fasExclamationCircle } from './assets/icons/fas/faExclamationCircle'
import { faPencil as fasPencil } from './assets/icons/fas/faPencil'
import { faPlus as fasPlus } from './assets/icons/fas/faPlus'
import { faPlusCircle as fasPlusCircle } from './assets/icons/fas/faPlusCircle'
import { faQuestion as fasQuestion } from './assets/icons/fas/faQuestion'
import { faSignOut as fasSignOut } from './assets/icons/fas/faSignOut'
import { faTimes as fasTimes } from './assets/icons/fas/faTimes'
import { faTimesCircle as fasTimesCircle } from './assets/icons/fas/faTimesCircle'
import { faTrashAlt as fasTrashAlt } from './assets/icons/fas/faTrashAlt'
import { faUserPlus as fasUserPlus } from './assets/icons/fas/faUserPlus'
import { faUserSecret as fasUserSecret } from './assets/icons/fas/faUserSecret'

// // Light
import { faAngleDown as falAngleDown } from './assets/icons/fal/faAngleDown'
import { faArrowLeft as falArrowLeft } from './assets/icons/fal/faArrowLeft'
import { faArrowRight as falArrowRight } from './assets/icons/fal/faArrowRight'
import { faAt as falAt } from './assets/icons/fal/faAt'
import { faBars as falBars } from './assets/icons/fal/faBars'
import { faBeer as falBeer } from './assets/icons/fal/faBeer'
import { faBowlingPins as falBowlingPins } from './assets/icons/fal/faBowlingPins'
import { faBriefcase as falBriefcase } from './assets/icons/fal/faBriefcase'
import { faCalendarAlt as falCalendarAlt } from './assets/icons/fal/faCalendarAlt'
import { faCalendarEdit as falCalendarEdit } from './assets/icons/fal/faCalendarEdit'
import { faCalendarPlus as falCalendarPlus } from './assets/icons/fal/faCalendarPlus'
import { faCameraAlt as falCameraAlt } from './assets/icons/fal/faCameraAlt'
import { faCheck as falCheck } from './assets/icons/fal/faCheck'
import { faCheckCircle as falCheckCircle } from './assets/icons/fal/faCheckCircle'
import { faClock as falClock } from './assets/icons/fal/faClock'
import { faCocktail as falCocktail } from './assets/icons/fal/faCocktail'
import { faComment as falComment } from './assets/icons/fal/faComment'
import { faComments as falComments } from './assets/icons/fal/faComments'
import { faCopy as falCopy } from './assets/icons/fal/faCopy'
import { faCouch as falCouch } from './assets/icons/fal/faCouch'
import { faCrown as falCrown } from './assets/icons/fal/faCrown'
import { faDumbbell as falDumbbell } from './assets/icons/fal/faDumbbell'
import { faEllipsisH as falEllipsisH } from './assets/icons/fal/faEllipsisH'
import { faGamepad as falGamepad } from './assets/icons/fal/faGamepad'
import { faGlobe as falGlobe } from './assets/icons/fal/faGlobe'
import { faHeart as falHeart } from './assets/icons/fal/faHeart'
import { faLocationArrow as falLocationArrow } from './assets/icons/fal/faLocationArrow'
import { faMapMarkerAlt as falMapMarkerAlt } from './assets/icons/fal/faMapMarkerAlt'
import { faMedal as falMedal } from './assets/icons/fal/faMedal'
import { faMusic as falMusic } from './assets/icons/fal/faMusic'
import { faPencil as falPencil } from './assets/icons/fal/faPencil'
import { faPlus as falPlus } from './assets/icons/fal/faPlus'
import { faRedo as falRedo } from './assets/icons/fal/faRedo'
import { faSearch as falSearch } from './assets/icons/fal/faSearch'
import { faShare as falShare } from './assets/icons/fal/faShare'
import { faSignIn as falSignIn } from './assets/icons/fal/faSignIn'
import { faSignInAlt as falSignInAlt } from './assets/icons/fal/faSignInAlt'
import { faSignOut as falSignOut } from './assets/icons/fal/faSignOut'
import { faSpinnerThird as falSpinnerThird } from './assets/icons/fal/faSpinnerThird'
import { faTag as falTag } from './assets/icons/fal/faTag'
import { faTennisBall as falTennisBall } from './assets/icons/fal/faTennisBall'
import { faThumbsUp as falThumbsUp } from './assets/icons/fal/faThumbsUp'
import { faTimes as falTimes } from './assets/icons/fal/faTimes'
import { faTimesCircle as falTimesCircle } from './assets/icons/fal/faTimesCircle'
import { faTrashAlt as falTrashAlt } from './assets/icons/fal/faTrashAlt'
import { faTrophy as falTrophy } from './assets/icons/fal/faTrophy'
import { faUserCircle as falUserCircle } from './assets/icons/fal/faUserCircle'
import { faUserCrown as falUserCrown } from './assets/icons/fal/faUserCrown'
import { faUserPlus as falUserPlus } from './assets/icons/fal/faUserPlus'
import { faUsers as falUsers } from './assets/icons/fal/faUsers'
import { faUtensils as falUtensils } from './assets/icons/fal/faUtensils'

// // Brands
import { faApple as fabApple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faWhatsapp as fabWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'

import { GOOGLE_MAPS_API_KEY } from '@/utils/constants'
import { determineInitialLocale } from '@/utils/i18n'
import { getMetaForRoute } from '@/utils/router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import Cookies from 'js-cookie'
import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from './i18n'
import breakpointMixin from './mixins/breakpointMixin'
import * as Sentry from './utils/sentry'

/* ================
   Init Vue plugins
   ================ */
Vue.use(Vuelidate)
Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  }
})

if (process.env.VUE_APP_GA_TRACKING_ID) {
  const isProd = process.env.NODE_ENV === 'production'
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GA_TRACKING_ID,
    router,
    debug: {
      enabled: !isProd,
      sendHitTask: isProd
    }
  })
}
Vue.use(BootstrapVue)
Vue.mixin(breakpointMixin)

/* ===========
   Init locale
   =========== */
const locale = determineInitialLocale()

Cookies.set('lang', locale, { expires: 730 })

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

if (locale === 'nl') {
  require('dayjs/locale/nl')
  dayjs.locale('nl')
}

/* ======================
   Init FontAwesome icons
   ====================== */
library.add(
  // Solid
  fasCalendarPlus,
  fasCameraAlt,
  fasCaretRight,
  fasCheck,
  fasCheckCircle,
  fasDownload,
  fasEllipsisH,
  fasEnvelope,
  fasExclamationCircle,
  fasPencil,
  fasPlus,
  fasPlusCircle,
  fasQuestion,
  fasSignOut,
  fasTimes,
  fasTimesCircle,
  fasTrashAlt,
  fasUserPlus,
  fasUserSecret,

  // // Light
  falAngleDown,
  falTrashAlt,
  falArrowLeft,
  falArrowRight,
  falAt,
  falBars,
  falBeer,
  falBowlingPins,
  falBriefcase,
  falCalendarAlt,
  falCalendarEdit,
  falCalendarPlus,
  falCameraAlt,
  falCheck,
  falCheckCircle,
  falClock,
  falCocktail,
  falComment,
  falComments,
  falCopy,
  falCouch,
  falCrown,
  falDumbbell,
  falEllipsisH,
  falGlobe,
  falGamepad,
  falHeart,
  falLocationArrow,
  falMapMarkerAlt,
  falMedal,
  falMusic,
  falPencil,
  falPlus,
  falRedo,
  falSearch,
  falShare,
  falSignIn,
  falSignInAlt,
  falSignOut,
  falSpinnerThird,
  falTag,
  falTennisBall,
  falThumbsUp,
  falTimes,
  falTimesCircle,
  falTrophy,
  falUserCircle,
  falUserCrown,
  falUserPlus,
  falUsers,
  falUtensils,

  // // Brands
  fabWhatsapp,
  fabInstagram,
  fabTwitter,
  fabApple,
  fabFacebook,
  fabGoogle
)
Vue.component('icon', FontAwesomeIcon)

/* ===================
   Init error reporting
   =================== */
Sentry.init()

/* ===================
   Init authentication
   =================== */
router.beforeEach((to, from, next) => {
  const requiresAuth = getMetaForRoute('requiresAuth', to)

  if (requiresAuth && !store.getters.isAuthenticated) {
    store.dispatch('loadUser').then(() => {
      if (store.getters.isAuthenticated) {
        next()
      } else {
        next({ name: 'Login', query: { email: to.query.email, redirect: to.fullPath } })
      }
    })
  } else {
    next()
  }
})

/* ========
   Init app
   ======== */
/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  components: { App },
  render: (h) => h(App)
}).$mount('#app')
