import { BrowserClient } from '@sentry/browser'

const dsn = process.env.VUE_APP_SENTRY_DSN
const client = dsn ? new BrowserClient({ dsn }) : null

export function captureException(error) {
  if (client) {
    client.captureException(error)
  } else {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export function captureApiException(error) {
  if (!error.response) captureException(error)
}
