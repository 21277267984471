<template>
  <img
    :src="randomGif"
    class="rounded-circle d-block m-auto fail-gif"
    :height="size"
    :width="size"
  />
</template>

<script>
export default {
  name: 'FailGif',
  props: {
    size: {
      type: [Number, String],
      default: 250
    }
  },
  data() {
    const gifs = [
      require('@/assets/gifs/fail/fail1.gif'),
      require('@/assets/gifs/fail/fail2.gif'),
      require('@/assets/gifs/fail/fail3.gif'),
      require('@/assets/gifs/fail/fail4.gif'),
      require('@/assets/gifs/fail/fail5.gif')
    ]

    const randomGif = gifs[Math.floor(Math.random() * gifs.length)]

    return {
      randomGif
    }
  }
}
</script>

<style lang="scss">
.fail-gif {
  border: 4px solid $white;
}
</style>
