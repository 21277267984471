import { REQUEST_STATE_LOADING, REQUEST_STATE_SUCCESS } from '@/utils/constants'
import { APPLE_REDIRECT_URL, GOOGLE_REDIRECT_URL, resetState } from '@/utils/oauth'
import * as Sentry from '@/utils/sentry'
import {
  AUTH_ERROR,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AVATAR_UPDATE_ERROR,
  AVATAR_UPDATE_REQUEST,
  AVATAR_UPDATE_SUCCESS,
  PROFILE_UPDATE,
  USER_SUCCESS
} from './mutations'

import { getInstance, setAuthorisationToken, unsetAuthorisationToken } from '@/utils/api'
import { captureApiException } from '@/utils/errors'

import i18n from '@/i18n'

export const api = getInstance('auth')

export default {
  loadUser: ({ commit, dispatch, state }) => {
    if (
      state.status === REQUEST_STATE_SUCCESS ||
      state.status === REQUEST_STATE_LOADING ||
      !state.token
    ) {
      return
    }

    commit(AUTH_REQUEST)

    return api
      .get('/me/')
      .then((response) => {
        commit(USER_SUCCESS, response.data)
        Sentry.setUserContext(response.data)

        return dispatch('loadInitialData')
      })
      .catch((error) => {
        commit(AUTH_ERROR)
        // if resp is unauthorized, logout, to
        dispatch('logout')

        captureApiException(error)
      })
  },
  loadInitialData: ({ dispatch }) => {
    return Promise.all([dispatch('loadEvents'), dispatch('loadTeams')])
  },
  editProfile: ({ commit, dispatch }, { firstName, lastName, nickname }) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      nickname
    }

    return api
      .put('/me/', data)
      .then((response) => {
        commit(PROFILE_UPDATE, response.data)
        dispatch('loadTeams')
        dispatch('loadEvents')
      })
      .catch((error) => {
        const { response } = error
        const message =
          response && response.data && response.data.errors
            ? response.data.errors[0].message
            : i18n.t('errors.auth.edit_profile')

        captureApiException(error)

        throw new Error(message)
      })
  },
  editAvatar: ({ commit, dispatch }, { image }) => {
    const data = new FormData()

    commit(AVATAR_UPDATE_REQUEST)
    data.append('avatar', image, 'avatar.jpg')

    return api
      .put('/me/avatar/', data)
      .then((resp) => {
        commit(AVATAR_UPDATE_SUCCESS, resp.data)
        dispatch('loadTeams')
        dispatch('loadEvents')
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.auth.edit_avatar'),
          type: AVATAR_UPDATE_ERROR
        })

        captureApiException(error)
      })
  },
  setLanguage: ({ dispatch }, newLanguage) => {
    return api
      .put('/set-language/', { language: newLanguage })
      .then((response) => response.data.language)
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.auth.set_language')
        })

        captureApiException(error)
      })
  },
  authorize: ({ commit, dispatch }, { token, user, is_new }) => {
    Sentry.setUserContext(user)

    localStorage.setItem('user-token', token)
    setAuthorisationToken(token)
    commit(AUTH_SUCCESS, token)
    commit(USER_SUCCESS, user)
    return dispatch('loadInitialData').then(() => is_new)
  },
  loginApple: ({ commit, dispatch }, { code, error, user }) => {
    commit(AUTH_REQUEST)
    if (error) {
      return commit(AUTH_ERROR, i18n.t('errors.auth.login_apple'))
    }

    return api
      .post('/login/social/apple-id', {
        code,
        redirect_uri: APPLE_REDIRECT_URL,
        user
      })
      .then((response) => dispatch('authorize', response.data))
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.auth.login_apple'),
          type: AUTH_ERROR
        })

        captureApiException(error)
      })
  },
  loginGoogle: ({ commit, dispatch }, { code, error }) => {
    commit(AUTH_REQUEST)
    if (error) {
      return commit(AUTH_ERROR, i18n.t('errors.auth.login_google'))
    }

    return api
      .post('/login/social/google-oauth2/', {
        code,
        redirect_uri: GOOGLE_REDIRECT_URL
      })
      .then((response) => dispatch('authorize', response.data))
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.auth.login_google'),
          type: AUTH_ERROR
        })

        captureApiException(error)
      })
  },
  requestSignInCode: (_, { email }) => {
    const data = {
      email
    }

    return api.post('/code/request/', data).catch((error) => {
      const { response } = error

      if (response && response.data && Array.isArray(response.data.errors)) {
        error.message = response.data.errors[0].message
        error.status = response.data.status
      } else {
        error.message = i18n.t('errors.auth.request_otp')
      }

      captureApiException(error)

      throw error
    })
  },
  verifySignInCode: ({ dispatch }, data) => {
    return api
      .post('/code/verify/', data)
      .catch((error) => {
        const { response } = error

        if (response && response.data && Array.isArray(response.data.errors)) {
          error.message = response.data.errors[0].message
          error.status = response.data.status
        } else {
          error.message = i18n.t('errors.auth.verify_otp')
        }

        captureApiException(error)

        throw error
      })
      .then((response) => dispatch('authorize', response.data))
  },
  logout: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      resetState() // reset oauth state, to prevent redirecting to the same url all the time
      localStorage.removeItem('user-token')
      unsetAuthorisationToken()
      dispatch('resetAll')
      resolve()
    })
  }
}
