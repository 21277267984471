import Vue from 'vue'

import {
  REQUEST_STATE_ERROR,
  REQUEST_STATE_LOADING,
  REQUEST_STATE_SUCCESS,
  REQUEST_STATE_UPLOADING_DATA
} from '@/utils/constants'
import { getDefaultState } from './default'

export const AUTH_RESET = 'AUTH_RESET'
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'

export const USER_SUCCESS = 'USER_SUCCESS'

export const PROFILE_UPDATE = 'PROFILE_UPDATE'

export const AVATAR_UPDATE_REQUEST = 'AVATAR_UPDATE_REQUEST'
export const AVATAR_UPDATE_SUCCESS = 'AVATAR_UPDATE_SUCCESS'
export const AVATAR_UPDATE_ERROR = 'AVATAR_UPDATE_ERROR'

export default {
  [AUTH_RESET]: (state) => {
    Object.assign(state, getDefaultState())
  },
  [AUTH_REQUEST]: (state) => {
    state.status = REQUEST_STATE_LOADING
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = REQUEST_STATE_SUCCESS
    state.error = null
    state.token = token
  },
  [AUTH_ERROR]: (state, error) => {
    state.token = null
    state.status = REQUEST_STATE_ERROR
    state.error = error
  },
  [USER_SUCCESS]: (state, profile) => {
    state.status = REQUEST_STATE_SUCCESS
    state.error = null
    Vue.set(state, 'profile', profile)
  },
  [PROFILE_UPDATE]: (state, profile) => {
    Vue.set(state, 'profile', profile)
  },
  [AVATAR_UPDATE_REQUEST]: (state) => {
    state.status = REQUEST_STATE_UPLOADING_DATA
  },
  [AVATAR_UPDATE_SUCCESS]: (state, profile) => {
    state.status = REQUEST_STATE_SUCCESS
    state.error = null
    state.profile.avatar_small = profile.avatar_small
    state.profile.avatar_large = profile.avatar_large
  },
  [AVATAR_UPDATE_ERROR]: (state, error) => {
    state.error = error
    state.status = REQUEST_STATE_ERROR
  }
}
