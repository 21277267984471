import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING
} from '@/utils/constants'
import v4 from 'uuid/v4'
import { NOTIFICATIONS_ADD, NOTIFICATIONS_CLEAR, NOTIFICATIONS_REMOVE } from './mutations'

export default {
  addNotification: ({ commit }, { text, title, ttl, type = NOTIFICATION_TYPE_SUCCESS }) => {
    const validTypes = [
      NOTIFICATION_TYPE_SUCCESS,
      NOTIFICATION_TYPE_ERROR,
      NOTIFICATION_TYPE_WARNING,
      NOTIFICATION_TYPE_INFO
    ]

    if (!text) throw new Error('Invalid notification text')
    if (ttl !== undefined && !Number.isInteger(ttl)) {
      throw new Error('Parameter ttl is not a number')
    }
    if (validTypes.indexOf(type) === -1) {
      throw new Error('Invalid notification type: ' + type)
    }

    const uuid = v4()
    commit(NOTIFICATIONS_ADD, { text, title, type, ttl, uuid })
  },
  removeNotification: ({ commit }, { uuid }) => {
    commit(NOTIFICATIONS_REMOVE, uuid)
  },
  clearNotifications: ({ commit }) => {
    commit(NOTIFICATIONS_CLEAR)
  }
}
