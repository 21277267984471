export default {
  data() {
    return {
      fromRoute: null
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from.name
    })
  },

  methods: {
    goBack(name = 'Home') {
      if (this.fromRoute) {
        this.$router.back()
      } else {
        this.$router.push({ name })
      }
    }
  }
}
