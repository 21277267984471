<template>
  <modal :title="$t('event_wizard.when.time_zone')" @close="$emit('close')" size="sm">
    <select v-model="timeZoneValue" class="custom-select">
      <option
        v-for="timeZone in timeZones"
        :value="timeZone.value"
        v-text="getAlternativeTimeZoneLabel(timeZone)"
        :key="timeZone.value"
      />
    </select>
    <button
      class="btn btn-secondary mt-3"
      @click.prevent="confirm"
      type="button"
      v-text="$t('general.buttons.confirm')"
    />
  </modal>
</template>

<script>
import { getTimeZones, getTimeZone, getAlternativeTimeZoneLabel } from '@/utils/timeZones'
import Modal from '@/components/Modal'

export default {
  name: 'TimeZoneModal',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      getAlternativeTimeZoneLabel,
      timeZones: getTimeZones(),
      timeZoneValue: this.value
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm', getTimeZone(this.timeZoneValue))
    }
  }
}
</script>
