<template>
  <modal
    v-if="event"
    :title="$t('events.delete.title')"
    @close="$router.replace({ name: 'EventDetails' })"
    size="sm"
  >
    <div class="modal__stretcher">
      <p v-html="$t('events.delete.text')"></p>
      <div v-if="hasFutureDeleteOption" class="form-group row align-items-center">
        <label for="deleteFuture" class="col-9 col-form-label">{{
          $t('events.delete.also_remove_future')
        }}</label>
        <div class="col-3 text-right">
          <toggle-switch id="deleteFuture" v-model="deleteFuture" />
        </div>
      </div>
      <div v-if="event.canCancel" class="form-group row align-items-center">
        <label for="notifyInvitees" class="col-9 col-form-label">{{
          $t('events.delete.notify_invitees')
        }}</label>
        <div class="col-3 text-right">
          <toggle-switch id="notifyInvitees" v-model="notifyInvitees" />
        </div>
      </div>
      <div class="text-center mt-5">
        <button
          v-if="hasFutureDeleteOption && !deleteFuture"
          :disabled="loading"
          type="submit"
          class="btn btn-danger btn-block"
          @click="onConfirmDeletePress()"
        >
          {{ $t('events.delete.confirm_multiple_just_this_one') }}
        </button>
        <button
          v-if="hasFutureDeleteOption && deleteFuture"
          :disabled="loading"
          type="submit"
          class="btn btn-danger btn-block"
          @click="onConfirmDeletePress()"
        >
          {{ $t('events.delete.confirm_multiple_plus_future') }}
        </button>
        <button
          v-if="!hasFutureDeleteOption"
          :disabled="loading"
          type="submit"
          class="btn btn-danger btn-block"
          @click="onConfirmDeletePress()"
        >
          {{ $t('events.delete.confirm_single') }}
        </button>
        <a
          @click="$router.replace({ name: 'EventDetails' })"
          class="d-inline-block btn btn-link mt-3"
          >{{ $t('general.buttons.cancel') }}</a
        >
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import ToggleSwitch from '@/components/ToggleSwitch'

export default {
  name: 'EventDelete',
  data() {
    return {
      loading: false,
      deleteFuture: false,
      notifyInvitees: this.event.canCancel
    }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal,
    ToggleSwitch
  },
  created() {
    if (!this.event.canDelete && !this.event.canCancel) {
      this.$router.replace({ name: 'Home' })
    }
  },
  computed: {
    hasFutureDeleteOption() {
      return this.event.isRepeatable && !this.event.hasPassed
    }
  },
  methods: {
    onConfirmDeletePress() {
      this.loading = true

      this.$store
        .dispatch('deleteEvent', {
          eventId: this.event.id,
          deleteFuture: this.deleteFuture,
          notifyInvitees: this.notifyInvitees
        })
        .finally(() => {
          this.loading = false
          this.$router.replace({ name: 'Home' })
        })
    }
  }
}
</script>
