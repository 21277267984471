<template>
  <div class="text-page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h1 class="text-page__title">{{ $t('cookies.title') }}</h1>
          <p v-for="(text, index) in $t('cookies.intro')" v-text="text" :key="index" />
          <h2 class="h3">{{ $t('cookies.ga_cookie.title') }}</h2>
          <dl class="row">
            <dt class="col-sm-4">{{ $t('cookies.name') }}</dt>
            <dd class="col-sm-8">ga, gat, gid, utma, utmb, utmc, utmv, utmz</dd>
            <dt class="col-sm-4">{{ $t('cookies.function') }}</dt>
            <dd class="col-sm-8" v-text="$t('cookies.ga_cookie.description')" />
            <dt class="col-sm-4">{{ $t('cookies.period') }}</dt>
            <dd class="col-sm-8">2 {{ $t('general.years') }}</dd>
          </dl>
          <h2 class="h3">{{ $t('cookies.hide_bar_cookie.title') }}</h2>
          <dl class="row">
            <dt class="col-sm-4">{{ $t('cookies.name') }}</dt>
            <dd class="col-sm-8">cookies-confirmed</dd>
            <dt class="col-sm-4">{{ $t('cookies.function') }}</dt>
            <dd class="col-sm-8" v-text="$t('cookies.hide_bar_cookie.description')" />
            <dt class="col-sm-4">{{ $t('cookies.period') }}</dt>
            <dd class="col-sm-8">180 {{ $t('general.days') }}</dd>
          </dl>
          <h2 class="h3">{{ $t('cookies.lang_cookie.title') }}</h2>
          <dl class="row">
            <dt class="col-sm-4">{{ $t('cookies.name') }}</dt>
            <dd class="col-sm-8">lang</dd>
            <dt class="col-sm-4">{{ $t('cookies.function') }}</dt>
            <dd class="col-sm-8" v-text="$t('cookies.lang_cookie.description')" />
            <dt class="col-sm-4">{{ $t('cookies.period') }}</dt>
            <dd class="col-sm-8">730 {{ $t('general.days') }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cookies'
}
</script>
