<template>
  <div class="text-page">
    <div class="container container--xs">
      <h1 class="text-page__title">{{ $t('about.title') }}</h1>
      <about-english v-if="$i18n.locale === 'en'" />
      <about-dutch v-else />
      <h2 class="mt-5">{{ $t('about.founders.title') }}</h2>
      <div class="card-deck mt-3">
        <div class="card" style="founder">
          <img src="@/assets/avatars/michiel.jpg" class="card-img-top" alt="Michiel van Roon" />
          <div class="card-body">
            <h5 class="card-title">Michiel van Roon</h5>
            <p class="card-text">{{ $t('about.founders.michiel') }}</p>
          </div>
        </div>
        <div class="card" style="founder">
          <img
            src="@/assets/avatars/michael.jpg"
            class="card-img-top"
            alt="Michael van de Waeter"
          />
          <div class="card-body">
            <h5 class="card-title">Michael van de Waeter</h5>
            <p class="card-text">{{ $t('about.founders.michael') }}</p>
          </div>
        </div>
      </div>
      <h2 id="donate" class="mt-5">{{ $t('about.donate.title') }}</h2>
      <div class="text-sm-lg">
        <p>{{ $t('about.donate.text') }}</p>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=BTTTA2JQWY9KE"
          class="btn btn-primary mt-3"
        >
          <icon :icon="['fas', 'coffee']" class="btn__icon--left" /> {{ $t('about.donate.cta') }}
        </a>
      </div>
      <h2 class="mt-5">{{ $t('about.social.title') }}</h2>
      <div class="text-sm-lg">
        <i18n path="about.social.text" tag="p">
          <template v-slot:instagram>
            <a href="https://www.instagram.com/erbijapp/" target="_blank" rel="noreferrer nofollow"
              >Instagram</a
            >
          </template>

          <template v-slot:facebook>
            <a href="https://www.facebook.com/erbijapp/" target="_blank" rel="noreferrer nofollow"
              >Facebook</a
            >
          </template>

          <template v-slot:twitter>
            <a href="https://twitter.com/erbijapp" target="_blank" rel="noreferrer nofollow"
              >Twitter</a
            >
          </template>
        </i18n>
        <div class="text-muted">
          <a
            href="https://www.instagram.com/erbijapp/"
            target="_blank"
            rel="noreferrer nofollow"
            class="mr-3"
          >
            <icon :icon="['fab', 'instagram']" size="2x" class="instagram-icon" />
          </a>
          <a
            href="https://www.facebook.com/erbijapp/"
            target="_blank"
            rel="noreferrer nofollow"
            class="mr-3"
          >
            <icon :icon="['fab', 'facebook']" size="2x" class="facebook-icon" />
          </a>
          <a href="https://twitter.com/erbijapp" target="_blank" rel="noreferrer nofollow">
            <icon :icon="['fab', 'twitter']" size="2x" class="twitter-icon" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutEnglish from './translations/AboutEN'
import AboutDutch from './translations/AboutNL'

export default {
  name: 'About',
  components: {
    AboutEnglish,
    AboutDutch
  }
}
</script>
