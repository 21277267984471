import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from './actions'
import auth from './modules/auth'
import events from './modules/events'
import feedback from './modules/feedback'
import notifications from './modules/notifications'
import options from './modules/options'
import teams from './modules/teams'
import wizards from './modules/wizards'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  actions,
  modules: {
    auth,
    events,
    feedback,
    notifications,
    options,
    wizards,
    teams
  },
  strict: debug
})
