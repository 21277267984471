export const getDefaultState = () => {
  return {
    events: {},
    error: null,
    status: null,
    count: null,
    invitation: null,
    teamFilter: null,
    showHistory: false
  }
}
