export default {
  en: {
    not_found: {
      title: "Unfortunately, that's not going to work",
      text: 'The requested URL {path} does not exist (anymore). Sorry.'
    },
    validations: {
      email: 'Please enter a valid email address',
      required: 'This field is required',
      date: 'Invalid date',
      date_future: 'Date must be in the future',
      time: 'Invalid time (HH:mm)',
      end_date_after_start_date: 'It should end after it has started. More fun that way.'
    },
    general_title: 'Oops, something went wrong.',
    auth: {
      login: 'Something went wrong while logging you in.',
      login_apple: 'Something went wrong while logging you in with Apple.',
      login_google: 'Something went wrong while logging you in with Google.',
      request_otp: 'Something went wrong while requesting a login code.',
      verify_otp: 'Something went wrong while verifying the login code.',
      edit_profile: 'Something went wrong while updating your profile.',
      edit_avatar: 'Something went wrong while updating your avatar.',
      set_language: 'Something went wrong while updating your language.'
    },
    events: {
      add: 'Something went wrong while trying to add your activity.',
      delete: 'Something went wrong while trying to delete your activity.',
      edit: 'Something went wrong while trying to save your activity.',
      leave: 'Something went wrong while trying to leave this activity.',
      load: 'Something went wrong while loading your activities.',
      404: 'This event no longer exists. It has probably been deleted.',
      set_decision: 'Something went wrong updating your decision.',
      set_decision_other: "Something went wrong while trying to update {name}'s decision.",
      share: 'Something went wrong creating an invitation link.',
      load_invitation:
        'Something went wrong while trying to load your invitation. Please ask the person that invited you to send you a new invitation.',
      load_invitation_is_canceled:
        'This activity is canceled. You can no longer respond to this invitation.',
      load_invitation_is_full: 'This activity is fully booked. You can not attend this activity.',
      load_invitation_has_expired:
        'This activity has passed. You can no longer respond to this invitation.',
      anon_respond_invitation: 'Something went wrong while trying to respond to your invitation.',
      anon_confirm_invitation: 'Something went wrong while trying to confirm your decision.',
      anon_confirm_invitation_wrong_code:
        "It looks like this invitation does not exist (anymore). Perhaps you've already confirmed your decision.<br>If you would like to change your decision, we ask you to create an account."
    },
    feedback: {
      send_feedback:
        'Something went wrong while sending your feedback. Please try again later. Or send us an e-mail at feedback@erbij.app'
    },
    calendar_sync: {
      missing_privilege: 'You are not allowed to subscribe to your Erbij calendar'
    },
    teams: {
      create: 'Something went wrong while adding your group.',
      edit: 'Something went wrong while updating your group.',
      edit_photo: 'Something went wrong while updating your group photo.',
      leave: 'Something went wrong while leaving your group.',
      remove_other: 'Something went wrong while trying to remove this user from your group.',
      load: 'Something went wrong while loading your groups. Please try again later.',
      404: 'This group no longer exists or you are not a member of this group.',
      share: 'Something went wrong while trying to invite more members for your group.',
      make_manager:
        'Something went wrong while trying to promote {name} to manager of "{team}". Perhaps the person is already manager?',
      load_invitation:
        'Something went wrong while trying to load the invitation. Please ask the person that invited you to send you a new invitation.',
      invitation_generic:
        'Something went wrong while trying to respond to the invitation. Please try again later.',
      invitation_expired: 'This invitation has expired. Please ask the inviter to invite you again.'
    },
    sentry: {
      title: 'It looks like we’re having issues.',
      subtitle: 'Our team has been notified.',
      subtitle2: 'If you’d like to help, tell us what happened below',
      generic: 'An unknown error occurred while submitting your report. Please try again.',
      form: 'Some fields were invalid. Please correct the errors and try again.',
      success: 'Your feedback has been sent. Thank you!'
    }
  },
  nl: {
    not_found: {
      title: 'Daar kunnen we helaas niks mee',
      text: 'De opgevraagde URL {path} bestaat niet (meer). Sorry.'
    },
    validations: {
      email: 'Vul een geldig e-mailadres in',
      required: 'Dit veld is verplicht',
      date: 'Ongeldige datum',
      date_future: 'Datum moet in de toekomst liggen',
      time: 'Ongeldige tijd (UU:mm)',
      end_date_after_start_date:
        'Activiteiten zijn leuker als ze beginnen voordat ze afgelopen zijn.'
    },
    general_title: 'Oeps, daar ging iets fout.',
    auth: {
      login: 'Er ging iets fout bij het inloggen.',
      login_apple: 'Er ging iets fout bij het inloggen met Apple.',
      login_google: 'Er ging iets fout bij het inloggen met Google.',
      request_otp: 'Er ging iets fout bij het aanvragen van een inlogcode.',
      verify_otp: 'Er ging iets fout bij het controleren van de inlogcode.',
      edit_profile: 'Er ging iets fout bij het opslaan van je profiel.',
      edit_avatar: 'Er ging iets fout bij het opslaan van je profielfoto.',
      set_language: 'Er ging iets fout bij het opslaan van je voorkeurstaal.'
    },
    events: {
      add: 'Er ging iets fout bij het opslaan van je activiteit',
      delete: 'Er ging iets fout bij het verwijderen van je activiteit',
      edit: 'Er ging iets fout bij het wijzigen van je activiteit',
      leave: 'Er ging iets fout bij het verlaten van deze activiteit',
      load: 'Er ging iets fout bij het laden van je agenda.',
      404: 'Deze activiteit bestaat niet meer. Het is waarschijnlijk verwijderd.',
      set_decision: 'Er ging iets fout bij het zetten van je beslissing.',
      set_decision_other: "Er ging iets fout bij het aanpassen van {name}'s beslissing.",
      share: 'Er ging iets fout bij creëren van een uitnodigings link.',
      load_invitation:
        'Er ging iets fout bij het laden van je uitnodiging. Vraag degene die je heeft uitgenodigd om je een nieuwe uitnodiging te sturen.',
      load_invitation_is_canceled: 'Deze activiteit is afgelast. Je kunt niet meer reageren.',
      load_invitation_is_full:
        'Deze activiteit is volgeboekt. Je kunt je niet meer aanmelden voor deze activiteit.',
      load_invitation_has_expired:
        'Deze activiteit is inmiddels voorbij. Je kunt niet meer reageren.',
      anon_respond_invitation: 'Er ging iets fout bij het reageren op je uitnodiging.',
      anon_confirm_invitation: 'Er ging iets fout bij het bevestigen van je beslissing',
      anon_confirm_invitation_wrong_code:
        'Het lijkt erop dat deze uitnodiging niet (meer) bestaat. Wellicht heb je al een keer je besluit bevestigd?<br>Als je je besluit wilt wijzigen, dan verzoeken we je een account aan te maken.'
    },
    feedback: {
      send_feedback:
        'Er ging iets fout bij het versturen van je feedback. Probeer het later alsjblieft nog eens. Of benader ons direct via feedback@erbij.app'
    },
    calendar_sync: {
      missing_privilege: 'Je mist de rechten om je te abonneren op je Erbij-agenda'
    },
    teams: {
      create: 'Er ging iets fout bij het toevoegen van je groep.',
      edit: 'Er ging iets fout bij het wijzigen van je groep.',
      edit_photo: 'Er ging iets fout bij het wijzigen van je groepsfoto.',
      leave: 'Er ging iets fout bij het verlaten van je groep.',
      remove_other: 'Er ging iets fout bij het verwijderen van deze gebruiker uit je groep.',
      load: 'Er ging iets fout bij het laden van je groepen. Probeer het later alsjeblieft nog eens.',
      404: 'Deze groep bestaat niet (meer) of je bent geen lid van deze groep.',
      share: 'Er ging iets fout bij het proberen uitnodigen van nieuwe leden voor je groep.',
      make_manager:
        'Er ging iets fout bij het promoveren van {name} tot manager van "{team}". Is degene toevallig al manager?',
      load_invitation:
        'Er ging iets fout bij het laden van je uitnodiging. Vraag degene die je heeft uitgenodigd om je een nieuwe uitnodiging te sturen.',
      invitation_generic:
        'Er ging iets fout bij het reageren op de uitnodiging. Probeer het later nog eens.',
      invitation_expired:
        'De uitnodiging is verlopen. Vraag de uitnodiger om je opnieuw uit te nodigen.'
    },
    sentry: {
      title: 'Oeps, dat ging niet helemaal goed...',
      subtitle: 'We hebben ons team hiervan op de hoogte gesteld.',
      subtitle2: 'Als je wilt helpen, beschrijf dan hieronder wat er precies fout ging.',
      generic:
        'Er ging iets fout bij het versturen van je feedback. Zo blijven we bezig. Probeer het later nog eens.',
      form: 'Sommige velden hierboven zijn niet juist ingevuld. Corrigeer de fouten en probeer het opnieuw.',
      success: 'Je feedback is verstuurd. Dankjewel!'
    }
  }
}
