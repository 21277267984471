<template>
  <modal
    v-if="team"
    :title="$t('teams.edit.title')"
    @close="$router.push({ name: 'TeamDetails' })"
    size="sm"
  >
    <team-form
      @submit="onFormSubmit"
      class="modal__stretcher"
      :id="team.id"
      :loading="loading"
      :name="team.name"
      :type="team.team_type"
      :managersOnly="team.only_managers_can_add_events"
    />
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import TeamForm from '@/components/TeamForm'

export default {
  name: 'TeamEdit',
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  components: {
    Modal,
    TeamForm
  },
  methods: {
    onFormSubmit(values) {
      this.loading = true
      this.$store
        .dispatch('editTeam', {
          teamId: values.id,
          name: values.name,
          type: values.type,
          onlyManagersCanAddEvents: values.managersOnly
        })
        .then(() => {
          this.$router.replace({ name: 'TeamDetails' })
          this.$store.dispatch('addNotification', {
            title: this.$i18n.t('teams.edit.success_notification.title'),
            text: this.$i18n.t('teams.edit.success_notification.text')
          })
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>
