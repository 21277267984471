<template>
  <div>
    <h1 class="text-page__title">Privacy</h1>
    <p>
      Michiel van Roon Web Development, located at Battutalaan 523, 3526VS Utrecht, is responsible
      for the processing of personal data used in the Erbij apps and via erbij.app as shown in this
      privacy statement.
    </p>
    <h2 class="h3">Contact details</h2>
    <p>Michiel van Roon Web Development, Battutalaan 523, 3526VS Utrecht</p>
    <p>
      Michiel van Roon is the Data Protection Officer at Erbij. He can be reached via
      <a href="mailto:michiel@erbij.app">michiel@erbij.app</a>
    </p>
    <h2 class="h3">Personal data that we process</h2>
    <p>
      Erbij processes your personal data because you use our services and/or because you provide
      this information to us.
    </p>
    <p>Below you will find an overview of the personal data that we process:</p>
    <ul>
      <li>first name</li>
      <li>last name</li>
      <li>nickname</li>
      <li>email address</li>
      <li>profile picture</li>
      <li>
        data from your smartphone, such as the model, the operating system and the name of your
        smartphone (e.g. "iPhone from John Doe")
      </li>
    </ul>
    <h2 class="h3">Special and / or sensitive personal data that we process</h2>
    <p>
      Our website and our apps do not intend to collect data about users who are younger than 16
      years old, unless they have permission from their parents or guardians. However, we can not
      check if a visitor is older than 16. Therefore we recommend parents to be involved in the
      online activities of their children, in order to prevent data about children being collected
      without parental consent. If you are convinced that we have collected personal information
      about a minor without that permission, please contact us via
      <a href="mailto:privacy@erbij.app">privacy@erbij.app</a>, and we will remove this information.
    </p>
    <h2 class="h3">For what purpose and on what basis we process personal data</h2>
    <p>Erbij processes your personal data for the following purposes:</p>
    <h5>Profile picture, first name, last name and nickname</h5>
    <p>
      So that other group members and people that you've invited for activities can see who you are
    </p>
    <h5>Email address</h5>
    <p>
      So that we can email you when you are invited to an activity or a group. We will never send
      you a newsletter without you signing up and we promise never to share your email with third
      parties that are not mentioned in this statement.
    </p>
    <h5>Data from your smartphone</h5>
    <p>
      We use this information so that we can resolve errors in the website or in the apps faster,
      because we know on what kind of smartphone the error occurred.
    </p>
    <h2 class="h3">Automated decision-making</h2>
    <p>
      Erbij does not make decisions based on automated processing that are about matters that can
      have (significant) consequences for our users. These are decisions taken by computer programs
      or systems, where no person (for example an employee of Erbij) is involved with.
    </p>
    <h2 class="h3">Storage period</h2>
    <p>
      When you create an Erbij account (or log in via Apple or Google) we keep your profile in the
      principle forever. Unless you request us to delete your account.
    </p>
    <h2 class="h3">Removing your account</h2>
    <p>
      If you wish to have your Erbij account deleted, then please send an email to
      <a href="mailto:nolongererbij@erbij.app?subject=Delete%20my%20Erbij%20account."
        >nolongererbij@erbij.app</a
      >
      Do not forget to mention the email address that you use to log in. Whether you use Apple,
      Google or if you log in with your email adress. We will then delete your data within four
      weeks. We would appreciate it if you mention why you decided to leave us.
    </p>
    <h2 class="h3">Sharing personal data with third parties</h2>
    <p>
      As soon as you use the website or the apps to add an activity and invite others or when you
      join a group, then these Erbij members can view the data in your public profile. This profile
      contains the following information:
    </p>
    <ul>
      <li>first name</li>
      <li>last name</li>
      <li>nickname</li>
      <li>email address</li>
      <li>profile picture</li>
    </ul>
    <p>
      When you log in via Google, your profile photo of this service is automatically added to your
      profile.
    </p>
    <p>The above data is stored on the secure servers of Amazon Web Services (AWS).</p>
    <h2 class="h3">Cookies</h2>
    <p>
      The Erbij website (erbij.app) uses functional and analytical cookies. A cookie is a small text
      file that is stored in the browser of your computer, tablet or smartphone, on the first visit
      to this website. Erbij uses cookies with a purely technical functionality. These cookies
      ensure that the website works properly and that, for example, your preferred settings are
      remembered. In addition, we place analytical cookies that track the way you use erbij.app.
    </p>
    <p>During your first visit to our website we have already informed you about these cookies.</p>
    <p>
      On <a href="/cookies" title="About cookies">our page about cookies</a> you can find more
      information about the cookies that we use.
    </p>
    <p>
      You can opt out of cookies by setting your internet browser so that it does not store cookies
      anymore. In addition, you can also delete all information previously saved cookies via the
      settings of your browser.
    </p>
    <p>
      For more information:
      <a
        href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/"
        >https://veiliginternetten.nl/</a
      >
      (Dutch)
    </p>
    <h2 class="h3">View, modify or delete data</h2>
    <p>
      You have the right to view, correct or delete your personal data. You also have the right to
      withdraw your consent to the data processing or to object to the processing of your personal
      data by Erbij and you have the right to data portability. This means that you can submit a
      request to us to send the personal information we hold on you to you or any another
      organization mentioned by you in a computer file.
    </p>
    <p>
      You can send a request for access, correction, deletion, data transfer of your personal data
      or request for cancellation of your consent or objection to the processing of your personal
      data to
      <a href="mailto:privacy@erbij.app">privacy@erbij.app</a>.
    </p>
    <p>
      To ensure that the request for access has been made by you, we ask you to send a copy of your
      ID with the request. In this copy, please mask your photo, MRZ (machine readable zone, the
      strip with numbers at the bottom of the ID), ID number and citizen service number (BSN). This
      is to protect your privacy. We will respond as quickly as possible, but within four weeks, at
      your request.
    </p>
    <p>
      Erbij also would like to point out that you have the opportunity to file a complaint with the
      national supervisory authority, the Dutch Data Protection Authority. This can be done online
      on the website of
      <a
        href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
        >Autoriteit Persoonsgegevens (Dutch)</a
      >.
    </p>
    <h2 class="h3">How we protect personal data</h2>
    <p>
      Erbij takes the protection of your data seriously and takes appropriate measures to prevent
      misuse, loss, unauthorized access, unwanted disclosure and unauthorized modification. If you
      feel that your data is not properly secured if there are any indications of abuse, please send
      us an email at:
      <a href="mailto:privacy@erbij.app">privacy@erbij.app</a>. Erbij has taken the following
      measures to protect your personal data:
    </p>
    <ul>
      <li>
        All data traffic that we send or receive is encrypted using TLS. Our websites and apps are
        secured with an SSL (TLS) certificate. The details of the certificate, including the party
        responsible for issuing it and - for business validated certificates - the business details
        of the website owner can be checked by clicking on the lock in your browser.
      </li>
      <li>
        Your data is stored on the secure servers of Amazon Web Services. More information regarding
        the security of their servers can be found on the
        <a href="https://aws.amazon.com/security/">website of Amazon</a>.
      </li>
    </ul>
    <h2 class="h3">Providing to third parties</h2>
    <p>
      Erbij does not sell your data to third parties and will only provide them if this is necessary
      for the execution of our agreement with you or to comply with a legal obligation. Companies
      that process your data in our assignment ensure the same level of security and confidentiality
      of your data. Erbij remains responsible for these processing.
    </p>
    <p>Below is a list of companies and/or services that process your data::</p>
    <h4>OneSignal</h4>
    <dl class="row">
      <dt class="col-sm-4">Function:</dt>
      <dd class="col-sm-8">
        To send push notifications to your smartphone, for example when you are invited to an
        activity or a group
      </dd>
      <dt class="col-sm-4">Which data and why?</dt>
      <dd class="col-sm-8">
        <p>
          We share the unique code of your smartphone, so that OneSignal knows to which device
          relevant push notifications should be sent.
        </p>
        <p>
          OneSignal also reads information about your Erbij sessions. Think of the version of the
          app you use, how often and for how long you use it and from which country you are.
        </p>
        <p>
          <strong>We do not provide</strong> information such as your name, your email address or
          other personal information to OneSignal.
        </p>
      </dd>
      <dt class="col-sm-4">Privacystatement:</dt>
      <dd class="col-sm-8">
        <a href="https://onesignal.com/privacy_policy">https://onesignal.com/privacy_policy</a>
      </dd>
    </dl>
    <h4>Sentry</h4>
    <dl class="row">
      <dt class="col-sm-4">Goal:</dt>
      <dd class="col-sm-8">Process error reports and provide insight for our developers</dd>
      <dt class="col-sm-4">Which data and why?</dt>
      <dd class="col-sm-8">
        Your name and email address will be sent along with the error logs as soon as something goes
        wrong in the website or app. This way we can email you and ask you for more information with
        regard to the error message.
      </dd>
      <dt class="col-sm-4">Privacystatement:</dt>
      <dd class="col-sm-8"><a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a></dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'PrivacyContentEnglish'
}
</script>
