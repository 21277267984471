<template>
  <div class="cookie-bar" :class="{ 'cookie-bar--show': show }">
    <div class="cookie-bar__inner">
      <p class="cookie-bar__copy">
        {{ $t('cookies.bar.text') }} <a href="/cookies">{{ $t('cookies.bar.read_more') }}</a
        >.
      </p>
      <button class="cookie-bar__button btn btn-primary" @click="close">
        {{ $t('cookies.bar.close') }}
      </button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'CookieBar',
  data() {
    return {
      show: false
    }
  },
  mounted() {
    setTimeout(() => {
      if (!Cookies.get('cookies-confirmed')) {
        this.show = true
      } else {
        this.$emit('destroy')
      }
    }, 1000)
  },
  methods: {
    close() {
      Cookies.set('cookies-confirmed', 'yeah', { expires: 180 })
      this.show = false
      setTimeout(() => {
        this.$emit('destroy')
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.cookie-bar {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  background-color: $white;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.08);
  z-index: 1;
  transition: transform 0.3s;

  &--show {
    transform: translateY(-100%);
  }

  &__inner {
    max-width: 840px;
    align-items: center;
    margin: auto;
    padding: 20px;
    text-align: center;
  }

  &__button {
    white-space: nowrap;
  }

  &__copy {
    font-weight: normal;
    margin: 0 0 20px 0;
    font-size: 12px;
    line-height: 1.4;
  }

  @include sm-up {
    &__inner {
      display: flex;
      text-align: left;
    }

    &__copy {
      margin: 0 30px 0 0;
    }
  }
}
</style>
