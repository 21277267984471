import { REQUEST_STATE_LOADING } from '@/utils/constants'
import { sortEvents } from '@/utils/events'

export default {
  events: (state) => sortEvents(Object.values(state.events), state.showHistory),
  getEventById: (state) => (id) => state.events[id],
  getInviteeByEventIdAndUserId: (state) => (eventId, userId) =>
    state.events[eventId].invitees.find((invitee) => invitee.user.id === userId),
  eventsOverviewError: (state) => state.error,
  moreEventsAvailable: (state) =>
    state.count && state.events && state.count > Object.values(state.events).length,
  eventInvitation: (state) => state.invitation,
  isEventsLoading: (state) => state.status === REQUEST_STATE_LOADING
}
