<template>
  <ul class="user-list mb-0 row">
    <li
      class="user-list__item mb-3"
      :class="classObject"
      v-for="user in users"
      :key="user.id"
      :user="user.user"
      @click="$emit('userClick', user.id)"
    >
      <user-avatar
        class="user-list__avatar mr-3"
        :user="user"
        :allow-full-screen="!hasClickListener"
      ></user-avatar>
      <div>
        <span class="user-list__name"
          >{{ user.first_name }} <em v-if="user.nickname">"{{ user.nickname }}" </em
          >{{ user.last_name }}</span
        >
        <span class="user-list__manager" v-if="user.is_manager">{{
          $t('teams.general.manager')
        }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'UserList',
  props: {
    users: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 1
    }
  },
  computed: {
    hasClickListener() {
      return this.$listeners && this.$listeners.userClick
    },
    classObject() {
      return {
        'user-list__item--clickable': this.hasClickListener,
        ['col-sm-' + 12 / this.columns]: true
      }
    }
  },
  components: {
    UserAvatar
  }
}
</script>

<style lang="scss">
.user-list {
  @include list-unstyled;

  &__item {
    display: flex;
    align-items: center;

    &--clickable {
      @include hover-focus {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  &__name {
    display: block;
  }

  &__manager {
    display: block;
    font-size: $font-size-sm;
    color: $gray-600;
  }
}
</style>
