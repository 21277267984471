<template>
  <div class="home">
    <nutshell />
    <download-app />
  </div>
</template>

<script>
import DownloadApp from './views/DownloadApp'
import Nutshell from './views/Nutshell'

export default {
  name: 'Homepage',
  components: {
    Nutshell,
    DownloadApp
  }
}
</script>
