<template>
  <div class="text-page">
    <div class="container container--xs">
      <h1 class="text-page__title">{{ $t('about.donate.title') }}</h1>
      <div class="text-sm-lg">
        <p>{{ $t('about.donate.text') }}</p>
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=BTTTA2JQWY9KE"
          class="btn btn-lg btn-inline-sm btn-primary mt-4"
        >
          <icon :icon="['fas', 'coffee']" class="btn__icon--left" /> {{ $t('about.donate.cta') }}
        </a>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Donate'
}
</script>
