import i18n from '@/i18n'
import TimeZonesEN from './timeZones/timeZonesEN'
import TimeZonesNL from './timeZones/timeZonesNL'

const types = {
  nl: TimeZonesNL,
  en: TimeZonesEN
}

export function getTimeZone(value) {
  let timeZone = getTimeZones().find((zone) => zone.value === value)
  if (!timeZone) {
    timeZone = getTimeZones().find((zone) => zone.value === 'Europe/Amsterdam')
  }
  return timeZone
}

export function getTimeZones() {
  return types[i18n.locale]
}

export function getTimeZoneLabel(timeZone) {
  const { label, offset } = timeZone

  return `${label} (${getOffsetText(offset)}) `
}

export function getAlternativeTimeZoneLabel(timeZone) {
  const { label, offset } = timeZone

  return `(${getOffsetText(offset)}) ${label}`
}

export function getOffsetText(offset) {
  return [
    'GMT',
    offset < 0 ? '-' : '+',
    offset < 10 && offset > -10 ? '0' : '',
    Math.floor(Math.abs(offset)),
    ':',
    offset % 1 > 0 ? '30' : '00'
  ].join('')
}
