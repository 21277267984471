export default {
  en: {
    or: 'or',
    days: 'days',
    years: 'year(s)',
    for_example: 'E.g.',
    app_promo: 'This page works better in the app',
    buttons: {
      accept_changes: 'Accept',
      back: 'Back',
      cancel: 'Cancel',
      close: 'Close',
      confirm: 'Confirm',
      continue: 'Continue',
      download: 'Download Erbij',
      edit_name: 'edit name',
      edit: 'Edit',
      next: 'Next',
      not_now: 'Not now',
      open_in_app: 'Open link using the Erbij app',
      open: 'Open',
      previous: 'Previous',
      save_changes: 'Save changes',
      save: 'Save',
      sign_up: 'Sign up',
      submit: 'Submit',
      switch_to_app: 'Switch to app',
      to_home: 'Back to home',
      try_again: 'Try again'
    },
    fields: {
      comment: 'Comment',
      email_placeholder: 'becks_boy75@example.com',
      email: 'Email',
      name: 'Name',
      name_placeholder: 'David',
      first_name: 'First name',
      first_name_placeholder: 'David',
      last_name: 'Last name',
      last_name_placeholder: 'Beckham',
      nickname_placeholder: 'Becks',
      nickname: 'Nickname'
    },
    menu: {
      calendar_sync: 'Sync with your calendar app',
      faq: 'FAQ',
      donate: 'Buy us a coffee',
      feedback: 'Feedback',
      about: 'About Erbij',
      privacy: 'Privacy',
      otherLanguage: 'Nederlands'
    },
    header: {
      enter: 'Go to my calendar',
      edit_profile: 'Edit profile',
      log_out: 'Log out',
      log_in: 'Log in',
      menu: 'Menu',
      teams: 'Groups',
      calendar: 'Calendar',
      add_event: 'Add activity'
    },
    datetime_format: {
      long: 'dddd, MMMM Do, YYYY [at] HH:mm',
      medium: 'dd D MMM YYYY, HH:mm',
      short: 'D MMMM YYYY, HH:mm'
    },
    date_format: {
      long: 'dddd D MMMM YYYY',
      medium: 'dd D MMM YYYY',
      short: 'MMMM Do, YYYY',
      tiny: 'MMM YYYY'
    },
    time_format: {
      short: 'HH:mm',
      long: 'HH:mm'
    }
  },
  nl: {
    or: 'of',
    days: 'dagen',
    years: 'jaar',
    for_example: 'Bijv:',
    app_promo: 'Deze pagina kan je het beste in de app bekijken',
    buttons: {
      accept_changes: 'Accepteren',
      back: 'Terug',
      cancel: 'Annuleren',
      close: 'Sluiten',
      confirm: 'Bevestigen',
      continue: 'Verder',
      download: 'Download Erbij',
      edit_name: 'naam wijzigen',
      edit: 'Wijzigen',
      next: 'Volgende',
      not_now: 'Niet nu',
      open_in_app: 'Open link met de Erbij-app',
      open: 'Openen',
      previous: 'Vorige',
      save_changes: 'Wijzigingen opslaan',
      save: 'Opslaan',
      sign_up: 'Aanmelden',
      submit: 'Verzenden',
      switch_to_app: 'Naar app schakelen',
      to_home: 'Naar de homepage',
      try_again: 'Opnieuw proberen'
    },
    fields: {
      comment: 'Opmerking',
      email_placeholder: 'kromme_boy44@voorbeeld.nl',
      email: 'E-mailadres',
      name: 'Naam',
      name_placeholder: 'Willem',
      first_name: 'Voornaam',
      first_name_placeholder: 'Willem',
      last_name_placeholder: 'van Hanegem',
      last_name: 'Achternaam',
      nickname_placeholder: 'De Kromme',
      nickname: 'Bijnaam'
    },
    menu: {
      calendar_sync: 'Synchroniseren met je agenda-app',
      faq: 'Veelgestelde vragen',
      donate: 'Trakteer ons op een bakkie',
      feedback: 'Feedback',
      about: 'Over Erbij',
      privacy: 'Privacy',
      otherLanguage: 'English'
    },
    header: {
      enter: 'Ga naar mijn agenda',
      edit_profile: 'Profiel bewerken',
      log_out: 'Uitloggen',
      log_in: 'Aanmelden',
      menu: 'Menu',
      teams: 'Groepen',
      calendar: 'Agenda',
      add_event: 'Activiteit toevoegen'
    },
    datetime_format: {
      long: 'dddd D MMMM YYYY [om] HH:mm [uur]',
      medium: 'dd D MMM YYYY, HH:mm [uur]',
      short: 'D MMMM YYYY, HH:mm'
    },
    date_format: {
      long: 'dddd D MMMM YYYY',
      medium: 'dd D MMM YYYY',
      short: 'D MMMM YYYY',
      tiny: 'MMM YYYY'
    },
    time_format: {
      short: 'HH:mm',
      long: 'HH:mm [uur]'
    }
  }
}
