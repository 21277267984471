<template>
  <event-wizard-base
    :title="$t('event_wizard.edit_title', { event: event.name })"
    :progress="100"
    :eventId="parseInt($route.params.eventId)"
    @close="onClose"
  />
</template>

<script>
import loadEventMixin from '@/mixins/loadEventMixin'
import EventWizardBase from './views/EventWizardBase'

export default {
  name: 'EventEdit',
  mixins: [loadEventMixin],
  components: {
    EventWizardBase
  },
  created() {
    const {
      name,
      description,
      team_id,
      startDateTime,
      endDateTime,
      presentTime,
      location,
      timeZone
    } = this.event

    const team = team_id ? this.$store.getters.getTeamById(team_id) : null

    this.$store.dispatch('setWizardValues', {
      name,
      description,
      team,
      startDateTime,
      endDateTime,
      presentTime,
      location,
      timeZone
    })

    if (this.$route.name !== 'EditEventWizardOverview') {
      this.$router.replace({ name: 'EditEventWizardOverview' })
    }
  },
  methods: {
    onClose() {
      this.$store.dispatch('resetWizard')
      this.$router.push({ name: 'EventDetails' })
    }
  }
}
</script>
