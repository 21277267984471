<template>
  <wizard :progress="progress" v-on="listeners" :wide="isOnIntro">
    <transition-fade>
      <router-view class="wizard__screen"></router-view>
    </transition-fade>
  </wizard>
</template>

<script>
import TransitionFade from '@/components/TransitionFade'
import Wizard from '@/components/Wizard'

export default {
  name: 'Welcome',
  components: {
    TransitionFade,
    Wizard
  },
  computed: {
    listeners() {
      const listeners = {}

      if (this.isOnIntro) {
        listeners.close = this.closeWizard
      }

      return listeners
    },
    progress() {
      return {
        ActivationSuccess: 25,
        CompleteProfile: 50,
        UploadAvatar: 75,
        Gateway: 100
      }[this.$route.name]
    },
    isOnIntro() {
      return this.$route.name === 'Intro'
    }
  },
  methods: {
    closeWizard() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
