<template>
  <div class="container mb-3 mb-sm-5">
    <div class="row justify-content-center">
      <div class="col-md-10 col-lg-8 col-xl-6">
        <page-header :title="$t('profile.title')" />
        <page-section>
          <page-subsection
            :title="$t('profile.edit_profile.title')"
            header-class="d-none d-sm-block"
          >
            <div class="row flex-md-row-reverse">
              <div class="col-md-5 offset-md-1 mb-4 mb-sm-0">
                <label class="form-label">{{ $t('profile.edit_profile.profile_picture') }}</label>
                <avatar-uploader @uploaded="onAvatarUploaded" />
              </div>
              <div class="col-md-6">
                <b-form @submit.prevent.stop="onEditProfileSubmit">
                  <b-form-group
                    :label="$t('profile.edit_profile.first_name')"
                    label-class="form-label"
                    label-for="inputFirstName"
                  >
                    <b-form-input
                      v-model="editProfile.firstName"
                      :placeholder="$t('general.fields.first_name_placeholder')"
                      id="inputFirstName"
                      :state="
                        $v.editProfile.firstName.$dirty ? !$v.editProfile.firstName.$invalid : null
                      "
                      autocomplete="given-name"
                    />
                    <b-form-invalid-feedback id="inputFirstName">{{
                      $t('errors.validations.required')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    class="mt-4"
                    :label="$t('profile.edit_profile.last_name')"
                    label-class="form-label"
                    label-for="inputLastName"
                  >
                    <b-form-input
                      v-model="editProfile.lastName"
                      :placeholder="$t('general.fields.last_name_placeholder')"
                      id="inputLastName"
                      :state="
                        $v.editProfile.lastName.$dirty ? !$v.editProfile.lastName.$invalid : null
                      "
                      autocomplete="family-name"
                    />
                    <b-form-invalid-feedback id="inputLastName">{{
                      $t('errors.validations.required')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    class="mt-4"
                    :label="$t('profile.edit_profile.nickname')"
                    label-class="form-label"
                    label-for="inputNickname"
                  >
                    <b-form-input
                      v-model="editProfile.nickname"
                      :placeholder="$t('general.fields.nickname_placeholder')"
                      id="inputNickname"
                      :state="
                        $v.editProfile.nickname.$dirty ? !$v.editProfile.nickname.$invalid : null
                      "
                      autocomplete="nickname"
                    />
                    <b-form-invalid-feedback id="inputNickname">{{
                      $t('errors.validations.required')
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                  <div class="alert alert-warning mt-4 mb-0" role="alert" v-if="editProfileError">
                    {{ editProfileError }}
                  </div>
                  <div class="mt-4">
                    <button type="submit" class="btn btn-primary" :disabled="loading">
                      {{ $t('profile.edit_profile.submit') }}
                    </button>
                  </div>
                </b-form>
              </div>
            </div>
          </page-subsection>
          <page-subsection :title="$t('profile.settings')" class="mb-3">
            <label class="form-label">{{ $t('profile.settings_form.language') }}</label>
            <div class="row">
              <div class="col-sm-6">
                <b-form-select :options="languageOptions" v-model="$i18n.locale" />
              </div>
            </div>
          </page-subsection>
        </page-section>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import AvatarUploader from '@/components/AvatarUploader'
import PageHeader from '@/components/PageHeader'
import PageSection from '@/components/PageSection'
import PageSubsection from '@/components/PageSubsection'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  components: {
    AvatarUploader,
    PageHeader,
    PageSection,
    PageSubsection
  },
  data() {
    const profile = this.$store.getters.profile

    return {
      loading: false,
      languageOptions: [
        {
          value: 'nl',
          text: 'Nederlands'
        },
        {
          value: 'en',
          text: 'English'
        }
      ],
      editProfileError: null,
      editProfile: {
        firstName: profile.first_name,
        lastName: profile.last_name,
        nickname: profile.nickname
      }
    }
  },
  validations: {
    editProfile: {
      firstName: { required, maxLength: maxLength(30) },
      lastName: { required, maxLength: maxLength(30) },
      nickname: { maxLength: maxLength(30) }
    }
  },
  methods: {
    onEditProfileSubmit() {
      this.$v.editProfile.$touch()
      this.editProfileError = ''

      if (this.$v.editProfile.$error) return

      this.loading = true

      this.$store
        .dispatch('editProfile', this.editProfile)
        .then(() => {
          this.$store.dispatch('addNotification', {
            title: this.$i18n.t('profile.profile_saved.title'),
            text: this.$i18n.t('profile.profile_saved.text')
          })
        })
        .catch((error) => (this.editProfileError = error.message))
        .finally(() => (this.loading = false))
    },
    onAvatarUploaded() {
      this.$store.dispatch('addNotification', {
        title: this.$i18n.t('profile.avatar_saved.title'),
        text: this.$i18n.t('profile.avatar_saved.text')
      })
    }
  },
  computed: mapGetters({
    profile: 'profile'
  })
}
</script>
