<template>
  <div class="error">
    <fail-gif class="mb-5" size="200" />
    <h4 class="mb-3 error__title">{{ $t('errors.general_title') }}</h4>
    <p>{{ error }}</p>
    <button
      v-if="hasTryAgainListener"
      class="btn btn-primary mt-4"
      type="button"
      @click="$emit('tryAgain')"
    >
      <icon :icon="['fal', 'redo']" class="btn__icon--left" />{{ $t('general.buttons.try_again') }}
    </button>
  </div>
</template>
x
<script>
import FailGif from '@/components/FailGif'
export default {
  name: 'Error',
  components: {
    FailGif
  },
  props: ['error', 'try-again'],
  computed: {
    hasTryAgainListener() {
      return this.$listeners && this.$listeners.tryAgain
    }
  }
}
</script>

<style lang="scss">
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $gray-600;

  &__title {
    font-weight: $font-weight-normal;
  }
}
</style>
