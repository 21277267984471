export default {
  en: {
    general: {
      decision_present: 'Attending',
      decision_not_present: 'Absent',
      decision_maybe: 'Maybe',
      decision_unknown: 'Not responded',
      back_label: 'Go back',
      delete_label: 'Remove',
      from: 'From',
      full: 'Sadly this activity is full 😢',
      to: 'to',
      starting: 'Starting',
      start: 'Start',
      present: 'Meet at {time}',
      end: 'End',
      your_decision: 'Your decision'
    },
    details: {
      edit_label: 'Edit',
      leave_label: 'Leave',
      invite_label: 'Invite',
      decision_title: 'Are you coming?',
      date_title: 'Date',
      details_title: 'Details',
      no_description: 'No description',
      location_title: 'Location',
      get_directions: 'get directions',
      copy_address: 'copy address',
      add_to_calendar: 'add to calendar',
      canceled: 'Sadly this activity has been canceled 😢',
      max: 'Max. {count} spots',
      copy_title: 'Ready to paste!',
      copy_success: 'The address has been copied to your clipboard',
      decision_notification: "We've passed along your attendance",
      decision_notification_other: "We've passed along the attendance of {name}",
      turn_out: {
        title: 'Turn out',
        present: '{count} attending',
        not_present: '{count} not coming',
        maybe: '{count} maybe',
        unknown: "0 haven't decided|1 hasn't decided|{count} haven't decided",
        present_history: '{count} attended',
        not_present_history: "{count} did't attend",
        maybe_history: "0 weren't sure|1 wasn't sure|{count} weren't sure",
        unknown_history: "{count} didn't respond",
        spots_taken: '{count}/{maxAttendees} spots taken'
      }
    },
    overview: {
      title: 'Calendar',
      add_label: 'Add new activity',
      today: 'Today',
      this_week: 'This week',
      is_attending: 'are attending | is attending | are attending',
      has_attended: 'attended',
      max_count_attendees: '{attending}/{max} spots taken',
      no_results: 'There are no upcoming activities in your calendar.',
      no_results_past: 'There are no past activities in your calendar.',
      no_results_team: 'There are no upcoming activities with "{team}" in your calendar.',
      no_results_past_team: 'There are no past activities with "{team}" in your calendar.',
      no_results_cta: 'Why not {add_own}?',
      no_results_add: 'add a new activity',
      get_directions: 'get directions',
      copy_address: 'copy address',
      add_to_calendar: 'add to calendar',
      filter_team_all: 'All groups',
      future_events: 'Upcoming activities',
      past_events: 'Past activities',
      load_more: 'Load more',
      are_you_coming: 'Are you coming?',
      more_details: 'More details'
    },
    invite: {
      title: "Who's in?",
      intro:
        'Using this link, you can invite all your teammates, friends, colleagues and family to your activity.',
      warning:
        'Heads up! Be careful when handing out this link. Everyone can use it to RSVP to your activity.',
      share: {
        title: 'Invite people to {event}',
        text: 'I would like to invite you to {event} on {date}. Are you coming?\n\nYou can RSVP using the following link:\n{url}\n\nThis link expires on {expires}',
        subject: 'You are invited to {event}',
        email: 'Email',
        whatsapp: 'WhatsApp',
        copy: 'Copy link',
        other: 'Other'
      },
      copy_success: 'Copied!'
    },
    invitation: {
      title: 'Invitation',
      text: '{name} has invited you to',
      code_error: {
        title: 'Oh no!',
        text: 'Invalid invitation code. Ask the person that invited you to send you a new invitation-link.'
      }
    },
    invitation_success: {
      title: 'Invitation',
      lead: 'Confirm your email',
      result: "Almost there. We've sent an email to {email} with a confirmation link.",
      heads_up: 'Heads up!',
      instructions:
        'You will need to click on the link inside that email in order to confirm your decision.'
    },
    confirm_invitation: {
      title: "Alright, we've passed it on",
      title_full: 'Sorry, this activity is full',
      result_present: "{inviter} and the other attendees will love the fact that you'll be coming!",
      result_maybe: '{inviter} and the other attendees hope that you will be able to make it.',
      result_not_present:
        "{inviter} and the other attendees will be disappointed to hear that you won't be coming.",
      result_full:
        'We were therefor unable to sign you up. You can try again later. Perhaps someone has dropped out by then.',
      sign_up: {
        lead: "Easily change your decision, view the event's details or organize something yourself?",
        cta: 'Sign up for free'
      }
    },
    add_to_calendar: {
      title: 'Add to calendar',
      single_event: 'Just add this one',
      multiple_events: 'Sync all events'
    },
    delete: {
      title: 'Are you sure?',
      text: 'Removing this activity cannot be undone.',
      also_remove_future: 'Also remove future occurrenses',
      notify_invitees: 'Notify invitees',
      confirm_single: 'Get rid of it!',
      confirm_multiple_just_this_one: 'Delete just this one',
      confirm_multiple_plus_future: 'Delete this and future recurrences',
      delete_success: {
        single: {
          title: 'Activity deleted',
          text: '"{name}" has been removed from everyone\'s calendar.'
        },
        multiple: {
          title: 'Activities deleted',
          text: '"{name}" and all future recurrences have been removed from everyone\'s calendar.'
        }
      },
      cancel_success: {
        single: {
          title: 'Maybe next time 🤷',
          text: "We've let them know that the activity has been canceled."
        },
        multiple: {
          title: 'Maybe next time 🤷',
          text: "We've let them know that the activities have been canceled."
        }
      }
    },
    leave: {
      title: 'Leave activity',
      text_single: 'Are you sure you want to leave this activity?',
      text_multiple:
        'This is a repeated activity. Do you also want to leave all recurrences after this?',
      confirm_single: 'Get rid of it!',
      confirm_multiple_just_this_one: 'Leave just this one',
      confirm_multiple_plus_future: 'All future recurrences too',
      success: {
        single: {
          title: 'Toodeloo! 👋',
          text: '"{name}" has been removed from your calendar.'
        },
        multiple: {
          title: 'Toodeloo! 👋',
          text: '"{name}" and all future recurrences have been removed from your calendar.'
        }
      }
    },
    choose_push_type: {
      title: 'Send notifications',
      text: 'Who would you like to notify about these changes?',
      allInvited: 'Everyone that was invited',
      allGoing: 'Just the people that might be going',
      none: 'No one, this is between you and me',
      save: 'Save',
      saveAndSend: 'Save & send'
    }
  },
  nl: {
    general: {
      decision_present: 'Erbij',
      decision_not_present: 'Afwezig',
      decision_maybe: 'Misschien',
      decision_unknown: 'Niet gereageerd',
      back_label: 'Ga terug',
      delete_label: 'Verwijderen',
      from: 'Van',
      full: 'Helaas, deze activiteit zit vol 😢',
      to: 'tot',
      starting: 'vanaf',
      start: 'Start',
      present: 'Aanwezig om {time} uur',
      end: 'Einde',
      your_decision: 'Jouw keuze'
    },
    details: {
      edit_label: 'Wijzigen',
      leave_label: 'Verlaten',
      invite_label: 'Uitnodigen',
      decision_title: 'Ben je Erbij?',
      date_title: 'Datum',
      details_title: 'Details',
      no_description: 'Geen omschrijving',
      location_title: 'Locatie',
      get_directions: 'routebeschrijving',
      copy_address: 'kopieer adres',
      add_to_calendar: 'zet in agenda',
      canceled: 'Helaas, deze activiteit gaat niet door 😢',
      max: 'Max. {count} plekken',
      copy_title: 'Plakken maar!',
      copy_success: 'Het adres is naar je klembord gekopieerd',
      decision_notification: 'Je aanwezigheid is doorgegeven',
      decision_notification_other: 'De aanwezigheid van {name} is doorgegeven',
      turn_out: {
        title: 'Opkomst',
        present: '{count} Erbij',
        not_present: '{count} afwezig',
        maybe: '{count} misschien',
        unknown: '{count} niet gereageerd',
        present_history: '0 waren Erbij | 1 was Erbij | {count} waren Erbij',
        not_present_history: '0 waren afwezig | 1 was afwezig | {count} waren afwezig',
        maybe_history:
          '0 wisten het niet zeker | 1 wist het niet zeker | {count} wisten het niet zeker',
        unknown_history:
          '0 hebben niet gereageerd | 1 heeft niet gereageerd | {count} hebben niet gereageerd',
        spots_taken: '{count}/{maxAttendees} plekken bezet'
      }
    },
    overview: {
      title: 'Agenda',
      add_label: 'Activiteit toevoegen',
      today: 'Vandaag',
      this_week: 'Deze week',
      is_attending: 'zijn Erbij | is Erbij | zijn Erbij',
      has_attended: 'waren Erbij | was Erbij | waren Erbij',
      max_count_attendees: '{attending}/{max} plekken bezet',
      no_results: 'Er staan geen aankomende activiteiten in je agenda.',
      no_results_past: 'Er staan geen afgelopen activiteiten in je agenda.',
      no_results_team: 'Er staan geen aankomende activiteiten met "{team}" in je agenda.',
      no_results_past_team: 'Er staan geen afgelopen activiteiten met "{team}" in je agenda.',
      no_results_cta: 'Waarom voeg je geen {add_own} toe?',
      no_results_add: 'nieuwe activiteit',
      filter_team_all: 'Alle groepen',
      future_events: 'Binnenkort',
      past_events: 'In het verleden',
      load_more: 'Meer laden',
      are_you_coming: 'Ben je Erbij?',
      more_details: 'Meer details'
    },
    invite: {
      title: 'Wie mag Erbij?',
      intro:
        'Met deze unieke link kan je al je sportmaatjes, vrienden, collega’s en familieleden uitnodigen voor je activiteit.',
      warning:
        'Let op! Iedereen die deze link in handen krijgt kan zich aan- of afmelden voor je activiteit.',
      share: {
        title: 'Uitnodigen voor {event}',
        text: 'Bij deze ben je uitgenodigd voor {event} op {date}. Ben je Erbij?\n\nJe kunt je aan- of afmelden via de volgende link:\n{url}\n\nDeze link is geldig tot {expires}',
        subject: 'Uitnodiging voor {event}',
        email: 'Email',
        whatsapp: 'WhatsApp',
        copy: 'Copy link',
        other: 'Other'
      },
      copy_success: 'Gekopieerd!'
    },
    invitation: {
      title: 'Uitnodiging',
      text: '{name} heeft je uitgenodigd voor',
      code_error: {
        title: 'Oh nee!',
        text: 'Ongeldige uitnodigingscode. Vraag de persoon die je heeft uitgenodigd om je een nieuwe uitnodiginslink te sturen.'
      }
    },
    invitation_success: {
      title: 'Uitnodiging',
      lead: 'Bevestig je e-mailadres',
      result:
        'Je bent Erbij-na. We hebben een e-mail verstuurd naar {email} met daarin een bevestigingslink.',
      heads_up: 'Let op!',
      instructions: 'Pas wanneer je op de link in de e-mail klikt wordt je besluit doorgegeven.'
    },
    confirm_invitation: {
      title: 'Oké, we hebben het doorgeven',
      title_full: 'Helaas, de activiteit zit vol',
      result_present:
        '{inviter} en de andere aanwezigen zullen het geweldig vinden dat je Erbij bent!',
      result_maybe: '{inviter} en de andere aanwezigen hopen dat je Erbij zult zijn!',
      result_not_present:
        '{inviter} en de andere aanwezigen zullen het jammer vinden dat je er niet bij kunt zijn.',
      result_full:
        'We hebben je daarom helaas niet kunnen aanmelden. Je kunt het later nog eens proberen in de hoop dat iemand zich tegen die tijd heeft afgemeld.',
      sign_up: {
        lead: 'Je beslissing gemakkelijk wijzigen, de details van de activiteit inzien of zelf iets organiseren?',
        cta: 'Meld je gratis aan'
      }
    },
    add_to_calendar: {
      title: 'Zet in agenda',
      single_event: 'Alleen deze toevoegen',
      multiple_events: 'Synchroniseer alle activiteiten'
    },
    delete: {
      title: 'Weet je het zeker?',
      text: 'Het verwijderen van deze activiteit kan niet ongedaan gemaakt worden.',
      also_remove_future: 'Ook toekomstige herhalingen verwijderen',
      notify_invitees: 'Breng genodigden op de hoogte',
      confirm_single: 'Weg ermee!',
      confirm_multiple_just_this_one: 'Verwijder alleen deze',
      confirm_multiple_plus_future: 'Verwijder deze en alle herhalingen hierna',
      delete_success: {
        single: {
          title: 'Activiteit verwijderd',
          text: '"{name}" is uit alle agenda\'s verwijderd.'
        },
        multiple: {
          title: 'Activiteiten verwijderd',
          text: '"{name}" en alle toekomstige herhalingen zijn uit alle agenda\'s verwijderd.'
        }
      },
      cancel_success: {
        single: {
          title: 'Ander keertje? 🤷',
          text: '"{name}" is afgelast.'
        },
        multiple: {
          title: 'Ander keertje? 🤷',
          text: '"{name}" en alle toekomstige herhalingen zijn afgelast.'
        }
      }
    },
    leave: {
      title: 'Activiteit verlaten',
      text_single: 'Weet je zeker dat je deze activiteit wil verlaten?',
      text_multiple:
        'Dit is een herhaalde activiteit. Wil je ook meteen alle herhalingen hierna verlaten?',
      confirm_single: 'Weg ermee!',
      confirm_multiple_just_this_one: 'Verlaat alleen deze',
      confirm_multiple_plus_future: 'Ook alle herhalingen hierna',
      success: {
        single: {
          title: 'Toedeledokie! 👋',
          text: '"{name}" is uit je agenda verwijderd.'
        },
        multiple: {
          title: 'Toedeledokie! 👋',
          text: '"{name}" en alle toekomstige herhalingen zijn uit je agenda verwijderd.'
        }
      }
    },
    choose_push_type: {
      title: 'Melding versturen',
      text: 'Wie wil je op de hoogte brengen van deze wijzigingen?',
      allInvited: 'Iedereen die uitgenodigd is',
      allGoing: 'Alleen de mensen die (misschien) Erbij zijn',
      none: 'Niemand, dit blijft tussen ons',
      save: 'Opslaan',
      saveAndSend: 'Opslaan & verstuur'
    }
  }
}
