<template>
  <div class="container container--xs mb-3 mb-sm-5">
    <page-header :title="$t('events.overview.title')" :actions="actions">
      <template slot="meta">
        <select v-if="teams" v-model="selectedTeamFilter" class="naked-select">
          <option :value="null">{{ $t('events.overview.filter_team_all') }}</option>
          <option v-for="team in teams" v-bind:key="team.id" :value="team.id">
            {{ team.name }}
          </option>
        </select>
        <select v-model="selectedTimelineFilter" class="naked-select">
          <option :value="null">{{ $t('events.overview.future_events') }}</option>
          <option :value="TIMELINE_HISTORY">{{ $t('events.overview.past_events') }}</option>
        </select>
      </template>
    </page-header>
    <error :error="error" @tryAgain="loadEvents" v-if="error" />
    <div v-else>
      <event-list :events="events" v-if="events.length" />
      <div v-else-if="!isLoading">
        <p class="event-overview__empty-text lead my-5 my-sm-0">
          {{ noResultsText }}
          <i18n path="events.overview.no_results_cta" class="d-sm-block">
            <template v-slot:add_own>
              <router-link :to="{ name: 'EventAdd' }">{{
                $t('events.overview.no_results_add')
              }}</router-link>
            </template>
          </i18n>
        </p>
      </div>
      <div class="text-center event-overview__loading-container" v-if="moreAvailable">
        <spinner class="event-overview__spinner" v-if="isLoading" />
        <button
          class="btn btn-outline-gray-dark"
          @click="loadEvents"
          :disabled="isLoading"
          v-else-if="events.length <= EVENTS_PAGE_SIZE"
        >
          {{ $t('events.overview.load_more') }}
        </button>
        <scroll-spy @reach="loadEvents" :threshold="-300" v-else />
      </div>
    </div>
    <router-link
      v-if="$breakpoint.xsOnly"
      tag="button"
      :to="{ name: 'EventAdd' }"
      class="action-button event-overview__add-event"
      :aria-label="$t('events.overview.add_label')"
      :title="$t('events.overview.add_label')"
      ><icon icon="plus" fixed-width
    /></router-link>
    <router-view></router-view>
  </div>
</template>
<script>
import Error from '@/components/Error'
import EventList from '@/components/EventList'
import PageHeader from '@/components/PageHeader'
import ScrollSpy from '@/components/ScrollSpy'
import Spinner from '@/components/Spinner'
import handleInvitationMixin from '@/mixins/handleInvitationMixin'
import { EVENTS_PAGE_SIZE, TIMELINE_HISTORY } from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'EventOverview',
  mixins: [handleInvitationMixin],
  data() {
    const { teamFilter, showHistory } = this.$store.state.events

    return {
      selectedTeamFilter: teamFilter,
      selectedTimelineFilter: showHistory ? TIMELINE_HISTORY : null,
      TIMELINE_HISTORY,
      EVENTS_PAGE_SIZE
    }
  },
  components: {
    Error,
    Spinner,
    ScrollSpy,
    PageHeader,
    EventList
  },
  computed: {
    ...mapGetters({
      events: 'events',
      eventsOverviewError: 'eventsOverviewError',
      isLoading: 'isEventsLoading',
      teams: 'teams'
    }),
    noResultsText() {
      const { selectedTeamFilter, selectedTimelineFilter } = this
      if (selectedTeamFilter) {
        const team = this.$store.getters.getTeamById(selectedTeamFilter).name

        if (selectedTimelineFilter === TIMELINE_HISTORY) {
          return this.$i18n.t('events.overview.no_results_past_team', { team })
        } else {
          return this.$i18n.t('events.overview.no_results_team', { team })
        }
      } else {
        if (selectedTimelineFilter === TIMELINE_HISTORY) {
          return this.$i18n.t('events.overview.no_results_past')
        } else {
          return this.$i18n.t('events.overview.no_results')
        }
      }
    },
    isOnDefaultFilters() {
      return this.selectedTeamFilter === null && this.selectedTimelineFilter === null
    },
    moreAvailable() {
      return this.$store.getters.moreEventsAvailable
    },
    actions() {
      if (this.$breakpoint.xsOnly) return []

      return [
        {
          icon: 'plus',
          to: { name: 'EventAdd' },
          label: this.$i18n.t('events.overview.add_label')
        }
      ]
    }
  },
  watch: {
    eventsOverviewError(error) {
      this.error = error
    },
    selectedTeamFilter(teamId) {
      this.$store.dispatch('setTeamFilter', { teamId })
    },
    selectedTimelineFilter(timelineFilter) {
      this.$store.dispatch('setShowHistory', {
        showHistory: timelineFilter === TIMELINE_HISTORY
      })
    }
  },
  methods: {
    loadEvents() {
      const offset = Array.isArray(this.events) ? this.events.length : 0
      return this.$store.dispatch('loadEvents', { offset })
    }
  }
}
</script>

<style lang="scss">
.event-overview {
  &__add-event {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 3;
  }

  &__loading-container {
    height: 30px;
  }

  &__spinner {
    font-size: 30px;
    color: $gray-500;
  }

  &__empty-text {
    font-size: $font-size-lg;
    color: $gray-600;
  }
}
</style>
