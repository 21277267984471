<template>
  <form
    class="wizard__screen wizard__screen--has-actions wizard__when"
    @submit.prevent="onSubmit"
    action="#"
  >
    <h2 class="h4 wizard__subtitle">{{ $t('event_wizard.when.title') }}</h2>
    <div class="wizard__section">
      <div>
        <label class="form-label" for="inputStartDate">{{ $t('event_wizard.when.start') }}</label>
        <div class="form-row align-items-center">
          <div class="col-10 col-sm-auto mb-2 mb-sm-0">
            <datepicker
              id="inputStartDate"
              v-model="startDate"
              :invalid="$v.startDate.$error"
              :min="now"
              :disabled="this.isDatePickerDisabled"
              :title="datePickerTitle"
            />
          </div>
          <div class="col-2 col-sm-auto mb-2 mb-sm-0">
            <span class="text-muted">{{ $t('event_wizard.when.at') }}</span>
          </div>
          <div class="col-10 col-sm-auto">
            <label class="sr-only" for="inputStartTime">{{ $t('event_wizard.when.time') }}</label>
            <time-input
              id="inputStartTime"
              v-model="startTime"
              placeholder="12:00"
              :invalid="$v.startTime.$error"
            />
          </div>
          <div class="col-2 col-sm-auto">
            <span class="text-muted">{{ $t('event_wizard.when.hrs') }}</span>
          </div>
        </div>
        <div class="invalid-feedback d-block" v-if="$v.$dirty && !$v.startTime.validTime">
          {{ $t('errors.validations.time') }}
        </div>
        <div class="invalid-feedback d-block" v-else-if="$v.$dirty && !$v.startDate.minValue">
          {{ $t('errors.validations.date_future') }}
        </div>
      </div>
      <div class="mt-4">
        <label class="form-label">{{ $t('event_wizard.when.end') }}</label>
        <div class="form-row align-items-center">
          <div class="col-auto">
            <input
              type="radio"
              class="sr-only"
              v-model="endDateTimeEnabled"
              value=""
              id="disableEndDateTime"
            />
            <label
              for="disableEndDateTime"
              class="btn"
              :class="endDateTimeEnabled ? 'btn-outline' : 'btn-primary'"
              >{{ $t('event_wizard.when.no') }}</label
            >
          </div>
          <div class="col-auto">
            <input
              type="radio"
              class="sr-only"
              v-model="endDateTimeEnabled"
              value="true"
              id="enableEndDateTime"
            />
            <label
              for="enableEndDateTime"
              class="btn"
              :class="endDateTimeEnabled ? 'btn-primary' : 'btn-outline'"
              >{{ $t('event_wizard.when.yes') }}</label
            >
          </div>
        </div>
        <div v-if="endDateTimeEnabled">
          <div class="form-row align-items-center mt-3">
            <div class="col-10 col-sm-auto mb-2 mb-sm-0">
              <datepicker
                id="inputEndDate"
                v-model="endDate"
                :invalid="$v.endDate.$error"
                :min="startDate"
                :title="datePickerTitle"
                :disabled="isDatePickerDisabled"
              />
            </div>
            <div class="col-2 col-sm-auto mb-2 mb-sm-0">
              <span class="text-muted">{{ $t('event_wizard.when.at') }}</span>
            </div>
            <div class="col-10 col-sm-auto">
              <label class="sr-only" for="inputStartTime">{{ $t('event_wizard.when.time') }}</label>
              <time-input
                id="inputEndTime"
                v-model="endTime"
                placeholder="13:00"
                :invalid="$v.endTime.$error"
              />
            </div>
            <div class="col-2 col-sm-auto">
              <span class="text-muted">{{ $t('event_wizard.when.hrs') }}</span>
            </div>
          </div>
          <div class="invalid-feedback d-block" v-if="$v.$dirty && !$v.endTime.validTime">
            {{ $t('errors.validations.time') }}
          </div>
          <div class="invalid-feedback d-block" v-if="$v.$dirty && !$v.endDate.minValue">
            {{ $t('errors.validations.end_date_after_start_date') }}
          </div>
        </div>
      </div>
      <div class="mt-4">
        <label class="form-label">{{ $t('event_wizard.when.enable_present_time') }}</label>
        <div class="form-row align-items-center">
          <div class="col-auto">
            <input
              type="radio"
              class="sr-only"
              v-model="presentTimeEnabled"
              value=""
              id="disablePresentTime"
            />
            <label
              for="disablePresentTime"
              class="btn"
              :class="presentTimeEnabled ? 'btn-outline' : 'btn-primary'"
              >{{ $t('event_wizard.when.no') }}</label
            >
          </div>
          <div class="col-auto">
            <input
              type="radio"
              class="sr-only"
              v-model="presentTimeEnabled"
              value="true"
              id="enablePresentTime"
            />
            <label
              for="enablePresentTime"
              class="btn"
              :class="presentTimeEnabled ? 'btn-primary' : 'btn-outline'"
              >{{ $t('event_wizard.when.yes') }}</label
            >
          </div>
          <template v-if="presentTimeEnabled">
            <div class="col-auto">
              <span class="text-muted">{{ $t('event_wizard.when.at') }}</span>
            </div>
            <div class="col-10 col-sm-auto mt-2 mt-sm-0">
              <label class="sr-only" for="inputPresentTime">{{
                $t('event_wizard.when.end')
              }}</label>
              <time-input
                id="inputPresentTime"
                v-model="presentTime"
                placeholder="11:45"
                :invalid="$v.presentTime.$error"
              />
              <div class="invalid-feedback">
                {{ $t('errors.validations.time') }}
              </div>
            </div>
            <div class="col-2 col-sm-auto mt-2 mt-sm-0">
              <span class="text-muted">{{ $t('event_wizard.when.hrs') }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-4" v-if="!eventId">
        <label
          v-text="$t('event_wizard.when.repetition')"
          for="selectRepetition"
          class="form-label"
        />
        <div class="form-row align-items-center">
          <div class="col-12 col-sm-4">
            <select
              v-model="repetition"
              class="custom-select"
              id="selectRepetition"
              :options="repetitionOptions"
              :disabled="!team"
            >
              <option
                v-for="option in repetitionOptions"
                :value="option.value"
                v-text="option.text"
                :key="option.value"
              />
            </select>
          </div>
          <div class="col-12 col-sm-auto" v-if="repetition !== REPETITION_ONCE">
            <div class="form-row align-items-center">
              <div class="col-2 col-sm-auto mt-2 mt-sm-0">
                <span class="text-muted">{{ $t('event_wizard.when.repetition_until') }}</span>
              </div>
              <div class="col-10 col-sm-auto mt-2 mt-sm-0">
                <datepicker
                  id="inputRepetitionEndDate"
                  v-model="repetitionEndDate"
                  :class="{
                    'is-invalid': $v.repetitionEndDate.$dirty
                      ? $v.repetitionEndDate.$invalid
                      : false
                  }"
                  :min="minRepEndDate"
                  :max="maxRepEndDate"
                />
                <template v-if="$v.repetitionEndDate.$dirty">
                  <div class="invalid-feedback d-block" v-if="!$v.repetitionEndDate.required">
                    {{ $t('errors.validations.required') }}
                  </div>
                  <div class="invalid-feedback d-block" v-else-if="!$v.repetitionEndDate.validDate">
                    {{ $t('errors.validations.date') }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-8 mt-3 mt-sm-0" v-if="!team">
            <p class="m-0 text-muted">
              {{ $t('event_wizard.when.repetition_no_team') }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <label
          v-text="$t('event_wizard.when.time_zone')"
          class="form-label cursor-pointer"
          @click.prevent="showTimeZoneModal = true"
        />
        <p
          v-if="hasDifferentTimeZone"
          class="text-muted cursor-pointer"
          @click.prevent="showTimeZoneModal = true"
        >
          {{ timeZoneText }}
        </p>
        <time-zone-modal
          v-if="showTimeZoneModal"
          @close="showTimeZoneModal = false"
          @confirm="updateTimeZone"
          :value="timeZone.value"
        />
      </div>
    </div>
    <div class="wizard__footer">
      <div class="wizard__actions" v-if="eventId">
        <button class="btn btn-responsive btn-outline" type="button" @click="onCancelPress">
          <icon class="btn__icon--left" :icon="['fal', 'times']" />{{
            $t('general.buttons.cancel')
          }}
        </button>
        <button class="btn btn-responsive btn-primary" tabindex="4" type="submit">
          {{ $t('general.buttons.accept_changes')
          }}<icon :icon="['fal', 'check']" class="btn__icon--right" />
        </button>
      </div>
      <div class="wizard__actions" v-else-if="$route.params.fromOverview">
        <button class="btn btn-responsive btn-outline" type="button" @click="onCancelPress">
          <icon class="btn__icon--left" :icon="['fal', 'times']" />{{
            $t('general.buttons.cancel')
          }}
        </button>
        <button class="btn btn-responsive btn-secondary" tabindex="4" type="submit">
          {{ $t('general.buttons.continue')
          }}<icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
      <div class="wizard__actions" v-else>
        <button class="btn btn-outline" type="button" @click="onPrevPress" tabindex="-1">
          <icon class="btn__icon--left" :icon="['fal', 'arrow-left']" />
          {{ $t('general.buttons.back') }}
        </button>
        <button class="btn btn-secondary" type="submit">
          {{ $t('general.buttons.next') }}
          <icon :icon="['fal', 'arrow-right']" class="btn__icon--right" />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Datepicker from '@/components/Datepicker'
import TimeInput from '@/components/TimeInput'
import TimeZoneModal from '@/components/TimeZoneModal'
import {
  DATE_INPUT_FORMAT,
  REPETITION_BIWEEKLY,
  REPETITION_ONCE,
  REPETITION_WEEKLY
} from '@/utils/constants'
import { getNextHalfHourDate } from '@/utils/date'
import { hhmm, yyyymmdd } from '@/utils/regex'
import { getTimeZoneLabel } from '@/utils/timeZones'
import dayjs from 'dayjs'
import { helpers, required, requiredIf, requiredUnless } from 'vuelidate/lib/validators'

const validDate = helpers.regex('date', yyyymmdd)
const validTime = helpers.regex('time', hhmm)

export default {
  name: 'EventWizardWhen',
  props: {
    eventId: {
      type: Number,
      required: false
    }
  },
  components: {
    TimeZoneModal,
    Datepicker,
    TimeInput
  },
  data() {
    const nextHalfHour = getNextHalfHourDate()
    const wizardValues = this.$store.getters.wizardValues
    const { startDateTime, endDateTime, repetitionEndDate, timeZone, team } = wizardValues
    const today = dayjs()
    const startDate = startDateTime || today

    const startTime = startDateTime ? startDateTime.format('HH:mm') : nextHalfHour.format('HH:mm')

    const isRepeatable = this.eventId
      ? this.$store.getters.getEventById(this.eventId).isRepeatable
      : false

    const repetitionOptionOnce = {
      value: REPETITION_ONCE,
      text: this.$i18n.t('event_wizard.when.repetition_once')
    }

    const repetitionOptionWeekly = {
      value: REPETITION_WEEKLY,
      text: this.$i18n.t('event_wizard.when.repetition_weekly')
    }

    const repetitionOptionBiweekly = {
      value: REPETITION_BIWEEKLY,
      text: this.$i18n.t('event_wizard.when.repetition_biweekly')
    }

    const allOptions = [repetitionOptionOnce, repetitionOptionWeekly, repetitionOptionBiweekly]

    const data = {
      REPETITION_ONCE,
      team,
      showTimeZoneModal: false,
      now: dayjs().format(DATE_INPUT_FORMAT),
      startDate: startDate.format(DATE_INPUT_FORMAT),
      startTime,
      endDateTimeEnabled: false,
      timeZone: timeZone || this.$store.getters.userTimeZone,
      presentTimeEnabled: !!wizardValues.presentTime,
      presentTime: wizardValues.presentTime || '11:45',
      repetition: wizardValues.repetition || REPETITION_ONCE,
      repetitionEndDate: repetitionEndDate ? repetitionEndDate.format(DATE_INPUT_FORMAT) : '',
      repetitionOptions: team ? allOptions : [repetitionOptionOnce],
      isRepeatable
    }

    if (endDateTime) {
      Object.assign(data, {
        endDateTimeEnabled: true,
        endDate: endDateTime.format(DATE_INPUT_FORMAT),
        endTime: endDateTime.format('HH:mm')
      })
    }

    return data
  },
  computed: {
    timeZoneText() {
      return getTimeZoneLabel(this.timeZone)
    },
    minRepEndDate() {
      const weeksToAdd = this.repetition === REPETITION_BIWEEKLY ? 2 : 1
      return dayjs(this.startDate).add(weeksToAdd, 'weeks').format(DATE_INPUT_FORMAT)
    },
    maxRepEndDate() {
      return dayjs(this.startDate).add(1, 'year').format(DATE_INPUT_FORMAT)
    },
    hasDifferentTimeZone() {
      return this.timeZone.value !== this.$store.getters.userTimeZone.value
    },
    isDatePickerDisabled() {
      // We disallow changing dates for repeatable events (for now)
      return this.isRepeatable
    },
    datePickerTitle() {
      return this.isDatePickerDisabled
        ? this.$i18n.t('event_wizard.when.disable_date_picker_title')
        : ''
    }
  },
  mounted() {
    document.getElementById('inputStartDate').focus()
  },
  watch: {
    timeZoneOptions(options) {
      this.timeZone = options[0]
    },
    presentTimeEnabled(isEnabled) {
      if (isEnabled) {
        const justBeforeStart = dayjs(this.startTime, 'HH:mm')
          .subtract(15, 'minutes')
          .format('HH:mm')

        this.presentTime = justBeforeStart
      }
    },
    endDateTimeEnabled(isEnabled) {
      if (isEnabled) {
        const twoHoursAfterStart = dayjs(
          `${this.startDate}T${this.startTime}`,
          'YYYY-MM-DDTHH:mm'
        ).add(2, 'hours')

        this.endDate = twoHoursAfterStart.format('YYYY-MM-DD')
        this.endTime = twoHoursAfterStart.format('HH:mm')
      }
    },
    repetition(newRepetition, oldRepetition) {
      if (newRepetition === REPETITION_ONCE) {
        this.repetitionEndDate = undefined
      }

      if (oldRepetition === REPETITION_ONCE) {
        this.repetitionEndDate = this.minRepEndDate
      }
    }
  },
  validations: {
    startDate: {
      required,
      validDate,
      minValue: function (startDate) {
        return dayjs(`${startDate}T${this.startTime}`, 'YYYY-MM-DDTHH:mm').isAfter(dayjs())
      }
    },
    startTime: {
      required,
      validTime,
      minValue: function (startTime) {
        return dayjs(`${this.startDate}T${startTime}`, 'YYYY-MM-DDTHH:mm').isAfter(dayjs())
      }
    },
    endDate: {
      required: requiredIf('endDateTimeEnabled'),
      validDate,
      minValue: function (endDate) {
        return `${endDate}T${this.endTime}` > `${this.startDate}T${this.startTime}`
      }
    },
    endTime: {
      required: requiredIf('endDateTimeEnabled'),
      validTime,
      minValue: function (endTime) {
        return `${this.endDate}T${endTime}` > `${this.startDate}T${this.startTime}`
      }
    },
    presentTime: { required: requiredIf('presentTimeEnabled'), validTime },
    repetitionEndDate: {
      required: requiredUnless(function () {
        return this.repetition === REPETITION_ONCE
      }),
      validDate
    }
  },
  methods: {
    updateTimeZone(timeZone) {
      this.showTimeZoneModal = false
      this.timeZone = timeZone
    },
    onPrevPress() {
      this.$router.push({ name: 'AddEventWizardWhat' })
    },
    onCancelPress() {
      if (this.eventId) {
        this.$router.push({ name: 'EditEventWizardOverview' })
      } else {
        this.$router.push({ name: 'AddEventWizardSummary' })
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$error) return

      const {
        startDate,
        startTime,
        endDateTimeEnabled,
        endDate,
        endTime,
        presentTimeEnabled,
        presentTime,
        repetition,
        repetitionEndDate,
        timeZone
      } = this

      const newValues = {
        timeZone,
        startDateTime: dayjs(`${startDate}T${startTime}`),
        endDateTime: endDateTimeEnabled ? dayjs(`${endDate}T${endTime}`) : null,
        presentTime: presentTimeEnabled && presentTime ? presentTime : null
      }

      if (!this.eventId) {
        Object.assign(newValues, {
          repetition,
          repetitionEndDate: repetitionEndDate ? dayjs(repetitionEndDate) : null
        })
      }

      this.$store.dispatch('setWizardValues', newValues).then(() => {
        const nextRoute = this.eventId
          ? 'EditEventWizardOverview'
          : this.$route.params.fromOverview
          ? 'AddEventWizardSummary'
          : 'AddEventWizardWhere'

        this.$router.push({
          name: nextRoute
        })
      })
    }
  }
}
</script>
