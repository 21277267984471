import { getDefaultState } from './default'

export const SET_WIZARD_VALUES = 'SET_WIZARD_VALUES'
export const WIZARDS_RESET = 'WIZARDS_RESET'

export default {
  [WIZARDS_RESET]: (state) => {
    Object.assign(state, getDefaultState())
  },
  [SET_WIZARD_VALUES]: (state, values) => {
    state.event = {
      ...state.event,
      ...values
    }
  }
}
