export const SET_OPTIONS = 'SET_OPTIONS'

export default {
  [SET_OPTIONS]: (state, values) => {
    state.options = {
      ...state.options,
      ...values
    }
  }
}
