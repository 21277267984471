<template>
  <div class="user-avatar-row">
    <user-avatar
      class="user-avatar-row__item"
      v-for="user in limitedUsers"
      :key="user.id"
      :user="user"
      :size="$breakpoint.xs ? avatarSize : avatarSizeLg"
    ></user-avatar>
    <span class="user-avatar-row__rest" v-if="othersCount"
      >+ {{ othersCount }} {{ $tc('teams.overview.others', othersCount) }}</span
    >
  </div>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'UserAvatarRow',
  props: {
    users: {
      type: Array,
      required: true
    },
    avatarSize: {
      type: Number,
      default: 50
    },
    avatarSizeLg: {
      type: Number,
      default: 70
    },
    maxAvatars: {
      type: Number,
      default: 5
    }
  },
  computed: {
    limitedUsers() {
      return this.users.slice(0, this.maxAvatars)
    },
    othersCount() {
      if (this.users.length > this.maxAvatars) {
        return this.users.length - this.maxAvatars
      }
      return 0
    }
  },
  components: {
    UserAvatar
  }
}
</script>

<style lang="scss">
.user-avatar-row {
  display: flex;
  align-items: center;

  &__item + &__item {
    margin-left: -1.2rem;
  }

  &__rest {
    margin-left: 1rem;
    color: $gray-500;
  }
}
</style>
