<template>
  <modal @close="$router.push({ name: 'Donate' })" size="sm" class="text-center">
    <img
      src="@/assets/gifs/donation/success.gif"
      class="rounded-circle mb-5 mt-3 mx-auto"
      height="200"
      width="200"
    />
    <h1 class="h4">{{ $t('about.donation_success.title') }}</h1>
    <p>{{ $t('about.donation_success.text') }}</p>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'DonationSuccess',
  components: {
    Modal
  }
}
</script>
