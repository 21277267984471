<template>
  <header class="header" :class="modeClass">
    <div class="header__inner container">
      <div class="header__logo">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/logo.svg" width="70" height="40" v-if="type === 'inverted'" />
          <img src="@/assets/logo-inverted.svg" width="70" height="40" v-else />
        </router-link>
      </div>
      <template v-if="!isAuthLoading">
        <template v-if="isAuthenticated">
          <div class="header__links">
            <router-link
              :to="{ name: 'Home' }"
              class="header__link"
              :class="{ 'header__link--active': isCalendar }"
            >
              <icon :icon="['fal', 'calendar-alt']" class="header__link-icon" />
              <span>{{ $t('general.header.calendar') }}</span>
            </router-link>
            <router-link
              :to="{ name: 'TeamOverview' }"
              class="header__link"
              :class="{ 'header__link--active': isTeams }"
              data-cy="menu-item-teams"
            >
              <icon :icon="['fal', 'users']" class="header__link-icon" />
              <span>{{ $t('general.header.teams') }}</span>
            </router-link>
            <a href="javascript:void(0)" @click.stop.prevent="isSubMenuVisible = !isSubMenuVisible">
              <user-avatar :user="profile" :size="40" class="header__avatar ml-4" />
            </a>
          </div>
        </template>
        <div class="header__links" v-else>
          <router-link :to="{ name: 'Login' }" class="header__link header__link--emphasize">
            <span>{{ $t('general.header.log_in') }}</span>
          </router-link>
          <span class="header__seperator ml-4"></span>
          <a
            href="javascript:void(0)"
            @click.stop.prevent="isSubMenuVisible = !isSubMenuVisible"
            class="header__link header__more caret"
          >
            <span>{{ $t('general.header.menu') }}</span>
          </a>
        </div>
        <div
          class="sub-menu"
          v-if="isSubMenuVisible"
          :class="{ 'sub-menu--scootch-left': !isAuthenticated }"
        >
          <template v-if="profile">
            <div class="sub-menu__header d-flex align-items-center mb-4 mt-2">
              <div>
                <span class="sub-menu__name">{{ fullName }}</span>
                <span class="sub-menu__email">{{ email }}</span>
                <router-link class="sub-menu__link" :to="{ name: 'Profile' }">{{
                  $t('general.header.edit_profile')
                }}</router-link>
              </div>
              <user-avatar :user="profile" :size="80" class="ml-5" />
            </div>
            <hr />
          </template>
          <nav>
            <ul class="sub-menu__list">
              <li v-if="hasSyncPrivilege">
                <router-link class="sub-menu__link" :to="{ name: 'CalendarSync' }">{{
                  $t('general.menu.calendar_sync')
                }}</router-link>
              </li>
              <li>
                <router-link class="sub-menu__link" :to="{ name: 'Donate' }">{{
                  $t('general.menu.donate')
                }}</router-link>
              </li>
              <li>
                <router-link class="sub-menu__link" :to="{ name: 'FAQ' }">{{
                  $t('general.menu.faq')
                }}</router-link>
              </li>
              <li>
                <router-link class="sub-menu__link" :to="{ name: 'Feedback' }">{{
                  $t('general.menu.feedback')
                }}</router-link>
              </li>
              <li>
                <router-link class="sub-menu__link" :to="{ name: 'About' }">{{
                  $t('general.menu.about')
                }}</router-link>
              </li>
              <li>
                <router-link class="sub-menu__link" :to="{ name: 'Privacy' }">{{
                  $t('general.menu.privacy')
                }}</router-link>
              </li>
            </ul>
            <template v-if="profile">
              <hr />
              <ul class="sub-menu__list">
                <li>
                  <a
                    :href="'?lang=' + otherLocale"
                    :hreflang="otherLocale"
                    class="sub-menu__link"
                    @click.prevent="switchLanguage"
                    >{{ $t('general.menu.otherLanguage') }}</a
                  >
                </li>
                <li>
                  <router-link
                    class="sub-menu__link"
                    :to="{ name: 'Login', params: { logout: true } }"
                    >{{ $t('general.header.log_out') }}</router-link
                  >
                </li>
              </ul>
            </template>
          </nav>
        </div>
      </template>
    </div>
  </header>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import { CALENDAR_SYNC_PRIVILEGE } from '@/utils/constants'
import { getMetaForRoute } from '@/utils/router'
import { mapGetters } from 'vuex'

export default {
  name: 'AppHeader',
  components: {
    UserAvatar
  },
  methods: {
    switchLanguage() {
      this.$i18n.locale = this.otherLocale
    },
    onBodyClick(event) {
      if (event.target.closest('.sub-menu') === null || event.target.tagName === 'A') {
        this.isSubMenuVisible = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.onBodyClick)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onBodyClick)
  },
  data() {
    return {
      isSubMenuVisible: false
    }
  },
  watch: {
    $route() {
      this.isSubMenuVisible = false
    }
  },
  computed: {
    otherLocale() {
      return this.$i18n.locale === 'en' ? 'nl' : 'en'
    },
    ...mapGetters({
      profile: 'profile',
      isAuthenticated: 'isAuthenticated',
      isAuthLoading: 'isAuthLoading'
    }),
    isCalendar() {
      const match = this.$route.matched[0]
      return (
        (match && match.name === 'EventDetails') ||
        (this.$route.name === 'Home' && this.$store.getters.isAuthenticated)
      )
    },
    isTeams() {
      const match = this.$route.matched[0]

      return match && (match.name === 'TeamOverview' || match.name === 'TeamDetails')
    },
    modeClass() {
      return this.type ? `header--${this.type}` : ''
    },
    type() {
      return getMetaForRoute('headerType', this.$route)
    },
    fullName() {
      if (!this.$store.getters.profile) return null

      const { first_name, last_name } = this.$store.getters.profile

      return [first_name, last_name].join(' ')
    },
    email() {
      if (!this.$store.getters.profile) return null
      return this.$store.getters.profile.email
    },
    hasSyncPrivilege() {
      return this.$store.getters.hasPrivilege(CALENDAR_SYNC_PRIVILEGE)
    }
  }
}
</script>

<style lang="scss">
.header {
  width: 100%;
  color: $white;
  background: $primary;
  @include box-shadow-light-1;
  @include font-smoothing;
  z-index: 2;

  &--inverted {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: $primary;
    background: $white;
    box-shadow: none;
  }

  &--transparent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: $white;
    @include font-smoothing;
    background: none;
    box-shadow: none;
  }

  &__logo {
    position: relative;
    top: 2px;
  }

  &__inner {
    height: 56px;
    padding: 0 $spacer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__links {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__link {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    padding: 18px 20px;
    text-decoration: none;
    text-transform: uppercase;

    &:focus,
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &.header__link--active {
      &,
      &:hover,
      &:focus {
        @include box-shadow-dark-3;
        color: $primary;
        background-color: $white;

        .header--inverted & {
          color: $white;
          background-color: $primary;
          @include font-smoothing;
        }
      }
    }

    &.header__link--emphasize {
      padding: 4px 12px;
      background-color: $white;
      color: $primary;
      border-radius: 3px;

      .header--inverted & {
        color: $white;
        background-color: $primary;
        @include font-smoothing;
      }
    }
  }

  &__more {
    @include caret;
  }

  &__link-icon {
    margin-right: 0.6rem;
    font-size: $font-size-base * 1.2;
  }

  &__seperator {
    height: 20px;
    width: 1px;

    background-color: $white;

    .header--inverted & {
      background-color: $primary;
    }
  }
}

.sub-menu {
  position: absolute;
  right: 8px;
  top: calc(100% + 2px);
  background: $white;
  padding: $spacer $spacer * 2;
  min-width: 150px;
  z-index: 1;

  &--scootch-left {
    right: 40px;
  }
  @include box-shadow-light-2;

  &::before {
    content: '';
    position: absolute;
    right: 20px;
    bottom: 100%;
    border-top: 0;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-left: 7px solid transparent;
  }

  &__name {
    color: $black;
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
  }

  &__email {
    display: block;
    font-size: $font-size-sm;
    color: $gray-800;
    margin-bottom: 10px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__link {
    display: block;
    padding: 4px 0;
    color: $gray-900;

    &:focus,
    &:hover {
      color: $gray-800;
    }
  }
}
</style>
