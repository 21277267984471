<template>
  <div class="auth-base centered">
    <div class="container">
      <router-link :to="{ name: 'Home' }" class="auth-base__close">
        <icon :icon="['fal', 'times']" />
      </router-link>
      <div class="auth-base__center">
        <router-link :to="{ name: 'Home' }">
          <img
            class="mb-3 auth-base__logo"
            src="@/assets/logo-inverted.svg"
            width="140"
            height="80"
            v-if="$breakpoint.smAndUp"
          />
        </router-link>
        <div class="card">
          <div class="card-body p-sm-5">
            <div class="mb-3">
              <h1 class="h3 card-title" v-if="title">{{ title }}</h1>
              <p class="card-text" v-if="intro">{{ intro }}</p>
              <p class="card-text" v-else-if="$slots.intro"><slot name="intro"></slot></p>
            </div>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.auth-base {
  text-align: center;
  flex: 1 1 auto;
  padding: 50px 0;
  background: $white;

  @include sm-up {
    padding: 30px 0;
  }

  .card {
    border: 0;
  }

  .card-text {
    color: $gray-600;
  }

  &__close {
    @include button-unstyled;
    color: $gray-700;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 2rem;
    line-height: 1em;
    z-index: 1;

    @include sm-up {
      position: absolute;
      top: 2rem;
      right: 2rem;
      font-size: 3rem;
      color: $white;
      &:hover {
        color: $white;
      }
    }

    @include xxl-up {
      top: 3rem;
      right: 3rem;
      font-size: 4rem;
    }
  }

  &__logo {
    @include xxl-up {
      width: 192px;
      height: 110px;
    }
  }

  @include sm-up {
    padding: 0;
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient($green, $green-light);

    .card {
      border: 0;
      @include box-shadow-dark-5;
    }

    &__center {
      max-width: 420px;
      margin: 2rem auto;
    }
  }

  @include media-breakpoint-down(sm) {
    .card {
      box-shadow: none;
    }

    .card-body {
      padding: 0;
    }
  }
}
</style>

<script>
export default {
  name: 'AuthBase',
  props: {
    title: {
      type: String,
      required: false
    },
    intro: {
      type: String,
      required: false
    }
  }
}
</script>
