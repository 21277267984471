<template>
  <icon :icon="['fal', 'spinner-third']" class="spinner" :style="{ 'font-size': size }" />
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: [Number, String],
      required: false
    }
  }
}
</script>

<style lang="scss">
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  color: $gray-600;
  animation: rotating 0.4s linear infinite;
}
</style>
