<template>
  <div class="wizard wizard--success centered">
    <img class="wizard__logo" src="@/assets/logo-inverted.svg" width="120" height="69" />
    <button class="wizard__close" @click="onCloseClick">
      <icon :icon="['fal', 'times']" />
    </button>
    <div class="container">
      <div class="wizard__center" v-if="event">
        <h1 class="wizard__title">
          {{ event.name }}<br /><small>{{ $t('event_wizard.add_success.title') }}</small>
        </h1>
        <success-gif class="mb-5" size="200" />
        <div class="mt-5 d-flex flex-column align-items-center">
          <p
            class="invite-text mb-5"
            v-html="
              event.team_id
                ? $t('event_wizard.add_success.team_members_invited')
                : $t('event_wizard.add_success.invite_friends')
            "
          />
          <template v-if="event.team_id">
            <button class="btn btn-lg btn-inverted" @click="onInviteClick">
              {{ $t('event_wizard.add_success.invite_more') }}
            </button>
            <button class="btn btn-lg btn-link text-white mt-3" @click="onCloseClick">
              {{ $t('general.buttons.close') }}
            </button>
          </template>
          <button v-else class="btn btn-lg btn-inverted" @click="onInviteClick">
            {{ $t('event_wizard.add_success.invite') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessGif from '@/components/SuccessGif'
import loadEventMixin from '@/mixins/loadEventMixin'

export default {
  name: 'EventAddSuccess',
  mixins: [loadEventMixin],
  components: {
    SuccessGif
  },
  methods: {
    onInviteClick() {
      this.$router.push({
        name: 'EventInvite',
        params: { eventId: this.$route.query.event_id }
      })
    },
    onCloseClick() {
      this.$router.push({
        name: 'EventDetails',
        params: { eventId: this.$route.query.event_id }
      })
    }
  }
}
</script>

<style lang="scss">
.wizard--success {
  .invite-text {
    text-align: center;
    color: $white;
    font-size: 1.2rem;
    line-height: 1.25;

    @include sm-up {
      font-size: 1.8rem;
    }
  }

  .success-gif {
    @include sm-up {
      height: 300px;
      width: 300px;
    }
  }
}
</style>
