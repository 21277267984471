import i18n from '@/i18n'
import { getInstance } from '@/utils/api'
import {
  DECISION_UNKNOWN,
  EVENTS_PAGE_SIZE,
  INVITATION_CONFIRM_STATUS_FAILED,
  MODIFY_TYPE_SINGLE,
  NOTIFICATION_TYPE_ERROR
} from '@/utils/constants'
import { captureApiException } from '@/utils/errors'
import dayjs from 'dayjs'
import {
  EVENTS_LOAD_ERROR,
  EVENTS_LOAD_REQUEST,
  EVENTS_LOAD_SUCCESS,
  EVENTS_SET_DECISION,
  EVENT_ADD,
  EVENT_EDIT,
  EVENT_INVITATION_RESET,
  EVENT_INVITATION_SET,
  SET_SHOW_HISTORY,
  SET_TEAM_FILTER
} from './mutations'
import { denormalizeEvent, normalizeEvent, normalizeEventInvitation } from './normalizers'

export const api = getInstance('events')

export default {
  loadEvents: ({ commit, state, dispatch, getters }, payload) => {
    const offset = payload && payload.offset ? payload.offset : 0
    const reset = payload && payload.reset ? payload.reset : false
    const team = state.teamFilter
    const showHistory = state.showHistory

    commit(EVENTS_LOAD_REQUEST)
    const url = showHistory ? '/history/' : '/'
    const params = { team, offset, limit: EVENTS_PAGE_SIZE }
    const userId = getters.userId
    const userTimeZoneValue = getters.userTimeZone.value

    return api
      .get(url, { params })
      .then((response) => {
        const { results, count } = response.data
        const events = results.map((event) => normalizeEvent(event, userId, userTimeZoneValue))

        commit(EVENTS_LOAD_SUCCESS, {
          reset,
          events,
          count
        })

        return events
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.events.load'),
          type: EVENTS_LOAD_ERROR
        })

        captureApiException(error)
      })
  },
  loadEvent: ({ commit, dispatch, getters }, { eventId }) => {
    commit(EVENTS_LOAD_REQUEST)
    return api
      .get(`/${eventId}/`)
      .then((response) => {
        const userId = getters.userId
        const userTimeZoneValue = getters.userTimeZone.value
        const event = normalizeEvent(response.data, userId, userTimeZoneValue)
        commit(EVENTS_LOAD_SUCCESS, { events: [event] })

        return event
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          dispatch('addNotification', {
            text: i18n.t('errors.events.404'),
            type: NOTIFICATION_TYPE_ERROR
          })

          throw error
        } else {
          dispatch('handleError', {
            error,
            shortError: i18n.t('errors.events.load'),
            type: EVENTS_LOAD_ERROR
          })

          captureApiException(error)
        }
      })
  },
  addEvent: ({ commit, dispatch, getters }, values) => {
    const data = denormalizeEvent(values)
    const userId = getters.userId
    const userTimeZoneValue = getters.userTimeZone.value

    return api
      .post('/', data)
      .then((response) => {
        const events = response.data.map((event) =>
          normalizeEvent(event, userId, userTimeZoneValue)
        )

        commit(EVENT_ADD, { events })

        return events
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.events.add')
        })

        captureApiException(error)

        throw error
      })
  },
  deleteEvent: (
    { commit, dispatch, getters },
    { eventId, deleteFuture = false, notifyInvitees = true }
  ) => {
    const data = {
      remove_type: deleteFuture ? 'FUTURE' : 'SINGLE',
      push_type: notifyInvitees ? 'ALL-INVITED' : 'NONE'
    }

    const name = getters.getEventById(eventId).name

    return api
      .delete(`/${eventId}/`, { data })
      .then((response) => {
        // simply reload all events after delete/cancel
        dispatch('loadEvents', { reset: true })
      })
      .then(() => {
        const title = deleteFuture
          ? notifyInvitees
            ? i18n.t('events.delete.cancel_success.multiple.title')
            : i18n.t('events.delete.delete_success.multiple.title')
          : notifyInvitees
          ? i18n.t('events.delete.cancel_success.single.title')
          : i18n.t('events.delete.delete_success.single.title')

        const text = deleteFuture
          ? notifyInvitees
            ? i18n.t('events.delete.cancel_success.multiple.text', { name })
            : i18n.t('events.delete.delete_success.multiple.text', { name })
          : notifyInvitees
          ? i18n.t('events.delete.cancel_success.single.text', { name })
          : i18n.t('events.delete.delete_success.single.text', { name })

        dispatch('addNotification', { title, text })
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.events.delete')
        })

        captureApiException(error)
      })
  },
  leaveEvent: ({ dispatch, getters }, { eventId, leaveFuture = false }) => {
    const data = {
      remove_type: leaveFuture ? 'FUTURE' : 'SINGLE'
    }

    const name = getters.getEventById(eventId).name

    return api
      .delete(`/${eventId}/invitee/`, { data })
      .then(() => {
        // first reload all events
        dispatch('loadEvents', { reset: true })

        const title = leaveFuture
          ? i18n.t('events.leave.success.multiple.title')
          : i18n.t('events.leave.success.single.title')

        const text = leaveFuture
          ? i18n.t('events.leave.success.multiple.text', { name })
          : i18n.t('events.leave.success.single.text', { name })

        // and then display helpful message
        dispatch('addNotification', { title, text })
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.events.leave')
        })

        captureApiException(error)
      })
  },
  editEvent: ({ commit, dispatch, getters }, { id, values, pushType, editType }) => {
    editType = editType || MODIFY_TYPE_SINGLE

    const data = {
      push_type: pushType,
      edit_type: editType,
      ...denormalizeEvent(values)
    }
    const userId = getters.userId
    const userTimeZoneValue = getters.userTimeZone.value

    return api
      .put(`/${id}/`, data)
      .then((response) => {
        const events = response.data.map((event) =>
          normalizeEvent(event, userId, userTimeZoneValue)
        )

        commit(EVENT_EDIT, { events })

        dispatch('addNotification', {
          text: i18n.t('event_wizard.edit_success.text', {
            event: data.name
          })
        })

        return events
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.events.edit')
        })

        captureApiException(error)
      })
  },
  shareEvent: ({ commit, dispatch }, { eventId }) => {
    return api
      .post(`/${eventId}/create-code/`)
      .then((response) => {
        const { code, expires } = response.data
        const { protocol, host } = window.location

        return {
          url: `${protocol}//${host}/event-invitation/?code=${code}`,
          expires: dayjs(expires)
        }
      })
      .catch((error) => {
        dispatch('handleError', {
          error,
          shortError: i18n.t('errors.events.share')
        })

        captureApiException(error)
      })
  },
  setDecision: ({ commit, dispatch, getters }, { eventId, userId, decision, comment }) => {
    userId = userId || getters.userId
    const isSelf = userId === getters.userId

    const invitee = getters.getInviteeByEventIdAndUserId(eventId, userId)
    const name = invitee.user.first_name
    const currentDecision = invitee?.decision ? invitee.decision : DECISION_UNKNOWN
    const currentComment = invitee?.comment || ''

    // don't bother setting decision if nothing changed
    if (decision === currentDecision && comment === currentComment) {
      return false
    } else {
      const addNotification = isSelf
        ? i18n.t('events.details.decision_notification')
        : i18n.t('events.details.decision_notification_other', { name })
      const errorNotification = isSelf
        ? i18n.t('errors.events.set_decision')
        : i18n.t('errors.events.set_decision_other', { name })

      const data = {
        user: userId,
        decision: decision,
        comment
      }

      return api
        .post(`/${eventId}/decisions/`, data)
        .then((response) => {
          dispatch('addNotification', {
            text: addNotification
          })
          commit(EVENTS_SET_DECISION, {
            eventId: eventId,
            invitee: response.data
          })

          return response.data
        })
        .catch((error) => {
          dispatch('handleError', {
            error,
            shortError: errorNotification
          })

          captureApiException(error)

          const isBadRequest = error.response.status === 400
          if (isBadRequest) {
            // reload event, when changing status fails because it's canceled or full
            dispatch('loadEvent', { eventId })
          }
        })
    }
  },
  convertEventInvitation: ({ commit, dispatch, getters }, { code }) => {
    const userId = getters.userId
    const userTimeZoneValue = getters.userTimeZone.value

    return api
      .post('/invites/convert/', { code })
      .then((response) => {
        const eventInvitation = normalizeEventInvitation(response.data, userId, userTimeZoneValue)

        // @TODO: Use a seperate mutator for this, like EVENTS_ADD_SINGLE or
        // turn EVENTS_ADD into EVENTS_ADD_MULTIPLE
        commit(EVENT_ADD, { events: [eventInvitation.event] })

        return eventInvitation
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.status === 'EVENT_EXPIRED'
        ) {
          throw new Error(i18n.t('errors.events.load_invitation_has_expired'))
        } else {
          captureApiException(error)
          throw new Error(i18n.t('errors.events.load_invitation'))
        }
      })
  },
  anonymouslyRespondToEventInvitation: ({ commit }, payload) => {
    const { name, email, decision, code } = payload

    const data = {
      name,
      email,
      decision,
      invitee_code: code
    }

    return api
      .post('/anonymous/', data)
      .then(() => commit(EVENT_INVITATION_RESET))
      .catch((error) => {
        const { response } = error

        if (!response || !response.data) {
          throw new Error(i18n.t('errors.events.anon_respond_invitation'))
        }

        if (response.data.errors && response.data.errors[0]) {
          error.message = response.data.errors[0].message
        }

        if (response.data.error) {
          error.message = response.data.error
        }

        if (response.data.status) {
          error.status = response.data.status
        }

        throw error
      })
  },
  confirmAnonymousEventDecision: ({ commit, getters }, { code }) => {
    const userId = getters.userId
    const userTimeZoneValue = getters.userTimeZone.value

    return api
      .post('/anonymous/confirm/', { confirmation_code: code })
      .then((response) => normalizeEventInvitation(response.data, userId, userTimeZoneValue))
      .catch((error) => {
        let errorMessage
        try {
          errorMessage =
            error.response.data.status === INVITATION_CONFIRM_STATUS_FAILED
              ? 'errors.events.anon_confirm_invitation_wrong_code'
              : 'errors.events.anon_confirm_invitation'
        } catch (e) {
          errorMessage = 'errors.events.anon_confirm_invitation'
        }

        throw new Error(i18n.t(errorMessage))
      })
  },
  loadEventInvitation: ({ commit, dispatch, getters }, { code }) => {
    const userId = getters.userId
    const userTimeZoneValue = getters.userTimeZone.value

    return api
      .get('/invites/details/', { params: { code } })
      .then((response) => {
        const eventInvitation = normalizeEventInvitation(response.data, userId, userTimeZoneValue)

        commit(EVENT_INVITATION_SET, eventInvitation)

        return eventInvitation
      })
      .catch(() => {
        throw new Error(i18n.t('errors.events.load_invitation'))
      })
  },
  setTeamFilter: ({ commit, dispatch, state }, { teamId }) => {
    commit(SET_TEAM_FILTER, teamId)

    dispatch('loadEvents')
  },
  setShowHistory: ({ commit, dispatch }, { showHistory }) => {
    commit(SET_SHOW_HISTORY, showHistory)

    dispatch('loadEvents')
  }
}
