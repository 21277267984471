export function sortTeams(teams) {
  return teams.sort((a, b) => {
    if (a.user_joined.isAfter(b.user_joined)) {
      return -1
    }

    if (a.user_joined.isBefore(b.user_joined)) {
      return 1
    }

    return 0
  })
}
