<template>
  <modal :title="$t('teams.add.title')" @close="$router.push({ name: 'TeamOverview' })" size="sm">
    <team-form :loading="loading" class="modal__stretcher" @submit="onFormSubmit">
      <p class="mb-5 text-muted text-sm">{{ $t('teams.overview.no_teams.text') }}</p>
    </team-form>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import TeamForm from '@/components/TeamForm'

export default {
  name: 'TeamAdd',
  data() {
    return {
      loading: false
    }
  },
  components: {
    TeamForm,
    Modal
  },
  methods: {
    onFormSubmit(values) {
      this.loading = true
      this.$store
        .dispatch('createTeam', {
          name: values.name,
          type: values.type,
          onlyManagersCanAddEvents: values.managersOnly
        })
        .then((newTeam) =>
          this.$router.push({
            name: 'TeamDetails',
            params: { teamId: newTeam.id }
          })
        )
        .finally(() => (this.loading = false))
    }
  }
}
</script>
