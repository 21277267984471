<template>
  <modal
    :title="$t('events.add_to_calendar.title')"
    @close="$router.replace({ name: 'EventDetails' })"
    size="sm"
  >
    <button class="btn btn-outline-primary btn-block">
      {{ $t('events.add_to_calendar.single_event') }}
    </button>
    <button class="btn btn-outline-primary btn-block">
      {{ $t('events.add_to_calendar.multiple_events') }}
    </button>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'AddToCalendar',
  components: {
    Modal
  },
  methods: {
    onAddEventPress() {}
  }
}
</script>
