const { VUE_APP_GOOGLE_APP_ID, VUE_APP_APPLE_APP_ID } = process.env
const { protocol, host } = window.location
const googleBaseUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
const appleBaseUrl = 'https://appleid.apple.com/auth/authorize'

export const OAUTH_STATE_KEY = 'oauth_state'

export const resetState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY)
}
export const isValidState = (state) => {
  return state === sessionStorage.getItem(OAUTH_STATE_KEY)
}

export const stateToRedirectUri = (state) => {
  let buff = Buffer.from(state, 'base64')
  const jsonData = buff.toString('utf-8')
  const { redirect } = JSON.parse(jsonData)
  return redirect
}

export const getState = (redirect) => {
  if (sessionStorage.getItem(OAUTH_STATE_KEY) === null) {
    // first time setting the state
    // add redirect_uri & random number in object
    // convert to JSON
    // convert to base64
    const state = {
      random:
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      redirect: redirect || ''
    }
    const base64State = Buffer.from(JSON.stringify(state)).toString('base64')
    sessionStorage.setItem(OAUTH_STATE_KEY, base64State)
  }
  return sessionStorage.getItem(OAUTH_STATE_KEY)
}

export const APPLE_REDIRECT_URL = `${protocol}//${host}/login/apple-id`
export function getAppleURL(redirect) {
  const state = getState(redirect)
  return `${appleBaseUrl}?client_id=${VUE_APP_APPLE_APP_ID}&redirect_uri=${APPLE_REDIRECT_URL}&response_type=code&response_mode=form_post&scope=name%20email&state=${state}`
}

export const GOOGLE_REDIRECT_URL = `${protocol}//${host}/google-oauth2-login/`
export function getGoogleURL(redirect) {
  const state = getState(redirect)
  return `${googleBaseUrl}?client_id=${VUE_APP_GOOGLE_APP_ID}&redirect_uri=${GOOGLE_REDIRECT_URL}&response_type=code&scope=email&state=${state}`
}
