<template>
  <div class="download-app">
    <div class="container">
      <div class="download-app__inner">
        <div class="download-app__text">
          <h2 class="download-app__title h4">{{ $t('home.download_app.title') }}</h2>
          <p class="download-app__intro lead" v-html="$t('home.download_app.text')" />
          <StoreButtons />
        </div>
        <div class="download-app__phones-container">
          <picture class="download-app__phones">
            <source :srcset="phones.lg + ', ' + phones.lg2x + ' 2x'" media="(min-width: 992px)" />
            <source :srcset="phones.md + ', ' + phones.md2x + ' 2x'" media="(min-width: 768px)" />
            <source :srcset="phones.sm + ', ' + phones.sm2x + ' 2x'" media="(max-width: 767px)" />
            <img class="download-app__phones" :src="phones.lg" />
          </picture>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreButtons from '@/components/StoreButtons'

export default {
  name: 'DownloadApp',
  components: {
    StoreButtons
  },
  data() {
    const isNL = this.$i18n.locale === 'nl'
    return {
      phones: {
        sm: isNL ? require('@/assets/nl/phones-sm.png') : require('@/assets/en/phones-sm.png'),
        sm2x: isNL
          ? require('@/assets/nl/phones-sm@2x.png')
          : require('@/assets/en/phones-sm@2x.png'),
        md: isNL ? require('@/assets/nl/phones-md.png') : require('@/assets/en/phones-md.png'),
        md2x: isNL
          ? require('@/assets/nl/phones-md@2x.png')
          : require('@/assets/en/phones-md@2x.png'),
        lg: isNL ? require('@/assets/nl/phones-lg.png') : require('@/assets/en/phones-lg.png'),
        lg2x: isNL
          ? require('@/assets/nl/phones-lg@2x.png')
          : require('@/assets/en/phones-lg@2x.png')
      }
    }
  }
}
</script>

<style lang="scss">
.download-app {
  color: $white;
  overflow: hidden;
  background-color: #30bf89;
  position: relative;

  &__inner {
    min-height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include sm-up {
      justify-content: center;
      flex-direction: row-reverse;
    }

    @include md-up {
      height: 440px;
    }

    @include lg-up {
      height: 520px;
    }
  }

  // Text
  &__text {
    @include sm-up {
      margin-left: 3rem;
    }

    @include lg-up {
      margin-left: 6rem;
    }
  }
  &__title {
    margin-bottom: 0.3rem;

    @include sm-up {
      font-size: 2.5rem;
    }
  }

  &__intro {
    @include xs-only {
      max-width: 200px;
      font-size: 1.1rem;
    }

    @include sm-up {
      margin-bottom: 1.5rem;
    }

    line-height: 1.35;
    max-width: 220px;
  }

  // Phones
  &__phones-container {
    align-self: flex-end;
  }

  &__phones {
    width: auto;
    height: auto;

    @media (min-width: 420px) and (max-width: 767px) {
      width: 150px;
      margin-top: 3rem;
    }
  }
}
</style>
