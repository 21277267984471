<template>
  <transition name="notification">
    <div class="notification" :class="{ ['notification--' + type]: true }">
      <div class="notification__icon-container">
        <icon :icon="icon" class="notification__icon" />
      </div>
      <div class="notification__text-container">
        <p class="notification__title" v-if="title" data-cy="notification-title">{{ title }}</p>
        <p class="notification__text" data-cy="notification-text">{{ text }}</p>
      </div>
      <button class="notification__close" @click="remove"><icon :icon="['fal', 'times']" /></button>
    </div>
  </transition>
</template>

<script>
import {
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_INFO
} from '@/utils/constants'

export default {
  name: 'Notification',
  props: {
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return (
          [
            NOTIFICATION_TYPE_SUCCESS,
            NOTIFICATION_TYPE_INFO,
            NOTIFICATION_TYPE_WARNING,
            NOTIFICATION_TYPE_ERROR
          ].indexOf(value) !== -1
        )
      }
    },
    ttl: {
      type: Number,
      default: 4000
    },
    title: String,
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  },
  mounted() {
    this.removeTimeout = setTimeout(this.remove, this.ttl)
  },
  beforeDestroy() {
    clearTimeout(this.removeTimeout)
  },
  computed: {
    icon() {
      return {
        [NOTIFICATION_TYPE_SUCCESS]: 'check-circle',
        [NOTIFICATION_TYPE_INFO]: 'info-circle',
        [NOTIFICATION_TYPE_WARNING]: 'exclamation-triangle',
        [NOTIFICATION_TYPE_ERROR]: 'exclamation-circle'
      }[this.type]
    }
  }
}
</script>

<style lang="scss">
.notification {
  display: flex;
  align-items: center;
  background: $white;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
  position: relative;
  padding-right: 1rem;
  transition: all 0.3s;
  @include box-shadow-light-1;

  &__icon-container {
    @include center-content;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
  }

  &--success &__icon-container {
    background-color: $success;
  }

  &--warning &__icon-container {
    background-color: $warning;
  }

  &--error &__icon-container {
    background-color: $error;
  }

  &--info &__icon-container {
    background-color: $info;
  }

  &__icon {
    color: $white;
    font-size: 24px;
  }

  &__close {
    padding: 0;
    background: 0;
    border: 0;
  }

  &__title {
    font-weight: $font-weight-bold;
  }

  &__text-container {
    margin: 0;
    flex: 1;
    padding: 1rem 1.5rem 1rem calc(50px + 1.5rem);
    color: $gray-700;

    @include media-breakpoint-up(lg) {
      width: 400px;
    }
  }

  &__text {
    margin: 0;
  }

  &__title {
    margin: 0;
    font-weight: $font-weight-bold;
  }
}

.notification-enter-active,
.notification-leave-active {
  transition: all 0.3s ease;
}

.notification-enter,
.notification-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
