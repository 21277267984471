<template>
  <div class="avatar-cropper">
    <modal :title="title" @close="destroy" :size="size">
      <div class="modal__stretcher">
        <div v-if="newImage">
          <img
            ref="img"
            class="avatar-cropper__image"
            :src="newImage"
            alt
            @load.stop="createCropper"
          />
        </div>
        <div v-else class="avatar-cropper__no-image" @click="pickImage">
          <icon :icon="['fal', 'camera-alt']" class="avatar-cropper__icon" />
        </div>
        <div class="text-center mt-5">
          <template v-if="newImage">
            <button class="btn btn-outline mb-3 mb-sm-0 mr-sm-3 btn-inline-sm" @click="pickImage">
              {{ $t('profile.profile_picture.upload') }}
            </button>
            <button v-if="newImage" class="btn btn-primary btn-inline-sm" @click="submit">
              {{ $t('profile.profile_picture.save') }}
            </button>
          </template>
          <button v-else class="btn btn-primary btn-inline-sm" @click="pickImage">
            {{ $t('profile.profile_picture.upload') }}
          </button>
        </div>
      </div>
    </modal>
    <input ref="input" type="file" class="sr-only" :accept="mimes" />
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import Cropper from 'cropperjs'

export default {
  name: 'AvatarCropper',
  components: {
    Modal
  },
  data() {
    return {
      cropper: undefined,
      newImage: this.image,
      filename: undefined
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'md'
    },
    image: {
      type: String,
      required: false
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    outputOptions: {
      type: Object,
      default() {
        return {
          fillColor: '#ffffff',
          width: 800,
          height: 800
        }
      }
    },
    outputMime: {
      type: String,
      default: 'image/jpeg'
    },
    outputQuality: {
      type: Number,
      default: 0.9
    },
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg,'
    }
  },
  methods: {
    destroy() {
      if (this.cropper) this.cropper.destroy()
      if (this.$refs.input) this.$refs.input.value = ''
      this.newImage = undefined
      this.$emit('close')
    },
    submit() {
      if (this.newImage === this.image) {
        this.$emit('close')
      } else {
        this.cropper.getCroppedCanvas(this.outputOptions).toBlob(
          (blob) => {
            this.$emit('submit', blob)
            this.destroy()
          },
          'image/jpeg',
          this.outputQuality
        )
      }
    },
    pickImage() {
      this.$refs.input.click()
    },
    createCropper() {
      if (this.newImage === this.image) return
      if (this.cropper) this.cropper.destroy()

      this.cropper = new Cropper(this.$refs.img, {
        aspectRatio: this.aspectRatio,
        autoCropArea: 1,
        viewMode: 1,
        movable: false,
        zoomable: true
      })
    }
  },
  mounted() {
    const fileInput = this.$refs.input
    fileInput.addEventListener('change', () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.newImage = e.target.result
        }

        reader.readAsDataURL(fileInput.files[0])

        this.filename = fileInput.files[0].name || 'unknown'
      }
    })
  }
}
</script>

<style lang="scss">
.avatar-cropper {
  &__image {
    pointer-events: none;
    user-select: none;
    max-width: 100%;
    max-height: 60vh;
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
  }

  &__no-image {
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-100;
  }

  &__icon {
    font-size: 50px;
    color: $gray-400;
  }
}
</style>
