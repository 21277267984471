<template>
  <div class="text-page" @click="onClick">
    <div class="container container--xs">
      <h1 class="text-page__title">{{ $t('faq.title') }}</h1>
      <div
        v-for="(section, sectionIndex) in questions"
        :key="sectionIndex"
        :id="section.id"
        class="mb-5"
      >
        <h2 class="h3">{{ section.title }}</h2>
        <div class="accordion mt-4" role="tablist">
          <div
            class="card"
            v-for="(tab, index) in section.tabs"
            :key="index"
            :id="slugify(tab.header)"
          >
            <div
              :id="slugify(tab.header) + '-header'"
              :class="{ 'card-header--active': activeTab === slugify(tab.header) }"
              class="card-header"
            >
              <button
                class="btn btn-link px-0"
                type="button"
                :aria-expanded="activeTab === slugify(tab.header) ? 'true' : 'false'"
                :aria-controls="slugify(tab.header) + '-panel'"
                v-html="tab.header"
                @click.prevent.stop="toggle(slugify(tab.header))"
              />
            </div>
            <transition-expand>
              <div
                :id="slugify(tab.header) + '-panel'"
                v-show="activeTab === slugify(tab.header)"
                :aria-labelledby="slugify(tab.header) + '-header'"
              >
                <div class="card-body p-4 p-lg-5 text-muted" v-html="tab.body" />
              </div>
            </transition-expand>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand'
import animateScrollTo from 'animated-scroll-to'
import slugify from 'slugify'
import QuestionsEN from './faqEN.json'
import QuestionsNL from './faqNL.json'

export default {
  name: 'FAQ',
  components: {
    TransitionExpand
  },
  data() {
    return {
      activeTab: null,
      questions: this.$i18n.locale === 'en' ? QuestionsEN : QuestionsNL
    }
  },
  mounted() {
    requestAnimationFrame(() => this.maybeScrollToElement())
  },
  methods: {
    onClick(event) {
      const { target } = event
      if (target.tagName === 'A') {
        event.stopPropagation()
        event.preventDefault()

        if (target.host === window.location.host) {
          // Treat <a>'s as <router-link>'s
          this.$router.push(target.pathname)
        } else {
          // Unless they're outgoing links, in which case we open them in a new tab
          window.open(target.href, '_blank')
        }
      }
    },
    maybeScrollToElement() {
      const hash = window.location.hash.substring(1)

      if (!hash) return

      const el = document.getElementById(hash)

      if (el) {
        animateScrollTo(el, {
          offset: -100,
          onComplete: () => {
            this.activeTab = hash
          }
        })
      } else {
        this.setHash('')
      }
    },
    slugify(string) {
      return slugify(string.toLowerCase())
    },
    setHash(hash) {
      hash = hash ? '#' + hash : ''
      history.replaceState(
        '',
        document.title,
        window.location.pathname + window.location.search + hash
      )
    },
    toggle(id) {
      this.activeTab = this.activeTab === id ? null : id
      this.setHash(this.activeTab || '')
    }
  }
}
</script>

<style lang="scss">
.accordion {
  a {
    text-decoration: underline;
  }

  .card-header {
    background-color: $white;
    transition: background-color 0.2s;

    &--active {
      background-color: $primary-lighter;
    }
  }

  .btn {
    white-space: initial;
    display: block;
    text-align: left;

    &,
    &:hover {
      color: $gray-700;
    }
  }
}
</style>
