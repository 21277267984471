<template>
  <div class="event-list-item">
    <div
      class="event-list-item__wrapper"
      :class="{
        'event-list-item__wrapper--highlighted': active,
        'event-list-item__wrapper--inactive': event.isCanceled
      }"
    >
      <div class="event-list-item__date-container">
        <span class="event-list-item__day-of-week">{{ event.startDateTime.format('dd') }}</span>
        <span class="event-list-item__day-of-month">{{ event.startDateTime.format('D') }}</span>
      </div>
      <div
        :class="{
          'event-list-item__text-container': true,
          'event-list-item__text-container--inactive': event.isCanceled
        }"
      >
        <h3 class="event-list-item__title">{{ event.name }}</h3>
        <div class="event-list-item__meta">
          <span>{{ event.startDateTime.format($t('general.time_format.short')) }}</span>
          <span v-if="event.endDateTime">
            - {{ event.endDateTime.format($t('general.time_format.short')) }}</span
          >
          <span v-if="event.hasPassed">
            - {{ event.countAttending }}
            {{ $tc('events.overview.has_attended', event.countAttending) }}</span
          >
          <span v-else-if="event.isCanceled"></span>
          <span v-else-if="event.maxAttendees > 0">
            -
            {{
              $t('events.overview.max_count_attendees', {
                attending: event.countAttending,
                max: event.maxAttendees
              })
            }}
          </span>
          <span v-else>
            - {{ event.countAttending }}
            {{ $tc('events.overview.is_attending', event.countAttending) }}</span
          >
        </div>
      </div>
    </div>
    <div v-if="!event.isCanceled" class="event-list-item__button-container">
      <div
        v-if="event.hasPassed"
        :class="'event-list-item__history-icon--' + event.userInvitee.decision"
      >
        <icon :icon="icon" />
      </div>
      <button
        v-else
        class="event-list-item__button"
        :class="'event-list-item__button--' + event.userInvitee.decision"
        type="button"
        @click.stop="changeDecision"
      >
        <icon :icon="icon" />
      </button>
    </div>
  </div>
</template>

<script>
import { isBusy } from '@/utils/date'
import {
  DECISION_PRESENT,
  DECISION_NOT_PRESENT,
  DECISION_MAYBE,
  DECISION_UNKNOWN
} from '@/utils/constants'

export default {
  name: 'EventListItem',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      active: isBusy(this.event.startDateTime, this.event.endDateTime)
    }
  },
  computed: {
    icon() {
      return {
        [DECISION_PRESENT]: 'check',
        [DECISION_NOT_PRESENT]: 'times',
        [DECISION_MAYBE]: 'question',
        [DECISION_UNKNOWN]: 'ellipsis-h'
      }[this.event.userInvitee.decision]
    },
    suffix() {
      return {
        [DECISION_PRESENT]: 'present',
        [DECISION_NOT_PRESENT]: 'not-present',
        [DECISION_MAYBE]: 'maybe',
        [DECISION_UNKNOWN]: 'unknown'
      }[this.event.userInvitee.decision]
    }
  },
  methods: {
    changeDecision() {
      const currentDecision = this.event.userInvitee.decision

      let newDecision =
        currentDecision === DECISION_PRESENT ? DECISION_NOT_PRESENT : DECISION_PRESENT

      // override setting to PRESENT if the event is full, then we toggle between maybe and not-present
      if (newDecision === DECISION_PRESENT && this.event.isFull) {
        if (currentDecision === DECISION_MAYBE) {
          newDecision = DECISION_NOT_PRESENT
        } else {
          newDecision = DECISION_MAYBE
        }
      }

      this.$store.dispatch('setDecision', {
        eventId: this.event.id,
        decision: newDecision,
        comment: this.event.userInvitee.comment
      })
    }
  }
}
</script>

<style lang="scss">
.event-list-item {
  position: relative;

  &__wrapper {
    display: flex;
    align-items: center;
    height: 80px;
    background: $white;
    cursor: pointer;
    position: relative;
    padding-right: 60px;

    &--highlighted {
      color: $blue;
    }

    &--inactive {
      opacity: 0.5;
    }

    &::before {
      content: '';
      background-color: $primary;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 8px;
      opacity: 0;
    }

    @include xs-only {
      &:active::before {
        opacity: 0.4;
      }
    }

    @include sm-up {
      padding-right: 80px;
      &:hover::before {
        opacity: 0.4;
      }
    }
  }

  &:not(:first-child) &__wrapper {
    border-top: 1px solid $gray-200;
  }

  &__date-container,
  &__button-container {
    width: 60px;

    @include sm-up {
      width: 80px;
    }
  }

  &__history-icon {
    &--present {
      color: $green;
    }
    &--not-present {
      color: $grimson;
    }
    &--maybe {
      color: $orange;
    }
    &--unknown {
      color: $gray-400;
    }
  }

  &__date-container {
    @include center-content;
    flex-direction: column;
  }

  &__day-of-week {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 3px;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
  }

  &__day-of-month {
    font-size: 20px;
    line-height: 1;
    font-weight: $font-weight-semi-bold;
  }

  &__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    margin: 0;

    @include sm-up {
      font-size: 16px;
    }
  }

  &__meta {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    font-size: $font-size-sm;
    color: $gray-500;

    .event-list-item--highlighted & {
      color: $blue;
    }
  }

  &__text-container {
    flex: 1;
    min-width: 0;

    &--inactive * {
      text-decoration: line-through;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__button {
    width: 28px;
    height: 28px;
    font-size: 12px;
    border-radius: 50%;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    @include button-unstyled;

    @include sm-up {
      width: 36px;
      height: 36px;
      font-size: 16px;
    }

    &--present {
      background-color: $green;

      @include hover-focus {
        background-color: lighten($green, 5%);
      }
    }
    &--not-present {
      background-color: $grimson;

      @include hover-focus {
        background-color: lighten($grimson, 10%);
      }
    }

    &--maybe {
      background-color: $orange;

      @include hover-focus {
        background-color: lighten($orange, 5%);
      }
    }

    &--unknown {
      background-color: $gray-400;

      @include hover-focus {
        background-color: lighten($gray-400, 3%);
      }
    }
  }
}
</style>
