<template>
  <div>
    <div class="wizard__section">
      <h1 class="h4 mb-5 text-center">{{ $t('onboarding.upload_avatar.title') }}</h1>
      <avatar-uploader :size="250" class="m-auto" />
    </div>
    <div class="wizard__footer">
      <div class="wizard__actions">
        <router-link :to="{ name: 'CompleteProfile' }" class="btn-link"
          ><icon :icon="['fal', 'arrow-left']" class="btn__icon--left" />{{
            $t('general.buttons.back')
          }}</router-link
        >
        <router-link :to="{ name: 'IntroGateway' }" class="btn btn-secondary" v-if="hasAvatar"
          >{{ $t('onboarding.upload_avatar.submit') }}
          <icon :icon="['fal', 'arrow-right']" class="btn__icon--right"
        /></router-link>
        <router-link :to="{ name: 'IntroGateway' }" class="btn btn-outline" v-else
          >{{ $t('onboarding.upload_avatar.skip') }}
          <icon :icon="['fal', 'arrow-right']" class="btn__icon--right"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarUploader from '@/components/AvatarUploader'
import { mapGetters } from 'vuex'
export default {
  name: 'UploadAvatar',
  components: {
    AvatarUploader
  },
  computed: {
    hasAvatar() {
      return !!this.profile.avatar_large
    },
    ...mapGetters({
      profile: 'profile'
    })
  }
}
</script>
