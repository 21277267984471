<template>
  <p>
    {{ $t('events.general.your_decision') }}:
    <span :class="'history-event-decision--' + this.decision">{{ decisionLabel }}</span>
  </p>
</template>

<script>
import {
  DECISION_PRESENT,
  DECISION_NOT_PRESENT,
  DECISION_MAYBE,
  DECISION_UNKNOWN
} from '@/utils/constants'

export default {
  name: 'HistoryEventDecision',
  props: {
    decision: {
      type: String,
      required: true
    }
  },
  computed: {
    decisionLabel() {
      return {
        [DECISION_PRESENT]: this.$t('events.general.decision_present'),
        [DECISION_NOT_PRESENT]: this.$t('events.general.decision_not_present'),
        [DECISION_MAYBE]: this.$t('events.general.decision_maybe'),
        [DECISION_UNKNOWN]: this.$t('events.general.decision_unknown')
      }[this.decision]
    }
  }
}
</script>

<style lang="scss">
.history-event-decision {
  &--present {
    color: $green;
  }
  &--not-present {
    color: $grimson;
  }
  &--maybe {
    color: $orange;
  }
  &--unknown {
    color: $gray-400;
  }
}
</style>
