import i18n from '@/i18n'

const types = {
  nl: [
    { id: 1, label: 'Sport' },
    { id: 2, label: 'Vrienden' },
    { id: 3, label: "Collega's" },
    { id: 4, label: 'Familie' },
    { id: 5, label: 'Anders' }
  ],
  en: [
    { id: 1, label: 'Sports' },
    { id: 2, label: 'Friends' },
    { id: 3, label: 'Colleagues' },
    { id: 4, label: 'Family' },
    { id: 5, label: 'Other' }
  ]
}

export default function getTypes() {
  return types[i18n.locale]
}
