export function getMetaForRoute(field, route) {
  const match = route.matched // get all route-objects matched to the given route
    .slice() // copy the array so we dont reverse the original
    .reverse() // reverse the array so we find the most specific route first
    .find((m) => m.meta && m.meta[field]) // get the first route that specifies the requested meta field

  if (!match) return null

  const meta = match.meta[field]

  return typeof meta === 'function' ? meta() : meta
}
