<template>
  <wizard
    :title="$t('events.invitation.title')"
    @close="$router.push({ name: 'Home' })"
    :hasHero="invitation && invitation.event.event_photo_large"
  >
    <div class="text-center">
      <transition-fade>
        <div v-if="invitation">
          <div v-if="invitation.event.event_photo_large" class="event-invitation__photo-header">
            <img :src="invitation.event.event_photo_large" />
          </div>
          <div v-else class="event-invitation__header"></div>
          <div class="event-invitation__body">
            <user-avatar :user="invitation.inviter" :size="120" class="event-invitation__avatar" />
            <div class="wizard__section">
              <i18n path="events.invitation.text" tag="p" class="text-lg">
                <template v-slot:name
                  ><strong>{{ invitation.inviter.first_name }}</strong></template
                >
              </i18n>
              <h2 class="mt-3 mb-3">{{ invitation.event.name }}</h2>
              <div v-if="invitationDescription" class="mb-3">
                <p class="m-0 white-space-preline" v-html="invitationDescription" />
              </div>
              <div class="mb-2">
                <icon :icon="['fal', 'clock']" class="mr-2" />
                <span>{{
                  invitation.event.startDateTime.format($t('general.datetime_format.long'))
                }}</span>
              </div>
              <div class="mb-2" v-if="invitation.event.location.name">
                <icon :icon="['fal', 'map-marker-alt']" class="mr-2" />
                <span v-if="hasRoute" style="cursor: pointer" @click="onRoutePress">{{
                  invitation.event.location.name
                }}</span>
                <span v-else>{{ invitation.event.location.name }}</span>
              </div>
              <div>
                <icon :icon="['fal', 'users']" class="mr-2" />
                <span v-if="invitation.event.maxAttendees == 0">{{
                  $tc('events.details.turn_out.present', invitation.event.countAttending, {
                    count: invitation.event.countAttending
                  })
                }}</span>
                <span v-else>{{
                  $tc('events.details.turn_out.spots_taken', maxAttendees, {
                    count: invitation.event.countAttending,
                    maxAttendees: invitation.event.maxAttendees
                  })
                }}</span>
              </div>
              <div class="mt-4" v-if="showButtons">
                <button
                  :disabled="invitation.event.isFull"
                  class="btn btn-outline-primary mr-3"
                  @click="invitation.event.isFull ? false : respond(DECISION_PRESENT)"
                >
                  {{ $t('events.general.decision_present') }}
                </button>
                <button class="btn btn-outline-warning mr-3" @click="respond(DECISION_MAYBE)">
                  {{ $t('events.general.decision_maybe') }}
                </button>
                <button class="btn btn-outline-secondary" @click="respond(DECISION_NOT_PRESENT)">
                  {{ $t('events.general.decision_not_present') }}
                </button>
              </div>
              <div class="mt-4 alert alert-info d-sm-inline-block" v-if="showCanceledMessage">
                {{ $t('errors.events.load_invitation_is_canceled') }}
              </div>
              <div class="mt-4 alert alert-info d-sm-inline-block" v-if="showExpiredMessage">
                {{ $t('errors.events.load_invitation_has_expired') }}
              </div>
              <div class="mt-4 alert alert-info d-sm-inline-block" v-if="showIsFullMessage">
                {{ $t('errors.events.load_invitation_is_full') }}
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="error" class="wizard__section">
          <h3>{{ $t('events.invitation.code_error.title') }}</h3>
          <p class="mt-3 mb-0">{{ error }}</p>
        </div>
        <spinner :size="50" v-else />
      </transition-fade>
    </div>
  </wizard>
</template>

<script>
import { mapGetters } from 'vuex'

import Spinner from '@/components/Spinner'
import TransitionFade from '@/components/TransitionFade'
import UserAvatar from '@/components/UserAvatar'
import Wizard from '@/components/Wizard'
import linkify from 'linkifyjs/html'

import {
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT,
  DECISION_UNKNOWN,
  INVITATION_TYPE_EVENT
} from '@/utils/constants'

export default {
  name: 'EventInvitation',
  components: {
    Wizard,
    Spinner,
    TransitionFade,
    UserAvatar
  },
  data() {
    return {
      DECISION_PRESENT,
      DECISION_MAYBE,
      DECISION_NOT_PRESENT,
      hasAccepted: true,
      invitation: null,
      error: null
    }
  },
  created() {
    const { code } = this.$route.query

    if (!code) {
      this.error = this.$t('events.invitation.code_error.text')
    } else if (this.isAuthenticated) {
      this.$store
        .dispatch('convertEventInvitation', { code })
        .then((invitation) => {
          if (invitation.event.userInvitee.decision === DECISION_UNKNOWN) {
            this.invitation = invitation
          } else {
            this.$router.push({
              name: 'EventDetails',
              params: { eventId: invitation.event.id }
            })
          }
        })
        .catch((error) => (this.error = error.message))
    } else {
      this.$store
        .dispatch('loadEventInvitation', { code })
        .then((invitation) => (this.invitation = invitation))
        .catch((error) => (this.error = error.message))
    }
  },
  methods: {
    respond(decision) {
      if (this.isAuthenticated) {
        const eventId = this.invitation.event.id

        this.$store.dispatch('setDecision', { eventId, decision }).then(() => {
          const params = { eventId }
          this.$router.replace({ name: 'EventDetails', params })
        })
      } else {
        // In case of an anonynmous user, we save the invitation details in localstorage
        // so that it can be redeemed in the EventInvitationAuth screen or after a user has signed in with social
        const invitationObj = {
          type: INVITATION_TYPE_EVENT,
          decision,
          code: this.$route.query.code
        }

        localStorage.setItem('invitation', JSON.stringify(invitationObj))
        this.$router.push({ name: 'EventInvitationAuth' })
      }
    },
    onRoutePress() {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${this.destination}`
      window.open(url)
    }
  },
  computed: {
    destination() {
      const { address, name } = this.invitation.event.location
      return address || name
    },
    hasRoute() {
      return this.invitation.event.location.gps
    },
    attendingInvitees() {
      return this.invitation.event.invitees
        .filter(
          (invitee) => invitee.decision === DECISION_PRESENT || invitee.decision === DECISION_MAYBE
        )
        .map((invitee) => invitee.user)
    },
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    }),
    showButtons() {
      // to be able to show buttons, the invitation should be valid
      return !this.invitation.hasExpired && !this.invitation.event.isCanceled
    },
    showCanceledMessage() {
      // show message when event is canceled
      return this.invitation.event.isCanceled
    },
    showIsFullMessage() {
      // show message that there are no more spots left, except when the invitation is expired or canceled
      return (
        this.invitation.event.isFull &
        !this.invitation.hasExpired &
        !this.invitation.event.isCanceled
      )
    },
    showExpiredMessage() {
      // show expired message when it's expired, except when it's canceled, then we show the cancel message
      return this.invitation.hasExpired & !this.invitation.event.isCanceled
    },
    invitationDescription() {
      return linkify(this.invitation.event.description.replace(/(<([^>]+)>)/gi, ''))
    }
  }
}
</script>

<style lang="scss">
.event-invitation {
  &__header {
    height: 150px;
    background-color: $gray-200;

    @include xs-fluid;
  }

  &__photo-header {
    padding-bottom: percentage(3 / 4);

    @include md-up {
      padding-bottom: percentage(9 / 16);
    }

    overflow: hidden;
    height: 0;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include xs-only {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 35%);
      }
    }

    @include xs-fluid;
  }

  &__avatar {
    margin: -62px auto 0;
  }

  &__body {
    position: relative;
  }
}

@include xs-only {
  .wizard__title {
    display: none;
  }
}
</style>
