// eslint-disable-next-line max-len

export default [
  {
    label: 'Niuese tijd',
    value: 'Pacific/Niue',
    offset: -11
  },
  {
    label: 'Samoaanse standaardtijd',
    value: 'Pacific/Pago_Pago',
    offset: -11
  },
  {
    label: 'Cookeilandse standaardtijd',
    value: 'Pacific/Rarotonga',
    offset: -10
  },
  {
    label: 'Hawaii-Aleoetische standaardtijd',
    value: 'Pacific/Honolulu',
    offset: -10
  },
  {
    label: 'Hawaii-Aleoetische tijd',
    value: 'America/Adak',
    offset: -10
  },
  {
    label: 'Tahitiaanse tijd',
    value: 'Pacific/Tahiti',
    offset: -10
  },
  {
    label: 'Marquesaseilandse tijd',
    value: 'Pacific/Marquesas',
    offset: -9.5
  },
  {
    label: 'Alaska-tijd - Anchorage',
    value: 'America/Anchorage',
    offset: -9
  },
  {
    label: 'Alaska-tijd - Juneau',
    value: 'America/Juneau',
    offset: -9
  },
  {
    label: 'Alaska-tijd - Nome',
    value: 'America/Nome',
    offset: -9
  },
  {
    label: 'Alaska-tijd - Sitka',
    value: 'America/Sitka',
    offset: -9
  },
  {
    label: 'Alaska-tijd - Yakutat',
    value: 'America/Yakutat',
    offset: -9
  },
  {
    label: 'Gambiereilandse tijd',
    value: 'Pacific/Gambier',
    offset: -9
  },
  {
    label: 'Pacific-tijd - Dawson',
    value: 'America/Dawson',
    offset: -8
  },
  {
    label: 'Pacific-tijd - Los Angeles',
    value: 'America/Los_Angeles',
    offset: -8
  },
  {
    label: 'Pacific-tijd - Metlakatla',
    value: 'America/Metlakatla',
    offset: -8
  },
  {
    label: 'Pacific-tijd - Tijuana',
    value: 'America/Tijuana',
    offset: -8
  },
  {
    label: 'Pacific-tijd - Vancouver',
    value: 'America/Vancouver',
    offset: -8
  },
  {
    label: 'Pacific-tijd - Whitehorse',
    value: 'America/Whitehorse',
    offset: -8
  },
  {
    label: 'Pitcairneilandse tijd',
    value: 'Pacific/Pitcairn',
    offset: -8
  },
  {
    label: 'Mexicaanse Pacific-standaardtijd',
    value: 'America/Hermosillo',
    offset: -7
  },
  {
    label: 'Mexicaanse Pacific-tijd - Chihuahua',
    value: 'America/Chihuahua',
    offset: -7
  },
  {
    label: 'Mexicaanse Pacific-tijd - Mazatlán',
    value: 'America/Mazatlan',
    offset: -7
  },
  {
    label: 'Mountain-standaardtijd - Creston',
    value: 'America/Creston',
    offset: -7
  },
  {
    label: 'Mountain-standaardtijd - Dawson Creek',
    value: 'America/Dawson_Creek',
    offset: -7
  },
  {
    label: 'Mountain-standaardtijd - Fort Nelson',
    value: 'America/Fort_Nelson',
    offset: -7
  },
  {
    label: 'Mountain-standaardtijd - Phoenix',
    value: 'America/Phoenix',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Boise',
    value: 'America/Boise',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Cambridge Bay',
    value: 'America/Cambridge_Bay',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Denver',
    value: 'America/Denver',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Edmonton',
    value: 'America/Edmonton',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Inuvik',
    value: 'America/Inuvik',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Ojinaga',
    value: 'America/Ojinaga',
    offset: -7
  },
  {
    label: 'Mountain-tijd - Yellowknife',
    value: 'America/Yellowknife',
    offset: -7
  },
  {
    label: 'Central-standaardtijd - Belize',
    value: 'America/Belize',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - Costa Rica',
    value: 'America/Costa_Rica',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - El Salvador',
    value: 'America/El_Salvador',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - Guatemala',
    value: 'America/Guatemala',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - Managua',
    value: 'America/Managua',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - Regina',
    value: 'America/Regina',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - Swift Current',
    value: 'America/Swift_Current',
    offset: -6
  },
  {
    label: 'Central-standaardtijd - Tegucigalpa',
    value: 'America/Tegucigalpa',
    offset: -6
  },
  {
    label: 'Central-tijd - Bahía de Banderas',
    value: 'America/Bahia_Banderas',
    offset: -6
  },
  {
    label: 'Central-tijd - Beulah, Noord-Dakota',
    value: 'America/North_Dakota/Beulah',
    offset: -6
  },
  {
    label: 'Central-tijd - Center, Noord-Dakota',
    value: 'America/North_Dakota/Center',
    offset: -6
  },
  {
    label: 'Central-tijd - Chicago',
    value: 'America/Chicago',
    offset: -6
  },
  {
    label: 'Central-tijd - Knox, Indiana',
    value: 'America/Indiana/Knox',
    offset: -6
  },
  {
    label: 'Central-tijd - Matamoros',
    value: 'America/Matamoros',
    offset: -6
  },
  {
    label: 'Central-tijd - Menominee',
    value: 'America/Menominee',
    offset: -6
  },
  {
    label: 'Central-tijd - Mérida',
    value: 'America/Merida',
    offset: -6
  },
  {
    label: 'Central-tijd - Mexico-Stad',
    value: 'America/Mexico_City',
    offset: -6
  },
  {
    label: 'Central-tijd - Monterrey',
    value: 'America/Monterrey',
    offset: -6
  },
  {
    label: 'Central-tijd - New Salem, Noord-Dakota',
    value: 'America/North_Dakota/New_Salem',
    offset: -6
  },
  {
    label: 'Central-tijd - Rainy River',
    value: 'America/Rainy_River',
    offset: -6
  },
  {
    label: 'Central-tijd - Rankin Inlet',
    value: 'America/Rankin_Inlet',
    offset: -6
  },
  {
    label: 'Central-tijd - Resolute',
    value: 'America/Resolute',
    offset: -6
  },
  {
    label: 'Central-tijd - Tell City, Indiana',
    value: 'America/Indiana/Tell_City',
    offset: -6
  },
  {
    label: 'Central-tijd - Winnipeg',
    value: 'America/Winnipeg',
    offset: -6
  },
  {
    label: 'Galapagoseilandse standaardtijd',
    value: 'Pacific/Galapagos',
    offset: -6
  },
  {
    label: 'Acre-standaardtijd - Eirunepe',
    value: 'America/Eirunepe',
    offset: -5
  },
  {
    label: 'Acre-standaardtijd - Rio Branco',
    value: 'America/Rio_Branco',
    offset: -5
  },
  {
    label: 'Colombiaanse standaardtijd',
    value: 'America/Bogota',
    offset: -5
  },
  {
    label: 'Cubaanse tijd',
    value: 'America/Havana',
    offset: -5
  },
  {
    label: 'Eastern-standaardtijd - Atikokan',
    value: 'America/Atikokan',
    offset: -5
  },
  {
    label: 'Eastern-standaardtijd - Cancun',
    value: 'America/Cancun',
    offset: -5
  },
  {
    label: 'Eastern-standaardtijd - Jamaica',
    value: 'America/Jamaica',
    offset: -5
  },
  {
    label: 'Eastern-standaardtijd - Panama',
    value: 'America/Panama',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Detroit',
    value: 'America/Detroit',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Grand Turk',
    value: 'America/Grand_Turk',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Indianapolis',
    value: 'America/Indiana/Indianapolis',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Iqaluit',
    value: 'America/Iqaluit',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Louisville',
    value: 'America/Kentucky/Louisville',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Marengo, Indiana',
    value: 'America/Indiana/Marengo',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Monticello, Kentucky',
    value: 'America/Kentucky/Monticello',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Nassau',
    value: 'America/Nassau',
    offset: -5
  },
  {
    label: 'Eastern-tijd - New York',
    value: 'America/New_York',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Nipigon',
    value: 'America/Nipigon',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Pangnirtung',
    value: 'America/Pangnirtung',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Petersburg, Indiana',
    value: 'America/Indiana/Petersburg',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Port-au-Prince',
    value: 'America/Port-au-Prince',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Thunder Bay',
    value: 'America/Thunder_Bay',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Toronto',
    value: 'America/Toronto',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Vevay, Indiana',
    value: 'America/Indiana/Vevay',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Vincennes, Indiana',
    value: 'America/Indiana/Vincennes',
    offset: -5
  },
  {
    label: 'Eastern-tijd - Winamac, Indiana',
    value: 'America/Indiana/Winamac',
    offset: -5
  },
  {
    label: 'Ecuadoraanse tijd',
    value: 'America/Guayaquil',
    offset: -5
  },
  {
    label: 'Paaseilandse tijd',
    value: 'Pacific/Easter',
    offset: -5
  },
  {
    label: 'Peruaanse standaardtijd',
    value: 'America/Lima',
    offset: -5
  },
  {
    label: 'Amazone-standaardtijd - Boa Vista',
    value: 'America/Boa_Vista',
    offset: -4
  },
  {
    label: 'Amazone-standaardtijd - Manaus',
    value: 'America/Manaus',
    offset: -4
  },
  {
    label: 'Amazone-standaardtijd - Porto Velho',
    value: 'America/Porto_Velho',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Barbados',
    value: 'America/Barbados',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Blanc-Sablon',
    value: 'America/Blanc-Sablon',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Curaçao',
    value: 'America/Curacao',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Martinique',
    value: 'America/Martinique',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Port of Spain',
    value: 'America/Port_of_Spain',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Puerto Rico',
    value: 'America/Puerto_Rico',
    offset: -4
  },
  {
    label: 'Atlantic-standaardtijd - Santo Domingo',
    value: 'America/Santo_Domingo',
    offset: -4
  },
  {
    label: 'Atlantic-tijd - Bermuda',
    value: 'Atlantic/Bermuda',
    offset: -4
  },
  {
    label: 'Atlantic-tijd - Glace Bay',
    value: 'America/Glace_Bay',
    offset: -4
  },
  {
    label: 'Atlantic-tijd - Goose Bay',
    value: 'America/Goose_Bay',
    offset: -4
  },
  {
    label: 'Atlantic-tijd - Halifax',
    value: 'America/Halifax',
    offset: -4
  },
  {
    label: 'Atlantic-tijd - Moncton',
    value: 'America/Moncton',
    offset: -4
  },
  {
    label: 'Atlantic-tijd - Thule',
    value: 'America/Thule',
    offset: -4
  },
  {
    label: 'Boliviaanse tijd',
    value: 'America/La_Paz',
    offset: -4
  },
  {
    label: 'Guyaanse tijd',
    value: 'America/Guyana',
    offset: -4
  },
  {
    label: 'Venezolaanse tijd',
    value: 'America/Caracas',
    offset: -4
  },
  {
    label: 'Newfoundland-tijd',
    value: 'America/St_Johns',
    offset: -3.5
  },
  {
    label: 'Amazone-tijd (Campo Grande)',
    value: 'America/Campo_Grande',
    offset: -3
  },
  {
    label: 'Amazone-tijd (Cuiabá)',
    value: 'America/Cuiaba',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Catamarca',
    value: 'America/Argentina/Catamarca',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Córdoba',
    value: 'America/Argentina/Cordoba',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Jujuy',
    value: 'America/Argentina/Jujuy',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - La Rioja',
    value: 'America/Argentina/La_Rioja',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Mendoza',
    value: 'America/Argentina/Mendoza',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Río Gallegos',
    value: 'America/Argentina/Rio_Gallegos',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Salta',
    value: 'America/Argentina/Salta',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - San Juan',
    value: 'America/Argentina/San_Juan',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Tucumán',
    value: 'America/Argentina/Tucuman',
    offset: -3
  },
  {
    label: 'Argentijnse standaardtijd - Ushuaia',
    value: 'America/Argentina/Ushuaia',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Araguaina',
    value: 'America/Araguaina',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Bahia',
    value: 'America/Bahia',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Belém',
    value: 'America/Belem',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Fortaleza',
    value: 'America/Fortaleza',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Maceió',
    value: 'America/Maceio',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Recife',
    value: 'America/Recife',
    offset: -3
  },
  {
    label: 'Braziliaanse standaardtijd - Santarem',
    value: 'America/Santarem',
    offset: -3
  },
  {
    label: 'Chileense tijd',
    value: 'America/Santiago',
    offset: -3
  },
  {
    label: 'Falklandeilandse standaardtijd',
    value: 'Atlantic/Stanley',
    offset: -3
  },
  {
    label: 'Frans-Guyaanse tijd',
    value: 'America/Cayenne',
    offset: -3
  },
  {
    label: 'Palmer-tijd',
    value: 'Antarctica/Palmer',
    offset: -3
  },
  {
    label: 'Paraguayaanse tijd',
    value: 'America/Asuncion',
    offset: -3
  },
  {
    label: 'Punta Arenas-tijd',
    value: 'America/Punta_Arenas',
    offset: -3
  },
  {
    label: 'Rothera-tijd',
    value: 'Antarctica/Rothera',
    offset: -3
  },
  {
    label: 'Saint Pierre en Miquelon-tijd',
    value: 'America/Miquelon',
    offset: -3
  },
  {
    label: 'Surinaamse tijd',
    value: 'America/Paramaribo',
    offset: -3
  },
  {
    label: 'Uruguayaanse standaardtijd',
    value: 'America/Montevideo',
    offset: -3
  },
  {
    label: 'West-Argentijnse standaardtijd',
    value: 'America/Argentina/San_Luis',
    offset: -3
  },
  {
    label: 'West-Groenlandse tijd',
    value: 'America/Godthab',
    offset: -3
  },
  {
    label: 'Braziliaanse tijd',
    value: 'America/Sao_Paulo',
    offset: -2
  },
  {
    label: 'Fernando de Noronha-standaardtijd',
    value: 'America/Noronha',
    offset: -2
  },
  {
    label: 'Zuid-Georgische tijd',
    value: 'Atlantic/South_Georgia',
    offset: -2
  },
  {
    label: 'Azoren-tijd',
    value: 'Atlantic/Azores',
    offset: -1
  },
  {
    label: 'Kaapverdische standaardtijd',
    value: 'Atlantic/Cape_Verde',
    offset: -1
  },
  {
    label: 'Oost-Groenlandse tijd',
    value: 'America/Scoresbysund',
    offset: -1
  },
  {
    label: 'Gecoördineerde wereldtijd',
    value: 'UTC',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time',
    value: 'GMT',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Abidjan',
    value: 'Africa/Abidjan',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Accra',
    value: 'Africa/Accra',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Bissau',
    value: 'Africa/Bissau',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Danmarkshavn',
    value: 'America/Danmarkshavn',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Monrovia',
    value: 'Africa/Monrovia',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Reykjavik',
    value: 'Atlantic/Reykjavik',
    offset: 0
  },
  {
    label: 'Greenwich Mean Time - Sao Tomé',
    value: 'Africa/Sao_Tome',
    offset: 0
  },
  {
    label: 'Ierland-tijd',
    value: 'Europe/Dublin',
    offset: 0
  },
  {
    label: 'Troll-tijd',
    value: 'Antarctica/Troll',
    offset: 0
  },
  {
    label: 'Verenigd Koninkrijk-tijd',
    value: 'Europe/London',
    offset: 0
  },
  {
    label: 'West-Europese tijd - Canarische Eilanden',
    value: 'Atlantic/Canary',
    offset: 0
  },
  {
    label: 'West-Europese tijd - Faeröer',
    value: 'Atlantic/Faroe',
    offset: 0
  },
  {
    label: 'West-Europese tijd - Lissabon',
    value: 'Europe/Lisbon',
    offset: 0
  },
  {
    label: 'West-Europese tijd - Madeira',
    value: 'Atlantic/Madeira',
    offset: 0
  },
  {
    label: 'Marokko-tijd',
    value: 'Africa/Casablanca',
    offset: 1
  },
  {
    label: 'Midden-Europese standaardtijd - Algiers',
    value: 'Africa/Algiers',
    offset: 1
  },
  {
    label: 'Midden-Europese standaardtijd - Tunis',
    value: 'Africa/Tunis',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Amsterdam',
    value: 'Europe/Amsterdam',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Andorra',
    value: 'Europe/Andorra',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Belgrado',
    value: 'Europe/Belgrade',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Berlijn',
    value: 'Europe/Berlin',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Boedapest',
    value: 'Europe/Budapest',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Brussel',
    value: 'Europe/Brussels',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Ceuta',
    value: 'Africa/Ceuta',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Gibraltar',
    value: 'Europe/Gibraltar',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Kopenhagen',
    value: 'Europe/Copenhagen',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Luxemburg',
    value: 'Europe/Luxembourg',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Madrid',
    value: 'Europe/Madrid',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Malta',
    value: 'Europe/Malta',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Monaco',
    value: 'Europe/Monaco',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Oslo',
    value: 'Europe/Oslo',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Parijs',
    value: 'Europe/Paris',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Praag',
    value: 'Europe/Prague',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Rome',
    value: 'Europe/Rome',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Stockholm',
    value: 'Europe/Stockholm',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Tirana',
    value: 'Europe/Tirane',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Warschau',
    value: 'Europe/Warsaw',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Wenen',
    value: 'Europe/Vienna',
    offset: 1
  },
  {
    label: 'Midden-Europese tijd - Zürich',
    value: 'Europe/Zurich',
    offset: 1
  },
  {
    label: 'West-Afrikaanse standaardtijd - Lagos',
    value: 'Africa/Lagos',
    offset: 1
  },
  {
    label: 'West-Afrikaanse standaardtijd - Ndjamena',
    value: 'Africa/Ndjamena',
    offset: 1
  },
  {
    label: 'Westelijke Sahara-tijd',
    value: 'Africa/El_Aaiun',
    offset: 1
  },
  {
    label: 'Centraal-Afrikaanse tijd - Khartoem',
    value: 'Africa/Khartoum',
    offset: 2
  },
  {
    label: 'Centraal-Afrikaanse tijd - Maputo',
    value: 'Africa/Maputo',
    offset: 2
  },
  {
    label: 'Centraal-Afrikaanse tijd - Windhoek',
    value: 'Africa/Windhoek',
    offset: 2
  },
  {
    label: 'Famagusta-tijd',
    value: 'Asia/Famagusta',
    offset: 2
  },
  {
    label: 'Israëlische tijd',
    value: 'Asia/Jerusalem',
    offset: 2
  },
  {
    label: 'Oost-Europese standaardtijd - Caïro',
    value: 'Africa/Cairo',
    offset: 2
  },
  {
    label: 'Oost-Europese standaardtijd - Kaliningrad',
    value: 'Europe/Kaliningrad',
    offset: 2
  },
  {
    label: 'Oost-Europese standaardtijd - Tripoli',
    value: 'Africa/Tripoli',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Amman',
    value: 'Asia/Amman',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Athene',
    value: 'Europe/Athens',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Beiroet',
    value: 'Asia/Beirut',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Boekarest',
    value: 'Europe/Bucharest',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Chisinau',
    value: 'Europe/Chisinau',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Damascus',
    value: 'Asia/Damascus',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Gaza',
    value: 'Asia/Gaza',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Hebron',
    value: 'Asia/Hebron',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Helsinki',
    value: 'Europe/Helsinki',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Kiev',
    value: 'Europe/Kiev',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Nicosia',
    value: 'Asia/Nicosia',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Oezjhorod',
    value: 'Europe/Uzhgorod',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Riga',
    value: 'Europe/Riga',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Sofia',
    value: 'Europe/Sofia',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Tallinn',
    value: 'Europe/Tallinn',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Vilnius',
    value: 'Europe/Vilnius',
    offset: 2
  },
  {
    label: 'Oost-Europese tijd - Zaporizja',
    value: 'Europe/Zaporozhye',
    offset: 2
  },
  {
    label: 'Zuid-Afrikaanse tijd',
    value: 'Africa/Johannesburg',
    offset: 2
  },
  {
    label: 'Arabische standaardtijd - Bagdad',
    value: 'Asia/Baghdad',
    offset: 3
  },
  {
    label: 'Arabische standaardtijd - Qatar',
    value: 'Asia/Qatar',
    offset: 3
  },
  {
    label: 'Arabische standaardtijd - Riyad',
    value: 'Asia/Riyadh',
    offset: 3
  },
  {
    label: 'Kirov-tijd',
    value: 'Europe/Kirov',
    offset: 3
  },
  {
    label: 'Moskou-standaardtijd - Minsk',
    value: 'Europe/Minsk',
    offset: 3
  },
  {
    label: 'Moskou-standaardtijd - Moskou',
    value: 'Europe/Moscow',
    offset: 3
  },
  {
    label: 'Moskou-standaardtijd - Simferopol',
    value: 'Europe/Simferopol',
    offset: 3
  },
  {
    label: 'Oost-Afrikaanse tijd - Juba',
    value: 'Africa/Juba',
    offset: 3
  },
  {
    label: 'Oost-Afrikaanse tijd - Nairobi',
    value: 'Africa/Nairobi',
    offset: 3
  },
  {
    label: 'Syowa-tijd',
    value: 'Antarctica/Syowa',
    offset: 3
  },
  {
    label: 'Turkije-tijd',
    value: 'Europe/Istanbul',
    offset: 3
  },
  {
    label: 'Iraanse tijd',
    value: 'Asia/Tehran',
    offset: 3.5
  },
  {
    label: 'Armeense standaardtijd',
    value: 'Asia/Yerevan',
    offset: 4
  },
  {
    label: 'Astrakhan-tijd',
    value: 'Europe/Astrakhan',
    offset: 4
  },
  {
    label: 'Azerbeidzjaanse standaardtijd',
    value: 'Asia/Baku',
    offset: 4
  },
  {
    label: 'Georgische standaardtijd',
    value: 'Asia/Tbilisi',
    offset: 4
  },
  {
    label: 'Golf-standaardtijd',
    value: 'Asia/Dubai',
    offset: 4
  },
  {
    label: 'Mauritiaanse standaardtijd',
    value: 'Indian/Mauritius',
    offset: 4
  },
  {
    label: 'Réunionse tijd',
    value: 'Indian/Reunion',
    offset: 4
  },
  {
    label: 'Samara-standaardtijd',
    value: 'Europe/Samara',
    offset: 4
  },
  {
    label: 'Saratov-tijd',
    value: 'Europe/Saratov',
    offset: 4
  },
  {
    label: 'Seychelse tijd',
    value: 'Indian/Mahe',
    offset: 4
  },
  {
    label: 'Ulyanovsk-tijd',
    value: 'Europe/Ulyanovsk',
    offset: 4
  },
  {
    label: 'Wolgograd-standaardtijd',
    value: 'Europe/Volgograd',
    offset: 4
  },
  {
    label: 'Afghaanse tijd',
    value: 'Asia/Kabul',
    offset: 4.5
  },
  {
    label: 'Franse zuidelijke en Antarctische tijd',
    value: 'Indian/Kerguelen',
    offset: 5
  },
  {
    label: 'Jekaterinenburg-standaardtijd',
    value: 'Asia/Yekaterinburg',
    offset: 5
  },
  {
    label: 'Maldivische tijd',
    value: 'Indian/Maldives',
    offset: 5
  },
  {
    label: 'Mawson-tijd',
    value: 'Antarctica/Mawson',
    offset: 5
  },
  {
    label: 'Oezbeekse standaardtijd - Samarkand',
    value: 'Asia/Samarkand',
    offset: 5
  },
  {
    label: 'Oezbeekse standaardtijd - Tasjkent',
    value: 'Asia/Tashkent',
    offset: 5
  },
  {
    label: 'Pakistaanse standaardtijd',
    value: 'Asia/Karachi',
    offset: 5
  },
  {
    label: 'Tadzjiekse tijd',
    value: 'Asia/Dushanbe',
    offset: 5
  },
  {
    label: 'Turkmeense standaardtijd',
    value: 'Asia/Ashgabat',
    offset: 5
  },
  {
    label: 'West-Kazachse tijd - Aqtau',
    value: 'Asia/Aqtau',
    offset: 5
  },
  {
    label: 'West-Kazachse tijd - Aqtöbe',
    value: 'Asia/Aqtobe',
    offset: 5
  },
  {
    label: 'West-Kazachse tijd - Atıraw',
    value: 'Asia/Atyrau',
    offset: 5
  },
  {
    label: 'West-Kazachse tijd - Oral',
    value: 'Asia/Oral',
    offset: 5
  },
  {
    label: 'West-Kazachse tijd - Qyzylorda',
    value: 'Asia/Qyzylorda',
    offset: 5
  },
  {
    label: 'Indiase tijd - Calcutta',
    value: 'Asia/Kolkata',
    offset: 5.5
  },
  {
    label: 'Indiase tijd - Colombo',
    value: 'Asia/Colombo',
    offset: 5.5
  },
  {
    label: 'Nepalese tijd',
    value: 'Asia/Kathmandu',
    offset: 5.75
  },
  {
    label: 'Bengalese standaardtijd',
    value: 'Asia/Dhaka',
    offset: 6
  },
  {
    label: 'Bhutaanse tijd',
    value: 'Asia/Thimphu',
    offset: 6
  },
  {
    label: 'Indische Oceaan-tijd',
    value: 'Indian/Chagos',
    offset: 6
  },
  {
    label: 'Kirgizische tijd',
    value: 'Asia/Bishkek',
    offset: 6
  },
  {
    label: 'Omsk-standaardtijd',
    value: 'Asia/Omsk',
    offset: 6
  },
  {
    label: 'Oost-Kazachse tijd - Alma-Ata',
    value: 'Asia/Almaty',
    offset: 6
  },
  {
    label: 'Urumqi-tijd',
    value: 'Asia/Urumqi',
    offset: 6
  },
  {
    label: 'Vostok-tijd',
    value: 'Antarctica/Vostok',
    offset: 6
  },
  {
    label: 'Cocoseilandse tijd',
    value: 'Indian/Cocos',
    offset: 6.5
  },
  {
    label: 'Myanmarese tijd',
    value: 'Asia/Yangon',
    offset: 6.5
  },
  {
    label: 'Barnaul-tijd',
    value: 'Asia/Barnaul',
    offset: 7
  },
  {
    label: 'Christmaseilandse tijd',
    value: 'Indian/Christmas',
    offset: 7
  },
  {
    label: 'Davis-tijd',
    value: 'Antarctica/Davis',
    offset: 7
  },
  {
    label: 'Hovd-standaardtijd',
    value: 'Asia/Hovd',
    offset: 7
  },
  {
    label: 'Indochinese tijd - Bangkok',
    value: 'Asia/Bangkok',
    offset: 7
  },
  {
    label: 'Indochinese tijd - Ho Chi Minhstad',
    value: 'Asia/Ho_Chi_Minh',
    offset: 7
  },
  {
    label: 'Krasnojarsk-standaardtijd - Krasnojarsk',
    value: 'Asia/Krasnoyarsk',
    offset: 7
  },
  {
    label: 'Krasnojarsk-standaardtijd - Novokuznetsk',
    value: 'Asia/Novokuznetsk',
    offset: 7
  },
  {
    label: 'Novosibirsk-standaardtijd',
    value: 'Asia/Novosibirsk',
    offset: 7
  },
  {
    label: 'Tomsk-tijd',
    value: 'Asia/Tomsk',
    offset: 7
  },
  {
    label: 'West-Indonesische tijd - Jakarta',
    value: 'Asia/Jakarta',
    offset: 7
  },
  {
    label: 'West-Indonesische tijd - Pontianak',
    value: 'Asia/Pontianak',
    offset: 7
  },
  {
    label: 'Bruneise tijd',
    value: 'Asia/Brunei',
    offset: 8
  },
  {
    label: 'Centraal-Indonesische tijd',
    value: 'Asia/Makassar',
    offset: 8
  },
  {
    label: 'Chinese standaardtijd - Macau',
    value: 'Asia/Macau',
    offset: 8
  },
  {
    label: 'Chinese standaardtijd - Sjanghai',
    value: 'Asia/Shanghai',
    offset: 8
  },
  {
    label: 'Filipijnse standaardtijd',
    value: 'Asia/Manila',
    offset: 8
  },
  {
    label: 'Hongkongse standaardtijd',
    value: 'Asia/Hong_Kong',
    offset: 8
  },
  {
    label: 'Irkoetsk-standaardtijd',
    value: 'Asia/Irkutsk',
    offset: 8
  },
  {
    label: 'Maleisische tijd - Kuala Lumpur',
    value: 'Asia/Kuala_Lumpur',
    offset: 8
  },
  {
    label: 'Maleisische tijd - Kuching',
    value: 'Asia/Kuching',
    offset: 8
  },
  {
    label: 'Singaporese standaardtijd',
    value: 'Asia/Singapore',
    offset: 8
  },
  {
    label: 'Taipei-standaardtijd',
    value: 'Asia/Taipei',
    offset: 8
  },
  {
    label: 'Tsjojbalsan-standaardtijd',
    value: 'Asia/Choibalsan',
    offset: 8
  },
  {
    label: 'Ulaanbaatar-standaardtijd',
    value: 'Asia/Ulaanbaatar',
    offset: 8
  },
  {
    label: 'West-Australische standaardtijd - Casey',
    value: 'Antarctica/Casey',
    offset: 8
  },
  {
    label: 'West-Australische standaardtijd - Perth',
    value: 'Australia/Perth',
    offset: 8
  },
  {
    label: 'Midden-Australische westelijke standaardtijd',
    value: 'Australia/Eucla',
    offset: 8.75
  },
  {
    label: 'Belause tijd',
    value: 'Pacific/Palau',
    offset: 9
  },
  {
    label: 'Jakoetsk-standaardtijd - Chita',
    value: 'Asia/Chita',
    offset: 9
  },
  {
    label: 'Jakoetsk-standaardtijd - Jakoetsk',
    value: 'Asia/Yakutsk',
    offset: 9
  },
  {
    label: 'Jakoetsk-standaardtijd - Khandyga',
    value: 'Asia/Khandyga',
    offset: 9
  },
  {
    label: 'Japanse standaardtijd',
    value: 'Asia/Tokyo',
    offset: 9
  },
  {
    label: 'Koreaanse standaardtijd - Pyongyang',
    value: 'Asia/Pyongyang',
    offset: 9
  },
  {
    label: 'Koreaanse standaardtijd - Seoul',
    value: 'Asia/Seoul',
    offset: 9
  },
  {
    label: 'Oost-Indonesische tijd',
    value: 'Asia/Jayapura',
    offset: 9
  },
  {
    label: 'Oost-Timorese tijd',
    value: 'Asia/Dili',
    offset: 9
  },
  {
    label: 'Midden-Australische standaardtijd',
    value: 'Australia/Darwin',
    offset: 9.5
  },
  {
    label: 'Chamorro-tijd',
    value: 'Pacific/Guam',
    offset: 10
  },
  {
    label: 'Chuukse tijd',
    value: 'Pacific/Chuuk',
    offset: 10
  },
  {
    label: 'Dumont-d’Urville-tijd',
    value: 'Antarctica/DumontDUrville',
    offset: 10
  },
  {
    label: 'Oost-Australische standaardtijd - Brisbane',
    value: 'Australia/Brisbane',
    offset: 10
  },
  {
    label: 'Oost-Australische standaardtijd - Lindeman',
    value: 'Australia/Lindeman',
    offset: 10
  },
  {
    label: 'Papoea-Nieuw-Guineese tijd',
    value: 'Pacific/Port_Moresby',
    offset: 10
  },
  {
    label: 'Vladivostok-standaardtijd - Ust-Nera',
    value: 'Asia/Ust-Nera',
    offset: 10
  },
  {
    label: 'Vladivostok-standaardtijd - Vladivostok',
    value: 'Asia/Vladivostok',
    offset: 10
  },
  {
    label: 'Midden-Australische tijd - Adelaide',
    value: 'Australia/Adelaide',
    offset: 10.5
  },
  {
    label: 'Midden-Australische tijd - Broken Hill',
    value: 'Australia/Broken_Hill',
    offset: 10.5
  },
  {
    label: 'Bougainville-tijd',
    value: 'Pacific/Bougainville',
    offset: 11
  },
  {
    label: 'Kosraese tijd',
    value: 'Pacific/Kosrae',
    offset: 11
  },
  {
    label: 'Lord Howe-eilandse tijd',
    value: 'Australia/Lord_Howe',
    offset: 11
  },
  {
    label: 'Macquarie-eilandse tijd',
    value: 'Antarctica/Macquarie',
    offset: 11
  },
  {
    label: 'Magadan-standaardtijd',
    value: 'Asia/Magadan',
    offset: 11
  },
  {
    label: 'Nieuw-Caledonische standaardtijd',
    value: 'Pacific/Noumea',
    offset: 11
  },
  {
    label: 'Norfolkeilandse tijd',
    value: 'Pacific/Norfolk',
    offset: 11
  },
  {
    label: 'Oost-Australische tijd - Currie',
    value: 'Australia/Currie',
    offset: 11
  },
  {
    label: 'Oost-Australische tijd - Hobart',
    value: 'Australia/Hobart',
    offset: 11
  },
  {
    label: 'Oost-Australische tijd - Melbourne',
    value: 'Australia/Melbourne',
    offset: 11
  },
  {
    label: 'Oost-Australische tijd - Sydney',
    value: 'Australia/Sydney',
    offset: 11
  },
  {
    label: 'Pohnpei-tijd',
    value: 'Pacific/Pohnpei',
    offset: 11
  },
  {
    label: 'Sachalin-standaardtijd',
    value: 'Asia/Sakhalin',
    offset: 11
  },
  {
    label: 'Salomonseilandse tijd',
    value: 'Pacific/Guadalcanal',
    offset: 11
  },
  {
    label: 'Srednekolymsk-tijd',
    value: 'Asia/Srednekolymsk',
    offset: 11
  },
  {
    label: 'Vanuatuaanse standaardtijd',
    value: 'Pacific/Efate',
    offset: 11
  },
  {
    label: 'Anadyr-standaardtijd',
    value: 'Asia/Anadyr',
    offset: 12
  },
  {
    label: 'Fijische tijd',
    value: 'Pacific/Fiji',
    offset: 12
  },
  {
    label: 'Gilberteilandse tijd',
    value: 'Pacific/Tarawa',
    offset: 12
  },
  {
    label: 'Marshalleilandse tijd - Kwajalein',
    value: 'Pacific/Kwajalein',
    offset: 12
  },
  {
    label: 'Marshalleilandse tijd - Majuro',
    value: 'Pacific/Majuro',
    offset: 12
  },
  {
    label: 'Nauruaanse tijd',
    value: 'Pacific/Nauru',
    offset: 12
  },
  {
    label: 'Petropavlovsk-Kamtsjatski-standaardtijd',
    value: 'Asia/Kamchatka',
    offset: 12
  },
  {
    label: 'Tuvaluaanse tijd',
    value: 'Pacific/Funafuti',
    offset: 12
  },
  {
    label: 'Wake-eilandse tijd',
    value: 'Pacific/Wake',
    offset: 12
  },
  {
    label: 'Wallis en Futunase tijd',
    value: 'Pacific/Wallis',
    offset: 12
  },
  {
    label: 'Nieuw-Zeelandse tijd',
    value: 'Pacific/Auckland',
    offset: 13
  },
  {
    label: 'Phoenixeilandse tijd',
    value: 'Pacific/Enderbury',
    offset: 13
  },
  {
    label: 'Tokelau-eilandse tijd',
    value: 'Pacific/Fakaofo',
    offset: 13
  },
  {
    label: 'Tongaanse standaardtijd',
    value: 'Pacific/Tongatapu',
    offset: 13
  },
  {
    label: 'Chatham-tijd',
    value: 'Pacific/Chatham',
    offset: 13.75
  },
  {
    label: 'Apia-tijd',
    value: 'Pacific/Apia',
    offset: 14
  },
  {
    label: 'Line-eilandse tijd',
    value: 'Pacific/Kiritimati',
    offset: 14
  }
]
