<template>
  <div class="container container--sm mb-5">
    <div v-if="$breakpoint.xsOnly && event.isCanceled" class="event-details__canceled row">
      {{ $t('events.details.canceled') }}
    </div>
    <div v-else-if="$breakpoint.xsOnly && showIsFull" class="event-details__full row">
      {{ $t('events.general.full') }}
    </div>

    <page-header
      :title="event.name"
      :back-button="{ route: 'Home', label: $t('events.overview.title') }"
      :actions="actions"
      :image="event.event_photo_large"
    >
      <template slot="meta" v-if="$breakpoint.smAndUp">
        <div class="event-details__meta-list">
          <div class="event-details__meta-item" v-if="team">
            <icon :icon="['fal', 'users']" size="lg" class="mr-2 event-details__meta-icon" />
            {{ team }}
          </div>
          <div class="event-details__meta-item">
            <icon
              :icon="['fal', 'calendar-edit']"
              size="lg"
              class="mr-2 event-details__meta-icon mt-2 mt-sm-0"
            />
            {{ event.created_by.first_name }}
            <em v-if="event.created_by.nickname">"{{ event.created_by.nickname }}" </em>
            {{ event.created_by.last_name }}
          </div>
        </div>
      </template>
    </page-header>
    <div class="row">
      <div class="col-lg-7">
        <page-section>
          <div class="event-details__header" v-if="$breakpoint.smAndUp && event.event_photo_large">
            <img :alt="event.title" :src="event.event_photo_large" />
          </div>
          <div v-if="$breakpoint.smAndUp && event.isCanceled" class="event-details__canceled">
            {{ $t('events.details.canceled') }}
          </div>
          <div v-else-if="$breakpoint.smAndUp && showIsFull" class="event-details__full">
            {{ $t('events.general.full') }}
          </div>
          <page-subsection v-if="event.hasPassed || event.isCanceled">
            <history-event-decision :decision="decision" />
          </page-subsection>
          <page-subsection v-else :title="$t('events.details.decision_title')">
            <b-form @submit.prevent="onSubmit">
              <div class="mb-4 event-details__decision-buttons">
                <button
                  class="event-details__decision-btn btn btn-sm mr-3"
                  :class="decision === DECISION_PRESENT ? 'btn-success' : 'btn-outline-gray'"
                  @click="setDecision(DECISION_PRESENT)"
                  :disabled="showIsFull"
                  :title="showIsFull ? $t('events.general.full') : ''"
                  type="button"
                >
                  <icon icon="check" class="btn__icon btn__icon--left" />{{
                    $t('events.general.decision_present')
                  }}
                </button>
                <button
                  class="event-details__decision-btn btn btn-sm mr-3"
                  :class="decision === DECISION_MAYBE ? 'btn-warning' : 'btn-outline-gray'"
                  type="button"
                  @click="setDecision(DECISION_MAYBE)"
                >
                  <icon icon="question" class="btn__icon btn__icon--left" />{{
                    $t('events.general.decision_maybe')
                  }}
                </button>
                <button
                  class="event-details__decision-btn btn btn-sm"
                  :class="decision === DECISION_NOT_PRESENT ? 'btn-danger' : 'btn-outline-gray'"
                  type="button"
                  @click="setDecision(DECISION_NOT_PRESENT)"
                >
                  <icon icon="times" class="btn__icon btn__icon--left" />{{
                    $t('events.general.decision_not_present')
                  }}
                </button>
              </div>
              <b-form-group
                :label="$t('general.fields.comment')"
                label-for="inputComment"
                label-class="form-label"
              >
                <b-form-textarea
                  id="inputComment"
                  :placeholder="randomExcuse"
                  rows="3"
                  v-model="comment"
                />
              </b-form-group>
              <button
                class="btn btn-primary ml-auto btn-inline-sm"
                type="submit"
                :disabled="loading || !isCommentDirty"
              >
                {{ $t('general.buttons.save') }}
              </button>
            </b-form>
          </page-subsection>
          <page-subsection :title="$t('events.details.date_title')">
            <h5 class="text-muted" v-if="event.hasDifferentTimeZone">{{ eventTimeZoneLabel }}</h5>
            <div class="row mb-3 mb-sm-0">
              <div class="col-sm-6">
                <h6>{{ $t('events.general.start') }}</h6>
                <span class="d-block">{{
                  event.startDateTime.format($t('general.date_format.long'))
                }}</span>
                <span class="d-block">{{
                  event.startDateTime.format($t('general.time_format.long'))
                }}</span>
                <span class="d-block text-muted" v-if="event.presentTime">{{
                  $t('events.general.present', { time: event.presentTime })
                }}</span>
              </div>
              <div class="col-sm-6 mt-3 mt-sm-0" v-if="event.endDateTime">
                <h6>{{ $t('events.general.end') }}</h6>
                <span class="d-block">{{
                  event.endDateTime.format($t('general.date_format.long'))
                }}</span>
                <span class="d-block">{{
                  event.endDateTime.format($t('general.time_format.long'))
                }}</span>
              </div>
            </div>

            <template v-if="event.hasDifferentTimeZone">
              <h5 class="text-muted mt-4">{{ userTimeZoneLabel }}</h5>
              <div class="row mb-3 mb-sm-0">
                <div class="col-sm-6">
                  <h6>{{ $t('events.general.start') }}</h6>

                  <span class="d-block">{{
                    event.userStartDateTime.format($t('general.date_format.long'))
                  }}</span>
                  <span class="d-block">{{
                    event.userStartDateTime.format($t('general.time_format.long'))
                  }}</span>
                  <span class="d-block text-muted" v-if="event.presentTime">{{
                    $t('events.general.present', { time: event.presentTime })
                  }}</span>
                </div>
                <div class="col-sm-6 mt-3 mt-sm-0" v-if="event.userEndDateTime">
                  <h6>{{ $t('events.general.end') }}</h6>
                  <span class="d-block">{{
                    event.userEndDateTime.format($t('general.date_format.long'))
                  }}</span>
                  <span class="d-block">{{
                    event.userEndDateTime.format($t('general.time_format.long'))
                  }}</span>
                </div>
              </div>
            </template>
          </page-subsection>
          <page-subsection :title="$t('events.details.details_title')">
            <div class="event-details__meta-list mb-3" v-if="$breakpoint.xsOnly">
              <div class="event-details__meta-item" v-if="team">
                <icon :icon="['fal', 'users']" size="lg" class="mr-2 event-details__meta-icon" />
                {{ team }}
              </div>
              <div class="event-details__meta-item">
                <icon
                  :icon="['fal', 'calendar-edit']"
                  size="lg"
                  class="mr-2 event-details__meta-icon mt-2 mt-sm-0"
                />
                {{ event.created_by.first_name }}
                <em v-if="event.created_by.nickname">"{{ event.created_by.nickname }}" </em>
                {{ event.created_by.last_name }}
              </div>
            </div>
            <p class="m-0 white-space-preline" v-html="description" v-if="description" />
            <p class="text-muted m-0" v-else>{{ $t('events.details.no_description') }}</p>
          </page-subsection>
          <page-subsection
            v-if="event.location.name"
            fluid
            :title="$t('events.details.location_title')"
            :actions="locationActions"
            content-class="p-0"
          >
            <p v-text="event.location.name" v-if="!event.location.gps" />
            <LocationPreview v-else class="event-details__map-container" v-bind="event.location" />
          </page-subsection>
        </page-section>
      </div>
      <div class="col-lg-5 mt-sm-5 mt-lg-0">
        <page-section>
          <page-subsection :title="$t('events.details.turn_out.title')">
            <p v-if="event.maxAttendees > 0" class="event-details__max-attendees">
              {{ $t('events.details.max', { count: event.maxAttendees }) }}
            </p>
            <invitee-list
              :eventId="event.id"
              :invitees="event.invitees"
              :hasPassed="event.hasPassed"
              :canEditDecisions="event.canEditDecisions"
              :isCanceled="event.isCanceled"
              :isFull="event.isFull"
            />
          </page-subsection>
        </page-section>
      </div>
    </div>
    <router-view :event="event"></router-view>
  </div>
</template>

<script>
import HistoryEventDecision from '@/components/HistoryEventDecision'
import InviteeList from '@/components/InviteeList'
import LocationPreview from '@/components/LocationPreview'
import PageHeader from '@/components/PageHeader'
import PageSection from '@/components/PageSection'
import PageSubsection from '@/components/PageSubsection'
import loadEventMixin from '@/mixins/loadEventMixin'
import {
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT,
  DECISION_UNKNOWN
} from '@/utils/constants'
import linkify from 'linkifyjs/html'

export default {
  name: 'EventDetails',
  mixins: [loadEventMixin],
  components: {
    HistoryEventDecision,
    InviteeList,
    LocationPreview,
    PageHeader,
    PageSection,
    PageSubsection
  },
  data() {
    const eventId = parseInt(this.$route.params.eventId)
    const event = this.$store.getters.getEventById(eventId)
    const { comment } = event.userInvitee

    const directionsAction = {
      label: this.$i18n.t('events.details.get_directions'),
      icon: 'location-arrow',
      onPress: this.onRoutePress
    }

    const copyAdressAction = {
      label: this.$i18n.t('events.details.copy_address'),
      icon: 'copy',
      onPress: this.onCopyAddressPress
    }

    // does the browser have support for copying to clipboard and is an address available?
    const canCopy = !!navigator.clipboard && event.location.address
    const hasAddress = event.location.address
    let locationActions = []

    if (canCopy) {
      locationActions = [directionsAction, copyAdressAction]
    } else if (hasAddress) {
      locationActions = [directionsAction]
    }

    return {
      comment,
      initialComment: comment,
      loading: false,
      isCommentDirty: false,
      locationActions: locationActions,
      DECISION_PRESENT,
      DECISION_NOT_PRESENT,
      DECISION_MAYBE,
      DECISION_UNKNOWN,
      mapOptions: {
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControlOptions: { position: 7 }, // Top left
        zoomControlOptions: { position: 7 } // Top right
      }
    }
  },
  computed: {
    decision() {
      const eventId = parseInt(this.$route.params.eventId)
      const event = this.$store.getters.getEventById(eventId)

      return event.userInvitee.decision
    },
    description() {
      return linkify(this.event.description.replace(/(<([^>]+)>)/gi, ''))
    },
    showIsFull() {
      return this.event.isFull && this.decision !== DECISION_PRESENT
    },
    team() {
      if (!this.event.team_id) return null

      const team = this.$store.getters.getTeamById(this.event.team_id)
      // sometimes teams haven't been loaded yet
      return team ? team.name : ''
    },
    eventTimeZoneLabel() {
      return this.event.timeZone.label
    },
    userTimeZoneLabel() {
      return this.$store.getters.userTimeZone.label
    },
    randomExcuse() {
      const isAttending = this.decision === DECISION_PRESENT
      const { locale } = this.$i18n
      const options = isAttending
        ? locale === 'nl'
          ? [
              'leuk, zin in!',
              'ik neem chips mee',
              'wie rijden er?',
              'dat ik dit nog mag meemaken!',
              'bedankt voor de uitnodiging',
              'komt je zusje ook?',
              'hieperdepieperdepiep',
              'moet ik een kado kopen?',
              'is aanhang welkom?',
              'dit wordt geweldig!',
              'wie is Debbie?'
            ]
          : [
              'cool, gonna be awesome!',
              "I'll bring popcorn",
              "who's driving?",
              "can't believe I live to see this",
              'thanks for the invite',
              'is your sister coming?',
              'this is going be awesome',
              "are +1's invited?",
              'do I need to bring something?'
            ]
        : locale === 'nl'
        ? [
            'dagje weg met schoonfamilie',
            'ik wil wel maar heb dan geen zin',
            'mijn moeder heeft de auto nodig',
            'mijn egel ligt in het ziekenhuis',
            'ik heb even tijd voor mezelf nodig',
            'ik moet dan mijn kamer opruimen',
            'heb beloofd om het gras te maaien',
            "ben geblesseerd aan m'n elleboog"
          ]
        : [
            'out with the in-laws',
            "looking after my sister's canary",
            'my mum says she needs the car',
            "I can't... stuck on Donkey Kong",
            'Just need some me-time right now',
            "it's my cactus's birthday",
            "I'm on disherwasher duty",
            "promised mum I'd clean up my room"
          ]

      const randomNumber = Math.round(Math.random() * (options.length - 1))

      return this.$i18n.t('general.for_example') + ' ' + options[randomNumber]
    },
    actions() {
      const actions = []
      if (this.event.canInvite) {
        actions.push({
          icon: 'user-plus',
          to: { name: 'EventInvite' },
          label: this.$i18n.t('events.details.invite_label')
        })
      }

      if (this.event.canEdit) {
        actions.push({
          icon: 'pencil',
          to: { name: 'EventEdit' },
          label: this.$i18n.t('events.details.edit_label')
        })
      }

      if (this.event.canLeave) {
        actions.push({
          icon: 'sign-out',
          to: { name: 'EventLeave' },
          label: this.$i18n.t('events.details.leave_label')
        })
      }

      if (this.event.canCancel || this.event.canDelete) {
        actions.push({
          icon: 'trash-alt',
          to: { name: 'EventDelete' },
          label: this.$i18n.t('events.general.delete_label'),
          isDestructive: true
        })
      }
      return actions
    }
  },
  watch: {
    event(event) {
      if (!event) {
        this.$router.replace({ name: 'Home' })
      }
    },
    comment(newValue) {
      this.isCommentDirty = newValue !== this.initialComment
    }
  },
  methods: {
    setDecision(decision) {
      this.loading = true
      this.$store
        .dispatch('setDecision', {
          eventId: this.event.id,
          decision,
          comment: this.comment
        })
        .finally(() => {
          this.loading = false
          this.initialComment = this.comment
          this.isCommentDirty = false
        })
    },
    onSubmit() {
      this.setDecision(this.decision)
    },
    onRoutePress() {
      const { address, name } = this.event.location
      const destination = address || name
      const url = `https://www.google.com/maps/dir/?api=1&destination=${destination}`

      window.open(url)
    },
    onAddToCalendarPress() {
      this.$router.replace({ name: 'AddToCalendar' })
    },
    onCopyAddressPress() {
      const address = `${this.event.location.name} ${this.event.location.address}`
      navigator.clipboard.writeText(address).then(() => {
        this.$store.dispatch('addNotification', {
          title: this.$i18n.t('events.details.copy_title'),
          text: this.$i18n.t('events.details.copy_success')
        })
      })
    }
  }
}
</script>

<style lang="scss">
.event-details {
  &__canceled {
    color: $white;
    font-weight: bold;
    background-color: $red;
    padding: 0.75rem 1.25rem;
  }
  &__full {
    color: $white;
    font-weight: bold;
    background-color: $red;
    padding: 0.75rem 1.25rem;
  }
  &__header {
    padding-bottom: percentage(9/16);
    height: 0;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__meta-list {
    font-size: $font-size-sm;
    color: $gray-600;
    @include sm-up {
      display: flex;
      margin-bottom: -1rem;
    }
  }

  &__decision-buttons {
    display: flex;
  }

  &__decision-btn {
    @include xs-only {
      margin: 0;
      padding: 4px 10px !important;
    }
  }

  &__meta-icon {
    @include xs-only {
      width: 1.25rem !important;
    }
  }

  &__meta-item {
    margin-right: 2rem;
  }

  &__map-container {
    @include sm-up {
      margin: 0 -1.25rem -1.25rem -1.25rem;
    }
  }
  &__max-attendees {
    color: $gray-500;
  }
}
</style>
