<template>
  <div class="invitee-list">
    <div class="invitee-list__section" v-for="(section, index) in sections" :key="index">
      <h5 v-text="section.title" />
      <ul class="invitee-list__user-list">
        <li
          class="invitee-list__item mt-3"
          v-for="invitee in section.invitees"
          :key="invitee.user.id"
        >
          <div class="invitee-list__invitee">
            <user-avatar
              class="invitee-list__avatar mr-2"
              :user="invitee.user"
              :size="40"
              allow-full-screen
            ></user-avatar>
            <div
              class="invitee-list__icon-container mr-2"
              :class="'invitee-list__icon-container--' + suffix(invitee.decision)"
            >
              <icon :icon="icon(invitee.decision)" class="invitee-list__icon" />
            </div>
            <div
              :class="{ 'invitee-list__text--expandable': canDecideForUser(invitee.user.id) }"
              class="invitee-list__text"
              @click="expandInvitee(invitee.user.id)"
            >
              <div class="invitee-list__name">
                {{ invitee.user.first_name }}
                <em v-if="invitee.user.nickname">"{{ invitee.user.nickname }}" </em
                >{{ invitee.user.last_name }}
              </div>
              <div class="invitee-list__comment text-muted" v-if="invitee.comment">
                "{{ invitee.comment }}"
              </div>
            </div>
          </div>
          <div
            class="invitee-list__actions"
            :class="{ 'invitee-list__actions--expanded': expandedInvitee === invitee.user.id }"
          >
            <button
              class="btn btn-xs btn-outline-primary mr-2"
              v-if="invitee.decision !== DECISION_PRESENT"
              :disabled="isFull"
              :title="isFull ? $t('events.general.full') : ''"
              @click="setDecision(invitee.user.id, DECISION_PRESENT)"
            >
              {{ $t('events.general.decision_present') }}
            </button>
            <button
              class="btn btn-xs btn-outline-warning mr-2"
              v-if="invitee.decision !== DECISION_MAYBE"
              @click="setDecision(invitee.user.id, DECISION_MAYBE)"
            >
              {{ $t('events.general.decision_maybe') }}
            </button>
            <button
              class="btn btn-xs btn-outline-secondary"
              v-if="invitee.decision !== DECISION_NOT_PRESENT"
              @click="setDecision(invitee.user.id, DECISION_NOT_PRESENT)"
            >
              {{ $t('events.general.decision_not_present') }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'

import {
  DECISION_PRESENT,
  DECISION_NOT_PRESENT,
  DECISION_MAYBE,
  DECISION_UNKNOWN
} from '@/utils/constants'

export default {
  name: 'InviteeList',
  data() {
    return {
      DECISION_PRESENT,
      DECISION_NOT_PRESENT,
      DECISION_MAYBE,
      DECISION_UNKNOWN,
      expandedInvitee: null
    }
  },
  props: {
    eventId: {
      type: Number,
      required: true
    },
    invitees: {
      type: Array,
      required: true
    },
    hasPassed: {
      type: Boolean,
      required: false,
      default: false
    },
    isCanceled: {
      type: Boolean,
      required: true
    },
    canEditDecisions: {
      type: Boolean,
      required: true,
      default: false
    },
    isFull: {
      type: Boolean,
      required: true
    }
  },
  components: {
    UserAvatar
  },
  methods: {
    canDecideForUser(userId) {
      const isCurrentUser = this.$store.getters.userId === userId
      const isInTheFuture = !this.hasPassed
      const isCanceled = this.isCanceled

      return this.canEditDecisions || (isCurrentUser && isInTheFuture && !isCanceled)
    },
    expandInvitee(userId) {
      if (this.canDecideForUser(userId)) {
        this.expandedInvitee = this.expandedInvitee === userId ? null : userId
      }
    },
    setDecision(userId, newDecision) {
      this.$store
        .dispatch('setDecision', {
          eventId: this.eventId,
          userId,
          decision: newDecision,
          comment: ''
        })
        .then(() => {
          this.expandInvitee(userId)
        })
    },
    icon(decision) {
      return {
        [DECISION_PRESENT]: 'check',
        [DECISION_NOT_PRESENT]: 'times',
        [DECISION_MAYBE]: 'question',
        [DECISION_UNKNOWN]: 'ellipsis-h'
      }[decision]
    },
    suffix(decision) {
      return {
        [DECISION_PRESENT]: 'present',
        [DECISION_NOT_PRESENT]: 'not-present',
        [DECISION_MAYBE]: 'maybe',
        [DECISION_UNKNOWN]: 'unknown'
      }[decision]
    }
  },
  computed: {
    sections() {
      const invitees = this.invitees
      const present = invitees.filter((x) => x.decision === DECISION_PRESENT)
      const maybe = invitees.filter((x) => x.decision === DECISION_MAYBE)
      const not_present = invitees.filter((x) => x.decision === DECISION_NOT_PRESENT)
      const unknown = invitees.filter((x) => x.decision === DECISION_UNKNOWN)

      const sections = [
        {
          title: this.hasPassed
            ? this.$i18n.tc('events.details.turn_out.present_history', present.length, {
                count: present.length
              })
            : this.$i18n.t('events.details.turn_out.present', {
                count: present.length
              }),
          invitees: present
        },
        {
          title: this.hasPassed
            ? this.$i18n.tc('events.details.turn_out.maybe_history', maybe.length, {
                count: maybe.length
              })
            : this.$i18n.t('events.details.turn_out.maybe', {
                count: maybe.length
              }),
          invitees: maybe
        },
        {
          title: this.hasPassed
            ? this.$i18n.tc('events.details.turn_out.not_present_history', not_present.length, {
                count: not_present.length
              })
            : this.$i18n.t('events.details.turn_out.not_present', {
                count: not_present.length
              }),
          invitees: not_present
        },
        {
          title: this.hasPassed
            ? this.$i18n.tc('events.details.turn_out.unknown_history', unknown.length, {
                count: unknown.length
              })
            : this.$i18n.tc('events.details.turn_out.unknown', unknown.length, {
                count: unknown.length
              }),
          invitees: unknown
        }
      ]

      return sections.filter((section) => section.invitees.length > 0)
    }
  }
}
</script>

<style lang="scss">
.invitee-list {
  &__section {
    & + & {
      margin-top: 30px;
    }
  }

  &__user-list {
    @include list-unstyled;
    margin: 0;

    @include media-breakpoint-only(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__invitee {
    display: flex;
    align-items: center;
  }

  &__item {
    @include media-breakpoint-only(md) {
      width: 50%;
    }
  }

  &__text {
    flex: 1;
    &--expandable {
      cursor: pointer;
    }
  }

  &__actions {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.2s, opacity 0.2s;
    padding-left: 75px;

    &--expanded {
      opacity: 1;
      height: 36px;
    }
  }

  &__name {
    font-size: $font-size-sm;
    line-height: 20px;
  }

  &__comment {
    font-size: 11px;
  }

  &__icon-container {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    text-align: center;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--present {
      background-color: $green;
    }

    &--not-present {
      background-color: $red;
    }

    &--maybe {
      background-color: $yellow;
    }

    &--unknown {
      background-color: $gray-400;
    }
  }

  &__icon {
    color: $white;
  }
}
</style>
