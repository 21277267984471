<template>
  <div
    class="wizard pt-0"
    :class="{ 'wizard--with-progress': showProgressBar, 'wizard--with-hero': hasHero }"
  >
    <router-link :to="{ name: 'Home' }">
      <img class="wizard__logo" src="@/assets/logo-inverted.svg" width="120" height="69" />
    </router-link>
    <transition name="fade">
      <button class="wizard__close" @click="onCloseClick" v-if="hasCloseHandler">
        <icon :icon="['fal', 'times']" />
      </button>
    </transition>
    <div class="container">
      <div class="wizard__center" :class="{ 'wizard__center--wide': wide }">
        <h1 class="h2 wizard__title" v-if="title">{{ title }}</h1>
        <div class="wizard__dialog">
          <div class="wizard__progress" v-if="showProgressBar">
            <div
              class="wizard__progress-bar bg-red"
              role="progressbar"
              :style="{ transform: `scaleX(${progress / 100})` }"
              :aria-valuenow="progress"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Wizard',
  props: {
    title: {
      type: String,
      required: false
    },
    progress: {
      type: Number,
      required: false
    },
    hasHero: {
      type: Boolean,
      required: false,
      default: false
    },
    wide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showProgressBar() {
      return typeof this.progress === 'number'
    },
    hasCloseHandler() {
      return this.$listeners && this.$listeners.close
    }
  },
  methods: {
    onCloseClick() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.wizard {
  background: $white;
  flex: 1;

  @include xs-only {
    &--success {
      background: $primary;
      color: $white;

      .wizard__title {
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 30px;
      }

      .wizard__close {
        color: $white;
      }
    }

    &--with-hero {
      .wizard__title,
      .wizard__close {
        color: $white;
      }
    }
  }

  @include sm-up {
    padding: 20px 0;
    min-height: 100vh;
    align-items: center;
    display: flex;
    background: linear-gradient($green, $green-light);
  }

  &__screen {
    will-change: height, opacity;

    @include xs-only {
      &--modal-visible {
        position: relative;
        z-index: 1;
      }

      &--has-actions {
        padding-bottom: 85px;
      }
    }
  }

  &__screen--gateway {
    @include sm-up {
      text-align: center;
    }

    .lead {
      font-size: 20px;
      line-height: 1.25;
      font-weight: $font-weight-normal;
      margin-bottom: 20px;

      @include sm-up {
        margin-bottom: 40px;
        font-size: 24px;
      }
    }
  }

  &__progress {
    background-color: #f9f6f6;
    height: 15px;
    display: flex;

    @include xs-fluid;

    @include sm-up {
      border-radius: 4px 4px 0 0;
      overflow: hidden;
    }

    &-bar {
      width: 100%;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s;
      will-change: transform;
      background-color: $grimson;
    }
  }

  &__close {
    @include button-unstyled;
    position: absolute;
    top: 1rem;
    right: 1rem;
    line-height: 1em;
    font-size: 2rem;
    color: $gray-700;
    z-index: 1;

    .wizard--with-progress & {
      top: 2rem;
    }

    @include sm-up {
      position: absolute;
      top: 2rem;
      right: 2rem;
      font-size: 3rem;
      color: $white;
    }

    @include xxl-up {
      top: 3rem;
      right: 3rem;
      font-size: 4rem;
    }
  }

  &__logo {
    display: none;

    @include sm-up {
      display: block;
      position: absolute;
      top: 2rem;
      left: 3rem;
    }

    @include xxl-up {
      width: 150px;
      height: auto;
      top: 2.5rem;
      left: 3rem;
    }
  }

  &__title {
    @include xs-only {
      font-size: 1.5rem;
      position: absolute;
      top: 1rem;
      left: 1rem;
    }

    @include sm-up {
      text-align: center;
      color: $white;
      margin-bottom: 1.5rem;
    }

    @include xxl-up {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }

  &__subtitle {
    border-bottom: 1px solid $gray-300;

    @include xs-only {
      margin: 0 -15px;
      padding: 1rem 15px 1rem 15px;
    }

    @include sm-up {
      text-align: center;
      margin-bottom: 0;
      padding: 1.5rem 15px;
    }

    @include xxl-up {
      padding: 2.5rem 15px;
    }
  }

  &__section {
    padding: 20px 0px;

    & + & {
      border-top: 1px solid $gray-300;
    }

    @include sm-up {
      padding: 30px 20px;
    }

    @include xxl-up {
      padding: 40px 30px;
    }
  }

  &__footer {
    padding: 15px;
    @include sm-up {
      padding: 20px;
    }

    @include xxl-up {
      padding: 30px;
    }

    @include xs-only {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      background-color: $white;
      box-shadow: 0 -1px 7px rgba(0, 0, 0, 0.03), 0 -1px 5px rgba(0, 0, 0, 0.07);
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dialog {
    @include sm-up {
      position: relative;
      background-color: $white;
      border-radius: 4px;
      @include box-shadow-dark-5;
    }
  }

  &__center {
    @include sm-up {
      max-width: 576px;
      margin: auto;

      &--wide {
        max-width: 800px;
      }
    }
  }
}
</style>
