<template>
  <div id="app" class="app loader" v-if="isLoading">
    <icon :icon="['fal', 'spinner-third']" class="spinner" />
  </div>
  <div id="app" class="app" v-else :lang="$i18n.locale">
    <div v-if="headerType !== 'none' && !isWebView" class="app__header">
      <app-header v-if="$breakpoint.mdAndUp" />
      <mobile-header v-else />
    </div>
    <div class="app__content">
      <router-view />
    </div>
    <div class="app__footer" v-if="footerType !== 'none' && !isWebView">
      <app-footer />
    </div>
    <app-promo v-if="shouldShowPromo" @destroy="renderAppPromo = false" />
    <cookie-bar v-if="renderCookieBar && !isWebView" @destroy="renderCookieBar = false" />
    <notifications />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'
import AppPromo from '@/components/AppPromo'
import CookieBar from '@/components/CookieBar'
import MobileHeader from '@/components/MobileHeader'
import Notifications from '@/components/Notifications'
import { getMetaForRoute } from '@/utils/router'
import Cookies from 'js-cookie'

export default {
  name: 'App',
  data() {
    return {
      renderAppPromo: true,
      renderCookieBar: true,
      renderWarning: true,
      isChangingLanguage: false
    }
  },
  created() {
    this.$store.dispatch('loadUser')

    this.maybeInitWebView()
  },
  components: {
    AppPromo,
    CookieBar,
    AppHeader,
    AppFooter,
    MobileHeader,
    Notifications
  },
  methods: {
    maybeInitWebView() {
      const { webview, ...otherQuery } = this.$route.query

      if (webview !== 'true') return

      this.$store.dispatch('setOptions', { webview: true })
      this.$router.replace({ query: otherQuery })
    }
  },
  computed: {
    shouldShowPromo() {
      return (
        // Only show the app promo if all these conditions are met:
        // We're one a page that requires logging in (e.g. not homepage, faq, etc.)
        this.$route.meta.requiresAuth &&
        // We're not already showing the cookie bar
        !this.renderCookieBar &&
        // We didn't already close the bar
        this.renderAppPromo &&
        // We're not inside the app already
        !this.isWebView &&
        // User is on mobile
        this.$breakpoint.xsOnly
      )
    },
    isWebView() {
      return this.$store.getters.getOption('webview')
    },
    isLoading() {
      return this.$store.getters.isAuthLoading || this.isChangingLanguage
    },
    headerType() {
      return getMetaForRoute('headerType', this.$route)
    },
    footerType() {
      return getMetaForRoute('footerType', this.$route)
    }
  },
  watch: {
    '$i18n.locale': function (newLanguage) {
      this.isChangingLanguage = true
      Cookies.set('lang', newLanguage)

      if (this.$store.getters.isAuthenticated) {
        this.$store.dispatch('setLanguage', newLanguage)
      }

      location.reload()
    }
  }
}
</script>

<style lang="scss">
@import 'styles/main';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $gray-100;

  &__header,
  &__footer {
    flex-shrink: 0;
    flex-shrink: 0;
  }

  &__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
