<template>
  <wizard @close="$router.push({ name: 'Home' })">
    <div class="wizard__section text-center py-5">
      <div v-if="error">
        <h2 class="h3 my-3">{{ $t('errors.general_title') }}</h2>
        <p class="text-muted" v-html="error" />
      </div>
      <div v-else-if="invitation">
        <icon v-if="isFull" icon="times-circle" class="icon icon--error" />
        <icon v-else icon="check-circle" class="icon" />

        <h2 class="h3 my-3">{{ resultTitle }}</h2>

        <p class="text-lg">{{ resultText }}</p>
        <user-avatar-row
          v-if="attendingInvitees.length"
          class="mt-5 justify-content-center"
          :users="attendingInvitees"
          :maxAvatars="$breakpoint.lgAndUp ? 10 : 5"
          :avatarSize="50"
          :avatarSizeLg="45"
        />
      </div>

      <spinner size="50px" v-else class="spinner" />
    </div>

    <div class="wizard__section text-center py-5" v-if="invitation">
      <p class="text-muted cta" v-text="$t('events.confirm_invitation.sign_up.lead')" />
      <router-link
        :to="{ name: 'Login', query: { email: this.invitation.invitee.user.email } }"
        class="btn btn-primary"
        >{{ $t('events.confirm_invitation.sign_up.cta') }}</router-link
      >
    </div>
  </wizard>
</template>

<script>
import Spinner from '@/components/Spinner'
import UserAvatarRow from '@/components/UserAvatarRow'
import Wizard from '@/components/Wizard'

import {
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT,
  DECISION_UNKNOWN
} from '@/utils/constants'

export default {
  name: 'ConfirmInvitation',
  components: {
    Wizard,
    Spinner,
    UserAvatarRow
  },
  data() {
    return {
      invitation: null,
      error: null,
      loading: false,
      email: null
    }
  },
  async created() {
    const { code } = this.$route.query

    if (code) {
      this.loading = true
      try {
        this.invitation = await this.$store.dispatch('confirmAnonymousEventDecision', {
          code: this.$route.query.code
        })
        this.loading = false
      } catch (error) {
        this.loading = false
        this.error = error.message
      }
    } else {
      this.error = this.$i18n.t('errors.events.anon_confirm_invitation')
    }
  },
  computed: {
    attendingInvitees() {
      return this.invitation.event.invitees
        .filter(
          (invitee) => invitee.decision === DECISION_PRESENT || invitee.decision === DECISION_MAYBE
        )
        .map((invitee) => invitee.user)
    },
    resultTitle() {
      if (this.isFull) {
        return this.$i18n.t('events.confirm_invitation.title_full')
      } else {
        return this.$i18n.t('events.confirm_invitation.title')
      }
    },
    resultText() {
      const decision = this.invitation.invitee.decision
      const inviter = this.invitation.invited_by.first_name

      const i18nPath = {
        [DECISION_PRESENT]: 'events.confirm_invitation.result_present',
        [DECISION_MAYBE]: 'events.confirm_invitation.result_maybe',
        [DECISION_NOT_PRESENT]: 'events.confirm_invitation.result_not_present',
        [DECISION_UNKNOWN]: 'events.confirm_invitation.result_full'
      }[decision]

      return this.$i18n.t(i18nPath, { inviter })
    },
    isFull() {
      const decision = this.invitation.invitee.decision
      return decision === DECISION_UNKNOWN
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  font-size: 6rem;
  color: $primary;
  &--error {
    color: $error;
  }
}
.spinner {
  color: $primary;
}

.cta {
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem;
}
</style>
