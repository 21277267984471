<template>
  <header class="mobile-header" :class="headerClass">
    <div class="mobile-header__inner">
      <div class="mobile-header__left">
        <div class="mobile-header__toggle-nav" @click="isMenuVisible = !isMenuVisible">
          <hamburger :menu-visible="isMenuVisible" />
        </div>
      </div>
      <div class="mobile-header__logo">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/logo-inverted.svg" width="60" height="34" />
        </router-link>
      </div>
      <div class="mobile-header__right">
        <router-link
          :to="{ name: 'Login', params: { logout: true } }"
          class="mobile-header__right-button"
          v-if="$route.name === 'Profile'"
        >
          <icon :icon="['fal', 'sign-out']" class="mobile-header__icon" />
        </router-link>
        <router-link
          :to="{ name: 'Profile' }"
          class="mobile-header__right-button"
          v-else-if="isAuthenticated"
        >
          <icon :icon="['fal', 'user-circle']" class="mobile-header__icon" />
        </router-link>
        <router-link :to="{ name: 'Login' }" class="btn btn-xs btn-inverted" v-else>
          {{ $t('general.header.log_in') }}
        </router-link>
      </div>
    </div>
    <mobile-nav :visible="isMenuVisible" />
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import MobileNav from './MobileNav'
import Hamburger from './Hamburger'
import { getMetaForRoute } from '@/utils/router'
import debounce from 'lodash.debounce'

export default {
  name: 'MobileHeader',
  components: {
    MobileNav,
    Hamburger
  },
  created() {
    this.$router.afterEach(() => {
      this.isMenuVisible = false
    })
  },
  mounted() {
    this.debounced = debounce(this.checkIfHasScrolled, 200, {
      leading: true,
      trailing: true
    })
    window.addEventListener('scroll', this.debounced)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.debounced)
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated'
    }),
    headerClass() {
      return {
        'mobile-header--transparent': this.type === 'transparent',
        'mobile-header--scrolled': this.hasScrolled
      }
    },
    type() {
      return getMetaForRoute('headerType', this.$route)
    }
  },
  methods: {
    checkIfHasScrolled() {
      this.hasScrolled = window.scrollY > 0
    }
  },
  data() {
    return {
      hasScrolled: false,
      isMenuVisible: false
    }
  }
}
</script>

<style lang="scss">
.mobile-header {
  position: relative;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  color: $white;
  height: $mobile-header-height;
  transition: height 0.5s;
  z-index: 2;

  .btn {
    text-transform: uppercase;
  }

  &--transparent {
    position: fixed;

    &:not(.mobile-header--scrolled) .mobile-header__inner {
      background-color: rgba($primary, 0);
    }
  }

  &__inner {
    background-color: $primary;
    transition: background-color 0.3s;
    height: $mobile-header-height;
    padding: 0 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  &__left,
  &__right {
    flex: 1;
  }

  &__right {
    text-align: right;
  }

  &__logo {
    position: relative;
    top: 2px;
  }

  &__right-button {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    left: 2px;
  }

  &__toggle-nav,
  &__right-button {
    &:hover {
      color: $white;
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &__icon {
    display: block;
    font-size: 29px;
  }
}
</style>
