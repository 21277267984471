export function nameToIntials(name) {
  if (name === '') return ''

  if (!name) throw Error('invalid name')

  if (name === ' ') return '?' // edge case when no firstName or lastName

  const parts = name.split(' ')

  if (parts.length === 1 || parts[1] === '') return name[0].toUpperCase()

  return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase()
}

export function capitalizeFirstCharacter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
