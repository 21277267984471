<template>
  <wizard :title="$t('teams.invitation.title')" @close="$router.push({ name: 'Home' })">
    <div class="wizard__section text-center" v-if="team">
      <success-gif size="200" />
      <h2 class="mt-5 mb-3">{{ $t('teams.invitation_success.title') }}</h2>
      <i18n path="teams.invitation_success.text" tag="p">
        <template v-slot:name>
          <span>{{ name }}</span>
        </template>
        <template v-slot:team>
          <strong>{{ team.name }}</strong>
        </template>
      </i18n>
      <div class="mt-5">
        <router-link :to="{ name: 'TeamDetails', params: { teamId: team.id } }" class="btn-link">{{
          $t('general.buttons.close')
        }}</router-link>
      </div>
    </div>
  </wizard>
</template>

<script>
import SuccessGif from '@/components/SuccessGif'
import Wizard from '@/components/Wizard'

export default {
  name: 'TeamInvitationSuccess',
  components: {
    Wizard,
    SuccessGif
  },
  computed: {
    name() {
      return this.$store.getters.profile.first_name
    },
    team() {
      return this.$store.getters.getTeamById(this.$route.query.team_id)
    }
  }
}
</script>
