<template>
  <modal
    v-if="team"
    :title="$t('teams.leave.title')"
    @close="$router.push({ name: 'TeamDetails' })"
    size="sm"
  >
    <div class="modal__stretcher">
      <div>
        <p>{{ $t('teams.leave.text', { team: team.name }) }}</p>
      </div>
      <div class="text-center mt-5">
        <button
          type="submit"
          :disabled="loading"
          class="btn btn-danger btn-block"
          @click="leaveTeam"
        >
          {{ $t('teams.leave.confirm') }}
        </button>
        <router-link :to="{ name: 'TeamDetails' }" class="d-inline-block mt-4">{{
          $t('general.buttons.cancel')
        }}</router-link>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  name: 'TeamLeave',
  data() {
    return {
      loading: false
    }
  },
  props: {
    team: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal
  },
  methods: {
    leaveTeam() {
      this.loading = true
      this.$store
        .dispatch('leaveTeam', { teamId: this.$route.params.teamId })
        .then(() => this.$router.replace({ name: 'TeamOverview' }))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss"></style>
