<template>
  <img
    :src="randomGif"
    class="rounded-circle d-block m-auto success-gif"
    :height="size"
    :width="size"
  />
</template>

<script>
export default {
  name: 'SuccesGif',
  props: {
    size: {
      type: [Number, String],
      default: 250
    }
  },
  data() {
    const gifs = [
      require('@/assets/gifs/success/success1.gif'),
      require('@/assets/gifs/success/success2.gif'),
      require('@/assets/gifs/success/success3.gif'),
      require('@/assets/gifs/success/success4.gif'),
      require('@/assets/gifs/success/success5.gif'),
      require('@/assets/gifs/success/success6.gif'),
      require('@/assets/gifs/success/success7.gif'),
      require('@/assets/gifs/success/success8.gif')
    ]

    // Note: keep gifs under 500Kb and squared, use: https://ezgif.com/crop

    const randomGif = gifs[Math.floor(Math.random() * gifs.length)]

    return {
      randomGif
    }
  }
}
</script>

<style lang="scss">
.success-gif {
  border: 4px solid $white;
}
</style>
