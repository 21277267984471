<template>
  <div class="wizard__screen--gateway">
    <div class="wizard__section">
      <p class="lead">{{ $t('onboarding.intro_gateway.show_intro') }}</p>
      <router-link :to="{ name: 'Intro' }" class="btn btn-secondary">{{
        $t('onboarding.intro_gateway.show_intro_label')
      }}</router-link>
    </div>
    <div class="wizard__section justify">
      <p class="lead">{{ $t('onboarding.intro_gateway.skip_intro') }}</p>
      <router-link :to="{ name: 'Home' }" class="btn btn-secondary">{{
        $t('onboarding.intro_gateway.skip_intro_label')
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntroGateway'
}
</script>
