var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-list-item"},[_c('div',{staticClass:"event-list-item__wrapper",class:{
      'event-list-item__wrapper--highlighted': _vm.active,
      'event-list-item__wrapper--inactive': _vm.event.isCanceled
    }},[_c('div',{staticClass:"event-list-item__date-container"},[_c('span',{staticClass:"event-list-item__day-of-week"},[_vm._v(_vm._s(_vm.event.startDateTime.format('dd')))]),_c('span',{staticClass:"event-list-item__day-of-month"},[_vm._v(_vm._s(_vm.event.startDateTime.format('D')))])]),_c('div',{class:{
        'event-list-item__text-container': true,
        'event-list-item__text-container--inactive': _vm.event.isCanceled
      }},[_c('h3',{staticClass:"event-list-item__title"},[_vm._v(_vm._s(_vm.event.name))]),_c('div',{staticClass:"event-list-item__meta"},[_c('span',[_vm._v(_vm._s(_vm.event.startDateTime.format(_vm.$t('general.time_format.short'))))]),(_vm.event.endDateTime)?_c('span',[_vm._v(" - "+_vm._s(_vm.event.endDateTime.format(_vm.$t('general.time_format.short'))))]):_vm._e(),(_vm.event.hasPassed)?_c('span',[_vm._v(" - "+_vm._s(_vm.event.countAttending)+" "+_vm._s(_vm.$tc('events.overview.has_attended', _vm.event.countAttending)))]):(_vm.event.isCanceled)?_c('span'):(_vm.event.maxAttendees > 0)?_c('span',[_vm._v(" - "+_vm._s(_vm.$t('events.overview.max_count_attendees', { attending: _vm.event.countAttending, max: _vm.event.maxAttendees }))+" ")]):_c('span',[_vm._v(" - "+_vm._s(_vm.event.countAttending)+" "+_vm._s(_vm.$tc('events.overview.is_attending', _vm.event.countAttending)))])])])]),(!_vm.event.isCanceled)?_c('div',{staticClass:"event-list-item__button-container"},[(_vm.event.hasPassed)?_c('div',{class:'event-list-item__history-icon--' + _vm.event.userInvitee.decision},[_c('icon',{attrs:{"icon":_vm.icon}})],1):_c('button',{staticClass:"event-list-item__button",class:'event-list-item__button--' + _vm.event.userInvitee.decision,attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.changeDecision.apply(null, arguments)}}},[_c('icon',{attrs:{"icon":_vm.icon}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }