<template>
  <modal
    v-if="url"
    :title="$t('events.invite.title')"
    @close="$router.replace({ name: 'EventDetails' })"
  >
    <div class="modal__stretcher">
      <div>
        <p v-html="$t('events.invite.intro')" class="m-0"></p>
        <div class="my-5">
          <div
            @click="onCopyClick"
            :class="{ 'cursor-pointer': canCopy }"
            class="alert alert-light d-flex align-items-center justify-content-between"
            role="alert"
            v-if="$breakpoint.smAndUp"
            id="copyBtn"
          >
            <span :class="{ 'text-no-select': canCopy }">{{ url }}</span>
            <icon v-if="canCopy" :icon="['fal', 'copy']" size="lg" />
          </div>
          <b-tooltip
            target="copyBtn"
            :title="$t('events.invite.copy_success')"
            :show.sync="showCopyTooltip"
            triggers=""
            placement="top"
          />
          <div class="d-sm-flex mt-3">
            <button
              class="btn btn-inline-sm btn-primary mr-sm-2"
              id="copyBtn"
              v-if="$breakpoint.xsOnly && canCopy"
              @click="onCopyClick"
            >
              <icon :icon="['fal', 'copy']" class="btn__icon--left" />{{
                $t('events.invite.share.copy')
              }}
            </button>
            <button
              class="btn btn-inline-sm btn-primary mt-2 mt-sm-0 mr-sm-2"
              @click="onEmailClick"
            >
              <icon :icon="['fal', 'at']" class="btn__icon--left" />{{
                $t('events.invite.share.email')
              }}
            </button>
            <button
              class="btn btn-inline-sm btn-primary mt-2 mt-sm-0 mr-sm-2"
              @click="onWhatsAppClick"
            >
              <icon :icon="['fab', 'whatsapp']" class="btn__icon--left" />{{
                $t('events.invite.share.whatsapp')
              }}
            </button>
            <button
              class="btn btn-inline-sm btn-primary mt-2 mt-sm-0"
              @click="onShareClick"
              v-if="canShare"
            >
              <icon :icon="['fal', 'ellipsis-h']" class="btn__icon--left" />{{
                $t('events.invite.share.other')
              }}
            </button>
          </div>
        </div>
        <div class="alert alert-warning" role="alert">{{ $t('events.invite.warning') }}</div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'EventInvite',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showCopyTooltip: false,
      url: '',
      canCopy: !!window.navigator.clipboard,
      canShare: !!window.navigator.share
    }
  },
  created() {
    const eventId = this.$route.params.eventId

    this.$store.dispatch('shareEvent', { eventId }).then(({ url, expires }) => {
      this.url = url
      this.expires = expires
    })
  },
  components: {
    Modal
  },
  computed: {
    date() {
      return this.event.startDateTime.format(this.$i18n.t('general.datetime_format.long'))
    },
    expirationDate() {
      return this.expires.format(this.$i18n.t('general.date_format.short'))
    }
  },
  methods: {
    onShareClick() {
      const i18nParams = {
        event: this.event.name,
        date: this.date,
        expires: this.expirationDate,
        url: this.url
      }

      navigator.share({
        title: this.$i18n.t('events.invite.share.title', i18nParams),
        text: this.$i18n.t('events.invite.share.text', i18nParams)
      })
    },
    onWhatsAppClick() {
      const i18nParams = {
        event: this.event.name,
        date: this.date,
        expires: this.expirationDate,
        url: this.url
      }

      const body = this.$i18n.t('events.invite.share.text', i18nParams)
      window.location.href = `whatsapp://send?text=${encodeURIComponent(body)}`
    },
    onEmailClick() {
      const i18nParams = {
        event: this.event.name,
        date: this.date,
        expires: this.expirationDate,
        url: this.url
      }

      const subject = this.$i18n.t('events.invite.share.subject', i18nParams)
      const body = this.$i18n.t('events.invite.share.text', i18nParams)
      window.location.href = `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`
    },
    onCopyClick() {
      if (!window.navigator.clipboard) return

      navigator.clipboard.writeText(this.url).then(() => {
        this.showCopyTooltip = true

        setTimeout(() => (this.showCopyTooltip = false), 1500)
      })
    }
  }
}
</script>
