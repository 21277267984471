<template>
  <wizard :title="$t('auth.event_invitation.title')" @close="$router.push({ name: 'Home' })">
    <div class="wizard__section text-center" v-if="error">
      <h3>{{ $t('events.invitation.code_error.title') }}</h3>
      <p class="mt-3 mb-0">{{ error }}</p>
    </div>
    <template v-else-if="eventInvitation">
      <div class="wizard__section text-center" v-if="eventInvitation">
        <h4 class="m-0">{{ eventInvitation.event.name }}</h4>
        <p class="mt-2 mb-0">
          {{ $t('auth.event_invitation.choice') }}
          <span v-if="decisionText">{{ decisionText }} </span>
          <decision-icon :decision="invitation.decision" v-if="invitation" class="mr-2" />
          <router-link :to="{ name: 'EventInvitation', query: { code: invitation.code } }">{{
            $t('general.buttons.edit')
          }}</router-link>
        </p>
      </div>
      <div class="wizard__section text-center">
        <div class="row justify-content-center">
          <div class="col-sm-8">
            <div>
              <a v-if="APPLE_ENABLED" class="btn btn-block btn-apple" :href="appleURL"
                ><icon :icon="['fab', 'apple']" class="btn__icon--left" size="lg" />{{
                  $t('auth.event_invitation.confirm_with_apple')
                }}</a
              >
              <a class="btn btn-block btn-google" :href="googleURL"
                ><icon :icon="['fab', 'google']" class="btn__icon--left" size="lg" />{{
                  $t('auth.event_invitation.confirm_with_google')
                }}</a
              >
            </div>
            <span class="my-3 my-sm-5 text-winged">{{ $t('general.or') }}</span>
            <!-- Login form -->

            <b-form class="login-page__form" @submit.prevent="onSubmit" novalidate>
              <div class="form-label-group">
                <b-form-input
                  :class="{ empty: form.name.length === 0 }"
                  v-model="form.name"
                  :placeholder="$t('general.fields.name')"
                  type="text"
                  id="inputFirstName"
                  autofocus=""
                  :state="$v.form.name.$dirty ? !$v.form.name.$invalid : null"
                  autocomplete="first-name"
                  aria-describedby="inputFirstNameFeedback"
                  tabindex="1"
                  :disabled="isLoading"
                />
                <b-form-invalid-feedback id="inputFirstNameFeedback">{{
                  $t('errors.validations.required')
                }}</b-form-invalid-feedback>
                <label for="inputFirstName">{{ $t('general.fields.name') }}</label>
              </div>
              <div class="form-label-group">
                <b-form-input
                  :class="{ empty: form.email.length === 0 }"
                  v-model="form.email"
                  :placeholder="$t('general.fields.email')"
                  type="email"
                  id="inputUsername"
                  :state="$v.form.email.$dirty ? !$v.form.email.$invalid : null"
                  autocomplete="email"
                  autocapitalize="off"
                  tabindex="1"
                  aria-describedby="inputEmailFeedback"
                  :disabled="isLoading"
                />
                <b-form-invalid-feedback id="inputEmailFeedback">{{
                  $t('errors.validations.email')
                }}</b-form-invalid-feedback>
                <label for="inputUsername">{{ $t('general.fields.email') }}</label>
              </div>
              <button class="btn btn-primary btn-block" type="submit" :disabled="isLoading">
                {{ $t('auth.event_invitation.submit_email') }}
              </button>
              <div class="alert alert-warning mt-4 mb-0" role="alert" v-if="error">
                {{ error }}
              </div>
            </b-form>
            <i18n
              path="auth.event_invitation.privacy_disclaimer"
              tag="p"
              class="small text-muted mt-3"
            >
              <template v-slot:link>
                <router-link :to="{ name: 'Privacy' }" target="_blank">{{
                  $t('auth.event_invitation.privacy')
                }}</router-link>
              </template>
            </i18n>
          </div>
        </div>
      </div>
    </template>
  </wizard>
</template>

<script>
import DecisionIcon from '@/components/DecisionIcon'
import Wizard from '@/components/Wizard'
import {
  APPLE_ENABLED,
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT
} from '@/utils/constants'
import { captureException } from '@/utils/errors'
import { getAppleURL, getGoogleURL } from '@/utils/oauth'
import { email, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'EventInvitationAuth',
  components: {
    Wizard,
    DecisionIcon
  },
  data() {
    return {
      APPLE_ENABLED,
      error: null,
      isLoading: false,
      invitation: null,
      form: {
        name: '',
        email: ''
      }
    }
  },
  validations: {
    form: {
      name: { required },
      email: { required, email }
    }
  },
  async mounted() {
    try {
      const invitationString = localStorage.getItem('invitation')

      if (!invitationString) {
        throw new Error('Invitation not found in localstorage')
      }

      this.invitation = JSON.parse(invitationString)
      this.$store.dispatch('loadEventInvitation', { code: this.invitation.code })
    } catch (error) {
      captureException(error)
      this.invitation = null
      this.error = this.$i18n.t('errors.events.load_invitation')
    }
  },
  computed: {
    decisionText() {
      const validDecisions = [DECISION_PRESENT, DECISION_MAYBE, DECISION_NOT_PRESENT]
      if (this.invitation && validDecisions.includes(this.invitation.decision)) {
        const path = {
          [DECISION_PRESENT]: 'events.general.decision_present',
          [DECISION_MAYBE]: 'events.general.decision_maybe',
          [DECISION_NOT_PRESENT]: 'events.general.decision_not_present'
        }[this.invitation.decision]
        return this.$i18n.t(path)
      }

      return null
    },
    appleURL() {
      return getAppleURL(this.$route.query.redirect)
    },
    googleURL() {
      return getGoogleURL(this.$route.query.redirect)
    },
    ...mapGetters({
      eventInvitation: 'eventInvitation'
    })
  },
  methods: {
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.$error) return
      this.error = null

      this.isLoading = true

      try {
        const { name, email } = this.form
        await this.$store.dispatch('anonymouslyRespondToEventInvitation', {
          name,
          email,
          code: this.invitation.code,
          decision: this.invitation.decision
        })

        this.isLoading = false

        this.$router.push({
          name: 'EventInvitationSuccess',
          query: { email }
        })
      } catch (error) {
        this.isLoading = false
        this.error = error.message
      }
    }
  }
}
</script>
