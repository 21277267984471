<template>
  <div class="decision-icon" :class="'decision-icon--' + suffix">
    <icon :icon="icon" class="decision-icon__icon" fixed-width />
  </div>
</template>

<script>
import {
  DECISION_MAYBE,
  DECISION_NOT_PRESENT,
  DECISION_PRESENT,
  DECISION_UNKNOWN
} from '@//utils/constants'

export default {
  name: 'DecisionIcon',
  props: {
    decision: {
      decision: String,
      required: true
    }
  },
  computed: {
    icon() {
      return {
        [DECISION_PRESENT]: 'check',
        [DECISION_NOT_PRESENT]: 'times',
        [DECISION_MAYBE]: 'question',
        [DECISION_UNKNOWN]: 'ellipsis-h'
      }[this.decision]
    },
    suffix() {
      return {
        [DECISION_PRESENT]: 'present',
        [DECISION_NOT_PRESENT]: 'not-present',
        [DECISION_MAYBE]: 'maybe',
        [DECISION_UNKNOWN]: 'unknown'
      }[this.decision]
    }
  }
}
</script>

<style lang="scss">
.decision-icon {
  color: $white;
  height: 1.3em;
  width: 1.3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  vertical-align: middle;

  &__icon {
    font-size: 0.7em;
  }

  &--present {
    background-color: $primary;
  }

  &--maybe {
    background-color: $warning;
  }

  &--not-present {
    background-color: $secondary;
  }

  &--unknown {
    background-color: $gray-500;
  }
}
</style>
