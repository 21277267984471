import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Vue from 'vue'

function _isSentryEnabled() {
  return !!process.env.VUE_APP_SENTRY_DSN
}

export function init() {
  if (!_isSentryEnabled()) return

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: require('../../package.json').version,
    integrations: [new Integrations.Vue({ Vue })]
  })
}

export function setUserContext({ id, email }) {
  if (!_isSentryEnabled()) return

  Sentry.configureScope((scope) => {
    scope.setUser({ id, email })
  })
}
