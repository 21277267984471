import Cookies from 'js-cookie'
import queryString from 'query-string'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const datepickerLocale = {
  nl: {
    days: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    months: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    pickers: ['volgende 7 dagen', 'volgende 30 dagen', 'vorige 7 dagen', 'vorige 30 dagen'],
    placeholder: {
      date: 'Selecteer een datum',
      dateRange: 'Selecteer een datumreeks'
    }
  },
  en: 'en'
}

/* Determine locale
 ----------------
 1. Use html lang attribute if it makes sense, or:
 2. Use ?lang= query parameter, or:
 3. Get previously set lang cookie, or:
 4. Use default (en)
*/
export function determineInitialLocale() {
  const htmlLang = document.documentElement.getAttribute('lang')
  const queryLang = queryString.parse(location.search).lang
  const cookieLang = Cookies.get('lang')
  const availableLocales = ['nl', 'en']

  if (availableLocales.indexOf(htmlLang) !== -1) return htmlLang
  if (availableLocales.indexOf(queryLang) !== -1) return queryLang
  if (availableLocales.indexOf(cookieLang) !== -1) return cookieLang

  return 'en'
}
